'use strict';

var cov_a4qwsbvl = function () {
    var path = '/home/lobo/repos/prospector-app/src/app/shared/js/strings.js',
        hash = 'b5631603d612cde883651a45ad324e89d89dff28',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/prospector-app/src/app/shared/js/strings.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 3,
                    column: 2
                }
            },
            '1': {
                start: {
                    line: 2,
                    column: 4
                },
                end: {
                    line: 2,
                    column: 42
                }
            },
            '2': {
                start: {
                    line: 6,
                    column: 0
                },
                end: {
                    line: 10,
                    column: 2
                }
            },
            '3': {
                start: {
                    line: 7,
                    column: 4
                },
                end: {
                    line: 9,
                    column: 7
                }
            },
            '4': {
                start: {
                    line: 8,
                    column: 8
                },
                end: {
                    line: 8,
                    column: 49
                }
            },
            '5': {
                start: {
                    line: 13,
                    column: 0
                },
                end: {
                    line: 17,
                    column: 2
                }
            },
            '6': {
                start: {
                    line: 14,
                    column: 4
                },
                end: {
                    line: 16,
                    column: 7
                }
            },
            '7': {
                start: {
                    line: 15,
                    column: 8
                },
                end: {
                    line: 15,
                    column: 38
                }
            },
            '8': {
                start: {
                    line: 20,
                    column: 0
                },
                end: {
                    line: 24,
                    column: 2
                }
            },
            '9': {
                start: {
                    line: 21,
                    column: 4
                },
                end: {
                    line: 23,
                    column: 7
                }
            },
            '10': {
                start: {
                    line: 22,
                    column: 8
                },
                end: {
                    line: 22,
                    column: 38
                }
            },
            '11': {
                start: {
                    line: 26,
                    column: 0
                },
                end: {
                    line: 38,
                    column: 2
                }
            },
            '12': {
                start: {
                    line: 27,
                    column: 19
                },
                end: {
                    line: 27,
                    column: 37
                }
            },
            '13': {
                start: {
                    line: 28,
                    column: 16
                },
                end: {
                    line: 28,
                    column: 41
                }
            },
            '14': {
                start: {
                    line: 29,
                    column: 18
                },
                end: {
                    line: 29,
                    column: 60
                }
            },
            '15': {
                start: {
                    line: 31,
                    column: 4
                },
                end: {
                    line: 33,
                    column: 5
                }
            },
            '16': {
                start: {
                    line: 32,
                    column: 8
                },
                end: {
                    line: 32,
                    column: 28
                }
            },
            '17': {
                start: {
                    line: 34,
                    column: 4
                },
                end: {
                    line: 36,
                    column: 5
                }
            },
            '18': {
                start: {
                    line: 35,
                    column: 8
                },
                end: {
                    line: 35,
                    column: 32
                }
            },
            '19': {
                start: {
                    line: 37,
                    column: 4
                },
                end: {
                    line: 37,
                    column: 33
                }
            },
            '20': {
                start: {
                    line: 40,
                    column: 0
                },
                end: {
                    line: 56,
                    column: 2
                }
            },
            '21': {
                start: {
                    line: 41,
                    column: 19
                },
                end: {
                    line: 41,
                    column: 37
                }
            },
            '22': {
                start: {
                    line: 42,
                    column: 16
                },
                end: {
                    line: 42,
                    column: 41
                }
            },
            '23': {
                start: {
                    line: 43,
                    column: 18
                },
                end: {
                    line: 43,
                    column: 60
                }
            },
            '24': {
                start: {
                    line: 44,
                    column: 18
                },
                end: {
                    line: 44,
                    column: 58
                }
            },
            '25': {
                start: {
                    line: 46,
                    column: 4
                },
                end: {
                    line: 48,
                    column: 5
                }
            },
            '26': {
                start: {
                    line: 47,
                    column: 8
                },
                end: {
                    line: 47,
                    column: 28
                }
            },
            '27': {
                start: {
                    line: 49,
                    column: 4
                },
                end: {
                    line: 51,
                    column: 5
                }
            },
            '28': {
                start: {
                    line: 50,
                    column: 8
                },
                end: {
                    line: 50,
                    column: 32
                }
            },
            '29': {
                start: {
                    line: 52,
                    column: 4
                },
                end: {
                    line: 54,
                    column: 5
                }
            },
            '30': {
                start: {
                    line: 53,
                    column: 8
                },
                end: {
                    line: 53,
                    column: 32
                }
            },
            '31': {
                start: {
                    line: 55,
                    column: 4
                },
                end: {
                    line: 55,
                    column: 49
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 24
                    },
                    end: {
                        line: 1,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 36
                    },
                    end: {
                        line: 3,
                        column: 1
                    }
                },
                line: 1
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 6,
                        column: 27
                    },
                    end: {
                        line: 6,
                        column: 28
                    }
                },
                loc: {
                    start: {
                        line: 6,
                        column: 39
                    },
                    end: {
                        line: 10,
                        column: 1
                    }
                },
                line: 6
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 7,
                        column: 38
                    },
                    end: {
                        line: 7,
                        column: 39
                    }
                },
                loc: {
                    start: {
                        line: 7,
                        column: 52
                    },
                    end: {
                        line: 9,
                        column: 5
                    }
                },
                line: 7
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 13,
                        column: 26
                    },
                    end: {
                        line: 13,
                        column: 27
                    }
                },
                loc: {
                    start: {
                        line: 13,
                        column: 38
                    },
                    end: {
                        line: 17,
                        column: 1
                    }
                },
                line: 13
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 14,
                        column: 36
                    },
                    end: {
                        line: 14,
                        column: 37
                    }
                },
                loc: {
                    start: {
                        line: 14,
                        column: 50
                    },
                    end: {
                        line: 16,
                        column: 5
                    }
                },
                line: 14
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 20,
                        column: 32
                    },
                    end: {
                        line: 20,
                        column: 33
                    }
                },
                loc: {
                    start: {
                        line: 20,
                        column: 44
                    },
                    end: {
                        line: 24,
                        column: 1
                    }
                },
                line: 20
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 21,
                        column: 36
                    },
                    end: {
                        line: 21,
                        column: 37
                    }
                },
                loc: {
                    start: {
                        line: 21,
                        column: 50
                    },
                    end: {
                        line: 23,
                        column: 5
                    }
                },
                line: 21
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 26,
                        column: 26
                    },
                    end: {
                        line: 26,
                        column: 27
                    }
                },
                loc: {
                    start: {
                        line: 26,
                        column: 38
                    },
                    end: {
                        line: 38,
                        column: 1
                    }
                },
                line: 26
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 40,
                        column: 28
                    },
                    end: {
                        line: 40,
                        column: 29
                    }
                },
                loc: {
                    start: {
                        line: 40,
                        column: 40
                    },
                    end: {
                        line: 56,
                        column: 1
                    }
                },
                line: 40
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 31,
                        column: 4
                    },
                    end: {
                        line: 33,
                        column: 5
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 31,
                        column: 4
                    },
                    end: {
                        line: 33,
                        column: 5
                    }
                }, {
                    start: {
                        line: 31,
                        column: 4
                    },
                    end: {
                        line: 33,
                        column: 5
                    }
                }],
                line: 31
            },
            '1': {
                loc: {
                    start: {
                        line: 34,
                        column: 4
                    },
                    end: {
                        line: 36,
                        column: 5
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 34,
                        column: 4
                    },
                    end: {
                        line: 36,
                        column: 5
                    }
                }, {
                    start: {
                        line: 34,
                        column: 4
                    },
                    end: {
                        line: 36,
                        column: 5
                    }
                }],
                line: 34
            },
            '2': {
                loc: {
                    start: {
                        line: 46,
                        column: 4
                    },
                    end: {
                        line: 48,
                        column: 5
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 46,
                        column: 4
                    },
                    end: {
                        line: 48,
                        column: 5
                    }
                }, {
                    start: {
                        line: 46,
                        column: 4
                    },
                    end: {
                        line: 48,
                        column: 5
                    }
                }],
                line: 46
            },
            '3': {
                loc: {
                    start: {
                        line: 49,
                        column: 4
                    },
                    end: {
                        line: 51,
                        column: 5
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 49,
                        column: 4
                    },
                    end: {
                        line: 51,
                        column: 5
                    }
                }, {
                    start: {
                        line: 49,
                        column: 4
                    },
                    end: {
                        line: 51,
                        column: 5
                    }
                }],
                line: 49
            },
            '4': {
                loc: {
                    start: {
                        line: 52,
                        column: 4
                    },
                    end: {
                        line: 54,
                        column: 5
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 52,
                        column: 4
                    },
                    end: {
                        line: 54,
                        column: 5
                    }
                }, {
                    start: {
                        line: 52,
                        column: 4
                    },
                    end: {
                        line: 54,
                        column: 5
                    }
                }],
                line: 52
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0,
            '28': 0,
            '29': 0,
            '30': 0,
            '31': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0],
            '2': [0, 0],
            '3': [0, 0],
            '4': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_a4qwsbvl.s[0]++;
String.prototype.trim = function () {
    cov_a4qwsbvl.f[0]++;
    cov_a4qwsbvl.s[1]++;

    return this.replace(/^\s+|\s+$/g, "");
};

// To Camel Case
cov_a4qwsbvl.s[2]++;
String.prototype.toCamel = function () {
    cov_a4qwsbvl.f[1]++;
    cov_a4qwsbvl.s[3]++;

    return this.replace(/(\-[a-z])/g, function ($1) {
        cov_a4qwsbvl.f[2]++;
        cov_a4qwsbvl.s[4]++;

        return $1.toUpperCase().replace('-', '');
    });
};

// To Dashed from Camel Case
cov_a4qwsbvl.s[5]++;
String.prototype.toDash = function () {
    cov_a4qwsbvl.f[3]++;
    cov_a4qwsbvl.s[6]++;

    return this.replace(/([A-Z])/g, function ($1) {
        cov_a4qwsbvl.f[4]++;
        cov_a4qwsbvl.s[7]++;

        return "-" + $1.toLowerCase();
    });
};

// To Underscore from Camel Case
cov_a4qwsbvl.s[8]++;
String.prototype.toUnderscore = function () {
    cov_a4qwsbvl.f[5]++;
    cov_a4qwsbvl.s[9]++;

    return this.replace(/([A-Z])/g, function ($1) {
        cov_a4qwsbvl.f[6]++;
        cov_a4qwsbvl.s[10]++;

        return "_" + $1.toLowerCase();
    });
};

cov_a4qwsbvl.s[11]++;
String.prototype.toHHMM = function () {
    cov_a4qwsbvl.f[7]++;

    var secNum = (cov_a4qwsbvl.s[12]++, parseInt(this, 10)); // don't forget the second param
    var hours = (cov_a4qwsbvl.s[13]++, Math.floor(secNum / 3600));
    var minutes = (cov_a4qwsbvl.s[14]++, Math.floor((secNum - hours * 3600) / 60));

    cov_a4qwsbvl.s[15]++;
    if (hours < 10) {
        cov_a4qwsbvl.b[0][0]++;
        cov_a4qwsbvl.s[16]++;

        hours = "0" + hours;
    } else {
        cov_a4qwsbvl.b[0][1]++;
    }
    cov_a4qwsbvl.s[17]++;
    if (minutes < 10) {
        cov_a4qwsbvl.b[1][0]++;
        cov_a4qwsbvl.s[18]++;

        minutes = "0" + minutes;
    } else {
        cov_a4qwsbvl.b[1][1]++;
    }
    cov_a4qwsbvl.s[19]++;
    return hours + ':' + minutes;
};

cov_a4qwsbvl.s[20]++;
String.prototype.toHHMMSS = function () {
    cov_a4qwsbvl.f[8]++;

    var secNum = (cov_a4qwsbvl.s[21]++, parseInt(this, 10)); // don't forget the second param
    var hours = (cov_a4qwsbvl.s[22]++, Math.floor(secNum / 3600));
    var minutes = (cov_a4qwsbvl.s[23]++, Math.floor((secNum - hours * 3600) / 60));
    var seconds = (cov_a4qwsbvl.s[24]++, secNum - hours * 3600 - minutes * 60);

    cov_a4qwsbvl.s[25]++;
    if (hours < 10) {
        cov_a4qwsbvl.b[2][0]++;
        cov_a4qwsbvl.s[26]++;

        hours = "0" + hours;
    } else {
        cov_a4qwsbvl.b[2][1]++;
    }
    cov_a4qwsbvl.s[27]++;
    if (minutes < 10) {
        cov_a4qwsbvl.b[3][0]++;
        cov_a4qwsbvl.s[28]++;

        minutes = "0" + minutes;
    } else {
        cov_a4qwsbvl.b[3][1]++;
    }
    cov_a4qwsbvl.s[29]++;
    if (seconds < 10) {
        cov_a4qwsbvl.b[4][0]++;
        cov_a4qwsbvl.s[30]++;

        seconds = "0" + seconds;
    } else {
        cov_a4qwsbvl.b[4][1]++;
    }
    cov_a4qwsbvl.s[31]++;
    return hours + ':' + minutes + ':' + seconds;
};