'use strict';

var cov_vg2hlnwaz = function () {
    var path = '/home/lobo/repos/prospector-app/src/app/components/tagDetails/tagDetails.js',
        hash = '080b7f9d3a90fdd439feff328ef9ffc0854547bc',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/prospector-app/src/app/components/tagDetails/tagDetails.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 53
                }
            },
            '1': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 59
                }
            },
            '2': {
                start: {
                    line: 6,
                    column: 8
                },
                end: {
                    line: 6,
                    column: 55
                }
            },
            '3': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 10,
                    column: 35
                }
            },
            '4': {
                start: {
                    line: 11,
                    column: 8
                },
                end: {
                    line: 11,
                    column: 32
                }
            },
            '5': {
                start: {
                    line: 12,
                    column: 8
                },
                end: {
                    line: 12,
                    column: 129
                }
            },
            '6': {
                start: {
                    line: 13,
                    column: 8
                },
                end: {
                    line: 13,
                    column: 65
                }
            },
            '7': {
                start: {
                    line: 17,
                    column: 8
                },
                end: {
                    line: 17,
                    column: 34
                }
            },
            '8': {
                start: {
                    line: 21,
                    column: 8
                },
                end: {
                    line: 27,
                    column: 11
                }
            },
            '9': {
                start: {
                    line: 25,
                    column: 12
                },
                end: {
                    line: 25,
                    column: 45
                }
            },
            '10': {
                start: {
                    line: 26,
                    column: 12
                },
                end: {
                    line: 26,
                    column: 29
                }
            },
            '11': {
                start: {
                    line: 31,
                    column: 8
                },
                end: {
                    line: 34,
                    column: 14
                }
            },
            '12': {
                start: {
                    line: 33,
                    column: 32
                },
                end: {
                    line: 33,
                    column: 64
                }
            },
            '13': {
                start: {
                    line: 38,
                    column: 8
                },
                end: {
                    line: 43,
                    column: 52
                }
            },
            '14': {
                start: {
                    line: 43,
                    column: 23
                },
                end: {
                    line: 43,
                    column: 50
                }
            },
            '15': {
                start: {
                    line: 47,
                    column: 0
                },
                end: {
                    line: 55,
                    column: 3
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 80
                    },
                    end: {
                        line: 7,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 9,
                        column: 4
                    },
                    end: {
                        line: 9,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 14
                    },
                    end: {
                        line: 14,
                        column: 5
                    }
                },
                line: 9
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 16,
                        column: 4
                    },
                    end: {
                        line: 16,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 16,
                        column: 16
                    },
                    end: {
                        line: 18,
                        column: 5
                    }
                },
                line: 16
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 20,
                        column: 4
                    },
                    end: {
                        line: 20,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 20,
                        column: 32
                    },
                    end: {
                        line: 28,
                        column: 5
                    }
                },
                line: 20
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 24,
                        column: 17
                    },
                    end: {
                        line: 24,
                        column: 18
                    }
                },
                loc: {
                    start: {
                        line: 24,
                        column: 23
                    },
                    end: {
                        line: 27,
                        column: 9
                    }
                },
                line: 24
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 30,
                        column: 4
                    },
                    end: {
                        line: 30,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 30,
                        column: 15
                    },
                    end: {
                        line: 35,
                        column: 5
                    }
                },
                line: 30
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 33,
                        column: 16
                    },
                    end: {
                        line: 33,
                        column: 17
                    }
                },
                loc: {
                    start: {
                        line: 33,
                        column: 32
                    },
                    end: {
                        line: 33,
                        column: 64
                    }
                },
                line: 33
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 37,
                        column: 4
                    },
                    end: {
                        line: 37,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 37,
                        column: 20
                    },
                    end: {
                        line: 44,
                        column: 5
                    }
                },
                line: 37
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 43,
                        column: 17
                    },
                    end: {
                        line: 43,
                        column: 18
                    }
                },
                loc: {
                    start: {
                        line: 43,
                        column: 23
                    },
                    end: {
                        line: 43,
                        column: 50
                    }
                },
                line: 43
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var TagDetailsController = function () {
    function TagDetailsController(OrganizationsTable, OrganizationTagsTable, MunicipalitiesTable) {
        _classCallCheck(this, TagDetailsController);

        cov_vg2hlnwaz.f[0]++;
        cov_vg2hlnwaz.s[0]++;

        this.OrganizationsTable = OrganizationsTable;
        cov_vg2hlnwaz.s[1]++;
        this.OrganizationTagsTable = OrganizationTagsTable;
        cov_vg2hlnwaz.s[2]++;
        this.MunicipalitiesTable = MunicipalitiesTable;
    }

    _createClass(TagDetailsController, [{
        key: '$onInit',
        value: function $onInit() {
            cov_vg2hlnwaz.f[1]++;
            cov_vg2hlnwaz.s[3]++;

            this.tag.$load('comments');
            cov_vg2hlnwaz.s[4]++;
            this.tag.$load('files');
            cov_vg2hlnwaz.s[5]++;
            this.organizationTags = this.OrganizationTagsTable.$search({ tag: this.tag.id, ordering: '-organization__profile_level' });
            cov_vg2hlnwaz.s[6]++;
            this.municipalities = this.MunicipalitiesTable.$search();
        }
    }, {
        key: 'clearForm',
        value: function clearForm() {
            cov_vg2hlnwaz.f[2]++;
            cov_vg2hlnwaz.s[7]++;

            this.newOrganization = {};
        }
    }, {
        key: 'updateWithTag',
        value: function updateWithTag(organization) {
            var _this = this;

            cov_vg2hlnwaz.f[3]++;
            cov_vg2hlnwaz.s[8]++;

            this.OrganizationTagsTable.$create({
                tag: this.tag.id,
                organization: organization.id
            }).$then(function () {
                cov_vg2hlnwaz.f[4]++;
                cov_vg2hlnwaz.s[9]++;

                _this.organizationTags.$refresh();
                cov_vg2hlnwaz.s[10]++;
                _this.clearForm();
            });
        }
    }, {
        key: 'quickAdd',
        value: function quickAdd() {
            var _this2 = this;

            cov_vg2hlnwaz.f[5]++;
            cov_vg2hlnwaz.s[11]++;

            this.OrganizationsTable.$create(this.newOrganization).$then(function (organization) {
                cov_vg2hlnwaz.f[6]++;
                cov_vg2hlnwaz.s[12]++;
                return _this2.updateWithTag(organization);
            });
        }
    }, {
        key: 'createComment',
        value: function createComment() {
            var _this3 = this;

            cov_vg2hlnwaz.f[7]++;
            cov_vg2hlnwaz.s[13]++;

            this.tag.$comments.$create({
                objectId: this.tag.id,
                objectType: 'INDUSTRY',
                content: this.newCommentContent
                /* eslint-disable no-return-assign */
            }).$then(function () {
                cov_vg2hlnwaz.f[8]++;
                cov_vg2hlnwaz.s[14]++;
                return _this3.newCommentContent = '';
            });
        }
    }]);

    return TagDetailsController;
}();

cov_vg2hlnwaz.s[15]++;


angular.module('app').component('tagDetails', {
    templateUrl: 'app/components/tagDetails/tagDetails.html',
    controller: TagDetailsController,
    bindings: {
        tag: '<'
    }
});