'use strict';

var cov_j7d3uk0bd = function () {
    var path = '/home/lobo/repos/prospector-app/src/app/components/createUserStoryModal/createUserStoryModal.js',
        hash = 'e5e32580cf7efff2dd8052066b2b956c35615530',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/prospector-app/src/app/components/createUserStoryModal/createUserStoryModal.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 34,
                    column: 12
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 4
                },
                end: {
                    line: 6,
                    column: 71
                }
            },
            '2': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 17,
                    column: 10
                }
            },
            '3': {
                start: {
                    line: 11,
                    column: 12
                },
                end: {
                    line: 16,
                    column: 15
                }
            },
            '4': {
                start: {
                    line: 22,
                    column: 8
                },
                end: {
                    line: 22,
                    column: 56
                }
            },
            '5': {
                start: {
                    line: 24,
                    column: 8
                },
                end: {
                    line: 26,
                    column: 10
                }
            },
            '6': {
                start: {
                    line: 25,
                    column: 12
                },
                end: {
                    line: 25,
                    column: 31
                }
            },
            '7': {
                start: {
                    line: 28,
                    column: 8
                },
                end: {
                    line: 32,
                    column: 10
                }
            },
            '8': {
                start: {
                    line: 29,
                    column: 12
                },
                end: {
                    line: 31,
                    column: 15
                }
            },
            '9': {
                start: {
                    line: 30,
                    column: 16
                },
                end: {
                    line: 30,
                    column: 42
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 1
                    },
                    end: {
                        line: 1,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 13
                    },
                    end: {
                        line: 34,
                        column: 1
                    }
                },
                line: 1
            },
            '1': {
                name: 'NewUserStoryModalService',
                decl: {
                    start: {
                        line: 9,
                        column: 13
                    },
                    end: {
                        line: 9,
                        column: 37
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 49
                    },
                    end: {
                        line: 18,
                        column: 5
                    }
                },
                line: 9
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 10,
                        column: 23
                    },
                    end: {
                        line: 10,
                        column: 24
                    }
                },
                loc: {
                    start: {
                        line: 10,
                        column: 35
                    },
                    end: {
                        line: 17,
                        column: 9
                    }
                },
                line: 10
            },
            '3': {
                name: 'CreateUserStoryModalController',
                decl: {
                    start: {
                        line: 21,
                        column: 13
                    },
                    end: {
                        line: 21,
                        column: 43
                    }
                },
                loc: {
                    start: {
                        line: 21,
                        column: 81
                    },
                    end: {
                        line: 33,
                        column: 5
                    }
                },
                line: 21
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 24,
                        column: 29
                    },
                    end: {
                        line: 24,
                        column: 30
                    }
                },
                loc: {
                    start: {
                        line: 24,
                        column: 41
                    },
                    end: {
                        line: 26,
                        column: 9
                    }
                },
                line: 24
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 28,
                        column: 33
                    },
                    end: {
                        line: 28,
                        column: 34
                    }
                },
                loc: {
                    start: {
                        line: 28,
                        column: 45
                    },
                    end: {
                        line: 32,
                        column: 9
                    }
                },
                line: 28
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 29,
                        column: 46
                    },
                    end: {
                        line: 29,
                        column: 47
                    }
                },
                loc: {
                    start: {
                        line: 29,
                        column: 67
                    },
                    end: {
                        line: 31,
                        column: 13
                    }
                },
                line: 29
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_j7d3uk0bd.s[0]++;
(function () {
    'use strict';

    cov_j7d3uk0bd.f[0]++;
    cov_j7d3uk0bd.s[1]++;
    angular.module('app').service('NewUserStoryModalService', NewUserStoryModalService);

    /* @ngInject */
    function NewUserStoryModalService($mdDialog) {
        cov_j7d3uk0bd.f[1]++;
        cov_j7d3uk0bd.s[2]++;

        this.display = function () {
            cov_j7d3uk0bd.f[2]++;
            cov_j7d3uk0bd.s[3]++;

            return $mdDialog.show({
                templateUrl: 'app/components/createUserStoryModal/createUserStoryModal.html',
                controller: CreateUserStoryModalController,
                clickOutsideToClose: true,
                escapeToClose: true
            });
        };
    }

    /* @ngInject */
    function CreateUserStoryModalController($scope, $mdDialog, UserStoriesTable) {
        cov_j7d3uk0bd.f[3]++;
        cov_j7d3uk0bd.s[4]++;

        $scope.newUserStory = UserStoriesTable.$build();

        cov_j7d3uk0bd.s[5]++;
        $scope.closeDialog = function () {
            cov_j7d3uk0bd.f[4]++;
            cov_j7d3uk0bd.s[6]++;

            $mdDialog.cancel();
        };

        cov_j7d3uk0bd.s[7]++;
        $scope.createUserStory = function () {
            cov_j7d3uk0bd.f[5]++;
            cov_j7d3uk0bd.s[8]++;

            $scope.newUserStory.$save().$then(function (userStory) {
                cov_j7d3uk0bd.f[6]++;
                cov_j7d3uk0bd.s[9]++;

                $mdDialog.hide(userStory);
            });
        };
    }
})(angular);