'use strict';

var cov_1p7u7yxnay = function () {
    var path = '/home/lobo/repos/prospector-app/src/app/components/ordering/ordering.js',
        hash = '1c3e34cf1bc6e4b3872c342baf6d5316e83fba19',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/prospector-app/src/app/components/ordering/ordering.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 37
                }
            },
            '1': {
                start: {
                    line: 9,
                    column: 8
                },
                end: {
                    line: 13,
                    column: 9
                }
            },
            '2': {
                start: {
                    line: 10,
                    column: 12
                },
                end: {
                    line: 10,
                    column: 42
                }
            },
            '3': {
                start: {
                    line: 12,
                    column: 12
                },
                end: {
                    line: 12,
                    column: 36
                }
            },
            '4': {
                start: {
                    line: 15,
                    column: 8
                },
                end: {
                    line: 15,
                    column: 58
                }
            },
            '5': {
                start: {
                    line: 20,
                    column: 0
                },
                end: {
                    line: 29,
                    column: 3
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 28
                    },
                    end: {
                        line: 5,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 7,
                        column: 4
                    },
                    end: {
                        line: 7,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 7,
                        column: 13
                    },
                    end: {
                        line: 16,
                        column: 5
                    }
                },
                line: 7
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 9,
                        column: 8
                    },
                    end: {
                        line: 13,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 9,
                        column: 8
                    },
                    end: {
                        line: 13,
                        column: 9
                    }
                }, {
                    start: {
                        line: 9,
                        column: 8
                    },
                    end: {
                        line: 13,
                        column: 9
                    }
                }],
                line: 9
            },
            '1': {
                loc: {
                    start: {
                        line: 9,
                        column: 12
                    },
                    end: {
                        line: 9,
                        column: 65
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 9,
                        column: 12
                    },
                    end: {
                        line: 9,
                        column: 36
                    }
                }, {
                    start: {
                        line: 9,
                        column: 40
                    },
                    end: {
                        line: 9,
                        column: 65
                    }
                }],
                line: 9
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0
        },
        f: {
            '0': 0,
            '1': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var OrderingController = function () {
    function OrderingController($rootScope) {
        _classCallCheck(this, OrderingController);

        cov_1p7u7yxnay.f[0]++;
        cov_1p7u7yxnay.s[0]++;

        this.$rootScope = $rootScope;
    }

    _createClass(OrderingController, [{
        key: 'toogle',
        value: function toogle() {
            cov_1p7u7yxnay.f[1]++;
            cov_1p7u7yxnay.s[1]++;

            // eslint-disable-next-line angular/definedundefined
            if ((cov_1p7u7yxnay.b[1][0]++, this.state === undefined) || (cov_1p7u7yxnay.b[1][1]++, this.state === this.field)) {
                cov_1p7u7yxnay.b[0][0]++;
                cov_1p7u7yxnay.s[2]++;

                this.state = '-' + this.field;
            } else {
                cov_1p7u7yxnay.b[0][1]++;
                cov_1p7u7yxnay.s[3]++;

                this.state = this.field;
            }

            cov_1p7u7yxnay.s[4]++;
            this.$rootScope.$emit('orderChanged', this.state);
        }
    }]);

    return OrderingController;
}();

cov_1p7u7yxnay.s[5]++;


angular.module('app').component('ordering', {
    templateUrl: 'app/components/ordering/ordering.html',
    controller: OrderingController,
    bindings: {
        field: '<',
        label: '<'
    }
});