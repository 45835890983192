'use strict';

var cov_v036q4w3v = function () {
    var path = '/home/lobo/repos/prospector-app/src/app/components/dashboard/dashboard.js',
        hash = '5d157b6d0120a068fa5eebb7a0958a57cd092c51',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/prospector-app/src/app/components/dashboard/dashboard.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 37
                }
            },
            '1': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 55
                }
            },
            '2': {
                start: {
                    line: 7,
                    column: 26
                },
                end: {
                    line: 7,
                    column: 57
                }
            },
            '3': {
                start: {
                    line: 8,
                    column: 24
                },
                end: {
                    line: 8,
                    column: 32
                }
            },
            '4': {
                start: {
                    line: 9,
                    column: 22
                },
                end: {
                    line: 9,
                    column: 76
                }
            },
            '5': {
                start: {
                    line: 10,
                    column: 20
                },
                end: {
                    line: 10,
                    column: 70
                }
            },
            '6': {
                start: {
                    line: 11,
                    column: 8
                },
                end: {
                    line: 17,
                    column: 10
                }
            },
            '7': {
                start: {
                    line: 18,
                    column: 8
                },
                end: {
                    line: 22,
                    column: 10
                }
            },
            '8': {
                start: {
                    line: 23,
                    column: 8
                },
                end: {
                    line: 26,
                    column: 10
                }
            },
            '9': {
                start: {
                    line: 27,
                    column: 8
                },
                end: {
                    line: 27,
                    column: 27
                }
            },
            '10': {
                start: {
                    line: 31,
                    column: 8
                },
                end: {
                    line: 31,
                    column: 24
                }
            },
            '11': {
                start: {
                    line: 35,
                    column: 22
                },
                end: {
                    line: 35,
                    column: 47
                }
            },
            '12': {
                start: {
                    line: 36,
                    column: 20
                },
                end: {
                    line: 36,
                    column: 43
                }
            },
            '13': {
                start: {
                    line: 38,
                    column: 25
                },
                end: {
                    line: 38,
                    column: 69
                }
            },
            '14': {
                start: {
                    line: 39,
                    column: 25
                },
                end: {
                    line: 39,
                    column: 65
                }
            },
            '15': {
                start: {
                    line: 40,
                    column: 8
                },
                end: {
                    line: 40,
                    column: 92
                }
            },
            '16': {
                start: {
                    line: 44,
                    column: 8
                },
                end: {
                    line: 45,
                    column: 64
                }
            },
            '17': {
                start: {
                    line: 45,
                    column: 23
                },
                end: {
                    line: 45,
                    column: 62
                }
            },
            '18': {
                start: {
                    line: 46,
                    column: 8
                },
                end: {
                    line: 47,
                    column: 67
                }
            },
            '19': {
                start: {
                    line: 47,
                    column: 23
                },
                end: {
                    line: 47,
                    column: 65
                }
            },
            '20': {
                start: {
                    line: 48,
                    column: 8
                },
                end: {
                    line: 49,
                    column: 68
                }
            },
            '21': {
                start: {
                    line: 49,
                    column: 23
                },
                end: {
                    line: 49,
                    column: 66
                }
            },
            '22': {
                start: {
                    line: 50,
                    column: 8
                },
                end: {
                    line: 51,
                    column: 67
                }
            },
            '23': {
                start: {
                    line: 51,
                    column: 23
                },
                end: {
                    line: 51,
                    column: 65
                }
            },
            '24': {
                start: {
                    line: 52,
                    column: 8
                },
                end: {
                    line: 53,
                    column: 59
                }
            },
            '25': {
                start: {
                    line: 53,
                    column: 23
                },
                end: {
                    line: 53,
                    column: 57
                }
            },
            '26': {
                start: {
                    line: 54,
                    column: 8
                },
                end: {
                    line: 54,
                    column: 101
                }
            },
            '27': {
                start: {
                    line: 54,
                    column: 93
                },
                end: {
                    line: 54,
                    column: 99
                }
            },
            '28': {
                start: {
                    line: 59,
                    column: 28
                },
                end: {
                    line: 72,
                    column: 9
                }
            },
            '29': {
                start: {
                    line: 73,
                    column: 30
                },
                end: {
                    line: 86,
                    column: 9
                }
            },
            '30': {
                start: {
                    line: 87,
                    column: 8
                },
                end: {
                    line: 87,
                    column: 40
                }
            },
            '31': {
                start: {
                    line: 91,
                    column: 23
                },
                end: {
                    line: 94,
                    column: 10
                }
            },
            '32': {
                start: {
                    line: 92,
                    column: 22
                },
                end: {
                    line: 92,
                    column: 59
                }
            },
            '33': {
                start: {
                    line: 93,
                    column: 12
                },
                end: {
                    line: 93,
                    column: 89
                }
            },
            '34': {
                start: {
                    line: 95,
                    column: 8
                },
                end: {
                    line: 95,
                    column: 36
                }
            },
            '35': {
                start: {
                    line: 96,
                    column: 8
                },
                end: {
                    line: 96,
                    column: 31
                }
            },
            '36': {
                start: {
                    line: 100,
                    column: 23
                },
                end: {
                    line: 103,
                    column: 10
                }
            },
            '37': {
                start: {
                    line: 101,
                    column: 25
                },
                end: {
                    line: 101,
                    column: 62
                }
            },
            '38': {
                start: {
                    line: 102,
                    column: 12
                },
                end: {
                    line: 102,
                    column: 84
                }
            },
            '39': {
                start: {
                    line: 105,
                    column: 27
                },
                end: {
                    line: 105,
                    column: 47
                }
            },
            '40': {
                start: {
                    line: 106,
                    column: 8
                },
                end: {
                    line: 106,
                    column: 39
                }
            },
            '41': {
                start: {
                    line: 107,
                    column: 8
                },
                end: {
                    line: 107,
                    column: 34
                }
            },
            '42': {
                start: {
                    line: 111,
                    column: 8
                },
                end: {
                    line: 116,
                    column: 10
                }
            },
            '43': {
                start: {
                    line: 120,
                    column: 8
                },
                end: {
                    line: 155,
                    column: 10
                }
            },
            '44': {
                start: {
                    line: 134,
                    column: 20
                },
                end: {
                    line: 134,
                    column: 35
                }
            },
            '45': {
                start: {
                    line: 137,
                    column: 20
                },
                end: {
                    line: 137,
                    column: 35
                }
            },
            '46': {
                start: {
                    line: 141,
                    column: 20
                },
                end: {
                    line: 141,
                    column: 37
                }
            },
            '47': {
                start: {
                    line: 144,
                    column: 20
                },
                end: {
                    line: 144,
                    column: 29
                }
            },
            '48': {
                start: {
                    line: 159,
                    column: 8
                },
                end: {
                    line: 190,
                    column: 10
                }
            },
            '49': {
                start: {
                    line: 179,
                    column: 24
                },
                end: {
                    line: 179,
                    column: 33
                }
            },
            '50': {
                start: {
                    line: 186,
                    column: 24
                },
                end: {
                    line: 186,
                    column: 52
                }
            },
            '51': {
                start: {
                    line: 195,
                    column: 0
                },
                end: {
                    line: 200,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 49
                    },
                    end: {
                        line: 28,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 30,
                        column: 4
                    },
                    end: {
                        line: 30,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 30,
                        column: 14
                    },
                    end: {
                        line: 32,
                        column: 5
                    }
                },
                line: 30
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 34,
                        column: 4
                    },
                    end: {
                        line: 34,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 34,
                        column: 26
                    },
                    end: {
                        line: 41,
                        column: 5
                    }
                },
                line: 34
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 43,
                        column: 4
                    },
                    end: {
                        line: 43,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 43,
                        column: 15
                    },
                    end: {
                        line: 55,
                        column: 5
                    }
                },
                line: 43
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 45,
                        column: 12
                    },
                    end: {
                        line: 45,
                        column: 13
                    }
                },
                loc: {
                    start: {
                        line: 45,
                        column: 23
                    },
                    end: {
                        line: 45,
                        column: 62
                    }
                },
                line: 45
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 47,
                        column: 12
                    },
                    end: {
                        line: 47,
                        column: 13
                    }
                },
                loc: {
                    start: {
                        line: 47,
                        column: 23
                    },
                    end: {
                        line: 47,
                        column: 65
                    }
                },
                line: 47
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 49,
                        column: 12
                    },
                    end: {
                        line: 49,
                        column: 13
                    }
                },
                loc: {
                    start: {
                        line: 49,
                        column: 23
                    },
                    end: {
                        line: 49,
                        column: 66
                    }
                },
                line: 49
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 51,
                        column: 12
                    },
                    end: {
                        line: 51,
                        column: 13
                    }
                },
                loc: {
                    start: {
                        line: 51,
                        column: 23
                    },
                    end: {
                        line: 51,
                        column: 65
                    }
                },
                line: 51
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 53,
                        column: 12
                    },
                    end: {
                        line: 53,
                        column: 13
                    }
                },
                loc: {
                    start: {
                        line: 53,
                        column: 23
                    },
                    end: {
                        line: 53,
                        column: 57
                    }
                },
                line: 53
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 54,
                        column: 83
                    },
                    end: {
                        line: 54,
                        column: 84
                    }
                },
                loc: {
                    start: {
                        line: 54,
                        column: 93
                    },
                    end: {
                        line: 54,
                        column: 99
                    }
                },
                line: 54
            },
            '10': {
                name: '(anonymous_10)',
                decl: {
                    start: {
                        line: 57,
                        column: 4
                    },
                    end: {
                        line: 57,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 57,
                        column: 26
                    },
                    end: {
                        line: 88,
                        column: 5
                    }
                },
                line: 57
            },
            '11': {
                name: '(anonymous_11)',
                decl: {
                    start: {
                        line: 90,
                        column: 4
                    },
                    end: {
                        line: 90,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 90,
                        column: 29
                    },
                    end: {
                        line: 97,
                        column: 5
                    }
                },
                line: 90
            },
            '12': {
                name: '(anonymous_12)',
                decl: {
                    start: {
                        line: 91,
                        column: 35
                    },
                    end: {
                        line: 91,
                        column: 36
                    }
                },
                loc: {
                    start: {
                        line: 91,
                        column: 45
                    },
                    end: {
                        line: 94,
                        column: 9
                    }
                },
                line: 91
            },
            '13': {
                name: '(anonymous_13)',
                decl: {
                    start: {
                        line: 99,
                        column: 4
                    },
                    end: {
                        line: 99,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 99,
                        column: 37
                    },
                    end: {
                        line: 108,
                        column: 5
                    }
                },
                line: 99
            },
            '14': {
                name: '(anonymous_14)',
                decl: {
                    start: {
                        line: 100,
                        column: 35
                    },
                    end: {
                        line: 100,
                        column: 36
                    }
                },
                loc: {
                    start: {
                        line: 100,
                        column: 45
                    },
                    end: {
                        line: 103,
                        column: 9
                    }
                },
                line: 100
            },
            '15': {
                name: '(anonymous_15)',
                decl: {
                    start: {
                        line: 110,
                        column: 4
                    },
                    end: {
                        line: 110,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 110,
                        column: 27
                    },
                    end: {
                        line: 117,
                        column: 5
                    }
                },
                line: 110
            },
            '16': {
                name: '(anonymous_16)',
                decl: {
                    start: {
                        line: 119,
                        column: 4
                    },
                    end: {
                        line: 119,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 119,
                        column: 17
                    },
                    end: {
                        line: 156,
                        column: 5
                    }
                },
                line: 119
            },
            '17': {
                name: '(anonymous_17)',
                decl: {
                    start: {
                        line: 133,
                        column: 19
                    },
                    end: {
                        line: 133,
                        column: 20
                    }
                },
                loc: {
                    start: {
                        line: 133,
                        column: 32
                    },
                    end: {
                        line: 135,
                        column: 17
                    }
                },
                line: 133
            },
            '18': {
                name: '(anonymous_18)',
                decl: {
                    start: {
                        line: 136,
                        column: 19
                    },
                    end: {
                        line: 136,
                        column: 20
                    }
                },
                loc: {
                    start: {
                        line: 136,
                        column: 32
                    },
                    end: {
                        line: 138,
                        column: 17
                    }
                },
                line: 136
            },
            '19': {
                name: '(anonymous_19)',
                decl: {
                    start: {
                        line: 140,
                        column: 23
                    },
                    end: {
                        line: 140,
                        column: 24
                    }
                },
                loc: {
                    start: {
                        line: 140,
                        column: 35
                    },
                    end: {
                        line: 142,
                        column: 17
                    }
                },
                line: 140
            },
            '20': {
                name: '(anonymous_20)',
                decl: {
                    start: {
                        line: 143,
                        column: 29
                    },
                    end: {
                        line: 143,
                        column: 30
                    }
                },
                loc: {
                    start: {
                        line: 143,
                        column: 42
                    },
                    end: {
                        line: 145,
                        column: 17
                    }
                },
                line: 143
            },
            '21': {
                name: '(anonymous_21)',
                decl: {
                    start: {
                        line: 158,
                        column: 4
                    },
                    end: {
                        line: 158,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 158,
                        column: 25
                    },
                    end: {
                        line: 191,
                        column: 5
                    }
                },
                line: 158
            },
            '22': {
                name: '(anonymous_22)',
                decl: {
                    start: {
                        line: 178,
                        column: 32
                    },
                    end: {
                        line: 178,
                        column: 33
                    }
                },
                loc: {
                    start: {
                        line: 178,
                        column: 45
                    },
                    end: {
                        line: 180,
                        column: 21
                    }
                },
                line: 178
            },
            '23': {
                name: '(anonymous_23)',
                decl: {
                    start: {
                        line: 185,
                        column: 32
                    },
                    end: {
                        line: 185,
                        column: 33
                    }
                },
                loc: {
                    start: {
                        line: 185,
                        column: 45
                    },
                    end: {
                        line: 187,
                        column: 21
                    }
                },
                line: 185
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0,
            '28': 0,
            '29': 0,
            '30': 0,
            '31': 0,
            '32': 0,
            '33': 0,
            '34': 0,
            '35': 0,
            '36': 0,
            '37': 0,
            '38': 0,
            '39': 0,
            '40': 0,
            '41': 0,
            '42': 0,
            '43': 0,
            '44': 0,
            '45': 0,
            '46': 0,
            '47': 0,
            '48': 0,
            '49': 0,
            '50': 0,
            '51': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var DashboardController = function () {
    function DashboardController(StatsTable, ConfigurationsTable) {
        _classCallCheck(this, DashboardController);

        cov_v036q4w3v.f[0]++;
        cov_v036q4w3v.s[0]++;

        this.StatsTable = StatsTable;
        cov_v036q4w3v.s[1]++;
        this.ConfigurationsTable = ConfigurationsTable;
        /* eslint-disable no-undef */
        var startDate = (cov_v036q4w3v.s[2]++, moment().subtract(12, 'months'));
        var endDate = (cov_v036q4w3v.s[3]++, moment());
        var start = (cov_v036q4w3v.s[4]++, { year: startDate.year(), month: startDate.month() + 2 });
        var end = (cov_v036q4w3v.s[5]++, { year: endDate.year(), month: endDate.month() + 2 });
        cov_v036q4w3v.s[6]++;
        this.filters = {
            organizations: { start: start, end: end },
            meetings: { start: start, end: end },
            proposals: { start: start, end: end },
            invoices: { start: start, end: end },
            contacts: { start: start, end: end }
        };
        cov_v036q4w3v.s[7]++;
        this.data = {
            organizations: [],
            proposals: [],
            invoices: []
        };
        cov_v036q4w3v.s[8]++;
        this.options = {
            organizations: [],
            proposals: []
        };
        cov_v036q4w3v.s[9]++;
        this.multibar = [];
    }

    _createClass(DashboardController, [{
        key: '$onInit',
        value: function $onInit() {
            cov_v036q4w3v.f[1]++;
            cov_v036q4w3v.s[10]++;

            this._refresh();
        }
    }, {
        key: 'prepareFilters',
        value: function prepareFilters(model) {
            cov_v036q4w3v.f[2]++;

            var start = (cov_v036q4w3v.s[11]++, this.filters[model].start);
            var end = (cov_v036q4w3v.s[12]++, this.filters[model].end);
            /* eslint-disable camelcase */
            var date__gt = (cov_v036q4w3v.s[13]++, start.year + '-' + start.month + '-01T00:00');
            var date__lt = (cov_v036q4w3v.s[14]++, end.year + '-' + end.month + '-01T00:00');
            cov_v036q4w3v.s[15]++;
            return { date__gt: date__gt, date__lt: date__lt, type: 'CREATED_' + model.toUpperCase(), page_size: 999 };
        }
    }, {
        key: '_refresh',
        value: function _refresh() {
            var _this = this;

            cov_v036q4w3v.f[3]++;
            cov_v036q4w3v.s[16]++;

            this.StatsTable.$search(this.prepareFilters('organizations')).$then(function (results) {
                cov_v036q4w3v.f[4]++;
                cov_v036q4w3v.s[17]++;
                return _this.setChart('organizations', results);
            });
            cov_v036q4w3v.s[18]++;
            this.StatsTable.$search(this.prepareFilters('contacts')).$then(function (results) {
                cov_v036q4w3v.f[5]++;
                cov_v036q4w3v.s[19]++;
                return _this.setMultibarChart('contacts', results);
            });
            cov_v036q4w3v.s[20]++;
            this.StatsTable.$search(this.prepareFilters('proposals')).$then(function (results) {
                cov_v036q4w3v.f[6]++;
                cov_v036q4w3v.s[21]++;
                return _this.setMultibarChart('proposals', results);
            });
            cov_v036q4w3v.s[22]++;
            this.StatsTable.$search(this.prepareFilters('meetings')).$then(function (results) {
                cov_v036q4w3v.f[7]++;
                cov_v036q4w3v.s[23]++;
                return _this.setMultibarChart('meetings', results);
            });
            cov_v036q4w3v.s[24]++;
            this.StatsTable.$search(this.prepareFilters('invoices')).$then(function (results) {
                cov_v036q4w3v.f[8]++;
                cov_v036q4w3v.s[25]++;
                return _this.setChart('invoices', results);
            });
            cov_v036q4w3v.s[26]++;
            this.lastUpdate = this.ConfigurationsTable.$find('LAST_STAT_UPDATE').$then(function (result) {
                cov_v036q4w3v.f[9]++;
                cov_v036q4w3v.s[27]++;
                return result;
            });
        }
    }, {
        key: 'parseMonth',
        value: function parseMonth(month_int) {
            cov_v036q4w3v.f[10]++;

            /* eslint-disable no-unused-vars */
            var months_full = (cov_v036q4w3v.s[28]++, {
                1: 'January',
                2: 'February',
                3: 'March',
                4: 'April',
                5: 'May',
                6: 'June',
                7: 'July',
                8: 'August',
                9: 'September',
                10: 'October',
                11: 'November',
                12: 'December'
            });
            var months_abbrev = (cov_v036q4w3v.s[29]++, {
                1: 'Jan',
                2: 'Feb',
                3: 'Mar',
                4: 'Apr',
                5: 'May',
                6: 'Jun',
                7: 'Jul',
                8: 'Aug',
                9: 'Sep',
                10: 'Oct',
                11: 'Nov',
                12: 'Dec'
            });
            cov_v036q4w3v.s[30]++;
            return months_abbrev[month_int];
        }
    }, {
        key: 'setChart',
        value: function setChart(model, results) {
            var _this2 = this;

            cov_v036q4w3v.f[11]++;

            var values = (cov_v036q4w3v.s[31]++, results.map(function (result) {
                cov_v036q4w3v.f[12]++;

                var y = (cov_v036q4w3v.s[32]++, result.year.toString(10).substr(2, 2));
                cov_v036q4w3v.s[33]++;
                return { label: _this2.parseMonth(result.month) + ' ' + y, value: result.count };
            }));
            cov_v036q4w3v.s[34]++;
            this.setData(model, values);
            cov_v036q4w3v.s[35]++;
            this.setOptions(model);
        }
    }, {
        key: 'setMultibarChart',
        value: function setMultibarChart(model, results) {
            var _this3 = this;

            cov_v036q4w3v.f[13]++;

            var values = (cov_v036q4w3v.s[36]++, results.map(function (result) {
                cov_v036q4w3v.f[14]++;

                var year = (cov_v036q4w3v.s[37]++, result.year.toString(10).substr(2, 2));
                cov_v036q4w3v.s[38]++;
                return { x: _this3.parseMonth(result.month) + ' ' + year, y: result.count };
            }));

            var collection = (cov_v036q4w3v.s[39]++, { key: model, values: values });
            cov_v036q4w3v.s[40]++;
            this.multibar.push(collection);
            cov_v036q4w3v.s[41]++;
            this.setMultibarOptions();
        }
    }, {
        key: 'setData',
        value: function setData(model, values) {
            cov_v036q4w3v.f[15]++;
            cov_v036q4w3v.s[42]++;

            this.data[model] = [{
                key: "Cumulative Return",
                values: values
            }];
        }
    }, {
        key: 'setOptions',
        value: function setOptions() {
            cov_v036q4w3v.f[16]++;
            cov_v036q4w3v.s[43]++;

            this.options.organizations = {
                chart: {
                    showYAxis: false,
                    type: 'discreteBarChart',
                    width: 1500,
                    height: 300,
                    margin: {
                        top: 20,
                        right: 20,
                        bottom: 50,
                        left: 55
                    },
                    /* eslint-disable babel/object-shorthand */
                    x: function x(d) {
                        cov_v036q4w3v.f[17]++;
                        cov_v036q4w3v.s[44]++;

                        return d.label;
                    },
                    y: function y(d) {
                        cov_v036q4w3v.f[18]++;
                        cov_v036q4w3v.s[45]++;

                        return d.value;
                    },
                    showValues: true,
                    color: function color() {
                        cov_v036q4w3v.f[19]++;
                        cov_v036q4w3v.s[46]++;

                        return '#fc9272';
                    },
                    valueFormat: function valueFormat(d) {
                        cov_v036q4w3v.f[20]++;
                        cov_v036q4w3v.s[47]++;

                        return d;
                    },
                    duration: 500,
                    xAxis: {
                        axisLabel: ''
                    },
                    yAxis: {
                        axisLabel: '',
                        axisLabelDistance: -10
                    }
                }
            };
        }
    }, {
        key: 'setMultibarOptions',
        value: function setMultibarOptions() {
            cov_v036q4w3v.f[21]++;
            cov_v036q4w3v.s[48]++;

            this.multibarOptions = {
                chart: {
                    showYAxis: false,
                    showControls: false,
                    type: 'multiBarChart',
                    width: 1500,
                    height: 300,
                    margin: {
                        top: 20,
                        right: 20,
                        bottom: 50,
                        left: 55
                    },
                    clipEdge: true,
                    duration: 500,
                    stacked: false,
                    xAxis: {
                        axisLabel: '',
                        showMaxMin: false,
                        tickFormat: function tickFormat(d) {
                            cov_v036q4w3v.f[22]++;
                            cov_v036q4w3v.s[49]++;

                            return d;
                        }
                    },
                    yAxis: {
                        axisLabel: '',
                        axisLabelDistance: -20,
                        tickFormat: function tickFormat(d) {
                            cov_v036q4w3v.f[23]++;
                            cov_v036q4w3v.s[50]++;

                            return d3.format(',.1f')(d);
                        }
                    }
                }
            };
        }
    }]);

    return DashboardController;
}();

cov_v036q4w3v.s[51]++;


angular.module('app').component('dashboard', {
    templateUrl: 'app/components/dashboard/dashboard.html',
    controller: DashboardController
});