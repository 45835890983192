'use strict';

var cov_1wshksmqj1 = function () {
    var path = '/home/lobo/repos/prospector-app/src/app/components/remindersList/remindersList.js',
        hash = 'a7e7840fc5f9b618d59992b2fd8f0ddacc66618e',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/prospector-app/src/app/components/remindersList/remindersList.js',
        statementMap: {
            '0': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 68
                }
            },
            '1': {
                start: {
                    line: 9,
                    column: 8
                },
                end: {
                    line: 9,
                    column: 35
                }
            },
            '2': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 10,
                    column: 68
                }
            },
            '3': {
                start: {
                    line: 14,
                    column: 8
                },
                end: {
                    line: 17,
                    column: 11
                }
            },
            '4': {
                start: {
                    line: 21,
                    column: 0
                },
                end: {
                    line: 29,
                    column: 3
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 13
                    },
                    end: {
                        line: 6,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 8,
                        column: 4
                    },
                    end: {
                        line: 8,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 8,
                        column: 16
                    },
                    end: {
                        line: 11,
                        column: 5
                    }
                },
                line: 8
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 13,
                        column: 4
                    },
                    end: {
                        line: 13,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 13,
                        column: 21
                    },
                    end: {
                        line: 18,
                        column: 5
                    }
                },
                line: 13
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var RemindersListController = function () {
    function RemindersListController() {
        _classCallCheck(this, RemindersListController);
    }

    _createClass(RemindersListController, [{
        key: 'search',
        value: function search() {
            cov_1wshksmqj1.f[0]++;
            cov_1wshksmqj1.s[0]++;

            /* eslint-disable camelcase */
            this.reminders.$refresh({ name__icontains: this.searchText });
        }
    }, {
        key: 'display',
        value: function display(to) {
            cov_1wshksmqj1.f[1]++;
            cov_1wshksmqj1.s[1]++;

            this.reminders.$page += to;
            cov_1wshksmqj1.s[2]++;
            this.reminders.$refresh({ name__icontains: this.searchText });
        }
    }, {
        key: 'createReminder',
        value: function createReminder() {
            cov_1wshksmqj1.f[2]++;
            cov_1wshksmqj1.s[3]++;

            this.reminders.$create({
                name: this.newReminder.name,
                remind_at: this.newReminder.remind_at
            });
        }
    }]);

    return RemindersListController;
}();

cov_1wshksmqj1.s[4]++;


angular.module('app').component('remindersList', {
    templateUrl: 'app/components/remindersList/remindersList.html',
    controller: RemindersListController,
    bindings: {
        reminders: '<'
    }
});