'use strict';

var cov_1ebu68u0gl = function () {
    var path = '/home/lobo/repos/prospector-app/src/app/components/createMediaModal/createMediaModal.js',
        hash = '75796e2781a46c25495b8845cbcbca14647e0a3a',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/prospector-app/src/app/components/createMediaModal/createMediaModal.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 45,
                    column: 12
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 4
                },
                end: {
                    line: 6,
                    column: 59
                }
            },
            '2': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 20,
                    column: 10
                }
            },
            '3': {
                start: {
                    line: 11,
                    column: 12
                },
                end: {
                    line: 19,
                    column: 15
                }
            },
            '4': {
                start: {
                    line: 26,
                    column: 8
                },
                end: {
                    line: 26,
                    column: 77
                }
            },
            '5': {
                start: {
                    line: 27,
                    column: 8
                },
                end: {
                    line: 33,
                    column: 9
                }
            },
            '6': {
                start: {
                    line: 28,
                    column: 12
                },
                end: {
                    line: 28,
                    column: 43
                }
            },
            '7': {
                start: {
                    line: 29,
                    column: 12
                },
                end: {
                    line: 29,
                    column: 36
                }
            },
            '8': {
                start: {
                    line: 31,
                    column: 12
                },
                end: {
                    line: 31,
                    column: 72
                }
            },
            '9': {
                start: {
                    line: 32,
                    column: 12
                },
                end: {
                    line: 32,
                    column: 43
                }
            },
            '10': {
                start: {
                    line: 35,
                    column: 8
                },
                end: {
                    line: 37,
                    column: 10
                }
            },
            '11': {
                start: {
                    line: 36,
                    column: 12
                },
                end: {
                    line: 36,
                    column: 31
                }
            },
            '12': {
                start: {
                    line: 39,
                    column: 8
                },
                end: {
                    line: 43,
                    column: 10
                }
            },
            '13': {
                start: {
                    line: 40,
                    column: 12
                },
                end: {
                    line: 42,
                    column: 15
                }
            },
            '14': {
                start: {
                    line: 41,
                    column: 16
                },
                end: {
                    line: 41,
                    column: 38
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 1
                    },
                    end: {
                        line: 1,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 13
                    },
                    end: {
                        line: 45,
                        column: 1
                    }
                },
                line: 1
            },
            '1': {
                name: 'NewMediaModalService',
                decl: {
                    start: {
                        line: 9,
                        column: 13
                    },
                    end: {
                        line: 9,
                        column: 33
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 45
                    },
                    end: {
                        line: 21,
                        column: 5
                    }
                },
                line: 9
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 10,
                        column: 23
                    },
                    end: {
                        line: 10,
                        column: 24
                    }
                },
                loc: {
                    start: {
                        line: 10,
                        column: 40
                    },
                    end: {
                        line: 20,
                        column: 9
                    }
                },
                line: 10
            },
            '3': {
                name: 'CreateMediaModalController',
                decl: {
                    start: {
                        line: 24,
                        column: 13
                    },
                    end: {
                        line: 24,
                        column: 39
                    }
                },
                loc: {
                    start: {
                        line: 24,
                        column: 98
                    },
                    end: {
                        line: 44,
                        column: 5
                    }
                },
                line: 24
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 35,
                        column: 29
                    },
                    end: {
                        line: 35,
                        column: 30
                    }
                },
                loc: {
                    start: {
                        line: 35,
                        column: 41
                    },
                    end: {
                        line: 37,
                        column: 9
                    }
                },
                line: 35
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 39,
                        column: 29
                    },
                    end: {
                        line: 39,
                        column: 30
                    }
                },
                loc: {
                    start: {
                        line: 39,
                        column: 41
                    },
                    end: {
                        line: 43,
                        column: 9
                    }
                },
                line: 39
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 40,
                        column: 42
                    },
                    end: {
                        line: 40,
                        column: 43
                    }
                },
                loc: {
                    start: {
                        line: 40,
                        column: 59
                    },
                    end: {
                        line: 42,
                        column: 13
                    }
                },
                line: 40
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 27,
                        column: 8
                    },
                    end: {
                        line: 33,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 27,
                        column: 8
                    },
                    end: {
                        line: 33,
                        column: 9
                    }
                }, {
                    start: {
                        line: 27,
                        column: 8
                    },
                    end: {
                        line: 33,
                        column: 9
                    }
                }],
                line: 27
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0
        },
        b: {
            '0': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_1ebu68u0gl.s[0]++;
(function () {
    'use strict';

    cov_1ebu68u0gl.f[0]++;
    cov_1ebu68u0gl.s[1]++;
    angular.module('app').service('NewMediaModalService', NewMediaModalService);

    /* @ngInject */
    function NewMediaModalService($mdDialog) {
        cov_1ebu68u0gl.f[1]++;
        cov_1ebu68u0gl.s[2]++;

        this.display = function (media) {
            cov_1ebu68u0gl.f[2]++;
            cov_1ebu68u0gl.s[3]++;

            return $mdDialog.show({
                templateUrl: 'app/components/createMediaModal/createMediaModal.html',
                controller: CreateMediaModalController,
                clickOutsideToClose: true,
                escapeToClose: true,
                locals: {
                    media: media
                }
            });
        };
    }

    /* @ngInject */
    function CreateMediaModalController($scope, $mdDialog, LinksTable, OrganizationsTable, media) {
        cov_1ebu68u0gl.f[3]++;
        cov_1ebu68u0gl.s[4]++;

        /* eslint-disable camelcase */
        $scope.organizations = OrganizationsTable.$search({ page_size: 9999 });
        cov_1ebu68u0gl.s[5]++;
        if (media) {
            cov_1ebu68u0gl.b[0][0]++;
            cov_1ebu68u0gl.s[6]++;

            $scope.action = 'Update Media';
            cov_1ebu68u0gl.s[7]++;
            $scope.newMedia = media;
        } else {
            cov_1ebu68u0gl.b[0][1]++;
            cov_1ebu68u0gl.s[8]++;

            $scope.newMedia = LinksTable.$build({ category: 5, read: 2 });
            cov_1ebu68u0gl.s[9]++;
            $scope.action = 'Create Media';
        }

        cov_1ebu68u0gl.s[10]++;
        $scope.closeDialog = function () {
            cov_1ebu68u0gl.f[4]++;
            cov_1ebu68u0gl.s[11]++;

            $mdDialog.cancel();
        };

        cov_1ebu68u0gl.s[12]++;
        $scope.createMedia = function () {
            cov_1ebu68u0gl.f[5]++;
            cov_1ebu68u0gl.s[13]++;

            $scope.newMedia.$save().$then(function (media) {
                cov_1ebu68u0gl.f[6]++;
                cov_1ebu68u0gl.s[14]++;

                $mdDialog.hide(media);
            });
        };
    }
})(angular);