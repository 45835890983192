'use strict';

var cov_2f4ios1ehc = function () {
    var path = '/home/lobo/repos/prospector-app/src/app/components/individualsList/individualsList.js',
        hash = '19265aa2cc8820d35d8e4165dfebf71206d4210a',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/prospector-app/src/app/components/individualsList/individualsList.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 67
                }
            },
            '1': {
                start: {
                    line: 8,
                    column: 8
                },
                end: {
                    line: 11,
                    column: 11
                }
            },
            '2': {
                start: {
                    line: 9,
                    column: 12
                },
                end: {
                    line: 9,
                    column: 46
                }
            },
            '3': {
                start: {
                    line: 16,
                    column: 0
                },
                end: {
                    line: 24,
                    column: 3
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 43
                    },
                    end: {
                        line: 5,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 7,
                        column: 4
                    },
                    end: {
                        line: 7,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 7,
                        column: 32
                    },
                    end: {
                        line: 12,
                        column: 5
                    }
                },
                line: 7
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 8,
                        column: 54
                    },
                    end: {
                        line: 8,
                        column: 55
                    }
                },
                loc: {
                    start: {
                        line: 8,
                        column: 68
                    },
                    end: {
                        line: 10,
                        column: 9
                    }
                },
                line: 8
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 10,
                        column: 11
                    },
                    end: {
                        line: 10,
                        column: 12
                    }
                },
                loc: {
                    start: {
                        line: 10,
                        column: 23
                    },
                    end: {
                        line: 11,
                        column: 9
                    }
                },
                line: 10
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var IndividualsListController = function () {
    function IndividualsListController(NewIndividualModalService) {
        _classCallCheck(this, IndividualsListController);

        cov_2f4ios1ehc.f[0]++;
        cov_2f4ios1ehc.s[0]++;

        this.NewIndividualModalService = NewIndividualModalService;
    }

    _createClass(IndividualsListController, [{
        key: 'displayNewIndividualModal',
        value: function displayNewIndividualModal() {
            var _this = this;

            cov_2f4ios1ehc.f[1]++;
            cov_2f4ios1ehc.s[1]++;

            this.NewIndividualModalService.display().then(function (individual) {
                cov_2f4ios1ehc.f[2]++;
                cov_2f4ios1ehc.s[2]++;

                _this.individuals.$add(individual);
            }, function () {
                cov_2f4ios1ehc.f[3]++;
            });
        }
    }]);

    return IndividualsListController;
}();

cov_2f4ios1ehc.s[3]++;


angular.module('app').component('individualsList', {
    templateUrl: 'app/components/individualsList/individualsList.html',
    controller: IndividualsListController,
    bindings: {
        individuals: '<'
    }
});