'use strict';

var cov_jzrmr9iza = function () {
    var path = '/home/lobo/repos/prospector-app/src/app/components/filesManager/filesManager.js',
        hash = 'ac9189beed8f5f4a6d697c87b8bf7b665dc5a987',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/prospector-app/src/app/components/filesManager/filesManager.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 8,
                    column: 11
                }
            },
            '1': {
                start: {
                    line: 5,
                    column: 12
                },
                end: {
                    line: 7,
                    column: 13
                }
            },
            '2': {
                start: {
                    line: 6,
                    column: 16
                },
                end: {
                    line: 6,
                    column: 79
                }
            },
            '3': {
                start: {
                    line: 12,
                    column: 8
                },
                end: {
                    line: 17,
                    column: 9
                }
            },
            '4': {
                start: {
                    line: 13,
                    column: 22
                },
                end: {
                    line: 13,
                    column: 61
                }
            },
            '5': {
                start: {
                    line: 14,
                    column: 12
                },
                end: {
                    line: 16,
                    column: 13
                }
            },
            '6': {
                start: {
                    line: 15,
                    column: 16
                },
                end: {
                    line: 15,
                    column: 41
                }
            },
            '7': {
                start: {
                    line: 18,
                    column: 8
                },
                end: {
                    line: 18,
                    column: 18
                }
            },
            '8': {
                start: {
                    line: 22,
                    column: 0
                },
                end: {
                    line: 31,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 24
                    },
                    end: {
                        line: 9,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 4,
                        column: 49
                    },
                    end: {
                        line: 4,
                        column: 50
                    }
                },
                loc: {
                    start: {
                        line: 4,
                        column: 59
                    },
                    end: {
                        line: 8,
                        column: 9
                    }
                },
                line: 4
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 11,
                        column: 4
                    },
                    end: {
                        line: 11,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 11,
                        column: 20
                    },
                    end: {
                        line: 19,
                        column: 5
                    }
                },
                line: 11
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 5,
                        column: 12
                    },
                    end: {
                        line: 7,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 5,
                        column: 12
                    },
                    end: {
                        line: 7,
                        column: 13
                    }
                }, {
                    start: {
                        line: 5,
                        column: 12
                    },
                    end: {
                        line: 7,
                        column: 13
                    }
                }],
                line: 5
            },
            '1': {
                loc: {
                    start: {
                        line: 12,
                        column: 8
                    },
                    end: {
                        line: 17,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 12,
                        column: 8
                    },
                    end: {
                        line: 17,
                        column: 9
                    }
                }, {
                    start: {
                        line: 12,
                        column: 8
                    },
                    end: {
                        line: 17,
                        column: 9
                    }
                }],
                line: 12
            },
            '2': {
                loc: {
                    start: {
                        line: 14,
                        column: 12
                    },
                    end: {
                        line: 16,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 14,
                        column: 12
                    },
                    end: {
                        line: 16,
                        column: 13
                    }
                }, {
                    start: {
                        line: 14,
                        column: 12
                    },
                    end: {
                        line: 16,
                        column: 13
                    }
                }],
                line: 14
            },
            '3': {
                loc: {
                    start: {
                        line: 14,
                        column: 16
                    },
                    end: {
                        line: 14,
                        column: 33
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 14,
                        column: 16
                    },
                    end: {
                        line: 14,
                        column: 17
                    }
                }, {
                    start: {
                        line: 14,
                        column: 21
                    },
                    end: {
                        line: 14,
                        column: 33
                    }
                }],
                line: 14
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0],
            '2': [0, 0],
            '3': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var FilesManagerController = function () {
    function FilesManagerController($scope) {
        var _this = this;

        _classCallCheck(this, FilesManagerController);

        cov_jzrmr9iza.f[0]++;
        cov_jzrmr9iza.s[0]++;

        $scope.$watch('$ctrl.newFileAttachment', function (newVal) {
            cov_jzrmr9iza.f[1]++;
            cov_jzrmr9iza.s[1]++;

            if (angular.isString(newVal)) {
                cov_jzrmr9iza.b[0][0]++;
                cov_jzrmr9iza.s[2]++;

                _this.uploadFile({ path: newVal, name: _this.fileToName(newVal) });
            } else {
                cov_jzrmr9iza.b[0][1]++;
            }
        });
    }

    _createClass(FilesManagerController, [{
        key: 'fileToName',
        value: function fileToName(url) {
            cov_jzrmr9iza.f[2]++;
            cov_jzrmr9iza.s[3]++;

            if (url) {
                cov_jzrmr9iza.b[1][0]++;

                var m = (cov_jzrmr9iza.s[4]++, url.toString().match(/.*\/(.+?)\.(.+)/));
                cov_jzrmr9iza.s[5]++;
                if ((cov_jzrmr9iza.b[3][0]++, m) && (cov_jzrmr9iza.b[3][1]++, m.length > 1)) {
                    cov_jzrmr9iza.b[2][0]++;
                    cov_jzrmr9iza.s[6]++;

                    return m[1] + '.' + m[2];
                } else {
                    cov_jzrmr9iza.b[2][1]++;
                }
            } else {
                cov_jzrmr9iza.b[1][1]++;
            }
            cov_jzrmr9iza.s[7]++;
            return "";
        }
    }]);

    return FilesManagerController;
}();

cov_jzrmr9iza.s[8]++;


angular.module('app').component('filesManager', {
    templateUrl: 'app/components/filesManager/filesManager.html',
    controller: FilesManagerController,
    bindings: {
        collection: '=',
        uploadFile: '&'
    }
});