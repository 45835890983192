'use strict';

var cov_sb3kca1fb = function () {
    var path = '/home/lobo/repos/prospector-app/src/app/components/relatedLinks/relatedLinks.js',
        hash = '064976bf2921293dcd5e0f9aacb529e3d6c6103e',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/prospector-app/src/app/components/relatedLinks/relatedLinks.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 37
                }
            },
            '1': {
                start: {
                    line: 11,
                    column: 8
                },
                end: {
                    line: 15,
                    column: 9
                }
            },
            '2': {
                start: {
                    line: 12,
                    column: 12
                },
                end: {
                    line: 12,
                    column: 41
                }
            },
            '3': {
                start: {
                    line: 14,
                    column: 12
                },
                end: {
                    line: 14,
                    column: 41
                }
            },
            '4': {
                start: {
                    line: 18,
                    column: 8
                },
                end: {
                    line: 18,
                    column: 42
                }
            },
            '5': {
                start: {
                    line: 20,
                    column: 8
                },
                end: {
                    line: 20,
                    column: 42
                }
            },
            '6': {
                start: {
                    line: 21,
                    column: 8
                },
                end: {
                    line: 21,
                    column: 24
                }
            },
            '7': {
                start: {
                    line: 25,
                    column: 21
                },
                end: {
                    line: 25,
                    column: 46
                }
            },
            '8': {
                start: {
                    line: 26,
                    column: 25
                },
                end: {
                    line: 26,
                    column: 42
                }
            },
            '9': {
                start: {
                    line: 27,
                    column: 23
                },
                end: {
                    line: 27,
                    column: 38
                }
            },
            '10': {
                start: {
                    line: 30,
                    column: 8
                },
                end: {
                    line: 32,
                    column: 9
                }
            },
            '11': {
                start: {
                    line: 31,
                    column: 12
                },
                end: {
                    line: 31,
                    column: 71
                }
            },
            '12': {
                start: {
                    line: 33,
                    column: 8
                },
                end: {
                    line: 33,
                    column: 22
                }
            },
            '13': {
                start: {
                    line: 37,
                    column: 8
                },
                end: {
                    line: 41,
                    column: 9
                }
            },
            '14': {
                start: {
                    line: 38,
                    column: 12
                },
                end: {
                    line: 38,
                    column: 46
                }
            },
            '15': {
                start: {
                    line: 40,
                    column: 12
                },
                end: {
                    line: 40,
                    column: 40
                }
            },
            '16': {
                start: {
                    line: 45,
                    column: 8
                },
                end: {
                    line: 45,
                    column: 29
                }
            },
            '17': {
                start: {
                    line: 46,
                    column: 8
                },
                end: {
                    line: 46,
                    column: 36
                }
            },
            '18': {
                start: {
                    line: 47,
                    column: 8
                },
                end: {
                    line: 47,
                    column: 37
                }
            },
            '19': {
                start: {
                    line: 51,
                    column: 8
                },
                end: {
                    line: 55,
                    column: 50
                }
            },
            '20': {
                start: {
                    line: 55,
                    column: 23
                },
                end: {
                    line: 55,
                    column: 48
                }
            },
            '21': {
                start: {
                    line: 59,
                    column: 23
                },
                end: {
                    line: 59,
                    column: 54
                }
            },
            '22': {
                start: {
                    line: 60,
                    column: 8
                },
                end: {
                    line: 60,
                    column: 84
                }
            },
            '23': {
                start: {
                    line: 60,
                    column: 56
                },
                end: {
                    line: 60,
                    column: 82
                }
            },
            '24': {
                start: {
                    line: 64,
                    column: 0
                },
                end: {
                    line: 74,
                    column: 3
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 14
                    },
                    end: {
                        line: 5,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 7,
                        column: 4
                    },
                    end: {
                        line: 7,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 7,
                        column: 25
                    },
                    end: {
                        line: 22,
                        column: 5
                    }
                },
                line: 7
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 24,
                        column: 4
                    },
                    end: {
                        line: 24,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 24,
                        column: 27
                    },
                    end: {
                        line: 34,
                        column: 5
                    }
                },
                line: 24
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 36,
                        column: 4
                    },
                    end: {
                        line: 36,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 36,
                        column: 26
                    },
                    end: {
                        line: 42,
                        column: 5
                    }
                },
                line: 36
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 44,
                        column: 4
                    },
                    end: {
                        line: 44,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 44,
                        column: 25
                    },
                    end: {
                        line: 48,
                        column: 5
                    }
                },
                line: 44
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 50,
                        column: 4
                    },
                    end: {
                        line: 50,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 50,
                        column: 37
                    },
                    end: {
                        line: 56,
                        column: 5
                    }
                },
                line: 50
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 55,
                        column: 17
                    },
                    end: {
                        line: 55,
                        column: 18
                    }
                },
                loc: {
                    start: {
                        line: 55,
                        column: 23
                    },
                    end: {
                        line: 55,
                        column: 48
                    }
                },
                line: 55
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 58,
                        column: 4
                    },
                    end: {
                        line: 58,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 58,
                        column: 32
                    },
                    end: {
                        line: 61,
                        column: 5
                    }
                },
                line: 58
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 60,
                        column: 48
                    },
                    end: {
                        line: 60,
                        column: 49
                    }
                },
                loc: {
                    start: {
                        line: 60,
                        column: 56
                    },
                    end: {
                        line: 60,
                        column: 82
                    }
                },
                line: 60
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 11,
                        column: 8
                    },
                    end: {
                        line: 15,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 11,
                        column: 8
                    },
                    end: {
                        line: 15,
                        column: 9
                    }
                }, {
                    start: {
                        line: 11,
                        column: 8
                    },
                    end: {
                        line: 15,
                        column: 9
                    }
                }],
                line: 11
            },
            '1': {
                loc: {
                    start: {
                        line: 30,
                        column: 8
                    },
                    end: {
                        line: 32,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 30,
                        column: 8
                    },
                    end: {
                        line: 32,
                        column: 9
                    }
                }, {
                    start: {
                        line: 30,
                        column: 8
                    },
                    end: {
                        line: 32,
                        column: 9
                    }
                }],
                line: 30
            },
            '2': {
                loc: {
                    start: {
                        line: 37,
                        column: 8
                    },
                    end: {
                        line: 41,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 37,
                        column: 8
                    },
                    end: {
                        line: 41,
                        column: 9
                    }
                }, {
                    start: {
                        line: 37,
                        column: 8
                    },
                    end: {
                        line: 41,
                        column: 9
                    }
                }],
                line: 37
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0],
            '2': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var RelatedLinksController = function () {
    function RelatedLinksController() {
        _classCallCheck(this, RelatedLinksController);
    }

    _createClass(RelatedLinksController, [{
        key: '$onInit',
        value: function $onInit() {
            cov_sb3kca1fb.f[0]++;
            cov_sb3kca1fb.s[0]++;

            this.links.$load('category');
        }
    }, {
        key: 'extractHostname',
        value: function extractHostname(url) {
            cov_sb3kca1fb.f[1]++;

            var hostname = void 0;
            // find & remove protocol (http, ftp, etc.) and get hostname

            cov_sb3kca1fb.s[1]++;
            if (url.indexOf("://") > -1) {
                cov_sb3kca1fb.b[0][0]++;
                cov_sb3kca1fb.s[2]++;

                hostname = url.split('/')[2];
            } else {
                cov_sb3kca1fb.b[0][1]++;
                cov_sb3kca1fb.s[3]++;

                hostname = url.split('/')[0];
            }

            // find & remove port number
            cov_sb3kca1fb.s[4]++;
            hostname = hostname.split(':')[0];
            // find & remove "?"
            cov_sb3kca1fb.s[5]++;
            hostname = hostname.split('?')[0];
            cov_sb3kca1fb.s[6]++;
            return hostname;
        }
    }, {
        key: 'extractRootDomain',
        value: function extractRootDomain(url) {
            cov_sb3kca1fb.f[2]++;

            var domain = (cov_sb3kca1fb.s[7]++, this.extractHostname(url));
            var splitArr = (cov_sb3kca1fb.s[8]++, domain.split('.'));
            var arrLen = (cov_sb3kca1fb.s[9]++, splitArr.length);

            // extracting the root domain here
            cov_sb3kca1fb.s[10]++;
            if (arrLen > 2) {
                cov_sb3kca1fb.b[1][0]++;
                cov_sb3kca1fb.s[11]++;

                domain = splitArr[arrLen - 2] + '.' + splitArr[arrLen - 1];
            } else {
                cov_sb3kca1fb.b[1][1]++;
            }
            cov_sb3kca1fb.s[12]++;
            return domain;
        }
    }, {
        key: 'setCategoryLink',
        value: function setCategoryLink(data) {
            cov_sb3kca1fb.f[3]++;
            cov_sb3kca1fb.s[13]++;

            if (data.length === 1) {
                cov_sb3kca1fb.b[2][0]++;
                cov_sb3kca1fb.s[14]++;

                this.newLinkCategory = data[0].id;
            } else {
                cov_sb3kca1fb.b[2][1]++;
                cov_sb3kca1fb.s[15]++;

                this.newLinkCategory = null;
            }
        }
    }, {
        key: 'reloadAndclearForm',
        value: function reloadAndclearForm() {
            cov_sb3kca1fb.f[4]++;
            cov_sb3kca1fb.s[16]++;

            this.newLinkUrl = '';
            cov_sb3kca1fb.s[17]++;
            this.newLinkCategory = null;
            cov_sb3kca1fb.s[18]++;
            this.links.$load('category');
        }
    }, {
        key: 'createNewLink',
        value: function createNewLink(category, newLink) {
            var _this = this;

            cov_sb3kca1fb.f[5]++;
            cov_sb3kca1fb.s[19]++;

            this.links.$create({
                organization: this.organization.id,
                category: category,
                link: newLink
            }).$then(function () {
                cov_sb3kca1fb.f[6]++;
                cov_sb3kca1fb.s[20]++;
                return _this.reloadAndclearForm();
            });
        }
    }, {
        key: 'autoSelectCategory',
        value: function autoSelectCategory(newLink) {
            var _this2 = this;

            cov_sb3kca1fb.f[7]++;

            var domain = (cov_sb3kca1fb.s[21]++, this.extractRootDomain(newLink));
            cov_sb3kca1fb.s[22]++;
            this.categories.$search({ domain: domain }).$then(function (data) {
                cov_sb3kca1fb.f[8]++;
                cov_sb3kca1fb.s[23]++;
                return _this2.setCategoryLink(data);
            });
        }
    }]);

    return RelatedLinksController;
}();

cov_sb3kca1fb.s[24]++;


angular.module('app').component('relatedLinks', {
    templateUrl: 'app/components/relatedLinks/relatedLinks.html',
    controller: RelatedLinksController,
    bindings: {
        organization: '<',
        links: '<',
        categories: '<'
    }
});