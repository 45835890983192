'use strict';

var cov_22rpb0klu5 = function () {
    var path = '/home/lobo/repos/prospector-app/src/app/components/mediaList/mediaList.js',
        hash = 'cc2e7fc6d9245f515cd11da7bbf8b36b737e3b91',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/prospector-app/src/app/components/mediaList/mediaList.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 57
                }
            },
            '1': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 8,
                    column: 10
                }
            },
            '2': {
                start: {
                    line: 9,
                    column: 8
                },
                end: {
                    line: 9,
                    column: 28
                }
            },
            '3': {
                start: {
                    line: 13,
                    column: 8
                },
                end: {
                    line: 16,
                    column: 10
                }
            },
            '4': {
                start: {
                    line: 17,
                    column: 8
                },
                end: {
                    line: 17,
                    column: 24
                }
            },
            '5': {
                start: {
                    line: 21,
                    column: 8
                },
                end: {
                    line: 21,
                    column: 43
                }
            },
            '6': {
                start: {
                    line: 25,
                    column: 8
                },
                end: {
                    line: 29,
                    column: 9
                }
            },
            '7': {
                start: {
                    line: 26,
                    column: 12
                },
                end: {
                    line: 26,
                    column: 27
                }
            },
            '8': {
                start: {
                    line: 28,
                    column: 12
                },
                end: {
                    line: 28,
                    column: 25
                }
            },
            '9': {
                start: {
                    line: 30,
                    column: 8
                },
                end: {
                    line: 30,
                    column: 30
                }
            },
            '10': {
                start: {
                    line: 34,
                    column: 23
                },
                end: {
                    line: 38,
                    column: 9
                }
            },
            '11': {
                start: {
                    line: 39,
                    column: 8
                },
                end: {
                    line: 39,
                    column: 43
                }
            },
            '12': {
                start: {
                    line: 43,
                    column: 8
                },
                end: {
                    line: 45,
                    column: 9
                }
            },
            '13': {
                start: {
                    line: 44,
                    column: 12
                },
                end: {
                    line: 44,
                    column: 36
                }
            },
            '14': {
                start: {
                    line: 47,
                    column: 8
                },
                end: {
                    line: 51,
                    column: 9
                }
            },
            '15': {
                start: {
                    line: 48,
                    column: 12
                },
                end: {
                    line: 48,
                    column: 40
                }
            },
            '16': {
                start: {
                    line: 50,
                    column: 12
                },
                end: {
                    line: 50,
                    column: 41
                }
            },
            '17': {
                start: {
                    line: 53,
                    column: 8
                },
                end: {
                    line: 53,
                    column: 24
                }
            },
            '18': {
                start: {
                    line: 57,
                    column: 8
                },
                end: {
                    line: 60,
                    column: 11
                }
            },
            '19': {
                start: {
                    line: 58,
                    column: 12
                },
                end: {
                    line: 58,
                    column: 28
                }
            },
            '20': {
                start: {
                    line: 64,
                    column: 0
                },
                end: {
                    line: 72,
                    column: 3
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 38
                    },
                    end: {
                        line: 10,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 12,
                        column: 4
                    },
                    end: {
                        line: 12,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 12,
                        column: 14
                    },
                    end: {
                        line: 18,
                        column: 5
                    }
                },
                line: 12
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 20,
                        column: 4
                    },
                    end: {
                        line: 20,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 20,
                        column: 15
                    },
                    end: {
                        line: 22,
                        column: 5
                    }
                },
                line: 20
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 24,
                        column: 4
                    },
                    end: {
                        line: 24,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 24,
                        column: 22
                    },
                    end: {
                        line: 31,
                        column: 5
                    }
                },
                line: 24
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 33,
                        column: 4
                    },
                    end: {
                        line: 33,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 33,
                        column: 20
                    },
                    end: {
                        line: 40,
                        column: 5
                    }
                },
                line: 33
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 42,
                        column: 4
                    },
                    end: {
                        line: 42,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 42,
                        column: 33
                    },
                    end: {
                        line: 54,
                        column: 5
                    }
                },
                line: 42
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 56,
                        column: 4
                    },
                    end: {
                        line: 56,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 56,
                        column: 32
                    },
                    end: {
                        line: 61,
                        column: 5
                    }
                },
                line: 56
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 57,
                        column: 54
                    },
                    end: {
                        line: 57,
                        column: 55
                    }
                },
                loc: {
                    start: {
                        line: 57,
                        column: 60
                    },
                    end: {
                        line: 59,
                        column: 9
                    }
                },
                line: 57
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 59,
                        column: 11
                    },
                    end: {
                        line: 59,
                        column: 12
                    }
                },
                loc: {
                    start: {
                        line: 59,
                        column: 23
                    },
                    end: {
                        line: 60,
                        column: 9
                    }
                },
                line: 59
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 25,
                        column: 8
                    },
                    end: {
                        line: 29,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 25,
                        column: 8
                    },
                    end: {
                        line: 29,
                        column: 9
                    }
                }, {
                    start: {
                        line: 25,
                        column: 8
                    },
                    end: {
                        line: 29,
                        column: 9
                    }
                }],
                line: 25
            },
            '1': {
                loc: {
                    start: {
                        line: 43,
                        column: 8
                    },
                    end: {
                        line: 45,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 43,
                        column: 8
                    },
                    end: {
                        line: 45,
                        column: 9
                    }
                }, {
                    start: {
                        line: 43,
                        column: 8
                    },
                    end: {
                        line: 45,
                        column: 9
                    }
                }],
                line: 43
            },
            '2': {
                loc: {
                    start: {
                        line: 47,
                        column: 8
                    },
                    end: {
                        line: 51,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 47,
                        column: 8
                    },
                    end: {
                        line: 51,
                        column: 9
                    }
                }, {
                    start: {
                        line: 47,
                        column: 8
                    },
                    end: {
                        line: 51,
                        column: 9
                    }
                }],
                line: 47
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0],
            '2': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var MediaListController = function () {
    function MediaListController(NewMediaModalService) {
        _classCallCheck(this, MediaListController);

        cov_22rpb0klu5.f[0]++;
        cov_22rpb0klu5.s[0]++;

        this.NewMediaModalService = NewMediaModalService;
        cov_22rpb0klu5.s[1]++;
        this.filters = {
            category: 5,
            read: 2
        };
        cov_22rpb0klu5.s[2]++;
        this.readFilter = 2;
    }

    _createClass(MediaListController, [{
        key: '$onInit',
        value: function $onInit() {
            cov_22rpb0klu5.f[1]++;
            cov_22rpb0klu5.s[3]++;

            this.medias.$params = {
                /* eslint-disable camelcase */
                page_size: 25
            };
            cov_22rpb0klu5.s[4]++;
            this._refresh();
        }
    }, {
        key: '_refresh',
        value: function _refresh() {
            cov_22rpb0klu5.f[2]++;
            cov_22rpb0klu5.s[5]++;

            this.medias.$refresh(this.filters);
        }
    }, {
        key: 'toogleRead',
        value: function toogleRead(media) {
            cov_22rpb0klu5.f[3]++;
            cov_22rpb0klu5.s[6]++;

            if (media.read === 2) {
                cov_22rpb0klu5.b[0][0]++;
                cov_22rpb0klu5.s[7]++;

                media.read = 0;
            } else {
                cov_22rpb0klu5.b[0][1]++;
                cov_22rpb0klu5.s[8]++;

                media.read++;
            }
            cov_22rpb0klu5.s[9]++;
            media.$save(['read']);
        }
    }, {
        key: 'getStyle',
        value: function getStyle(media) {
            cov_22rpb0klu5.f[4]++;

            var colors = (cov_22rpb0klu5.s[10]++, {
                0: '#eee',
                1: 'green',
                2: '#f7d342'
            });
            cov_22rpb0klu5.s[11]++;
            return { color: colors[media.read] };
        }
    }, {
        key: 'changeFilters',
        value: function changeFilters(filter, value) {
            cov_22rpb0klu5.f[5]++;
            cov_22rpb0klu5.s[12]++;

            if (angular.isArray(value)) {
                cov_22rpb0klu5.b[1][0]++;
                cov_22rpb0klu5.s[13]++;

                value = value.join(',');
            } else {
                cov_22rpb0klu5.b[1][1]++;
            }

            cov_22rpb0klu5.s[14]++;
            if (value === null) {
                cov_22rpb0klu5.b[2][0]++;
                cov_22rpb0klu5.s[15]++;

                delete this.filters[filter];
            } else {
                cov_22rpb0klu5.b[2][1]++;
                cov_22rpb0klu5.s[16]++;

                this.filters[filter] = value;
            }

            cov_22rpb0klu5.s[17]++;
            this._refresh();
        }
    }, {
        key: 'displayNewMediaModal',
        value: function displayNewMediaModal(media) {
            var _this = this;

            cov_22rpb0klu5.f[6]++;
            cov_22rpb0klu5.s[18]++;

            this.NewMediaModalService.display(media).then(function () {
                cov_22rpb0klu5.f[7]++;
                cov_22rpb0klu5.s[19]++;

                _this._refresh();
            }, function () {
                cov_22rpb0klu5.f[8]++;
            });
        }
    }]);

    return MediaListController;
}();

cov_22rpb0klu5.s[20]++;


angular.module('app').component('mediaList', {
    templateUrl: 'app/components/mediaList/mediaList.html',
    controller: MediaListController,
    bindings: {
        medias: '<'
    }
});