'use strict';

var cov_k62wb9q4g = function () {
    var path = '/home/lobo/repos/prospector-app/src/app/components/proposalDetails/proposalDetails.js',
        hash = '03911717b0c50756b87ce4728c8c4629dd1d372b',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/prospector-app/src/app/components/proposalDetails/proposalDetails.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 57
                }
            },
            '1': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 65
                }
            },
            '2': {
                start: {
                    line: 6,
                    column: 8
                },
                end: {
                    line: 6,
                    column: 53
                }
            },
            '3': {
                start: {
                    line: 7,
                    column: 8
                },
                end: {
                    line: 7,
                    column: 49
                }
            },
            '4': {
                start: {
                    line: 8,
                    column: 8
                },
                end: {
                    line: 8,
                    column: 53
                }
            },
            '5': {
                start: {
                    line: 9,
                    column: 8
                },
                end: {
                    line: 9,
                    column: 36
                }
            },
            '6': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 10,
                    column: 31
                }
            },
            '7': {
                start: {
                    line: 13,
                    column: 8
                },
                end: {
                    line: 18,
                    column: 11
                }
            },
            '8': {
                start: {
                    line: 15,
                    column: 12
                },
                end: {
                    line: 17,
                    column: 15
                }
            },
            '9': {
                start: {
                    line: 16,
                    column: 16
                },
                end: {
                    line: 16,
                    column: 38
                }
            },
            '10': {
                start: {
                    line: 19,
                    column: 8
                },
                end: {
                    line: 19,
                    column: 62
                }
            },
            '11': {
                start: {
                    line: 20,
                    column: 8
                },
                end: {
                    line: 29,
                    column: 11
                }
            },
            '12': {
                start: {
                    line: 21,
                    column: 12
                },
                end: {
                    line: 28,
                    column: 13
                }
            },
            '13': {
                start: {
                    line: 22,
                    column: 16
                },
                end: {
                    line: 26,
                    column: 29
                }
            },
            '14': {
                start: {
                    line: 27,
                    column: 16
                },
                end: {
                    line: 27,
                    column: 39
                }
            },
            '15': {
                start: {
                    line: 32,
                    column: 8
                },
                end: {
                    line: 41,
                    column: 11
                }
            },
            '16': {
                start: {
                    line: 33,
                    column: 12
                },
                end: {
                    line: 40,
                    column: 13
                }
            },
            '17': {
                start: {
                    line: 34,
                    column: 16
                },
                end: {
                    line: 38,
                    column: 19
                }
            },
            '18': {
                start: {
                    line: 39,
                    column: 16
                },
                end: {
                    line: 39,
                    column: 39
                }
            },
            '19': {
                start: {
                    line: 42,
                    column: 8
                },
                end: {
                    line: 46,
                    column: 11
                }
            },
            '20': {
                start: {
                    line: 43,
                    column: 12
                },
                end: {
                    line: 45,
                    column: 13
                }
            },
            '21': {
                start: {
                    line: 44,
                    column: 16
                },
                end: {
                    line: 44,
                    column: 39
                }
            },
            '22': {
                start: {
                    line: 47,
                    column: 8
                },
                end: {
                    line: 58,
                    column: 10
                }
            },
            '23': {
                start: {
                    line: 49,
                    column: 31
                },
                end: {
                    line: 49,
                    column: 51
                }
            },
            '24': {
                start: {
                    line: 50,
                    column: 32
                },
                end: {
                    line: 50,
                    column: 62
                }
            },
            '25': {
                start: {
                    line: 51,
                    column: 16
                },
                end: {
                    line: 51,
                    column: 39
                }
            },
            '26': {
                start: {
                    line: 53,
                    column: 16
                },
                end: {
                    line: 55,
                    column: 19
                }
            },
            '27': {
                start: {
                    line: 54,
                    column: 20
                },
                end: {
                    line: 54,
                    column: 63
                }
            },
            '28': {
                start: {
                    line: 59,
                    column: 8
                },
                end: {
                    line: 70,
                    column: 10
                }
            },
            '29': {
                start: {
                    line: 61,
                    column: 31
                },
                end: {
                    line: 61,
                    column: 51
                }
            },
            '30': {
                start: {
                    line: 62,
                    column: 32
                },
                end: {
                    line: 62,
                    column: 62
                }
            },
            '31': {
                start: {
                    line: 63,
                    column: 16
                },
                end: {
                    line: 63,
                    column: 39
                }
            },
            '32': {
                start: {
                    line: 65,
                    column: 16
                },
                end: {
                    line: 67,
                    column: 19
                }
            },
            '33': {
                start: {
                    line: 66,
                    column: 20
                },
                end: {
                    line: 66,
                    column: 63
                }
            },
            '34': {
                start: {
                    line: 71,
                    column: 8
                },
                end: {
                    line: 82,
                    column: 10
                }
            },
            '35': {
                start: {
                    line: 73,
                    column: 31
                },
                end: {
                    line: 73,
                    column: 51
                }
            },
            '36': {
                start: {
                    line: 74,
                    column: 30
                },
                end: {
                    line: 74,
                    column: 58
                }
            },
            '37': {
                start: {
                    line: 75,
                    column: 16
                },
                end: {
                    line: 75,
                    column: 37
                }
            },
            '38': {
                start: {
                    line: 77,
                    column: 16
                },
                end: {
                    line: 79,
                    column: 19
                }
            },
            '39': {
                start: {
                    line: 78,
                    column: 20
                },
                end: {
                    line: 78,
                    column: 58
                }
            },
            '40': {
                start: {
                    line: 83,
                    column: 8
                },
                end: {
                    line: 83,
                    column: 43
                }
            },
            '41': {
                start: {
                    line: 87,
                    column: 8
                },
                end: {
                    line: 92,
                    column: 9
                }
            },
            '42': {
                start: {
                    line: 88,
                    column: 22
                },
                end: {
                    line: 88,
                    column: 61
                }
            },
            '43': {
                start: {
                    line: 89,
                    column: 12
                },
                end: {
                    line: 91,
                    column: 13
                }
            },
            '44': {
                start: {
                    line: 90,
                    column: 16
                },
                end: {
                    line: 90,
                    column: 41
                }
            },
            '45': {
                start: {
                    line: 93,
                    column: 8
                },
                end: {
                    line: 93,
                    column: 18
                }
            },
            '46': {
                start: {
                    line: 97,
                    column: 8
                },
                end: {
                    line: 100,
                    column: 11
                }
            },
            '47': {
                start: {
                    line: 104,
                    column: 8
                },
                end: {
                    line: 106,
                    column: 11
                }
            },
            '48': {
                start: {
                    line: 110,
                    column: 8
                },
                end: {
                    line: 112,
                    column: 11
                }
            },
            '49': {
                start: {
                    line: 116,
                    column: 8
                },
                end: {
                    line: 118,
                    column: 11
                }
            },
            '50': {
                start: {
                    line: 122,
                    column: 8
                },
                end: {
                    line: 122,
                    column: 144
                }
            },
            '51': {
                start: {
                    line: 123,
                    column: 8
                },
                end: {
                    line: 125,
                    column: 9
                }
            },
            '52': {
                start: {
                    line: 124,
                    column: 12
                },
                end: {
                    line: 124,
                    column: 63
                }
            },
            '53': {
                start: {
                    line: 126,
                    column: 8
                },
                end: {
                    line: 128,
                    column: 9
                }
            },
            '54': {
                start: {
                    line: 127,
                    column: 12
                },
                end: {
                    line: 127,
                    column: 51
                }
            },
            '55': {
                start: {
                    line: 129,
                    column: 8
                },
                end: {
                    line: 129,
                    column: 34
                }
            },
            '56': {
                start: {
                    line: 130,
                    column: 8
                },
                end: {
                    line: 130,
                    column: 29
                }
            },
            '57': {
                start: {
                    line: 131,
                    column: 8
                },
                end: {
                    line: 131,
                    column: 29
                }
            },
            '58': {
                start: {
                    line: 132,
                    column: 8
                },
                end: {
                    line: 132,
                    column: 29
                }
            },
            '59': {
                start: {
                    line: 133,
                    column: 8
                },
                end: {
                    line: 133,
                    column: 31
                }
            },
            '60': {
                start: {
                    line: 134,
                    column: 8
                },
                end: {
                    line: 134,
                    column: 30
                }
            },
            '61': {
                start: {
                    line: 138,
                    column: 8
                },
                end: {
                    line: 141,
                    column: 11
                }
            },
            '62': {
                start: {
                    line: 139,
                    column: 12
                },
                end: {
                    line: 139,
                    column: 52
                }
            },
            '63': {
                start: {
                    line: 140,
                    column: 12
                },
                end: {
                    line: 140,
                    column: 33
                }
            },
            '64': {
                start: {
                    line: 145,
                    column: 8
                },
                end: {
                    line: 147,
                    column: 11
                }
            },
            '65': {
                start: {
                    line: 146,
                    column: 12
                },
                end: {
                    line: 146,
                    column: 33
                }
            },
            '66': {
                start: {
                    line: 151,
                    column: 8
                },
                end: {
                    line: 153,
                    column: 11
                }
            },
            '67': {
                start: {
                    line: 152,
                    column: 12
                },
                end: {
                    line: 152,
                    column: 35
                }
            },
            '68': {
                start: {
                    line: 157,
                    column: 8
                },
                end: {
                    line: 171,
                    column: 11
                }
            },
            '69': {
                start: {
                    line: 158,
                    column: 12
                },
                end: {
                    line: 158,
                    column: 40
                }
            },
            '70': {
                start: {
                    line: 159,
                    column: 12
                },
                end: {
                    line: 163,
                    column: 15
                }
            },
            '71': {
                start: {
                    line: 160,
                    column: 16
                },
                end: {
                    line: 160,
                    column: 42
                }
            },
            '72': {
                start: {
                    line: 161,
                    column: 16
                },
                end: {
                    line: 161,
                    column: 42
                }
            },
            '73': {
                start: {
                    line: 162,
                    column: 16
                },
                end: {
                    line: 162,
                    column: 39
                }
            },
            '74': {
                start: {
                    line: 165,
                    column: 12
                },
                end: {
                    line: 165,
                    column: 40
                }
            },
            '75': {
                start: {
                    line: 166,
                    column: 12
                },
                end: {
                    line: 168,
                    column: 15
                }
            },
            '76': {
                start: {
                    line: 167,
                    column: 16
                },
                end: {
                    line: 167,
                    column: 44
                }
            },
            '77': {
                start: {
                    line: 169,
                    column: 12
                },
                end: {
                    line: 169,
                    column: 41
                }
            },
            '78': {
                start: {
                    line: 170,
                    column: 12
                },
                end: {
                    line: 170,
                    column: 33
                }
            },
            '79': {
                start: {
                    line: 175,
                    column: 8
                },
                end: {
                    line: 175,
                    column: 80
                }
            },
            '80': {
                start: {
                    line: 176,
                    column: 8
                },
                end: {
                    line: 176,
                    column: 30
                }
            },
            '81': {
                start: {
                    line: 180,
                    column: 8
                },
                end: {
                    line: 184,
                    column: 11
                }
            },
            '82': {
                start: {
                    line: 181,
                    column: 12
                },
                end: {
                    line: 183,
                    column: 15
                }
            },
            '83': {
                start: {
                    line: 182,
                    column: 16
                },
                end: {
                    line: 182,
                    column: 44
                }
            },
            '84': {
                start: {
                    line: 188,
                    column: 8
                },
                end: {
                    line: 190,
                    column: 11
                }
            },
            '85': {
                start: {
                    line: 189,
                    column: 12
                },
                end: {
                    line: 189,
                    column: 50
                }
            },
            '86': {
                start: {
                    line: 194,
                    column: 8
                },
                end: {
                    line: 199,
                    column: 11
                }
            },
            '87': {
                start: {
                    line: 195,
                    column: 12
                },
                end: {
                    line: 198,
                    column: 15
                }
            },
            '88': {
                start: {
                    line: 203,
                    column: 8
                },
                end: {
                    line: 205,
                    column: 11
                }
            },
            '89': {
                start: {
                    line: 204,
                    column: 12
                },
                end: {
                    line: 204,
                    column: 52
                }
            },
            '90': {
                start: {
                    line: 209,
                    column: 8
                },
                end: {
                    line: 211,
                    column: 11
                }
            },
            '91': {
                start: {
                    line: 210,
                    column: 12
                },
                end: {
                    line: 210,
                    column: 52
                }
            },
            '92': {
                start: {
                    line: 215,
                    column: 8
                },
                end: {
                    line: 215,
                    column: 80
                }
            },
            '93': {
                start: {
                    line: 216,
                    column: 8
                },
                end: {
                    line: 216,
                    column: 38
                }
            },
            '94': {
                start: {
                    line: 220,
                    column: 0
                },
                end: {
                    line: 228,
                    column: 3
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 156
                    },
                    end: {
                        line: 84,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 13,
                        column: 36
                    },
                    end: {
                        line: 13,
                        column: 37
                    }
                },
                loc: {
                    start: {
                        line: 13,
                        column: 53
                    },
                    end: {
                        line: 18,
                        column: 9
                    }
                },
                line: 13
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 15,
                        column: 34
                    },
                    end: {
                        line: 15,
                        column: 35
                    }
                },
                loc: {
                    start: {
                        line: 15,
                        column: 40
                    },
                    end: {
                        line: 17,
                        column: 13
                    }
                },
                line: 15
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 20,
                        column: 42
                    },
                    end: {
                        line: 20,
                        column: 43
                    }
                },
                loc: {
                    start: {
                        line: 20,
                        column: 54
                    },
                    end: {
                        line: 29,
                        column: 9
                    }
                },
                line: 20
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 32,
                        column: 49
                    },
                    end: {
                        line: 32,
                        column: 50
                    }
                },
                loc: {
                    start: {
                        line: 32,
                        column: 59
                    },
                    end: {
                        line: 41,
                        column: 9
                    }
                },
                line: 32
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 42,
                        column: 48
                    },
                    end: {
                        line: 42,
                        column: 49
                    }
                },
                loc: {
                    start: {
                        line: 42,
                        column: 58
                    },
                    end: {
                        line: 46,
                        column: 9
                    }
                },
                line: 42
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 48,
                        column: 26
                    },
                    end: {
                        line: 48,
                        column: 27
                    }
                },
                loc: {
                    start: {
                        line: 48,
                        column: 35
                    },
                    end: {
                        line: 56,
                        column: 13
                    }
                },
                line: 48
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 53,
                        column: 47
                    },
                    end: {
                        line: 53,
                        column: 48
                    }
                },
                loc: {
                    start: {
                        line: 53,
                        column: 53
                    },
                    end: {
                        line: 55,
                        column: 17
                    }
                },
                line: 53
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 60,
                        column: 26
                    },
                    end: {
                        line: 60,
                        column: 27
                    }
                },
                loc: {
                    start: {
                        line: 60,
                        column: 35
                    },
                    end: {
                        line: 68,
                        column: 13
                    }
                },
                line: 60
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 65,
                        column: 47
                    },
                    end: {
                        line: 65,
                        column: 48
                    }
                },
                loc: {
                    start: {
                        line: 65,
                        column: 53
                    },
                    end: {
                        line: 67,
                        column: 17
                    }
                },
                line: 65
            },
            '10': {
                name: '(anonymous_10)',
                decl: {
                    start: {
                        line: 72,
                        column: 26
                    },
                    end: {
                        line: 72,
                        column: 27
                    }
                },
                loc: {
                    start: {
                        line: 72,
                        column: 35
                    },
                    end: {
                        line: 80,
                        column: 13
                    }
                },
                line: 72
            },
            '11': {
                name: '(anonymous_11)',
                decl: {
                    start: {
                        line: 77,
                        column: 45
                    },
                    end: {
                        line: 77,
                        column: 46
                    }
                },
                loc: {
                    start: {
                        line: 77,
                        column: 51
                    },
                    end: {
                        line: 79,
                        column: 17
                    }
                },
                line: 77
            },
            '12': {
                name: '(anonymous_12)',
                decl: {
                    start: {
                        line: 86,
                        column: 4
                    },
                    end: {
                        line: 86,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 86,
                        column: 20
                    },
                    end: {
                        line: 94,
                        column: 5
                    }
                },
                line: 86
            },
            '13': {
                name: '(anonymous_13)',
                decl: {
                    start: {
                        line: 96,
                        column: 4
                    },
                    end: {
                        line: 96,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 96,
                        column: 20
                    },
                    end: {
                        line: 101,
                        column: 5
                    }
                },
                line: 96
            },
            '14': {
                name: '(anonymous_14)',
                decl: {
                    start: {
                        line: 103,
                        column: 4
                    },
                    end: {
                        line: 103,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 103,
                        column: 20
                    },
                    end: {
                        line: 107,
                        column: 5
                    }
                },
                line: 103
            },
            '15': {
                name: '(anonymous_15)',
                decl: {
                    start: {
                        line: 109,
                        column: 4
                    },
                    end: {
                        line: 109,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 109,
                        column: 20
                    },
                    end: {
                        line: 113,
                        column: 5
                    }
                },
                line: 109
            },
            '16': {
                name: '(anonymous_16)',
                decl: {
                    start: {
                        line: 115,
                        column: 4
                    },
                    end: {
                        line: 115,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 115,
                        column: 22
                    },
                    end: {
                        line: 119,
                        column: 5
                    }
                },
                line: 115
            },
            '17': {
                name: '(anonymous_17)',
                decl: {
                    start: {
                        line: 121,
                        column: 4
                    },
                    end: {
                        line: 121,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 121,
                        column: 14
                    },
                    end: {
                        line: 135,
                        column: 5
                    }
                },
                line: 121
            },
            '18': {
                name: '(anonymous_18)',
                decl: {
                    start: {
                        line: 137,
                        column: 4
                    },
                    end: {
                        line: 137,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 137,
                        column: 25
                    },
                    end: {
                        line: 142,
                        column: 5
                    }
                },
                line: 137
            },
            '19': {
                name: '(anonymous_19)',
                decl: {
                    start: {
                        line: 138,
                        column: 38
                    },
                    end: {
                        line: 138,
                        column: 39
                    }
                },
                loc: {
                    start: {
                        line: 138,
                        column: 44
                    },
                    end: {
                        line: 141,
                        column: 9
                    }
                },
                line: 138
            },
            '20': {
                name: '(anonymous_20)',
                decl: {
                    start: {
                        line: 144,
                        column: 4
                    },
                    end: {
                        line: 144,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 144,
                        column: 25
                    },
                    end: {
                        line: 148,
                        column: 5
                    }
                },
                line: 144
            },
            '21': {
                name: '(anonymous_21)',
                decl: {
                    start: {
                        line: 145,
                        column: 38
                    },
                    end: {
                        line: 145,
                        column: 39
                    }
                },
                loc: {
                    start: {
                        line: 145,
                        column: 44
                    },
                    end: {
                        line: 147,
                        column: 9
                    }
                },
                line: 145
            },
            '22': {
                name: '(anonymous_22)',
                decl: {
                    start: {
                        line: 150,
                        column: 4
                    },
                    end: {
                        line: 150,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 150,
                        column: 19
                    },
                    end: {
                        line: 154,
                        column: 5
                    }
                },
                line: 150
            },
            '23': {
                name: '(anonymous_23)',
                decl: {
                    start: {
                        line: 151,
                        column: 36
                    },
                    end: {
                        line: 151,
                        column: 37
                    }
                },
                loc: {
                    start: {
                        line: 151,
                        column: 42
                    },
                    end: {
                        line: 153,
                        column: 9
                    }
                },
                line: 151
            },
            '24': {
                name: '(anonymous_24)',
                decl: {
                    start: {
                        line: 156,
                        column: 4
                    },
                    end: {
                        line: 156,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 156,
                        column: 17
                    },
                    end: {
                        line: 172,
                        column: 5
                    }
                },
                line: 156
            },
            '25': {
                name: '(anonymous_25)',
                decl: {
                    start: {
                        line: 157,
                        column: 38
                    },
                    end: {
                        line: 157,
                        column: 39
                    }
                },
                loc: {
                    start: {
                        line: 157,
                        column: 49
                    },
                    end: {
                        line: 171,
                        column: 9
                    }
                },
                line: 157
            },
            '26': {
                name: '(anonymous_26)',
                decl: {
                    start: {
                        line: 159,
                        column: 39
                    },
                    end: {
                        line: 159,
                        column: 40
                    }
                },
                loc: {
                    start: {
                        line: 159,
                        column: 47
                    },
                    end: {
                        line: 163,
                        column: 13
                    }
                },
                line: 159
            },
            '27': {
                name: '(anonymous_27)',
                decl: {
                    start: {
                        line: 166,
                        column: 56
                    },
                    end: {
                        line: 166,
                        column: 57
                    }
                },
                loc: {
                    start: {
                        line: 166,
                        column: 67
                    },
                    end: {
                        line: 168,
                        column: 13
                    }
                },
                line: 166
            },
            '28': {
                name: '(anonymous_28)',
                decl: {
                    start: {
                        line: 174,
                        column: 4
                    },
                    end: {
                        line: 174,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 174,
                        column: 27
                    },
                    end: {
                        line: 177,
                        column: 5
                    }
                },
                line: 174
            },
            '29': {
                name: '(anonymous_29)',
                decl: {
                    start: {
                        line: 179,
                        column: 4
                    },
                    end: {
                        line: 179,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 179,
                        column: 21
                    },
                    end: {
                        line: 185,
                        column: 5
                    }
                },
                line: 179
            },
            '30': {
                name: '(anonymous_30)',
                decl: {
                    start: {
                        line: 180,
                        column: 38
                    },
                    end: {
                        line: 180,
                        column: 39
                    }
                },
                loc: {
                    start: {
                        line: 180,
                        column: 50
                    },
                    end: {
                        line: 184,
                        column: 9
                    }
                },
                line: 180
            },
            '31': {
                name: '(anonymous_31)',
                decl: {
                    start: {
                        line: 181,
                        column: 29
                    },
                    end: {
                        line: 181,
                        column: 30
                    }
                },
                loc: {
                    start: {
                        line: 181,
                        column: 40
                    },
                    end: {
                        line: 183,
                        column: 13
                    }
                },
                line: 181
            },
            '32': {
                name: '(anonymous_32)',
                decl: {
                    start: {
                        line: 187,
                        column: 4
                    },
                    end: {
                        line: 187,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 187,
                        column: 27
                    },
                    end: {
                        line: 191,
                        column: 5
                    }
                },
                line: 187
            },
            '33': {
                name: '(anonymous_33)',
                decl: {
                    start: {
                        line: 188,
                        column: 49
                    },
                    end: {
                        line: 188,
                        column: 50
                    }
                },
                loc: {
                    start: {
                        line: 188,
                        column: 66
                    },
                    end: {
                        line: 190,
                        column: 9
                    }
                },
                line: 188
            },
            '34': {
                name: '(anonymous_34)',
                decl: {
                    start: {
                        line: 193,
                        column: 4
                    },
                    end: {
                        line: 193,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 193,
                        column: 31
                    },
                    end: {
                        line: 200,
                        column: 5
                    }
                },
                line: 193
            },
            '35': {
                name: '(anonymous_35)',
                decl: {
                    start: {
                        line: 194,
                        column: 53
                    },
                    end: {
                        line: 194,
                        column: 54
                    }
                },
                loc: {
                    start: {
                        line: 194,
                        column: 74
                    },
                    end: {
                        line: 199,
                        column: 9
                    }
                },
                line: 194
            },
            '36': {
                name: '(anonymous_36)',
                decl: {
                    start: {
                        line: 202,
                        column: 4
                    },
                    end: {
                        line: 202,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 202,
                        column: 30
                    },
                    end: {
                        line: 206,
                        column: 5
                    }
                },
                line: 202
            },
            '37': {
                name: '(anonymous_37)',
                decl: {
                    start: {
                        line: 203,
                        column: 39
                    },
                    end: {
                        line: 203,
                        column: 40
                    }
                },
                loc: {
                    start: {
                        line: 203,
                        column: 45
                    },
                    end: {
                        line: 205,
                        column: 9
                    }
                },
                line: 203
            },
            '38': {
                name: '(anonymous_38)',
                decl: {
                    start: {
                        line: 208,
                        column: 4
                    },
                    end: {
                        line: 208,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 208,
                        column: 27
                    },
                    end: {
                        line: 212,
                        column: 5
                    }
                },
                line: 208
            },
            '39': {
                name: '(anonymous_39)',
                decl: {
                    start: {
                        line: 209,
                        column: 33
                    },
                    end: {
                        line: 209,
                        column: 34
                    }
                },
                loc: {
                    start: {
                        line: 209,
                        column: 39
                    },
                    end: {
                        line: 211,
                        column: 9
                    }
                },
                line: 209
            },
            '40': {
                name: '(anonymous_40)',
                decl: {
                    start: {
                        line: 214,
                        column: 4
                    },
                    end: {
                        line: 214,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 214,
                        column: 19
                    },
                    end: {
                        line: 217,
                        column: 5
                    }
                },
                line: 214
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 21,
                        column: 12
                    },
                    end: {
                        line: 28,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 21,
                        column: 12
                    },
                    end: {
                        line: 28,
                        column: 13
                    }
                }, {
                    start: {
                        line: 21,
                        column: 12
                    },
                    end: {
                        line: 28,
                        column: 13
                    }
                }],
                line: 21
            },
            '1': {
                loc: {
                    start: {
                        line: 33,
                        column: 12
                    },
                    end: {
                        line: 40,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 33,
                        column: 12
                    },
                    end: {
                        line: 40,
                        column: 13
                    }
                }, {
                    start: {
                        line: 33,
                        column: 12
                    },
                    end: {
                        line: 40,
                        column: 13
                    }
                }],
                line: 33
            },
            '2': {
                loc: {
                    start: {
                        line: 43,
                        column: 12
                    },
                    end: {
                        line: 45,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 43,
                        column: 12
                    },
                    end: {
                        line: 45,
                        column: 13
                    }
                }, {
                    start: {
                        line: 43,
                        column: 12
                    },
                    end: {
                        line: 45,
                        column: 13
                    }
                }],
                line: 43
            },
            '3': {
                loc: {
                    start: {
                        line: 87,
                        column: 8
                    },
                    end: {
                        line: 92,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 87,
                        column: 8
                    },
                    end: {
                        line: 92,
                        column: 9
                    }
                }, {
                    start: {
                        line: 87,
                        column: 8
                    },
                    end: {
                        line: 92,
                        column: 9
                    }
                }],
                line: 87
            },
            '4': {
                loc: {
                    start: {
                        line: 89,
                        column: 12
                    },
                    end: {
                        line: 91,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 89,
                        column: 12
                    },
                    end: {
                        line: 91,
                        column: 13
                    }
                }, {
                    start: {
                        line: 89,
                        column: 12
                    },
                    end: {
                        line: 91,
                        column: 13
                    }
                }],
                line: 89
            },
            '5': {
                loc: {
                    start: {
                        line: 89,
                        column: 16
                    },
                    end: {
                        line: 89,
                        column: 33
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 89,
                        column: 16
                    },
                    end: {
                        line: 89,
                        column: 17
                    }
                }, {
                    start: {
                        line: 89,
                        column: 21
                    },
                    end: {
                        line: 89,
                        column: 33
                    }
                }],
                line: 89
            },
            '6': {
                loc: {
                    start: {
                        line: 123,
                        column: 8
                    },
                    end: {
                        line: 125,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 123,
                        column: 8
                    },
                    end: {
                        line: 125,
                        column: 9
                    }
                }, {
                    start: {
                        line: 123,
                        column: 8
                    },
                    end: {
                        line: 125,
                        column: 9
                    }
                }],
                line: 123
            },
            '7': {
                loc: {
                    start: {
                        line: 126,
                        column: 8
                    },
                    end: {
                        line: 128,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 126,
                        column: 8
                    },
                    end: {
                        line: 128,
                        column: 9
                    }
                }, {
                    start: {
                        line: 126,
                        column: 8
                    },
                    end: {
                        line: 128,
                        column: 9
                    }
                }],
                line: 126
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0,
            '28': 0,
            '29': 0,
            '30': 0,
            '31': 0,
            '32': 0,
            '33': 0,
            '34': 0,
            '35': 0,
            '36': 0,
            '37': 0,
            '38': 0,
            '39': 0,
            '40': 0,
            '41': 0,
            '42': 0,
            '43': 0,
            '44': 0,
            '45': 0,
            '46': 0,
            '47': 0,
            '48': 0,
            '49': 0,
            '50': 0,
            '51': 0,
            '52': 0,
            '53': 0,
            '54': 0,
            '55': 0,
            '56': 0,
            '57': 0,
            '58': 0,
            '59': 0,
            '60': 0,
            '61': 0,
            '62': 0,
            '63': 0,
            '64': 0,
            '65': 0,
            '66': 0,
            '67': 0,
            '68': 0,
            '69': 0,
            '70': 0,
            '71': 0,
            '72': 0,
            '73': 0,
            '74': 0,
            '75': 0,
            '76': 0,
            '77': 0,
            '78': 0,
            '79': 0,
            '80': 0,
            '81': 0,
            '82': 0,
            '83': 0,
            '84': 0,
            '85': 0,
            '86': 0,
            '87': 0,
            '88': 0,
            '89': 0,
            '90': 0,
            '91': 0,
            '92': 0,
            '93': 0,
            '94': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0,
            '28': 0,
            '29': 0,
            '30': 0,
            '31': 0,
            '32': 0,
            '33': 0,
            '34': 0,
            '35': 0,
            '36': 0,
            '37': 0,
            '38': 0,
            '39': 0,
            '40': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0],
            '2': [0, 0],
            '3': [0, 0],
            '4': [0, 0],
            '5': [0, 0],
            '6': [0, 0],
            '7': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var ProposalDetailsController = function () {
    function ProposalDetailsController($scope, MediaTable, MediaSelectorService, UserStorySelectorService, AppSettings, UserStoriesTable, ProposalFilesTable, $filter, $rootScope) {
        var _this = this;

        _classCallCheck(this, ProposalDetailsController);

        cov_k62wb9q4g.f[0]++;
        cov_k62wb9q4g.s[0]++;

        this.MediaSelectorService = MediaSelectorService;
        cov_k62wb9q4g.s[1]++;
        this.UserStorySelectorService = UserStorySelectorService;
        cov_k62wb9q4g.s[2]++;
        this.proposalsApp = AppSettings.proposalsApp;
        cov_k62wb9q4g.s[3]++;
        this.UserStoriesTable = UserStoriesTable;
        cov_k62wb9q4g.s[4]++;
        this.ProposalFilesTable = ProposalFilesTable;
        cov_k62wb9q4g.s[5]++;
        this.currentNav = 'details';
        cov_k62wb9q4g.s[6]++;
        this.$filter = $filter;

        /* eslint-disable angular/on-watch */
        cov_k62wb9q4g.s[7]++;
        $rootScope.$on('fileClose', function (event, file) {
            cov_k62wb9q4g.f[1]++;
            cov_k62wb9q4g.s[8]++;

            // eslint-disable-next-line camelcase
            file.$destroy().$then(function () {
                cov_k62wb9q4g.f[2]++;
                cov_k62wb9q4g.s[9]++;

                _this._parseComments();
            });
        });
        cov_k62wb9q4g.s[10]++;
        this.uploadedFiles = ProposalFilesTable.$collection();
        cov_k62wb9q4g.s[11]++;
        $scope.$watch('$ctrl.fileUpload', function (uploaded) {
            cov_k62wb9q4g.f[3]++;
            cov_k62wb9q4g.s[12]++;

            if (angular.isString(uploaded)) {
                cov_k62wb9q4g.b[0][0]++;
                cov_k62wb9q4g.s[13]++;

                _this.uploadedFiles.$build({
                    proposal: _this.proposal.id,
                    path: uploaded,
                    name: _this.fileToName(_this.fileUpload)
                }).$reveal();
                cov_k62wb9q4g.s[14]++;
                _this.fileUpload = null;
            } else {
                cov_k62wb9q4g.b[0][1]++;
            }
        });

        // files
        cov_k62wb9q4g.s[15]++;
        $scope.$watch('$ctrl.newFileAttachment', function (newVal) {
            cov_k62wb9q4g.f[4]++;
            cov_k62wb9q4g.s[16]++;

            if (angular.isString(newVal)) {
                cov_k62wb9q4g.b[1][0]++;
                cov_k62wb9q4g.s[17]++;

                _this.proposal.$files.$create({
                    proposal: _this.proposal.id,
                    path: newVal,
                    name: _this.fileToName(newVal)
                });
                cov_k62wb9q4g.s[18]++;
                _this.medias.$refresh();
            } else {
                cov_k62wb9q4g.b[1][1]++;
            }
        });
        cov_k62wb9q4g.s[19]++;
        $scope.$watch('$ctrl.newSection.cover', function (newVal) {
            cov_k62wb9q4g.f[5]++;
            cov_k62wb9q4g.s[20]++;

            if (angular.isString(newVal)) {
                cov_k62wb9q4g.b[2][0]++;
                cov_k62wb9q4g.s[21]++;

                _this.medias.$refresh();
            } else {
                cov_k62wb9q4g.b[2][1]++;
            }
        });
        cov_k62wb9q4g.s[22]++;
        this.sortableServices = {
            orderChanged: function orderChanged(event) {
                cov_k62wb9q4g.f[6]++;

                var newPos = (cov_k62wb9q4g.s[23]++, event.dest.index + 1);
                var service = (cov_k62wb9q4g.s[24]++, event.source.itemScope.service);
                cov_k62wb9q4g.s[25]++;
                service.order = newPos;

                cov_k62wb9q4g.s[26]++;
                service.$save(['order']).$then(function () {
                    cov_k62wb9q4g.f[7]++;
                    cov_k62wb9q4g.s[27]++;

                    _this.proposal.$proposalServices.$refresh();
                });
            },
            placeholder: '<div style="min-height: 60px;"></div>'
        };
        cov_k62wb9q4g.s[28]++;
        this.sortableSections = {
            orderChanged: function orderChanged(event) {
                cov_k62wb9q4g.f[8]++;

                var newPos = (cov_k62wb9q4g.s[29]++, event.dest.index + 1);
                var section = (cov_k62wb9q4g.s[30]++, event.source.itemScope.section);
                cov_k62wb9q4g.s[31]++;
                section.order = newPos;

                cov_k62wb9q4g.s[32]++;
                section.$save(['order']).$then(function () {
                    cov_k62wb9q4g.f[9]++;
                    cov_k62wb9q4g.s[33]++;

                    _this.proposal.$proposalSections.$refresh();
                });
            },
            placeholder: '<div style="min-height: 60px;"></div>'
        };
        cov_k62wb9q4g.s[34]++;
        this.sortableStories = {
            orderChanged: function orderChanged(event) {
                cov_k62wb9q4g.f[10]++;

                var newPos = (cov_k62wb9q4g.s[35]++, event.dest.index + 1);
                var story = (cov_k62wb9q4g.s[36]++, event.source.itemScope.story);
                cov_k62wb9q4g.s[37]++;
                story.order = newPos;

                cov_k62wb9q4g.s[38]++;
                story.$save(['order']).$then(function () {
                    cov_k62wb9q4g.f[11]++;
                    cov_k62wb9q4g.s[39]++;

                    _this.proposal.$userStories.$refresh();
                });
            },
            placeholder: '<div style="min-height: 60px;"></div>'
        };
        cov_k62wb9q4g.s[40]++;
        this.medias = MediaTable.$search();
    }

    _createClass(ProposalDetailsController, [{
        key: 'fileToName',
        value: function fileToName(url) {
            cov_k62wb9q4g.f[12]++;
            cov_k62wb9q4g.s[41]++;

            if (url) {
                cov_k62wb9q4g.b[3][0]++;

                var m = (cov_k62wb9q4g.s[42]++, url.toString().match(/.*\/(.+?)\.(.+)/));
                cov_k62wb9q4g.s[43]++;
                if ((cov_k62wb9q4g.b[5][0]++, m) && (cov_k62wb9q4g.b[5][1]++, m.length > 1)) {
                    cov_k62wb9q4g.b[4][0]++;
                    cov_k62wb9q4g.s[44]++;

                    return m[1] + '.' + m[2];
                } else {
                    cov_k62wb9q4g.b[4][1]++;
                }
            } else {
                cov_k62wb9q4g.b[3][1]++;
            }
            cov_k62wb9q4g.s[45]++;
            return "";
        }
    }, {
        key: '_buildComment',
        value: function _buildComment() {
            cov_k62wb9q4g.f[13]++;
            cov_k62wb9q4g.s[46]++;

            this.newComment = this.proposal.$comments.$build({
                objectType: 'PROPOSAL',
                objectId: this.proposal.id
            });
        }
    }, {
        key: '_buildSection',
        value: function _buildSection() {
            cov_k62wb9q4g.f[14]++;
            cov_k62wb9q4g.s[47]++;

            this.newSection = this.proposal.$proposalSections.$build({
                proposal: this.proposal.id
            });
        }
    }, {
        key: '_buildService',
        value: function _buildService() {
            cov_k62wb9q4g.f[15]++;
            cov_k62wb9q4g.s[48]++;

            this.newService = this.proposal.$proposalServices.$build({
                proposal: this.proposal.id
            });
        }
    }, {
        key: '_buildUserStory',
        value: function _buildUserStory() {
            cov_k62wb9q4g.f[16]++;
            cov_k62wb9q4g.s[49]++;

            this.newStory = this.proposal.$userStories.$build({
                proposal: this.proposal.id
            });
        }
    }, {
        key: '$onInit',
        value: function $onInit() {
            cov_k62wb9q4g.f[17]++;
            cov_k62wb9q4g.s[50]++;

            this.proposal.$load('proposalServices').$load('proposalSections').$load('client').$load('files').$load('comments').$load('userStories');
            cov_k62wb9q4g.s[51]++;
            if (this.proposal.expirationDate !== null) {
                cov_k62wb9q4g.b[6][0]++;
                cov_k62wb9q4g.s[52]++;

                this.expirationDate = this.proposal.expirationDate;
            } else {
                cov_k62wb9q4g.b[6][1]++;
            }
            cov_k62wb9q4g.s[53]++;
            if (this.proposal.date !== null) {
                cov_k62wb9q4g.b[7][0]++;
                cov_k62wb9q4g.s[54]++;

                this.proposalDate = this.proposal.date;
            } else {
                cov_k62wb9q4g.b[7][1]++;
            }
            cov_k62wb9q4g.s[55]++;
            this.selectedMedia = null;
            cov_k62wb9q4g.s[56]++;
            this._buildComment();
            cov_k62wb9q4g.s[57]++;
            this._buildSection();
            cov_k62wb9q4g.s[58]++;
            this._buildService();
            cov_k62wb9q4g.s[59]++;
            this._buildUserStory();
            cov_k62wb9q4g.s[60]++;
            this._parseComments();
        }
    }, {
        key: 'addProposalService',
        value: function addProposalService() {
            var _this2 = this;

            cov_k62wb9q4g.f[18]++;
            cov_k62wb9q4g.s[61]++;

            this.newService.$save().$then(function () {
                cov_k62wb9q4g.f[19]++;
                cov_k62wb9q4g.s[62]++;

                _this2.proposal.$refresh(['total_price']);
                cov_k62wb9q4g.s[63]++;
                _this2._buildService();
            });
        }
    }, {
        key: 'addProposalSection',
        value: function addProposalSection() {
            var _this3 = this;

            cov_k62wb9q4g.f[20]++;
            cov_k62wb9q4g.s[64]++;

            this.newSection.$save().$then(function () {
                cov_k62wb9q4g.f[21]++;
                cov_k62wb9q4g.s[65]++;

                _this3._buildSection();
            });
        }
    }, {
        key: 'addUserStory',
        value: function addUserStory() {
            var _this4 = this;

            cov_k62wb9q4g.f[22]++;
            cov_k62wb9q4g.s[66]++;

            this.newStory.$save().$then(function () {
                cov_k62wb9q4g.f[23]++;
                cov_k62wb9q4g.s[67]++;

                _this4._buildUserStory();
            });
        }
    }, {
        key: 'addComment',
        value: function addComment() {
            var _this5 = this;

            cov_k62wb9q4g.f[24]++;
            cov_k62wb9q4g.s[68]++;

            this.newComment.$save().$then(function (comment) {
                cov_k62wb9q4g.f[25]++;
                cov_k62wb9q4g.s[69]++;

                _this5._parseComment(comment);
                cov_k62wb9q4g.s[70]++;
                _this5.uploadedFiles.forEach(function (file) {
                    cov_k62wb9q4g.f[26]++;
                    cov_k62wb9q4g.s[71]++;

                    file.comment = comment.id;
                    cov_k62wb9q4g.s[72]++;
                    comment.$files.$add(file);
                    cov_k62wb9q4g.s[73]++;
                    file.$save().$reveal();
                });

                cov_k62wb9q4g.s[74]++;
                _this5.uploadedFiles.$clear();
                cov_k62wb9q4g.s[75]++;
                _this5.proposal.$comments.$add(comment).$then(function (comment) {
                    cov_k62wb9q4g.f[27]++;
                    cov_k62wb9q4g.s[76]++;

                    _this5._parseComment(comment);
                });
                cov_k62wb9q4g.s[77]++;
                _this5.proposal.$load('files');
                cov_k62wb9q4g.s[78]++;
                _this5._buildComment();
            });
        }
    }, {
        key: '_parseComment',
        value: function _parseComment(comment) {
            cov_k62wb9q4g.f[28]++;
            cov_k62wb9q4g.s[79]++;

            comment.$files = this.ProposalFilesTable.$search({ comment: comment.id });
            cov_k62wb9q4g.s[80]++;
            comment.$load('user');
        }
    }, {
        key: '_parseComments',
        value: function _parseComments() {
            var _this6 = this;

            cov_k62wb9q4g.f[29]++;
            cov_k62wb9q4g.s[81]++;

            this.proposal.$comments.$then(function (comments) {
                cov_k62wb9q4g.f[30]++;
                cov_k62wb9q4g.s[82]++;

                comments.forEach(function (comment) {
                    cov_k62wb9q4g.f[31]++;
                    cov_k62wb9q4g.s[83]++;

                    _this6._parseComment(comment);
                });
            });
        }
    }, {
        key: 'displayMediaSelector',
        value: function displayMediaSelector() {
            var _this7 = this;

            cov_k62wb9q4g.f[32]++;
            cov_k62wb9q4g.s[84]++;

            this.MediaSelectorService.display().then(function (selectedMedia) {
                cov_k62wb9q4g.f[33]++;
                cov_k62wb9q4g.s[85]++;

                _this7.newSection.cover = selectedMedia;
            });
        }
    }, {
        key: 'displayUserStorySelector',
        value: function displayUserStorySelector() {
            var _this8 = this;

            cov_k62wb9q4g.f[34]++;
            cov_k62wb9q4g.s[86]++;

            this.UserStorySelectorService.display().then(function (selectedUserStory) {
                cov_k62wb9q4g.f[35]++;
                cov_k62wb9q4g.s[87]++;

                _this8.proposal.$userStories.$create({
                    proposal: _this8.proposal.id,
                    name: selectedUserStory
                });
            });
        }
    }, {
        key: 'saveServicePrice',
        value: function saveServicePrice(service) {
            var _this9 = this;

            cov_k62wb9q4g.f[36]++;
            cov_k62wb9q4g.s[88]++;

            service.$save(['price']).$then(function () {
                cov_k62wb9q4g.f[37]++;
                cov_k62wb9q4g.s[89]++;

                _this9.proposal.$refresh(['total_price']);
            });
        }
    }, {
        key: 'deleteService',
        value: function deleteService(service) {
            var _this10 = this;

            cov_k62wb9q4g.f[38]++;
            cov_k62wb9q4g.s[90]++;

            service.$destroy().$then(function () {
                cov_k62wb9q4g.f[39]++;
                cov_k62wb9q4g.s[91]++;

                _this10.proposal.$refresh(['total_price']);
            });
        }
    }, {
        key: 'saveDate',
        value: function saveDate(date) {
            cov_k62wb9q4g.f[40]++;
            cov_k62wb9q4g.s[92]++;

            this.proposal.date = this.$filter('date')(new Date(date), 'yyyy-MM-dd');
            cov_k62wb9q4g.s[93]++;
            this.proposal.$save(['date']);
        }
    }]);

    return ProposalDetailsController;
}();

cov_k62wb9q4g.s[94]++;


angular.module('app').component('proposalDetails', {
    templateUrl: 'app/components/proposalDetails/proposalDetails.html',
    controller: ProposalDetailsController,
    bindings: {
        proposal: '<'
    }
});