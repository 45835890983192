'use strict';

var cov_1da8q8mcdv = function () {
    var path = '/home/lobo/repos/prospector-app/src/index.js',
        hash = '4df1024e92b63e6d53a2652ff86d9a26b41b173f',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/prospector-app/src/index.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 37,
                    column: 27
                }
            },
            '1': {
                start: {
                    line: 41,
                    column: 4
                },
                end: {
                    line: 43,
                    column: 35
                }
            },
            '2': {
                start: {
                    line: 48,
                    column: 4
                },
                end: {
                    line: 50,
                    column: 6
                }
            },
            '3': {
                start: {
                    line: 49,
                    column: 8
                },
                end: {
                    line: 49,
                    column: 31
                }
            },
            '4': {
                start: {
                    line: 52,
                    column: 4
                },
                end: {
                    line: 56,
                    column: 6
                }
            },
            '5': {
                start: {
                    line: 53,
                    column: 1
                },
                end: {
                    line: 53,
                    column: 39
                }
            },
            '6': {
                start: {
                    line: 54,
                    column: 1
                },
                end: {
                    line: 54,
                    column: 42
                }
            },
            '7': {
                start: {
                    line: 55,
                    column: 1
                },
                end: {
                    line: 55,
                    column: 53
                }
            },
            '8': {
                start: {
                    line: 58,
                    column: 4
                },
                end: {
                    line: 62,
                    column: 6
                }
            },
            '9': {
                start: {
                    line: 60,
                    column: 1
                },
                end: {
                    line: 60,
                    column: 95
                }
            },
            '10': {
                start: {
                    line: 61,
                    column: 1
                },
                end: {
                    line: 61,
                    column: 49
                }
            },
            '11': {
                start: {
                    line: 64,
                    column: 4
                },
                end: {
                    line: 64,
                    column: 32
                }
            }
        },
        fnMap: {
            '0': {
                name: 'materialTheme',
                decl: {
                    start: {
                        line: 40,
                        column: 9
                    },
                    end: {
                        line: 40,
                        column: 22
                    }
                },
                loc: {
                    start: {
                        line: 40,
                        column: 43
                    },
                    end: {
                        line: 44,
                        column: 1
                    }
                },
                line: 40
            },
            '1': {
                name: 'runBlock',
                decl: {
                    start: {
                        line: 47,
                        column: 9
                    },
                    end: {
                        line: 47,
                        column: 17
                    }
                },
                loc: {
                    start: {
                        line: 47,
                        column: 77
                    },
                    end: {
                        line: 65,
                        column: 1
                    }
                },
                line: 47
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 48,
                        column: 24
                    },
                    end: {
                        line: 48,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 48,
                        column: 30
                    },
                    end: {
                        line: 50,
                        column: 5
                    }
                },
                line: 48
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 52,
                        column: 33
                    },
                    end: {
                        line: 52,
                        column: 34
                    }
                },
                loc: {
                    start: {
                        line: 52,
                        column: 42
                    },
                    end: {
                        line: 56,
                        column: 5
                    }
                },
                line: 52
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 58,
                        column: 32
                    },
                    end: {
                        line: 58,
                        column: 33
                    }
                },
                loc: {
                    start: {
                        line: 58,
                        column: 44
                    },
                    end: {
                        line: 62,
                        column: 5
                    }
                },
                line: 58
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_1da8q8mcdv.s[0]++;
angular.module('app', ['ui.router', 'restmod', 'restmod.styles.drfPaged', 'ngMaterial', 'satellizer', 'ngFileUpload', 'cgBusy', 'angularMoment', 'angular-loading-bar', 'as.sortable', 'timer', 'ui.mask', 'ngMaterialDatePicker', 'mdPickers', 'textAngular', 'angular-bind-html-compile', 'nvd3']).constant('AppSettings', {
    appInProduction: false,
    // appUrl: 'http://prospector.local',
    // apiUrl: 'http://api.prospector.local',
    // mediaUrl: 'http://api.prospector.local/static/media',
    // proposalsApp: 'https://solo.com.pt/',
    appUrl: 'https://prospector.soloweb.pt',
    apiUrl: 'https://api.prospector.soloweb.pt',
    mediaUrl: 'https://api.prospector.soloweb.pt/static/media',
    proposalsApp: 'https://solo.com.pt/'
}).run(runBlock).value('cgBusyDefaults', {
    message: '',
    templateUrl: 'app/shared/html/cgbusy/cgbusy.template.html'
}).config(materialTheme);

/** @ngInject */
function materialTheme($mdThemingProvider) {
    cov_1da8q8mcdv.f[0]++;
    cov_1da8q8mcdv.s[1]++;

    $mdThemingProvider.theme('default').accentPalette('blue-grey').backgroundPalette('grey');
}

/** @ngInject */
function runBlock($rootScope, $window, MunicipalitiesTable, WorkspacesTable) {
    cov_1da8q8mcdv.f[1]++;
    cov_1da8q8mcdv.s[2]++;

    $rootScope.goBack = function () {
        cov_1da8q8mcdv.f[2]++;
        cov_1da8q8mcdv.s[3]++;

        $window.history.back();
    };

    cov_1da8q8mcdv.s[4]++;
    $rootScope.switchWorkspace = function (index) {
        cov_1da8q8mcdv.f[3]++;
        cov_1da8q8mcdv.s[5]++;

        $rootScope.me.activeWorkspace = index;
        cov_1da8q8mcdv.s[6]++;
        $rootScope.me.$save(['activeWorkspace']);
        cov_1da8q8mcdv.s[7]++;
        $rootScope.workspace = WorkspacesTable.$find(index);
    };

    cov_1da8q8mcdv.s[8]++;
    $rootScope.initStaticData = function () {
        cov_1da8q8mcdv.f[4]++;
        cov_1da8q8mcdv.s[9]++;

        /* eslint-disable camelcase */
        $rootScope.municipalities = MunicipalitiesTable.$search({ ordering: 'name', page_size: 99999 });
        cov_1da8q8mcdv.s[10]++;
        $rootScope.workspace = WorkspacesTable.$find(1);
    };

    cov_1da8q8mcdv.s[11]++;
    $rootScope.initStaticData();
}