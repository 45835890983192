'use strict';

var cov_1c2k3loppc = function () {
    var path = '/home/lobo/repos/prospector-app/src/routes.js',
        hash = '1c1f71a901fced7dae62e90a01e0fff0bf21f162',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/prospector-app/src/routes.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 3,
                    column: 22
                }
            },
            '1': {
                start: {
                    line: 7,
                    column: 4
                },
                end: {
                    line: 7,
                    column: 54
                }
            },
            '2': {
                start: {
                    line: 8,
                    column: 4
                },
                end: {
                    line: 8,
                    column: 38
                }
            },
            '3': {
                start: {
                    line: 10,
                    column: 4
                },
                end: {
                    line: 200,
                    column: 7
                }
            },
            '4': {
                start: {
                    line: 27,
                    column: 46
                },
                end: {
                    line: 27,
                    column: 109
                }
            },
            '5': {
                start: {
                    line: 27,
                    column: 95
                },
                end: {
                    line: 27,
                    column: 99
                }
            },
            '6': {
                start: {
                    line: 46,
                    column: 45
                },
                end: {
                    line: 46,
                    column: 114
                }
            },
            '7': {
                start: {
                    line: 46,
                    column: 93
                },
                end: {
                    line: 46,
                    column: 104
                }
            },
            '8': {
                start: {
                    line: 53,
                    column: 60
                },
                end: {
                    line: 53,
                    column: 140
                }
            },
            '9': {
                start: {
                    line: 53,
                    column: 120
                },
                end: {
                    line: 53,
                    column: 130
                }
            },
            '10': {
                start: {
                    line: 60,
                    column: 59
                },
                end: {
                    line: 60,
                    column: 149
                }
            },
            '11': {
                start: {
                    line: 60,
                    column: 121
                },
                end: {
                    line: 60,
                    column: 139
                }
            },
            '12': {
                start: {
                    line: 67,
                    column: 49
                },
                end: {
                    line: 67,
                    column: 124
                }
            },
            '13': {
                start: {
                    line: 67,
                    column: 101
                },
                end: {
                    line: 67,
                    column: 114
                }
            },
            '14': {
                start: {
                    line: 74,
                    column: 64
                },
                end: {
                    line: 74,
                    column: 150
                }
            },
            '15': {
                start: {
                    line: 74,
                    column: 128
                },
                end: {
                    line: 74,
                    column: 140
                }
            },
            '16': {
                start: {
                    line: 81,
                    column: 41
                },
                end: {
                    line: 81,
                    column: 104
                }
            },
            '17': {
                start: {
                    line: 81,
                    column: 85
                },
                end: {
                    line: 81,
                    column: 94
                }
            },
            '18': {
                start: {
                    line: 88,
                    column: 56
                },
                end: {
                    line: 88,
                    column: 130
                }
            },
            '19': {
                start: {
                    line: 88,
                    column: 112
                },
                end: {
                    line: 88,
                    column: 120
                }
            },
            '20': {
                start: {
                    line: 95,
                    column: 70
                },
                end: {
                    line: 95,
                    column: 165
                }
            },
            '21': {
                start: {
                    line: 95,
                    column: 140
                },
                end: {
                    line: 95,
                    column: 155
                }
            },
            '22': {
                start: {
                    line: 102,
                    column: 59
                },
                end: {
                    line: 102,
                    column: 137
                }
            },
            '23': {
                start: {
                    line: 102,
                    column: 118
                },
                end: {
                    line: 102,
                    column: 127
                }
            },
            '24': {
                start: {
                    line: 110,
                    column: 45
                },
                end: {
                    line: 110,
                    column: 140
                }
            },
            '25': {
                start: {
                    line: 110,
                    column: 119
                },
                end: {
                    line: 110,
                    column: 130
                }
            },
            '26': {
                start: {
                    line: 117,
                    column: 41
                },
                end: {
                    line: 117,
                    column: 104
                }
            },
            '27': {
                start: {
                    line: 117,
                    column: 85
                },
                end: {
                    line: 117,
                    column: 94
                }
            },
            '28': {
                start: {
                    line: 124,
                    column: 49
                },
                end: {
                    line: 124,
                    column: 120
                }
            },
            '29': {
                start: {
                    line: 124,
                    column: 101
                },
                end: {
                    line: 124,
                    column: 110
                }
            },
            '30': {
                start: {
                    line: 131,
                    column: 30
                },
                end: {
                    line: 131,
                    column: 78
                }
            },
            '31': {
                start: {
                    line: 131,
                    column: 64
                },
                end: {
                    line: 131,
                    column: 68
                }
            },
            '32': {
                start: {
                    line: 138,
                    column: 46
                },
                end: {
                    line: 138,
                    column: 105
                }
            },
            '33': {
                start: {
                    line: 138,
                    column: 92
                },
                end: {
                    line: 138,
                    column: 95
                }
            },
            '34': {
                start: {
                    line: 145,
                    column: 43
                },
                end: {
                    line: 145,
                    column: 109
                }
            },
            '35': {
                start: {
                    line: 145,
                    column: 89
                },
                end: {
                    line: 145,
                    column: 99
                }
            },
            '36': {
                start: {
                    line: 152,
                    column: 51
                },
                end: {
                    line: 152,
                    column: 129
                }
            },
            '37': {
                start: {
                    line: 152,
                    column: 105
                },
                end: {
                    line: 152,
                    column: 119
                }
            },
            '38': {
                start: {
                    line: 159,
                    column: 65
                },
                end: {
                    line: 159,
                    column: 152
                }
            },
            '39': {
                start: {
                    line: 159,
                    column: 130
                },
                end: {
                    line: 159,
                    column: 142
                }
            },
            '40': {
                start: {
                    line: 166,
                    column: 55
                },
                end: {
                    line: 166,
                    column: 139
                }
            },
            '41': {
                start: {
                    line: 166,
                    column: 113
                },
                end: {
                    line: 166,
                    column: 129
                }
            },
            '42': {
                start: {
                    line: 177,
                    column: 34
                },
                end: {
                    line: 177,
                    column: 87
                }
            },
            '43': {
                start: {
                    line: 177,
                    column: 71
                },
                end: {
                    line: 177,
                    column: 77
                }
            },
            '44': {
                start: {
                    line: 184,
                    column: 49
                },
                end: {
                    line: 184,
                    column: 113
                }
            },
            '45': {
                start: {
                    line: 184,
                    column: 98
                },
                end: {
                    line: 184,
                    column: 103
                }
            },
            '46': {
                start: {
                    line: 191,
                    column: 59
                },
                end: {
                    line: 191,
                    column: 147
                }
            },
            '47': {
                start: {
                    line: 191,
                    column: 125
                },
                end: {
                    line: 191,
                    column: 137
                }
            },
            '48': {
                start: {
                    line: 198,
                    column: 58
                },
                end: {
                    line: 198,
                    column: 135
                }
            },
            '49': {
                start: {
                    line: 198,
                    column: 116
                },
                end: {
                    line: 198,
                    column: 125
                }
            }
        },
        fnMap: {
            '0': {
                name: 'routesConfig',
                decl: {
                    start: {
                        line: 6,
                        column: 9
                    },
                    end: {
                        line: 6,
                        column: 21
                    }
                },
                loc: {
                    start: {
                        line: 6,
                        column: 77
                    },
                    end: {
                        line: 201,
                        column: 1
                    }
                },
                line: 6
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 27,
                        column: 18
                    },
                    end: {
                        line: 27,
                        column: 19
                    }
                },
                loc: {
                    start: {
                        line: 27,
                        column: 46
                    },
                    end: {
                        line: 27,
                        column: 109
                    }
                },
                line: 27
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 27,
                        column: 87
                    },
                    end: {
                        line: 27,
                        column: 88
                    }
                },
                loc: {
                    start: {
                        line: 27,
                        column: 95
                    },
                    end: {
                        line: 27,
                        column: 99
                    }
                },
                line: 27
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 46,
                        column: 25
                    },
                    end: {
                        line: 46,
                        column: 26
                    }
                },
                loc: {
                    start: {
                        line: 46,
                        column: 45
                    },
                    end: {
                        line: 46,
                        column: 114
                    }
                },
                line: 46
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 46,
                        column: 78
                    },
                    end: {
                        line: 46,
                        column: 79
                    }
                },
                loc: {
                    start: {
                        line: 46,
                        column: 93
                    },
                    end: {
                        line: 46,
                        column: 104
                    }
                },
                line: 46
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 53,
                        column: 24
                    },
                    end: {
                        line: 53,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 53,
                        column: 60
                    },
                    end: {
                        line: 53,
                        column: 140
                    }
                },
                line: 53
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 53,
                        column: 106
                    },
                    end: {
                        line: 53,
                        column: 107
                    }
                },
                loc: {
                    start: {
                        line: 53,
                        column: 120
                    },
                    end: {
                        line: 53,
                        column: 130
                    }
                },
                line: 53
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 60,
                        column: 32
                    },
                    end: {
                        line: 60,
                        column: 33
                    }
                },
                loc: {
                    start: {
                        line: 60,
                        column: 59
                    },
                    end: {
                        line: 60,
                        column: 149
                    }
                },
                line: 60
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 60,
                        column: 99
                    },
                    end: {
                        line: 60,
                        column: 100
                    }
                },
                loc: {
                    start: {
                        line: 60,
                        column: 121
                    },
                    end: {
                        line: 60,
                        column: 139
                    }
                },
                line: 60
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 67,
                        column: 27
                    },
                    end: {
                        line: 67,
                        column: 28
                    }
                },
                loc: {
                    start: {
                        line: 67,
                        column: 49
                    },
                    end: {
                        line: 67,
                        column: 124
                    }
                },
                line: 67
            },
            '10': {
                name: '(anonymous_10)',
                decl: {
                    start: {
                        line: 67,
                        column: 84
                    },
                    end: {
                        line: 67,
                        column: 85
                    }
                },
                loc: {
                    start: {
                        line: 67,
                        column: 101
                    },
                    end: {
                        line: 67,
                        column: 114
                    }
                },
                line: 67
            },
            '11': {
                name: '(anonymous_11)',
                decl: {
                    start: {
                        line: 74,
                        column: 26
                    },
                    end: {
                        line: 74,
                        column: 27
                    }
                },
                loc: {
                    start: {
                        line: 74,
                        column: 64
                    },
                    end: {
                        line: 74,
                        column: 150
                    }
                },
                line: 74
            },
            '12': {
                name: '(anonymous_12)',
                decl: {
                    start: {
                        line: 74,
                        column: 112
                    },
                    end: {
                        line: 74,
                        column: 113
                    }
                },
                loc: {
                    start: {
                        line: 74,
                        column: 128
                    },
                    end: {
                        line: 74,
                        column: 140
                    }
                },
                line: 74
            },
            '13': {
                name: '(anonymous_13)',
                decl: {
                    start: {
                        line: 81,
                        column: 23
                    },
                    end: {
                        line: 81,
                        column: 24
                    }
                },
                loc: {
                    start: {
                        line: 81,
                        column: 41
                    },
                    end: {
                        line: 81,
                        column: 104
                    }
                },
                line: 81
            },
            '14': {
                name: '(anonymous_14)',
                decl: {
                    start: {
                        line: 81,
                        column: 72
                    },
                    end: {
                        line: 81,
                        column: 73
                    }
                },
                loc: {
                    start: {
                        line: 81,
                        column: 85
                    },
                    end: {
                        line: 81,
                        column: 94
                    }
                },
                line: 81
            },
            '15': {
                name: '(anonymous_15)',
                decl: {
                    start: {
                        line: 88,
                        column: 22
                    },
                    end: {
                        line: 88,
                        column: 23
                    }
                },
                loc: {
                    start: {
                        line: 88,
                        column: 56
                    },
                    end: {
                        line: 88,
                        column: 130
                    }
                },
                line: 88
            },
            '16': {
                name: '(anonymous_16)',
                decl: {
                    start: {
                        line: 88,
                        column: 100
                    },
                    end: {
                        line: 88,
                        column: 101
                    }
                },
                loc: {
                    start: {
                        line: 88,
                        column: 112
                    },
                    end: {
                        line: 88,
                        column: 120
                    }
                },
                line: 88
            },
            '17': {
                name: '(anonymous_17)',
                decl: {
                    start: {
                        line: 95,
                        column: 29
                    },
                    end: {
                        line: 95,
                        column: 30
                    }
                },
                loc: {
                    start: {
                        line: 95,
                        column: 70
                    },
                    end: {
                        line: 95,
                        column: 165
                    }
                },
                line: 95
            },
            '18': {
                name: '(anonymous_18)',
                decl: {
                    start: {
                        line: 95,
                        column: 121
                    },
                    end: {
                        line: 95,
                        column: 122
                    }
                },
                loc: {
                    start: {
                        line: 95,
                        column: 140
                    },
                    end: {
                        line: 95,
                        column: 155
                    }
                },
                line: 95
            },
            '19': {
                name: '(anonymous_19)',
                decl: {
                    start: {
                        line: 102,
                        column: 23
                    },
                    end: {
                        line: 102,
                        column: 24
                    }
                },
                loc: {
                    start: {
                        line: 102,
                        column: 59
                    },
                    end: {
                        line: 102,
                        column: 137
                    }
                },
                line: 102
            },
            '20': {
                name: '(anonymous_20)',
                decl: {
                    start: {
                        line: 102,
                        column: 105
                    },
                    end: {
                        line: 102,
                        column: 106
                    }
                },
                loc: {
                    start: {
                        line: 102,
                        column: 118
                    },
                    end: {
                        line: 102,
                        column: 127
                    }
                },
                line: 102
            },
            '21': {
                name: '(anonymous_21)',
                decl: {
                    start: {
                        line: 110,
                        column: 25
                    },
                    end: {
                        line: 110,
                        column: 26
                    }
                },
                loc: {
                    start: {
                        line: 110,
                        column: 45
                    },
                    end: {
                        line: 110,
                        column: 140
                    }
                },
                line: 110
            },
            '22': {
                name: '(anonymous_22)',
                decl: {
                    start: {
                        line: 110,
                        column: 104
                    },
                    end: {
                        line: 110,
                        column: 105
                    }
                },
                loc: {
                    start: {
                        line: 110,
                        column: 119
                    },
                    end: {
                        line: 110,
                        column: 130
                    }
                },
                line: 110
            },
            '23': {
                name: '(anonymous_23)',
                decl: {
                    start: {
                        line: 117,
                        column: 23
                    },
                    end: {
                        line: 117,
                        column: 24
                    }
                },
                loc: {
                    start: {
                        line: 117,
                        column: 41
                    },
                    end: {
                        line: 117,
                        column: 104
                    }
                },
                line: 117
            },
            '24': {
                name: '(anonymous_24)',
                decl: {
                    start: {
                        line: 117,
                        column: 72
                    },
                    end: {
                        line: 117,
                        column: 73
                    }
                },
                loc: {
                    start: {
                        line: 117,
                        column: 85
                    },
                    end: {
                        line: 117,
                        column: 94
                    }
                },
                line: 117
            },
            '25': {
                name: '(anonymous_25)',
                decl: {
                    start: {
                        line: 124,
                        column: 23
                    },
                    end: {
                        line: 124,
                        column: 24
                    }
                },
                loc: {
                    start: {
                        line: 124,
                        column: 49
                    },
                    end: {
                        line: 124,
                        column: 120
                    }
                },
                line: 124
            },
            '26': {
                name: '(anonymous_26)',
                decl: {
                    start: {
                        line: 124,
                        column: 88
                    },
                    end: {
                        line: 124,
                        column: 89
                    }
                },
                loc: {
                    start: {
                        line: 124,
                        column: 101
                    },
                    end: {
                        line: 124,
                        column: 110
                    }
                },
                line: 124
            },
            '27': {
                name: '(anonymous_27)',
                decl: {
                    start: {
                        line: 131,
                        column: 17
                    },
                    end: {
                        line: 131,
                        column: 18
                    }
                },
                loc: {
                    start: {
                        line: 131,
                        column: 30
                    },
                    end: {
                        line: 131,
                        column: 78
                    }
                },
                line: 131
            },
            '28': {
                name: '(anonymous_28)',
                decl: {
                    start: {
                        line: 131,
                        column: 56
                    },
                    end: {
                        line: 131,
                        column: 57
                    }
                },
                loc: {
                    start: {
                        line: 131,
                        column: 64
                    },
                    end: {
                        line: 131,
                        column: 68
                    }
                },
                line: 131
            },
            '29': {
                name: '(anonymous_29)',
                decl: {
                    start: {
                        line: 138,
                        column: 17
                    },
                    end: {
                        line: 138,
                        column: 18
                    }
                },
                loc: {
                    start: {
                        line: 138,
                        column: 46
                    },
                    end: {
                        line: 138,
                        column: 105
                    }
                },
                line: 138
            },
            '30': {
                name: '(anonymous_30)',
                decl: {
                    start: {
                        line: 138,
                        column: 85
                    },
                    end: {
                        line: 138,
                        column: 86
                    }
                },
                loc: {
                    start: {
                        line: 138,
                        column: 92
                    },
                    end: {
                        line: 138,
                        column: 95
                    }
                },
                line: 138
            },
            '31': {
                name: '(anonymous_31)',
                decl: {
                    start: {
                        line: 145,
                        column: 24
                    },
                    end: {
                        line: 145,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 145,
                        column: 43
                    },
                    end: {
                        line: 145,
                        column: 109
                    }
                },
                line: 145
            },
            '32': {
                name: '(anonymous_32)',
                decl: {
                    start: {
                        line: 145,
                        column: 75
                    },
                    end: {
                        line: 145,
                        column: 76
                    }
                },
                loc: {
                    start: {
                        line: 145,
                        column: 89
                    },
                    end: {
                        line: 145,
                        column: 99
                    }
                },
                line: 145
            },
            '33': {
                name: '(anonymous_33)',
                decl: {
                    start: {
                        line: 152,
                        column: 28
                    },
                    end: {
                        line: 152,
                        column: 29
                    }
                },
                loc: {
                    start: {
                        line: 152,
                        column: 51
                    },
                    end: {
                        line: 152,
                        column: 129
                    }
                },
                line: 152
            },
            '34': {
                name: '(anonymous_34)',
                decl: {
                    start: {
                        line: 152,
                        column: 87
                    },
                    end: {
                        line: 152,
                        column: 88
                    }
                },
                loc: {
                    start: {
                        line: 152,
                        column: 105
                    },
                    end: {
                        line: 152,
                        column: 119
                    }
                },
                line: 152
            },
            '35': {
                name: '(anonymous_35)',
                decl: {
                    start: {
                        line: 159,
                        column: 26
                    },
                    end: {
                        line: 159,
                        column: 27
                    }
                },
                loc: {
                    start: {
                        line: 159,
                        column: 65
                    },
                    end: {
                        line: 159,
                        column: 152
                    }
                },
                line: 159
            },
            '36': {
                name: '(anonymous_36)',
                decl: {
                    start: {
                        line: 159,
                        column: 114
                    },
                    end: {
                        line: 159,
                        column: 115
                    }
                },
                loc: {
                    start: {
                        line: 159,
                        column: 130
                    },
                    end: {
                        line: 159,
                        column: 142
                    }
                },
                line: 159
            },
            '37': {
                name: '(anonymous_37)',
                decl: {
                    start: {
                        line: 166,
                        column: 30
                    },
                    end: {
                        line: 166,
                        column: 31
                    }
                },
                loc: {
                    start: {
                        line: 166,
                        column: 55
                    },
                    end: {
                        line: 166,
                        column: 139
                    }
                },
                line: 166
            },
            '38': {
                name: '(anonymous_38)',
                decl: {
                    start: {
                        line: 166,
                        column: 93
                    },
                    end: {
                        line: 166,
                        column: 94
                    }
                },
                loc: {
                    start: {
                        line: 166,
                        column: 113
                    },
                    end: {
                        line: 166,
                        column: 129
                    }
                },
                line: 166
            },
            '39': {
                name: '(anonymous_39)',
                decl: {
                    start: {
                        line: 177,
                        column: 20
                    },
                    end: {
                        line: 177,
                        column: 21
                    }
                },
                loc: {
                    start: {
                        line: 177,
                        column: 34
                    },
                    end: {
                        line: 177,
                        column: 87
                    }
                },
                line: 177
            },
            '40': {
                name: '(anonymous_40)',
                decl: {
                    start: {
                        line: 177,
                        column: 61
                    },
                    end: {
                        line: 177,
                        column: 62
                    }
                },
                loc: {
                    start: {
                        line: 177,
                        column: 71
                    },
                    end: {
                        line: 177,
                        column: 77
                    }
                },
                line: 177
            },
            '41': {
                name: '(anonymous_41)',
                decl: {
                    start: {
                        line: 184,
                        column: 19
                    },
                    end: {
                        line: 184,
                        column: 20
                    }
                },
                loc: {
                    start: {
                        line: 184,
                        column: 49
                    },
                    end: {
                        line: 184,
                        column: 113
                    }
                },
                line: 184
            },
            '42': {
                name: '(anonymous_42)',
                decl: {
                    start: {
                        line: 184,
                        column: 89
                    },
                    end: {
                        line: 184,
                        column: 90
                    }
                },
                loc: {
                    start: {
                        line: 184,
                        column: 98
                    },
                    end: {
                        line: 184,
                        column: 103
                    }
                },
                line: 184
            },
            '43': {
                name: '(anonymous_43)',
                decl: {
                    start: {
                        line: 191,
                        column: 26
                    },
                    end: {
                        line: 191,
                        column: 27
                    }
                },
                loc: {
                    start: {
                        line: 191,
                        column: 59
                    },
                    end: {
                        line: 191,
                        column: 147
                    }
                },
                line: 191
            },
            '44': {
                name: '(anonymous_44)',
                decl: {
                    start: {
                        line: 191,
                        column: 109
                    },
                    end: {
                        line: 191,
                        column: 110
                    }
                },
                loc: {
                    start: {
                        line: 191,
                        column: 125
                    },
                    end: {
                        line: 191,
                        column: 137
                    }
                },
                line: 191
            },
            '45': {
                name: '(anonymous_45)',
                decl: {
                    start: {
                        line: 198,
                        column: 23
                    },
                    end: {
                        line: 198,
                        column: 24
                    }
                },
                loc: {
                    start: {
                        line: 198,
                        column: 58
                    },
                    end: {
                        line: 198,
                        column: 135
                    }
                },
                line: 198
            },
            '46': {
                name: '(anonymous_46)',
                decl: {
                    start: {
                        line: 198,
                        column: 103
                    },
                    end: {
                        line: 198,
                        column: 104
                    }
                },
                loc: {
                    start: {
                        line: 198,
                        column: 116
                    },
                    end: {
                        line: 198,
                        column: 125
                    }
                },
                line: 198
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0,
            '28': 0,
            '29': 0,
            '30': 0,
            '31': 0,
            '32': 0,
            '33': 0,
            '34': 0,
            '35': 0,
            '36': 0,
            '37': 0,
            '38': 0,
            '39': 0,
            '40': 0,
            '41': 0,
            '42': 0,
            '43': 0,
            '44': 0,
            '45': 0,
            '46': 0,
            '47': 0,
            '48': 0,
            '49': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0,
            '28': 0,
            '29': 0,
            '30': 0,
            '31': 0,
            '32': 0,
            '33': 0,
            '34': 0,
            '35': 0,
            '36': 0,
            '37': 0,
            '38': 0,
            '39': 0,
            '40': 0,
            '41': 0,
            '42': 0,
            '43': 0,
            '44': 0,
            '45': 0,
            '46': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_1c2k3loppc.s[0]++;
angular.module('app').config(routesConfig);

/** @ngInject */
function routesConfig($stateProvider, $urlRouterProvider, $locationProvider) {
    cov_1c2k3loppc.f[0]++;
    cov_1c2k3loppc.s[1]++;

    $locationProvider.html5Mode(true).hashPrefix('!');
    cov_1c2k3loppc.s[2]++;
    $urlRouterProvider.otherwise('/');

    cov_1c2k3loppc.s[3]++;
    $stateProvider.state('login', {
        url: '/login',
        templateUrl: 'app/components/auth/views/auth.login.view.html',
        controller: 'AuthLoginController',
        controllerAs: 'vm'
    }).state('logout', {
        url: '/logout',
        template: '<div></div>',
        controller: 'AuthLogoutController',
        controllerAs: 'vm'
    }).state('profile', {
        url: '/perfil',
        template: '<edit-profile user="$resolve.user" flex layout="column"/>',
        resolve: {
            user: function user($rootScope, UsersTable) {
                cov_1c2k3loppc.f[1]++;
                cov_1c2k3loppc.s[4]++;
                return UsersTable.$find($rootScope.me.id).$then(function (user) {
                    cov_1c2k3loppc.f[2]++;
                    cov_1c2k3loppc.s[5]++;
                    return user;
                }).$promise;
            }
        }
    }).state('forgot', {
        url: '/recuperar_password',
        templateUrl: 'app/components/auth/views/auth.forgot.html',
        controller: 'AuthForgotController',
        controllerAs: 'vm'
    }).state('set_password', {
        url: '/alterar_password?email&token',
        templateUrl: 'app/components/auth/views/auth.set.password.html',
        controller: 'AuthSetPasswordController',
        controllerAs: 'vm'
    }).state('individualsList', {
        url: '/individuals',
        template: '<individuals-list individuals="$resolve.individuals" flex layout="column"/>',
        resolve: {
            individuals: function individuals(IndividualsTable) {
                cov_1c2k3loppc.f[3]++;
                cov_1c2k3loppc.s[6]++;
                return IndividualsTable.$search().$then(function (individuals) {
                    cov_1c2k3loppc.f[4]++;
                    cov_1c2k3loppc.s[7]++;
                    return individuals;
                }).$promise;
            }
        }
    }).state('individualDetails', {
        url: '/individual/:id',
        template: '<individual-details individual="$resolve.individual" flex layout="column"/>',
        resolve: {
            individual: function individual(IndividualsTable, $stateParams) {
                cov_1c2k3loppc.f[5]++;
                cov_1c2k3loppc.s[8]++;
                return IndividualsTable.$find($stateParams.id).$then(function (individual) {
                    cov_1c2k3loppc.f[6]++;
                    cov_1c2k3loppc.s[9]++;
                    return individual;
                }).$promise;
            }
        }
    }).state('economicActivitiesList', {
        url: '/economic-activities',
        template: '<economic-activities-list economic-activities="$resolve.economicActivities" flex layout="column"/>',
        resolve: {
            economicActivities: function economicActivities(EconomicActivitiesTable) {
                cov_1c2k3loppc.f[7]++;
                cov_1c2k3loppc.s[10]++;
                return EconomicActivitiesTable.$search().$then(function (economicActivities) {
                    cov_1c2k3loppc.f[8]++;
                    cov_1c2k3loppc.s[11]++;
                    return economicActivities;
                }).$promise;
            }
        }
    }).state('organizationsList', {
        url: '/organizations?tag&size&turnover&municipality&municipality__district&profile_level&interactions&people_count&target_state&target_score',
        template: '<organizations-list organizations="$resolve.organizations" flex layout="column"/>',
        resolve: {
            organizations: function organizations(OrganizationsTable) {
                cov_1c2k3loppc.f[9]++;
                cov_1c2k3loppc.s[12]++;
                return OrganizationsTable.$search().$then(function (organizations) {
                    cov_1c2k3loppc.f[10]++;
                    cov_1c2k3loppc.s[13]++;
                    return organizations;
                }).$promise;
            }
        }
    }).state('organizationDetails', {
        url: '/organization/:id',
        template: '<organization-details organization="$resolve.organization" flex layout="column"/>',
        resolve: {
            organization: function organization(OrganizationsTable, $stateParams) {
                cov_1c2k3loppc.f[11]++;
                cov_1c2k3loppc.s[14]++;
                return OrganizationsTable.$find($stateParams.id).$then(function (organization) {
                    cov_1c2k3loppc.f[12]++;
                    cov_1c2k3loppc.s[15]++;
                    return organization;
                }).$promise;
            }
        }
    }).state('proposalsList', {
        url: '/proposals',
        template: '<proposals-list proposals="$resolve.proposals" flex layout="column"/>',
        resolve: {
            proposals: function proposals(ProposalsTable) {
                cov_1c2k3loppc.f[13]++;
                cov_1c2k3loppc.s[16]++;
                return ProposalsTable.$search().$then(function (proposals) {
                    cov_1c2k3loppc.f[14]++;
                    cov_1c2k3loppc.s[17]++;
                    return proposals;
                }).$promise;
            }
        }
    }).state('proposalDetails', {
        url: '/proposal/:id',
        template: '<proposal-details proposal="$resolve.proposal" flex layout="column"/>',
        resolve: {
            proposal: function proposal(ProposalsTable, $stateParams) {
                cov_1c2k3loppc.f[15]++;
                cov_1c2k3loppc.s[18]++;
                return ProposalsTable.$find($stateParams.id).$then(function (proposal) {
                    cov_1c2k3loppc.f[16]++;
                    cov_1c2k3loppc.s[19]++;
                    return proposal;
                }).$promise;
            }
        }
    }).state('proposalSectionDetails', {
        url: '/proposal-section/:id',
        template: '<proposal-section-details proposal-section="$resolve.proposalSection" flex layout="column"/>',
        resolve: {
            proposalSection: function proposalSection(ProposalSectionsTable, $stateParams) {
                cov_1c2k3loppc.f[17]++;
                cov_1c2k3loppc.s[20]++;
                return ProposalSectionsTable.$find($stateParams.id).$then(function (proposalSection) {
                    cov_1c2k3loppc.f[18]++;
                    cov_1c2k3loppc.s[21]++;
                    return proposalSection;
                }).$promise;
            }
        }
    }).state('userStoryDetails', {
        url: '/user-story/:id',
        template: '<user-story-details user-story="$resolve.userStory" flex layout="column"/>',
        resolve: {
            userStory: function userStory(UserStoriesTable, $stateParams) {
                cov_1c2k3loppc.f[19]++;
                cov_1c2k3loppc.s[22]++;
                return UserStoriesTable.$find($stateParams.id).$then(function (userStory) {
                    cov_1c2k3loppc.f[20]++;
                    cov_1c2k3loppc.s[23]++;
                    return userStory;
                }).$promise;
            }
        }
    }).state('userStoriesList', {
        url: '/user-stories',
        template: '<user-stories-list user-stories="$resolve.userStories" flex layout="column"/>',
        resolve: {
            /* eslint-disable camelcase */
            userStories: function userStories(UserStoriesTable) {
                cov_1c2k3loppc.f[21]++;
                cov_1c2k3loppc.s[24]++;
                return UserStoriesTable.$search({ proposal__isnull: 'True' }).$then(function (userStories) {
                    cov_1c2k3loppc.f[22]++;
                    cov_1c2k3loppc.s[25]++;
                    return userStories;
                }).$promise;
            }
        }
    }).state('remindersList', {
        url: '/reminders',
        template: '<reminders-list reminders="$resolve.reminders" flex layout="column"/>',
        resolve: {
            reminders: function reminders(RemindersTable) {
                cov_1c2k3loppc.f[23]++;
                cov_1c2k3loppc.s[26]++;
                return RemindersTable.$search().$then(function (reminders) {
                    cov_1c2k3loppc.f[24]++;
                    cov_1c2k3loppc.s[27]++;
                    return reminders;
                }).$promise;
            }
        }
    }).state('documentsList', {
        url: '/documents',
        template: '<documents-list documents="$resolve.documents" flex layout="column"/>',
        resolve: {
            documents: function documents(OrganizationFilesTable) {
                cov_1c2k3loppc.f[25]++;
                cov_1c2k3loppc.s[28]++;
                return OrganizationFilesTable.$search().$then(function (documents) {
                    cov_1c2k3loppc.f[26]++;
                    cov_1c2k3loppc.s[29]++;
                    return documents;
                }).$promise;
            }
        }
    }).state('tagsList', {
        url: '/tags',
        template: '<tags-list tags="$resolve.tag" flex layout="column"/>',
        resolve: {
            tag: function tag(TagsTable) {
                cov_1c2k3loppc.f[27]++;
                cov_1c2k3loppc.s[30]++;
                return TagsTable.$search().$then(function (tags) {
                    cov_1c2k3loppc.f[28]++;
                    cov_1c2k3loppc.s[31]++;
                    return tags;
                }).$promise;
            }
        }
    }).state('tagDetails', {
        url: '/tag/:id',
        template: '<tag-details tag="$resolve.tag" flex layout="column"/>',
        resolve: {
            tag: function tag(TagsTable, $stateParams) {
                cov_1c2k3loppc.f[29]++;
                cov_1c2k3loppc.s[32]++;
                return TagsTable.$find($stateParams.id).$then(function (tag) {
                    cov_1c2k3loppc.f[30]++;
                    cov_1c2k3loppc.s[33]++;
                    return tag;
                }).$promise;
            }
        }
    }).state('componentsList', {
        url: '/components',
        template: '<components-list components="$resolve.components" flex layout="column"/>',
        resolve: {
            components: function components(ComponentsTable) {
                cov_1c2k3loppc.f[31]++;
                cov_1c2k3loppc.s[34]++;
                return ComponentsTable.$search().$then(function (components) {
                    cov_1c2k3loppc.f[32]++;
                    cov_1c2k3loppc.s[35]++;
                    return components;
                }).$promise;
            }
        }
    }).state('municipalitiesList', {
        url: '/municipalities',
        template: '<municipalities-list municipalities="$resolve.municipalities" flex layout="column"/>',
        resolve: {
            municipalities: function municipalities(MunicipalitiesTable) {
                cov_1c2k3loppc.f[33]++;
                cov_1c2k3loppc.s[36]++;
                return MunicipalitiesTable.$search().$then(function (municipalities) {
                    cov_1c2k3loppc.f[34]++;
                    cov_1c2k3loppc.s[37]++;
                    return municipalities;
                }).$promise;
            }
        }
    }).state('municipalityDetails', {
        url: '/municipality/:id',
        template: '<municipality-details municipality="$resolve.municipality" flex layout="column"/>',
        resolve: {
            municipality: function municipality(MunicipalitiesTable, $stateParams) {
                cov_1c2k3loppc.f[35]++;
                cov_1c2k3loppc.s[38]++;
                return MunicipalitiesTable.$find($stateParams.id).$then(function (municipality) {
                    cov_1c2k3loppc.f[36]++;
                    cov_1c2k3loppc.s[39]++;
                    return municipality;
                }).$promise;
            }
        }
    }).state('interactionTypesList', {
        url: '/interaction-types',
        template: '<interaction-types-list interaction-types="$resolve.interactionTypes" flex layout="column"/>',
        resolve: {
            interactionTypes: function interactionTypes(InteractionTypesTable) {
                cov_1c2k3loppc.f[37]++;
                cov_1c2k3loppc.s[40]++;
                return InteractionTypesTable.$search().$then(function (interactionTypes) {
                    cov_1c2k3loppc.f[38]++;
                    cov_1c2k3loppc.s[41]++;
                    return interactionTypes;
                }).$promise;
            }
        }
    }).state('dashboard', {
        url: '/',
        template: '<dashboard flex layout="column"/>'
    }).state('mediaList', {
        url: '/media',
        template: '<media-list medias="$resolve.medias" flex layout="column"/>',
        resolve: {
            medias: function medias(LinksTable) {
                cov_1c2k3loppc.f[39]++;
                cov_1c2k3loppc.s[42]++;
                return LinksTable.$search().$then(function (medias) {
                    cov_1c2k3loppc.f[40]++;
                    cov_1c2k3loppc.s[43]++;
                    return medias;
                }).$promise;
            }
        }
    }).state('mediaDetails', {
        url: '/media/:id',
        template: '<media-details media="$resolve.media" flex layout="column"/>',
        resolve: {
            media: function media(LinksTable, $stateParams) {
                cov_1c2k3loppc.f[41]++;
                cov_1c2k3loppc.s[44]++;
                return LinksTable.$find($stateParams.id).$then(function (media) {
                    cov_1c2k3loppc.f[42]++;
                    cov_1c2k3loppc.s[45]++;
                    return media;
                }).$promise;
            }
        }
    }).state('interactionsList', {
        url: '/interactions',
        template: '<interactions-list interactions="$resolve.interactions" flex layout="column"/>',
        resolve: {
            interactions: function interactions(OrganizationInteractionsTable) {
                cov_1c2k3loppc.f[43]++;
                cov_1c2k3loppc.s[46]++;
                return OrganizationInteractionsTable.$collection().$then(function (interactions) {
                    cov_1c2k3loppc.f[44]++;
                    cov_1c2k3loppc.s[47]++;
                    return interactions;
                }).$promise;
            }
        }
    }).state('workspaceDetails', {
        url: '/workspace/:id',
        template: '<workspace-details workspace="$resolve.workspace" flex layout="column"/>',
        resolve: {
            workspace: function workspace(WorkspacesTable, $stateParams) {
                cov_1c2k3loppc.f[45]++;
                cov_1c2k3loppc.s[48]++;
                return WorkspacesTable.$find($stateParams.id).$then(function (workspace) {
                    cov_1c2k3loppc.f[46]++;
                    cov_1c2k3loppc.s[49]++;
                    return workspace;
                }).$promise;
            }
        }
    });
}