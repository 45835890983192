'use strict';

var cov_4pfz0f5or = function () {
    var path = '/home/lobo/repos/prospector-app/src/app/components/economicActivitiesList/economicActivitiesList.js',
        hash = '42b426be4cbfc281da4b071c2a462c8131842e70',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/prospector-app/src/app/components/economicActivitiesList/economicActivitiesList.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 8,
                    column: 3
                }
            }
        },
        fnMap: {},
        branchMap: {},
        s: {
            '0': 0
        },
        f: {},
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_4pfz0f5or.s[0]++;
angular.module('app').component('economicActivitiesList', {
    templateUrl: 'app/components/economicActivitiesList/economicActivitiesList.html',
    bindings: {
        economicActivities: '<'
    }
});