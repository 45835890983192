'use strict';

var cov_2mb0e4p6ud = function () {
    var path = '/home/lobo/repos/prospector-app/src/app/components/proposalsList/proposalsList.js',
        hash = 'aa97274bb4702aaa019e0619636d5bf8c39a6341',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/prospector-app/src/app/components/proposalsList/proposalsList.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 39
                }
            },
            '1': {
                start: {
                    line: 8,
                    column: 8
                },
                end: {
                    line: 8,
                    column: 63
                }
            },
            '2': {
                start: {
                    line: 9,
                    column: 8
                },
                end: {
                    line: 9,
                    column: 53
                }
            },
            '3': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 10,
                    column: 33
                }
            },
            '4': {
                start: {
                    line: 11,
                    column: 8
                },
                end: {
                    line: 11,
                    column: 52
                }
            },
            '5': {
                start: {
                    line: 12,
                    column: 8
                },
                end: {
                    line: 12,
                    column: 27
                }
            },
            '6': {
                start: {
                    line: 13,
                    column: 8
                },
                end: {
                    line: 13,
                    column: 32
                }
            },
            '7': {
                start: {
                    line: 15,
                    column: 8
                },
                end: {
                    line: 15,
                    column: 77
                }
            },
            '8': {
                start: {
                    line: 18,
                    column: 8
                },
                end: {
                    line: 21,
                    column: 11
                }
            },
            '9': {
                start: {
                    line: 19,
                    column: 12
                },
                end: {
                    line: 19,
                    column: 40
                }
            },
            '10': {
                start: {
                    line: 20,
                    column: 12
                },
                end: {
                    line: 20,
                    column: 26
                }
            },
            '11': {
                start: {
                    line: 23,
                    column: 8
                },
                end: {
                    line: 26,
                    column: 11
                }
            },
            '12': {
                start: {
                    line: 24,
                    column: 12
                },
                end: {
                    line: 24,
                    column: 48
                }
            },
            '13': {
                start: {
                    line: 25,
                    column: 12
                },
                end: {
                    line: 25,
                    column: 26
                }
            },
            '14': {
                start: {
                    line: 28,
                    column: 8
                },
                end: {
                    line: 32,
                    column: 11
                }
            },
            '15': {
                start: {
                    line: 30,
                    column: 12
                },
                end: {
                    line: 30,
                    column: 55
                }
            },
            '16': {
                start: {
                    line: 31,
                    column: 12
                },
                end: {
                    line: 31,
                    column: 26
                }
            },
            '17': {
                start: {
                    line: 36,
                    column: 8
                },
                end: {
                    line: 36,
                    column: 50
                }
            },
            '18': {
                start: {
                    line: 37,
                    column: 8
                },
                end: {
                    line: 37,
                    column: 39
                }
            },
            '19': {
                start: {
                    line: 41,
                    column: 8
                },
                end: {
                    line: 43,
                    column: 11
                }
            },
            '20': {
                start: {
                    line: 42,
                    column: 12
                },
                end: {
                    line: 42,
                    column: 42
                }
            },
            '21': {
                start: {
                    line: 47,
                    column: 8
                },
                end: {
                    line: 54,
                    column: 9
                }
            },
            '22': {
                start: {
                    line: 48,
                    column: 12
                },
                end: {
                    line: 48,
                    column: 69
                }
            },
            '23': {
                start: {
                    line: 49,
                    column: 12
                },
                end: {
                    line: 51,
                    column: 13
                }
            },
            '24': {
                start: {
                    line: 50,
                    column: 16
                },
                end: {
                    line: 50,
                    column: 40
                }
            },
            '25': {
                start: {
                    line: 53,
                    column: 12
                },
                end: {
                    line: 53,
                    column: 41
                }
            },
            '26': {
                start: {
                    line: 58,
                    column: 8
                },
                end: {
                    line: 58,
                    column: 54
                }
            },
            '27': {
                start: {
                    line: 62,
                    column: 8
                },
                end: {
                    line: 62,
                    column: 43
                }
            },
            '28': {
                start: {
                    line: 63,
                    column: 8
                },
                end: {
                    line: 71,
                    column: 9
                }
            },
            '29': {
                start: {
                    line: 64,
                    column: 12
                },
                end: {
                    line: 68,
                    column: 15
                }
            },
            '30': {
                start: {
                    line: 65,
                    column: 16
                },
                end: {
                    line: 67,
                    column: 17
                }
            },
            '31': {
                start: {
                    line: 66,
                    column: 20
                },
                end: {
                    line: 66,
                    column: 49
                }
            },
            '32': {
                start: {
                    line: 70,
                    column: 12
                },
                end: {
                    line: 70,
                    column: 31
                }
            },
            '33': {
                start: {
                    line: 75,
                    column: 8
                },
                end: {
                    line: 75,
                    column: 25
                }
            },
            '34': {
                start: {
                    line: 76,
                    column: 8
                },
                end: {
                    line: 90,
                    column: 9
                }
            },
            '35': {
                start: {
                    line: 77,
                    column: 12
                },
                end: {
                    line: 83,
                    column: 15
                }
            },
            '36': {
                start: {
                    line: 78,
                    column: 16
                },
                end: {
                    line: 78,
                    column: 39
                }
            },
            '37': {
                start: {
                    line: 79,
                    column: 16
                },
                end: {
                    line: 81,
                    column: 19
                }
            },
            '38': {
                start: {
                    line: 80,
                    column: 20
                },
                end: {
                    line: 80,
                    column: 38
                }
            },
            '39': {
                start: {
                    line: 82,
                    column: 16
                },
                end: {
                    line: 82,
                    column: 34
                }
            },
            '40': {
                start: {
                    line: 84,
                    column: 12
                },
                end: {
                    line: 86,
                    column: 13
                }
            },
            '41': {
                start: {
                    line: 85,
                    column: 16
                },
                end: {
                    line: 85,
                    column: 40
                }
            },
            '42': {
                start: {
                    line: 87,
                    column: 12
                },
                end: {
                    line: 87,
                    column: 31
                }
            },
            '43': {
                start: {
                    line: 88,
                    column: 12
                },
                end: {
                    line: 88,
                    column: 101
                }
            },
            '44': {
                start: {
                    line: 89,
                    column: 12
                },
                end: {
                    line: 89,
                    column: 97
                }
            },
            '45': {
                start: {
                    line: 94,
                    column: 8
                },
                end: {
                    line: 94,
                    column: 25
                }
            },
            '46': {
                start: {
                    line: 95,
                    column: 8
                },
                end: {
                    line: 95,
                    column: 27
                }
            },
            '47': {
                start: {
                    line: 96,
                    column: 8
                },
                end: {
                    line: 101,
                    column: 9
                }
            },
            '48': {
                start: {
                    line: 97,
                    column: 12
                },
                end: {
                    line: 97,
                    column: 46
                }
            },
            '49': {
                start: {
                    line: 98,
                    column: 12
                },
                end: {
                    line: 100,
                    column: 15
                }
            },
            '50': {
                start: {
                    line: 99,
                    column: 16
                },
                end: {
                    line: 99,
                    column: 46
                }
            },
            '51': {
                start: {
                    line: 102,
                    column: 8
                },
                end: {
                    line: 107,
                    column: 9
                }
            },
            '52': {
                start: {
                    line: 103,
                    column: 12
                },
                end: {
                    line: 103,
                    column: 47
                }
            },
            '53': {
                start: {
                    line: 104,
                    column: 12
                },
                end: {
                    line: 106,
                    column: 15
                }
            },
            '54': {
                start: {
                    line: 105,
                    column: 16
                },
                end: {
                    line: 105,
                    column: 46
                }
            },
            '55': {
                start: {
                    line: 111,
                    column: 8
                },
                end: {
                    line: 111,
                    column: 28
                }
            },
            '56': {
                start: {
                    line: 112,
                    column: 8
                },
                end: {
                    line: 123,
                    column: 11
                }
            },
            '57': {
                start: {
                    line: 113,
                    column: 12
                },
                end: {
                    line: 113,
                    column: 32
                }
            },
            '58': {
                start: {
                    line: 114,
                    column: 12
                },
                end: {
                    line: 116,
                    column: 13
                }
            },
            '59': {
                start: {
                    line: 115,
                    column: 16
                },
                end: {
                    line: 115,
                    column: 50
                }
            },
            '60': {
                start: {
                    line: 118,
                    column: 12
                },
                end: {
                    line: 118,
                    column: 30
                }
            },
            '61': {
                start: {
                    line: 119,
                    column: 12
                },
                end: {
                    line: 119,
                    column: 32
                }
            },
            '62': {
                start: {
                    line: 120,
                    column: 12
                },
                end: {
                    line: 122,
                    column: 13
                }
            },
            '63': {
                start: {
                    line: 121,
                    column: 16
                },
                end: {
                    line: 121,
                    column: 50
                }
            },
            '64': {
                start: {
                    line: 127,
                    column: 8
                },
                end: {
                    line: 127,
                    column: 27
                }
            },
            '65': {
                start: {
                    line: 128,
                    column: 8
                },
                end: {
                    line: 130,
                    column: 9
                }
            },
            '66': {
                start: {
                    line: 129,
                    column: 12
                },
                end: {
                    line: 129,
                    column: 36
                }
            },
            '67': {
                start: {
                    line: 131,
                    column: 8
                },
                end: {
                    line: 131,
                    column: 34
                }
            },
            '68': {
                start: {
                    line: 132,
                    column: 8
                },
                end: {
                    line: 132,
                    column: 67
                }
            },
            '69': {
                start: {
                    line: 133,
                    column: 8
                },
                end: {
                    line: 133,
                    column: 93
                }
            },
            '70': {
                start: {
                    line: 137,
                    column: 0
                },
                end: {
                    line: 145,
                    column: 3
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 14
                    },
                    end: {
                        line: 5,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 7,
                        column: 4
                    },
                    end: {
                        line: 7,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 7,
                        column: 96
                    },
                    end: {
                        line: 33,
                        column: 5
                    }
                },
                line: 7
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 18,
                        column: 38
                    },
                    end: {
                        line: 18,
                        column: 39
                    }
                },
                loc: {
                    start: {
                        line: 18,
                        column: 55
                    },
                    end: {
                        line: 21,
                        column: 9
                    }
                },
                line: 18
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 23,
                        column: 39
                    },
                    end: {
                        line: 23,
                        column: 40
                    }
                },
                loc: {
                    start: {
                        line: 23,
                        column: 56
                    },
                    end: {
                        line: 26,
                        column: 9
                    }
                },
                line: 23
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 28,
                        column: 37
                    },
                    end: {
                        line: 28,
                        column: 38
                    }
                },
                loc: {
                    start: {
                        line: 28,
                        column: 54
                    },
                    end: {
                        line: 32,
                        column: 9
                    }
                },
                line: 28
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 35,
                        column: 4
                    },
                    end: {
                        line: 35,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 35,
                        column: 13
                    },
                    end: {
                        line: 38,
                        column: 5
                    }
                },
                line: 35
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 40,
                        column: 4
                    },
                    end: {
                        line: 40,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 40,
                        column: 30
                    },
                    end: {
                        line: 44,
                        column: 5
                    }
                },
                line: 40
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 41,
                        column: 52
                    },
                    end: {
                        line: 41,
                        column: 53
                    }
                },
                loc: {
                    start: {
                        line: 41,
                        column: 64
                    },
                    end: {
                        line: 43,
                        column: 9
                    }
                },
                line: 41
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 46,
                        column: 4
                    },
                    end: {
                        line: 46,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 46,
                        column: 28
                    },
                    end: {
                        line: 55,
                        column: 5
                    }
                },
                line: 46
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 57,
                        column: 4
                    },
                    end: {
                        line: 57,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 57,
                        column: 29
                    },
                    end: {
                        line: 59,
                        column: 5
                    }
                },
                line: 57
            },
            '10': {
                name: '(anonymous_10)',
                decl: {
                    start: {
                        line: 61,
                        column: 4
                    },
                    end: {
                        line: 61,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 61,
                        column: 24
                    },
                    end: {
                        line: 72,
                        column: 5
                    }
                },
                line: 61
            },
            '11': {
                name: '(anonymous_11)',
                decl: {
                    start: {
                        line: 64,
                        column: 30
                    },
                    end: {
                        line: 64,
                        column: 31
                    }
                },
                loc: {
                    start: {
                        line: 64,
                        column: 42
                    },
                    end: {
                        line: 68,
                        column: 13
                    }
                },
                line: 64
            },
            '12': {
                name: '(anonymous_12)',
                decl: {
                    start: {
                        line: 74,
                        column: 4
                    },
                    end: {
                        line: 74,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 74,
                        column: 27
                    },
                    end: {
                        line: 91,
                        column: 5
                    }
                },
                line: 74
            },
            '13': {
                name: '(anonymous_13)',
                decl: {
                    start: {
                        line: 77,
                        column: 34
                    },
                    end: {
                        line: 77,
                        column: 35
                    }
                },
                loc: {
                    start: {
                        line: 77,
                        column: 46
                    },
                    end: {
                        line: 83,
                        column: 13
                    }
                },
                line: 77
            },
            '14': {
                name: '(anonymous_14)',
                decl: {
                    start: {
                        line: 79,
                        column: 48
                    },
                    end: {
                        line: 79,
                        column: 49
                    }
                },
                loc: {
                    start: {
                        line: 79,
                        column: 60
                    },
                    end: {
                        line: 81,
                        column: 17
                    }
                },
                line: 79
            },
            '15': {
                name: '(anonymous_15)',
                decl: {
                    start: {
                        line: 93,
                        column: 4
                    },
                    end: {
                        line: 93,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 93,
                        column: 28
                    },
                    end: {
                        line: 108,
                        column: 5
                    }
                },
                line: 93
            },
            '16': {
                name: '(anonymous_16)',
                decl: {
                    start: {
                        line: 98,
                        column: 34
                    },
                    end: {
                        line: 98,
                        column: 35
                    }
                },
                loc: {
                    start: {
                        line: 98,
                        column: 46
                    },
                    end: {
                        line: 100,
                        column: 13
                    }
                },
                line: 98
            },
            '17': {
                name: '(anonymous_17)',
                decl: {
                    start: {
                        line: 104,
                        column: 35
                    },
                    end: {
                        line: 104,
                        column: 36
                    }
                },
                loc: {
                    start: {
                        line: 104,
                        column: 47
                    },
                    end: {
                        line: 106,
                        column: 13
                    }
                },
                line: 104
            },
            '18': {
                name: '(anonymous_18)',
                decl: {
                    start: {
                        line: 110,
                        column: 4
                    },
                    end: {
                        line: 110,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 110,
                        column: 29
                    },
                    end: {
                        line: 124,
                        column: 5
                    }
                },
                line: 110
            },
            '19': {
                name: '(anonymous_19)',
                decl: {
                    start: {
                        line: 112,
                        column: 32
                    },
                    end: {
                        line: 112,
                        column: 33
                    }
                },
                loc: {
                    start: {
                        line: 112,
                        column: 38
                    },
                    end: {
                        line: 117,
                        column: 9
                    }
                },
                line: 112
            },
            '20': {
                name: '(anonymous_20)',
                decl: {
                    start: {
                        line: 117,
                        column: 11
                    },
                    end: {
                        line: 117,
                        column: 12
                    }
                },
                loc: {
                    start: {
                        line: 117,
                        column: 17
                    },
                    end: {
                        line: 123,
                        column: 9
                    }
                },
                line: 117
            },
            '21': {
                name: '(anonymous_21)',
                decl: {
                    start: {
                        line: 126,
                        column: 4
                    },
                    end: {
                        line: 126,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 126,
                        column: 28
                    },
                    end: {
                        line: 134,
                        column: 5
                    }
                },
                line: 126
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 47,
                        column: 8
                    },
                    end: {
                        line: 54,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 47,
                        column: 8
                    },
                    end: {
                        line: 54,
                        column: 9
                    }
                }, {
                    start: {
                        line: 47,
                        column: 8
                    },
                    end: {
                        line: 54,
                        column: 9
                    }
                }],
                line: 47
            },
            '1': {
                loc: {
                    start: {
                        line: 49,
                        column: 12
                    },
                    end: {
                        line: 51,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 49,
                        column: 12
                    },
                    end: {
                        line: 51,
                        column: 13
                    }
                }, {
                    start: {
                        line: 49,
                        column: 12
                    },
                    end: {
                        line: 51,
                        column: 13
                    }
                }],
                line: 49
            },
            '2': {
                loc: {
                    start: {
                        line: 63,
                        column: 8
                    },
                    end: {
                        line: 71,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 63,
                        column: 8
                    },
                    end: {
                        line: 71,
                        column: 9
                    }
                }, {
                    start: {
                        line: 63,
                        column: 8
                    },
                    end: {
                        line: 71,
                        column: 9
                    }
                }],
                line: 63
            },
            '3': {
                loc: {
                    start: {
                        line: 65,
                        column: 16
                    },
                    end: {
                        line: 67,
                        column: 17
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 65,
                        column: 16
                    },
                    end: {
                        line: 67,
                        column: 17
                    }
                }, {
                    start: {
                        line: 65,
                        column: 16
                    },
                    end: {
                        line: 67,
                        column: 17
                    }
                }],
                line: 65
            },
            '4': {
                loc: {
                    start: {
                        line: 76,
                        column: 8
                    },
                    end: {
                        line: 90,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 76,
                        column: 8
                    },
                    end: {
                        line: 90,
                        column: 9
                    }
                }, {
                    start: {
                        line: 76,
                        column: 8
                    },
                    end: {
                        line: 90,
                        column: 9
                    }
                }],
                line: 76
            },
            '5': {
                loc: {
                    start: {
                        line: 84,
                        column: 12
                    },
                    end: {
                        line: 86,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 84,
                        column: 12
                    },
                    end: {
                        line: 86,
                        column: 13
                    }
                }, {
                    start: {
                        line: 84,
                        column: 12
                    },
                    end: {
                        line: 86,
                        column: 13
                    }
                }],
                line: 84
            },
            '6': {
                loc: {
                    start: {
                        line: 96,
                        column: 8
                    },
                    end: {
                        line: 101,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 96,
                        column: 8
                    },
                    end: {
                        line: 101,
                        column: 9
                    }
                }, {
                    start: {
                        line: 96,
                        column: 8
                    },
                    end: {
                        line: 101,
                        column: 9
                    }
                }],
                line: 96
            },
            '7': {
                loc: {
                    start: {
                        line: 102,
                        column: 8
                    },
                    end: {
                        line: 107,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 102,
                        column: 8
                    },
                    end: {
                        line: 107,
                        column: 9
                    }
                }, {
                    start: {
                        line: 102,
                        column: 8
                    },
                    end: {
                        line: 107,
                        column: 9
                    }
                }],
                line: 102
            },
            '8': {
                loc: {
                    start: {
                        line: 114,
                        column: 12
                    },
                    end: {
                        line: 116,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 114,
                        column: 12
                    },
                    end: {
                        line: 116,
                        column: 13
                    }
                }, {
                    start: {
                        line: 114,
                        column: 12
                    },
                    end: {
                        line: 116,
                        column: 13
                    }
                }],
                line: 114
            },
            '9': {
                loc: {
                    start: {
                        line: 120,
                        column: 12
                    },
                    end: {
                        line: 122,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 120,
                        column: 12
                    },
                    end: {
                        line: 122,
                        column: 13
                    }
                }, {
                    start: {
                        line: 120,
                        column: 12
                    },
                    end: {
                        line: 122,
                        column: 13
                    }
                }],
                line: 120
            },
            '10': {
                loc: {
                    start: {
                        line: 128,
                        column: 8
                    },
                    end: {
                        line: 130,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 128,
                        column: 8
                    },
                    end: {
                        line: 130,
                        column: 9
                    }
                }, {
                    start: {
                        line: 128,
                        column: 8
                    },
                    end: {
                        line: 130,
                        column: 9
                    }
                }],
                line: 128
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0,
            '28': 0,
            '29': 0,
            '30': 0,
            '31': 0,
            '32': 0,
            '33': 0,
            '34': 0,
            '35': 0,
            '36': 0,
            '37': 0,
            '38': 0,
            '39': 0,
            '40': 0,
            '41': 0,
            '42': 0,
            '43': 0,
            '44': 0,
            '45': 0,
            '46': 0,
            '47': 0,
            '48': 0,
            '49': 0,
            '50': 0,
            '51': 0,
            '52': 0,
            '53': 0,
            '54': 0,
            '55': 0,
            '56': 0,
            '57': 0,
            '58': 0,
            '59': 0,
            '60': 0,
            '61': 0,
            '62': 0,
            '63': 0,
            '64': 0,
            '65': 0,
            '66': 0,
            '67': 0,
            '68': 0,
            '69': 0,
            '70': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0],
            '2': [0, 0],
            '3': [0, 0],
            '4': [0, 0],
            '5': [0, 0],
            '6': [0, 0],
            '7': [0, 0],
            '8': [0, 0],
            '9': [0, 0],
            '10': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var ProposalsListController = function () {
    _createClass(ProposalsListController, [{
        key: '$onInit',
        value: function $onInit() {
            cov_2mb0e4p6ud.f[0]++;
            cov_2mb0e4p6ud.s[0]++;

            this.proposals.$load('client');
        }
    }]);

    function ProposalsListController(NewProposalModalService, OrganizationsTable, AppSettings, $rootScope, $mdToast) {
        var _this = this;

        _classCallCheck(this, ProposalsListController);

        cov_2mb0e4p6ud.f[1]++;
        cov_2mb0e4p6ud.s[1]++;

        this.NewProposalModalService = NewProposalModalService;
        cov_2mb0e4p6ud.s[2]++;
        this.proposalsApp = AppSettings.proposalsApp;
        cov_2mb0e4p6ud.s[3]++;
        this.$mdToast = $mdToast;
        cov_2mb0e4p6ud.s[4]++;
        this.clients = OrganizationsTable.$search();
        cov_2mb0e4p6ud.s[5]++;
        this.selected = [];
        cov_2mb0e4p6ud.s[6]++;
        this.allChecked = false;
        /* eslint-disable angular/document-service */
        cov_2mb0e4p6ud.s[7]++;
        this.el = angular.element(document.getElementById('list-proposals'));

        // eslint-disable-next-line angular/on-watch
        cov_2mb0e4p6ud.s[8]++;
        $rootScope.$on('pageChanged', function (event, data) {
            cov_2mb0e4p6ud.f[2]++;
            cov_2mb0e4p6ud.s[9]++;

            _this.proposals.$page = data;
            cov_2mb0e4p6ud.s[10]++;
            _this.update();
        });
        // eslint-disable-next-line angular/on-watch
        cov_2mb0e4p6ud.s[11]++;
        $rootScope.$on('orderChanged', function (event, data) {
            cov_2mb0e4p6ud.f[3]++;
            cov_2mb0e4p6ud.s[12]++;

            _this.requestData = { ordering: data };
            cov_2mb0e4p6ud.s[13]++;
            _this.update();
        });
        // eslint-disable-next-line angular/on-watch
        cov_2mb0e4p6ud.s[14]++;
        $rootScope.$on('searchText', function (event, data) {
            cov_2mb0e4p6ud.f[4]++;
            cov_2mb0e4p6ud.s[15]++;

            // eslint-disable-next-line camelcase
            _this.requestData = { name__icontains: data };
            cov_2mb0e4p6ud.s[16]++;
            _this.update();
        });
    }

    _createClass(ProposalsListController, [{
        key: 'update',
        value: function update() {
            cov_2mb0e4p6ud.f[5]++;
            cov_2mb0e4p6ud.s[17]++;

            this.proposals.$refresh(this.requestData);
            cov_2mb0e4p6ud.s[18]++;
            this.proposals.$load('client');
        }
    }, {
        key: 'displayNewProposalModal',
        value: function displayNewProposalModal() {
            var _this2 = this;

            cov_2mb0e4p6ud.f[6]++;
            cov_2mb0e4p6ud.s[19]++;

            this.NewProposalModalService.display().then(function (proposal) {
                cov_2mb0e4p6ud.f[7]++;
                cov_2mb0e4p6ud.s[20]++;

                _this2.proposals.$add(proposal);
            });
        }
    }, {
        key: 'checkProposal',
        value: function checkProposal(proposal) {
            cov_2mb0e4p6ud.f[8]++;
            cov_2mb0e4p6ud.s[21]++;

            if (this.selected.indexOf(proposal) > -1) {
                cov_2mb0e4p6ud.b[0][0]++;
                cov_2mb0e4p6ud.s[22]++;

                this.selected.splice(this.selected.indexOf(proposal), 1);
                cov_2mb0e4p6ud.s[23]++;
                if (this.allChecked) {
                    cov_2mb0e4p6ud.b[1][0]++;
                    cov_2mb0e4p6ud.s[24]++;

                    this.allChecked = false;
                } else {
                    cov_2mb0e4p6ud.b[1][1]++;
                }
            } else {
                cov_2mb0e4p6ud.b[0][1]++;
                cov_2mb0e4p6ud.s[25]++;

                this.selected.push(proposal);
            }
        }
    }, {
        key: 'proposalInList',
        value: function proposalInList(proposal) {
            cov_2mb0e4p6ud.f[9]++;
            cov_2mb0e4p6ud.s[26]++;

            return this.selected.indexOf(proposal) > -1;
        }
    }, {
        key: 'checkAll',
        value: function checkAll(proposals) {
            var _this3 = this;

            cov_2mb0e4p6ud.f[10]++;
            cov_2mb0e4p6ud.s[27]++;

            this.allChecked = !this.allChecked;
            cov_2mb0e4p6ud.s[28]++;
            if (this.allChecked) {
                cov_2mb0e4p6ud.b[2][0]++;
                cov_2mb0e4p6ud.s[29]++;

                proposals.forEach(function (proposal) {
                    cov_2mb0e4p6ud.f[11]++;
                    cov_2mb0e4p6ud.s[30]++;

                    if (!_this3.proposalInList(proposal)) {
                        cov_2mb0e4p6ud.b[3][0]++;
                        cov_2mb0e4p6ud.s[31]++;

                        _this3.selected.push(proposal);
                    } else {
                        cov_2mb0e4p6ud.b[3][1]++;
                    }
                });
            } else {
                cov_2mb0e4p6ud.b[2][1]++;
                cov_2mb0e4p6ud.s[32]++;

                this.selected = [];
            }
        }
    }, {
        key: 'passProposalsTo',
        value: function passProposalsTo(state) {
            var _this4 = this;

            cov_2mb0e4p6ud.f[12]++;
            cov_2mb0e4p6ud.s[33]++;

            this.counter = 0;
            cov_2mb0e4p6ud.s[34]++;
            if (state !== '') {
                cov_2mb0e4p6ud.b[4][0]++;
                cov_2mb0e4p6ud.s[35]++;

                this.selected.forEach(function (proposal) {
                    cov_2mb0e4p6ud.f[13]++;
                    cov_2mb0e4p6ud.s[36]++;

                    proposal.state = state;
                    cov_2mb0e4p6ud.s[37]++;
                    proposal.$save(['state']).$then(function (proposal) {
                        cov_2mb0e4p6ud.f[14]++;
                        cov_2mb0e4p6ud.s[38]++;

                        proposal.$fetch();
                    });
                    cov_2mb0e4p6ud.s[39]++;
                    _this4.counter += 1;
                });
                cov_2mb0e4p6ud.s[40]++;
                if (this.allChecked) {
                    cov_2mb0e4p6ud.b[5][0]++;
                    cov_2mb0e4p6ud.s[41]++;

                    this.allChecked = false;
                } else {
                    cov_2mb0e4p6ud.b[5][1]++;
                }
                cov_2mb0e4p6ud.s[42]++;
                this.selected = [];
                cov_2mb0e4p6ud.s[43]++;
                this.message = this.counter.toString() + ' proposal(s) passed to ' + state.toLowerCase();
                cov_2mb0e4p6ud.s[44]++;
                this.$mdToast.show(this.$mdToast.simple().parent(this.el).textContent(this.message));
            } else {
                cov_2mb0e4p6ud.b[4][1]++;
            }
        }
    }, {
        key: 'deleteProposals',
        value: function deleteProposals(option) {
            var _this5 = this;

            cov_2mb0e4p6ud.f[15]++;
            cov_2mb0e4p6ud.s[45]++;

            this.counter = 0;
            cov_2mb0e4p6ud.s[46]++;
            this.completed = 0;
            cov_2mb0e4p6ud.s[47]++;
            if (option === '1') {
                cov_2mb0e4p6ud.b[6][0]++;
                cov_2mb0e4p6ud.s[48]++;

                this.count = this.selected.length;
                cov_2mb0e4p6ud.s[49]++;
                this.selected.forEach(function (proposal) {
                    cov_2mb0e4p6ud.f[16]++;
                    cov_2mb0e4p6ud.s[50]++;

                    _this5.deleteProposal(proposal);
                });
            } else {
                cov_2mb0e4p6ud.b[6][1]++;
            }
            cov_2mb0e4p6ud.s[51]++;
            if (option === '2') {
                cov_2mb0e4p6ud.b[7][0]++;
                cov_2mb0e4p6ud.s[52]++;

                this.count = this.proposals.length;
                cov_2mb0e4p6ud.s[53]++;
                this.proposals.forEach(function (proposal) {
                    cov_2mb0e4p6ud.f[17]++;
                    cov_2mb0e4p6ud.s[54]++;

                    _this5.deleteProposal(proposal);
                });
            } else {
                cov_2mb0e4p6ud.b[7][1]++;
            }
        }
    }, {
        key: 'deleteProposal',
        value: function deleteProposal(proposal) {
            var _this6 = this;

            cov_2mb0e4p6ud.f[18]++;
            cov_2mb0e4p6ud.s[55]++;

            proposal.$destroy();
            cov_2mb0e4p6ud.s[56]++;
            proposal.$fetch().$then(function () {
                cov_2mb0e4p6ud.f[19]++;
                cov_2mb0e4p6ud.s[57]++;

                _this6.completed += 1;
                cov_2mb0e4p6ud.s[58]++;
                if (_this6.completed === _this6.count) {
                    cov_2mb0e4p6ud.b[8][0]++;
                    cov_2mb0e4p6ud.s[59]++;

                    _this6.messageDeleted(_this6.counter);
                } else {
                    cov_2mb0e4p6ud.b[8][1]++;
                }
            }, function () {
                cov_2mb0e4p6ud.f[20]++;
                cov_2mb0e4p6ud.s[60]++;

                _this6.counter += 1;
                cov_2mb0e4p6ud.s[61]++;
                _this6.completed += 1;
                cov_2mb0e4p6ud.s[62]++;
                if (_this6.completed === _this6.count) {
                    cov_2mb0e4p6ud.b[9][0]++;
                    cov_2mb0e4p6ud.s[63]++;

                    _this6.messageDeleted(_this6.counter);
                } else {
                    cov_2mb0e4p6ud.b[9][1]++;
                }
            });
        }
    }, {
        key: 'messageDeleted',
        value: function messageDeleted(counter) {
            cov_2mb0e4p6ud.f[21]++;
            cov_2mb0e4p6ud.s[64]++;

            this.selected = [];
            cov_2mb0e4p6ud.s[65]++;
            if (this.allChecked) {
                cov_2mb0e4p6ud.b[10][0]++;
                cov_2mb0e4p6ud.s[66]++;

                this.allChecked = false;
            } else {
                cov_2mb0e4p6ud.b[10][1]++;
            }
            cov_2mb0e4p6ud.s[67]++;
            this.proposals.$refresh();
            cov_2mb0e4p6ud.s[68]++;
            this.message = counter.toString() + ' proposal(s) deleted';
            cov_2mb0e4p6ud.s[69]++;
            this.$mdToast.show(this.$mdToast.simple().parent(this.el).textContent(this.message));
        }
    }]);

    return ProposalsListController;
}();

cov_2mb0e4p6ud.s[70]++;


angular.module('app').component('proposalsList', {
    templateUrl: 'app/components/proposalsList/proposalsList.html',
    controller: ProposalsListController,
    bindings: {
        proposals: '<'
    }
});