'use strict';

var cov_2m95ziytod = function () {
    var path = '/home/lobo/repos/prospector-app/src/app/components/createEmailModal/createEmailModal.js',
        hash = 'b2d38c4e7d51fde389281c4f85d33098f77ea05a',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/prospector-app/src/app/components/createEmailModal/createEmailModal.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 96,
                    column: 12
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 4
                },
                end: {
                    line: 6,
                    column: 59
                }
            },
            '2': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 20,
                    column: 10
                }
            },
            '3': {
                start: {
                    line: 11,
                    column: 12
                },
                end: {
                    line: 19,
                    column: 15
                }
            },
            '4': {
                start: {
                    line: 25,
                    column: 8
                },
                end: {
                    line: 25,
                    column: 25
                }
            },
            '5': {
                start: {
                    line: 27,
                    column: 8
                },
                end: {
                    line: 30,
                    column: 10
                }
            },
            '6': {
                start: {
                    line: 32,
                    column: 8
                },
                end: {
                    line: 32,
                    column: 66
                }
            },
            '7': {
                start: {
                    line: 34,
                    column: 8
                },
                end: {
                    line: 37,
                    column: 10
                }
            },
            '8': {
                start: {
                    line: 35,
                    column: 12
                },
                end: {
                    line: 35,
                    column: 42
                }
            },
            '9': {
                start: {
                    line: 36,
                    column: 12
                },
                end: {
                    line: 36,
                    column: 80
                }
            },
            '10': {
                start: {
                    line: 39,
                    column: 8
                },
                end: {
                    line: 46,
                    column: 15
                }
            },
            '11': {
                start: {
                    line: 41,
                    column: 16
                },
                end: {
                    line: 45,
                    column: 17
                }
            },
            '12': {
                start: {
                    line: 42,
                    column: 20
                },
                end: {
                    line: 42,
                    column: 52
                }
            },
            '13': {
                start: {
                    line: 44,
                    column: 20
                },
                end: {
                    line: 44,
                    column: 74
                }
            },
            '14': {
                start: {
                    line: 48,
                    column: 8
                },
                end: {
                    line: 56,
                    column: 10
                }
            },
            '15': {
                start: {
                    line: 49,
                    column: 12
                },
                end: {
                    line: 55,
                    column: 19
                }
            },
            '16': {
                start: {
                    line: 51,
                    column: 20
                },
                end: {
                    line: 51,
                    column: 79
                }
            },
            '17': {
                start: {
                    line: 52,
                    column: 20
                },
                end: {
                    line: 52,
                    column: 73
                }
            },
            '18': {
                start: {
                    line: 53,
                    column: 20
                },
                end: {
                    line: 53,
                    column: 35
                }
            },
            '19': {
                start: {
                    line: 54,
                    column: 20
                },
                end: {
                    line: 54,
                    column: 44
                }
            },
            '20': {
                start: {
                    line: 58,
                    column: 8
                },
                end: {
                    line: 62,
                    column: 10
                }
            },
            '21': {
                start: {
                    line: 59,
                    column: 12
                },
                end: {
                    line: 59,
                    column: 60
                }
            },
            '22': {
                start: {
                    line: 60,
                    column: 12
                },
                end: {
                    line: 60,
                    column: 54
                }
            },
            '23': {
                start: {
                    line: 61,
                    column: 12
                },
                end: {
                    line: 61,
                    column: 36
                }
            },
            '24': {
                start: {
                    line: 64,
                    column: 8
                },
                end: {
                    line: 66,
                    column: 10
                }
            },
            '25': {
                start: {
                    line: 65,
                    column: 12
                },
                end: {
                    line: 65,
                    column: 31
                }
            },
            '26': {
                start: {
                    line: 68,
                    column: 8
                },
                end: {
                    line: 78,
                    column: 10
                }
            },
            '27': {
                start: {
                    line: 69,
                    column: 24
                },
                end: {
                    line: 69,
                    column: 91
                }
            },
            '28': {
                start: {
                    line: 70,
                    column: 30
                },
                end: {
                    line: 70,
                    column: 64
                }
            },
            '29': {
                start: {
                    line: 71,
                    column: 26
                },
                end: {
                    line: 71,
                    column: 177
                }
            },
            '30': {
                start: {
                    line: 72,
                    column: 24
                },
                end: {
                    line: 72,
                    column: 116
                }
            },
            '31': {
                start: {
                    line: 73,
                    column: 23
                },
                end: {
                    line: 73,
                    column: 41
                }
            },
            '32': {
                start: {
                    line: 74,
                    column: 28
                },
                end: {
                    line: 74,
                    column: 71
                }
            },
            '33': {
                start: {
                    line: 75,
                    column: 25
                },
                end: {
                    line: 75,
                    column: 91
                }
            },
            '34': {
                start: {
                    line: 76,
                    column: 27
                },
                end: {
                    line: 76,
                    column: 83
                }
            },
            '35': {
                start: {
                    line: 77,
                    column: 12
                },
                end: {
                    line: 77,
                    column: 43
                }
            },
            '36': {
                start: {
                    line: 80,
                    column: 8
                },
                end: {
                    line: 83,
                    column: 10
                }
            },
            '37': {
                start: {
                    line: 81,
                    column: 12
                },
                end: {
                    line: 81,
                    column: 43
                }
            },
            '38': {
                start: {
                    line: 82,
                    column: 12
                },
                end: {
                    line: 82,
                    column: 81
                }
            },
            '39': {
                start: {
                    line: 82,
                    column: 48
                },
                end: {
                    line: 82,
                    column: 79
                }
            },
            '40': {
                start: {
                    line: 85,
                    column: 8
                },
                end: {
                    line: 87,
                    column: 10
                }
            },
            '41': {
                start: {
                    line: 86,
                    column: 12
                },
                end: {
                    line: 86,
                    column: 61
                }
            },
            '42': {
                start: {
                    line: 89,
                    column: 8
                },
                end: {
                    line: 94,
                    column: 10
                }
            },
            '43': {
                start: {
                    line: 90,
                    column: 12
                },
                end: {
                    line: 90,
                    column: 36
                }
            },
            '44': {
                start: {
                    line: 91,
                    column: 12
                },
                end: {
                    line: 91,
                    column: 41
                }
            },
            '45': {
                start: {
                    line: 92,
                    column: 12
                },
                end: {
                    line: 92,
                    column: 38
                }
            },
            '46': {
                start: {
                    line: 93,
                    column: 12
                },
                end: {
                    line: 93,
                    column: 36
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 1
                    },
                    end: {
                        line: 1,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 13
                    },
                    end: {
                        line: 96,
                        column: 1
                    }
                },
                line: 1
            },
            '1': {
                name: 'NewEmailModalService',
                decl: {
                    start: {
                        line: 9,
                        column: 13
                    },
                    end: {
                        line: 9,
                        column: 33
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 45
                    },
                    end: {
                        line: 21,
                        column: 5
                    }
                },
                line: 9
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 10,
                        column: 23
                    },
                    end: {
                        line: 10,
                        column: 24
                    }
                },
                loc: {
                    start: {
                        line: 10,
                        column: 47
                    },
                    end: {
                        line: 20,
                        column: 9
                    }
                },
                line: 10
            },
            '3': {
                name: 'CreateEmailModalController',
                decl: {
                    start: {
                        line: 24,
                        column: 13
                    },
                    end: {
                        line: 24,
                        column: 39
                    }
                },
                loc: {
                    start: {
                        line: 24,
                        column: 129
                    },
                    end: {
                        line: 95,
                        column: 5
                    }
                },
                line: 24
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 34,
                        column: 31
                    },
                    end: {
                        line: 34,
                        column: 32
                    }
                },
                loc: {
                    start: {
                        line: 34,
                        column: 55
                    },
                    end: {
                        line: 37,
                        column: 9
                    }
                },
                line: 34
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 40,
                        column: 12
                    },
                    end: {
                        line: 40,
                        column: 13
                    }
                },
                loc: {
                    start: {
                        line: 40,
                        column: 26
                    },
                    end: {
                        line: 46,
                        column: 13
                    }
                },
                line: 40
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 48,
                        column: 24
                    },
                    end: {
                        line: 48,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 48,
                        column: 36
                    },
                    end: {
                        line: 56,
                        column: 9
                    }
                },
                line: 48
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 50,
                        column: 16
                    },
                    end: {
                        line: 50,
                        column: 17
                    }
                },
                loc: {
                    start: {
                        line: 50,
                        column: 30
                    },
                    end: {
                        line: 55,
                        column: 17
                    }
                },
                line: 50
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 58,
                        column: 36
                    },
                    end: {
                        line: 58,
                        column: 37
                    }
                },
                loc: {
                    start: {
                        line: 58,
                        column: 61
                    },
                    end: {
                        line: 62,
                        column: 9
                    }
                },
                line: 58
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 64,
                        column: 29
                    },
                    end: {
                        line: 64,
                        column: 30
                    }
                },
                loc: {
                    start: {
                        line: 64,
                        column: 41
                    },
                    end: {
                        line: 66,
                        column: 9
                    }
                },
                line: 64
            },
            '10': {
                name: '(anonymous_10)',
                decl: {
                    start: {
                        line: 68,
                        column: 28
                    },
                    end: {
                        line: 68,
                        column: 29
                    }
                },
                loc: {
                    start: {
                        line: 68,
                        column: 40
                    },
                    end: {
                        line: 78,
                        column: 9
                    }
                },
                line: 68
            },
            '11': {
                name: '(anonymous_11)',
                decl: {
                    start: {
                        line: 80,
                        column: 22
                    },
                    end: {
                        line: 80,
                        column: 23
                    }
                },
                loc: {
                    start: {
                        line: 80,
                        column: 34
                    },
                    end: {
                        line: 83,
                        column: 9
                    }
                },
                line: 80
            },
            '12': {
                name: '(anonymous_12)',
                decl: {
                    start: {
                        line: 82,
                        column: 42
                    },
                    end: {
                        line: 82,
                        column: 43
                    }
                },
                loc: {
                    start: {
                        line: 82,
                        column: 48
                    },
                    end: {
                        line: 82,
                        column: 79
                    }
                },
                line: 82
            },
            '13': {
                name: '(anonymous_13)',
                decl: {
                    start: {
                        line: 85,
                        column: 22
                    },
                    end: {
                        line: 85,
                        column: 23
                    }
                },
                loc: {
                    start: {
                        line: 85,
                        column: 34
                    },
                    end: {
                        line: 87,
                        column: 9
                    }
                },
                line: 85
            },
            '14': {
                name: '(anonymous_14)',
                decl: {
                    start: {
                        line: 89,
                        column: 23
                    },
                    end: {
                        line: 89,
                        column: 24
                    }
                },
                loc: {
                    start: {
                        line: 89,
                        column: 35
                    },
                    end: {
                        line: 94,
                        column: 9
                    }
                },
                line: 89
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 41,
                        column: 16
                    },
                    end: {
                        line: 45,
                        column: 17
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 41,
                        column: 16
                    },
                    end: {
                        line: 45,
                        column: 17
                    }
                }, {
                    start: {
                        line: 41,
                        column: 16
                    },
                    end: {
                        line: 45,
                        column: 17
                    }
                }],
                line: 41
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0,
            '28': 0,
            '29': 0,
            '30': 0,
            '31': 0,
            '32': 0,
            '33': 0,
            '34': 0,
            '35': 0,
            '36': 0,
            '37': 0,
            '38': 0,
            '39': 0,
            '40': 0,
            '41': 0,
            '42': 0,
            '43': 0,
            '44': 0,
            '45': 0,
            '46': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0
        },
        b: {
            '0': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_2m95ziytod.s[0]++;
(function () {
    'use strict';

    cov_2m95ziytod.f[0]++;
    cov_2m95ziytod.s[1]++;
    angular.module('app').service('NewEmailModalService', NewEmailModalService);

    /* @ngInject */
    function NewEmailModalService($mdDialog) {
        cov_2m95ziytod.f[1]++;
        cov_2m95ziytod.s[2]++;

        this.display = function (organization) {
            cov_2m95ziytod.f[2]++;
            cov_2m95ziytod.s[3]++;

            return $mdDialog.show({
                templateUrl: 'app/components/createEmailModal/createEmailModal.html',
                controller: CreateEmailModalController,
                clickOutsideToClose: true,
                escapeToClose: true,
                locals: {
                    organization: organization
                }
            });
        };
    }

    /* @ngInject */
    function CreateEmailModalController($scope, $mdDialog, EmailsTable, $window, AppSettings, organization, EmailTemplatesTable) {
        cov_2m95ziytod.f[3]++;
        cov_2m95ziytod.s[4]++;

        $scope.index = 0;

        cov_2m95ziytod.s[5]++;
        $scope.filters = {
            /* eslint-disable camelcase */
            page_size: 1
        };

        cov_2m95ziytod.s[6]++;
        $scope.emailTemplates = EmailTemplatesTable.$collection();

        cov_2m95ziytod.s[7]++;
        $scope.changeFilters = function (field, value) {
            cov_2m95ziytod.f[4]++;
            cov_2m95ziytod.s[8]++;

            $scope.filters[field] = value;
            cov_2m95ziytod.s[9]++;
            $scope.emailTemplates = EmailTemplatesTable.$search($scope.filters);
        };

        cov_2m95ziytod.s[10]++;
        EmailsTable.$search({ organization: organization, state: 'DRAFT', ordering: '-id' }).$then(function (collection) {
            cov_2m95ziytod.f[5]++;
            cov_2m95ziytod.s[11]++;

            if (collection.length > 0) {
                cov_2m95ziytod.b[0][0]++;
                cov_2m95ziytod.s[12]++;

                $scope.newEmail = collection[0];
            } else {
                cov_2m95ziytod.b[0][1]++;
                cov_2m95ziytod.s[13]++;

                $scope.newEmail = EmailsTable.$create({ organization: organization });
            }
        });

        cov_2m95ziytod.s[14]++;
        $scope.import = function () {
            cov_2m95ziytod.f[6]++;
            cov_2m95ziytod.s[15]++;

            EmailsTable.$search({ state: 'SENT', ordering: '-id' }).$then(function (collection) {
                cov_2m95ziytod.f[7]++;
                cov_2m95ziytod.s[16]++;

                $scope.newEmail.subject = collection[$scope.index].subject;
                cov_2m95ziytod.s[17]++;
                $scope.newEmail.body = collection[$scope.index].body;
                cov_2m95ziytod.s[18]++;
                $scope.index++;
                cov_2m95ziytod.s[19]++;
                $scope.newEmail.$save();
            });
        };

        cov_2m95ziytod.s[20]++;
        $scope.importFromTemplate = function (emailTemplate) {
            cov_2m95ziytod.f[8]++;
            cov_2m95ziytod.s[21]++;

            $scope.newEmail.subject = emailTemplate.subject;
            cov_2m95ziytod.s[22]++;
            $scope.newEmail.body = emailTemplate.body;
            cov_2m95ziytod.s[23]++;
            $scope.newEmail.$save();
        };

        cov_2m95ziytod.s[24]++;
        $scope.closeDialog = function () {
            cov_2m95ziytod.f[9]++;
            cov_2m95ziytod.s[25]++;

            $mdDialog.cancel();
        };

        cov_2m95ziytod.s[26]++;
        $scope.openClient = function () {
            cov_2m95ziytod.f[10]++;

            var url = (cov_2m95ziytod.s[27]++, AppSettings.apiUrl + '/images/?p=' + $scope.newEmail.pixel + '.png');
            var signature = (cov_2m95ziytod.s[28]++, '<p><img src="' + url + '" /><p/>');
            var links = (cov_2m95ziytod.s[29]++, '<p style="color: #666; font-size: 13px">--<br/>www.solo.com.pt<br/>www.linkedin.com/in/ricardo-lobo/<br/>www.facebook.com/ricardo.lopes.lobo<br/></p>');
            var ref = (cov_2m95ziytod.s[30]++, '<p style="font-size: 8px; color: transparent">email ref #' + $scope.newEmail.pixel + '</p>');
            var to = (cov_2m95ziytod.s[31]++, $scope.newEmail.to);
            var subject = (cov_2m95ziytod.s[32]++, encodeURIComponent($scope.newEmail.subject));
            var body = (cov_2m95ziytod.s[33]++, encodeURIComponent($scope.newEmail.body + signature + links + ref));
            var mailto = (cov_2m95ziytod.s[34]++, "mailto:" + to + "?subject=" + subject + "&body=" + body);
            cov_2m95ziytod.s[35]++;
            $window.location.href = mailto;
        };

        cov_2m95ziytod.s[36]++;
        $scope.save = function () {
            cov_2m95ziytod.f[11]++;
            cov_2m95ziytod.s[37]++;

            $scope.newEmail.state = 'SENT';
            cov_2m95ziytod.s[38]++;
            $scope.newEmail.$save().$then(function () {
                cov_2m95ziytod.f[12]++;
                cov_2m95ziytod.s[39]++;
                return $mdDialog.hide($scope.newEmail);
            });
        };

        cov_2m95ziytod.s[40]++;
        $scope.send = function () {
            cov_2m95ziytod.f[13]++;
            cov_2m95ziytod.s[41]++;

            $scope.newEmail.$save().$then($scope.openClient);
        };

        cov_2m95ziytod.s[42]++;
        $scope.clear = function () {
            cov_2m95ziytod.f[14]++;
            cov_2m95ziytod.s[43]++;

            $scope.newEmail.to = '';
            cov_2m95ziytod.s[44]++;
            $scope.newEmail.subject = '';
            cov_2m95ziytod.s[45]++;
            $scope.newEmail.body = '';
            cov_2m95ziytod.s[46]++;
            $scope.newEmail.$save();
        };
    }
})(angular);