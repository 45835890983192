'use strict';

var cov_20vzoiqxw9 = function () {
    var path = '/home/lobo/repos/prospector-app/src/app/components/userProfile/userProfile.js',
        hash = 'c9f29d7e3306d66cd778b2b399feca27c20be859',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/prospector-app/src/app/components/userProfile/userProfile.js',
        statementMap: {
            '0': {
                start: {
                    line: 3,
                    column: 8
                },
                end: {
                    line: 17,
                    column: 10
                }
            },
            '1': {
                start: {
                    line: 5,
                    column: 16
                },
                end: {
                    line: 5,
                    column: 28
                }
            },
            '2': {
                start: {
                    line: 10,
                    column: 31
                },
                end: {
                    line: 10,
                    column: 51
                }
            },
            '3': {
                start: {
                    line: 11,
                    column: 29
                },
                end: {
                    line: 11,
                    column: 56
                }
            },
            '4': {
                start: {
                    line: 12,
                    column: 16
                },
                end: {
                    line: 12,
                    column: 36
                }
            },
            '5': {
                start: {
                    line: 14,
                    column: 16
                },
                end: {
                    line: 14,
                    column: 38
                }
            },
            '6': {
                start: {
                    line: 21,
                    column: 8
                },
                end: {
                    line: 21,
                    column: 37
                }
            },
            '7': {
                start: {
                    line: 22,
                    column: 8
                },
                end: {
                    line: 22,
                    column: 68
                }
            },
            '8': {
                start: {
                    line: 22,
                    column: 44
                },
                end: {
                    line: 22,
                    column: 66
                }
            },
            '9': {
                start: {
                    line: 26,
                    column: 20
                },
                end: {
                    line: 29,
                    column: 9
                }
            },
            '10': {
                start: {
                    line: 30,
                    column: 8
                },
                end: {
                    line: 32,
                    column: 9
                }
            },
            '11': {
                start: {
                    line: 31,
                    column: 12
                },
                end: {
                    line: 31,
                    column: 38
                }
            },
            '12': {
                start: {
                    line: 33,
                    column: 8
                },
                end: {
                    line: 33,
                    column: 94
                }
            },
            '13': {
                start: {
                    line: 33,
                    column: 71
                },
                end: {
                    line: 33,
                    column: 92
                }
            },
            '14': {
                start: {
                    line: 34,
                    column: 8
                },
                end: {
                    line: 34,
                    column: 30
                }
            },
            '15': {
                start: {
                    line: 38,
                    column: 0
                },
                end: {
                    line: 46,
                    column: 3
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 2,
                        column: 4
                    },
                    end: {
                        line: 2,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 2,
                        column: 18
                    },
                    end: {
                        line: 18,
                        column: 5
                    }
                },
                line: 2
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 20,
                        column: 4
                    },
                    end: {
                        line: 20,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 20,
                        column: 14
                    },
                    end: {
                        line: 23,
                        column: 5
                    }
                },
                line: 20
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 22,
                        column: 35
                    },
                    end: {
                        line: 22,
                        column: 36
                    }
                },
                loc: {
                    start: {
                        line: 22,
                        column: 44
                    },
                    end: {
                        line: 22,
                        column: 66
                    }
                },
                line: 22
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 25,
                        column: 4
                    },
                    end: {
                        line: 25,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 25,
                        column: 38
                    },
                    end: {
                        line: 35,
                        column: 5
                    }
                },
                line: 25
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 33,
                        column: 63
                    },
                    end: {
                        line: 33,
                        column: 64
                    }
                },
                loc: {
                    start: {
                        line: 33,
                        column: 71
                    },
                    end: {
                        line: 33,
                        column: 92
                    }
                },
                line: 33
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 30,
                        column: 8
                    },
                    end: {
                        line: 32,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 30,
                        column: 8
                    },
                    end: {
                        line: 32,
                        column: 9
                    }
                }, {
                    start: {
                        line: 30,
                        column: 8
                    },
                    end: {
                        line: 32,
                        column: 9
                    }
                }],
                line: 30
            },
            '1': {
                loc: {
                    start: {
                        line: 30,
                        column: 12
                    },
                    end: {
                        line: 30,
                        column: 49
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 30,
                        column: 12
                    },
                    end: {
                        line: 30,
                        column: 23
                    }
                }, {
                    start: {
                        line: 30,
                        column: 27
                    },
                    end: {
                        line: 30,
                        column: 49
                    }
                }],
                line: 30
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var UserProfileController = function () {
    function UserProfileController() {
        _classCallCheck(this, UserProfileController);

        cov_20vzoiqxw9.f[0]++;
        cov_20vzoiqxw9.s[0]++;

        this.sortableListeners = {
            accept: function accept() {
                cov_20vzoiqxw9.s[1]++;

                return true;
            },
            itemMoved: function itemMoved() {},
            orderChanged: function orderChanged(event) {
                var newPos = (cov_20vzoiqxw9.s[2]++, event.dest.index + 1);
                var task = (cov_20vzoiqxw9.s[3]++, event.source.itemScope.task);
                cov_20vzoiqxw9.s[4]++;
                task.order = newPos;

                cov_20vzoiqxw9.s[5]++;
                task.$save(['order']);
            },

            placeholder: '<div style="min-height: 60px;"></div>'
        };
    }

    _createClass(UserProfileController, [{
        key: '$onInit',
        value: function $onInit() {
            cov_20vzoiqxw9.f[1]++;
            cov_20vzoiqxw9.s[6]++;

            this.user.$load('openTasks');
            cov_20vzoiqxw9.s[7]++;
            this.user.$openTasks.$then(function (tasks) {
                cov_20vzoiqxw9.f[2]++;
                cov_20vzoiqxw9.s[8]++;
                return tasks.$load('project');
            });
        }
    }, {
        key: 'createTask',
        value: function createTask(taskName, taskProject) {
            cov_20vzoiqxw9.f[3]++;

            var obj = (cov_20vzoiqxw9.s[9]++, {
                name: taskName,
                user: this.user.id
            });
            cov_20vzoiqxw9.s[10]++;
            if ((cov_20vzoiqxw9.b[1][0]++, taskProject) && (cov_20vzoiqxw9.b[1][1]++, taskProject !== 'None')) {
                cov_20vzoiqxw9.b[0][0]++;
                cov_20vzoiqxw9.s[11]++;

                obj.project = taskProject;
            } else {
                cov_20vzoiqxw9.b[0][1]++;
            }
            cov_20vzoiqxw9.s[12]++;
            this.newTask = this.user.$openTasks.$create(obj).$then(function (task) {
                cov_20vzoiqxw9.f[4]++;
                cov_20vzoiqxw9.s[13]++;
                return task.$load('project');
            });
            cov_20vzoiqxw9.s[14]++;
            this.newTaskName = '';
        }
    }]);

    return UserProfileController;
}();

cov_20vzoiqxw9.s[15]++;


angular.module('app').component('userProfile', {
    templateUrl: 'app/components/userProfile/userProfile.html',
    controller: UserProfileController,
    bindings: {
        user: '<'
    }
});