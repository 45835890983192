'use strict';

var cov_4tcvznqaj = function () {
    var path = '/home/lobo/repos/prospector-app/src/app/components/organizationDetails/organizationDetails.js',
        hash = '7221c4c6f809047c36245106ff98d294eecc5fdd',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/prospector-app/src/app/components/organizationDetails/organizationDetails.js',
        statementMap: {
            '0': {
                start: {
                    line: 6,
                    column: 8
                },
                end: {
                    line: 6,
                    column: 55
                }
            },
            '1': {
                start: {
                    line: 7,
                    column: 8
                },
                end: {
                    line: 7,
                    column: 59
                }
            },
            '2': {
                start: {
                    line: 8,
                    column: 8
                },
                end: {
                    line: 8,
                    column: 35
                }
            },
            '3': {
                start: {
                    line: 9,
                    column: 8
                },
                end: {
                    line: 9,
                    column: 49
                }
            },
            '4': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 10,
                    column: 63
                }
            },
            '5': {
                start: {
                    line: 11,
                    column: 8
                },
                end: {
                    line: 11,
                    column: 55
                }
            },
            '6': {
                start: {
                    line: 12,
                    column: 8
                },
                end: {
                    line: 12,
                    column: 43
                }
            },
            '7': {
                start: {
                    line: 13,
                    column: 8
                },
                end: {
                    line: 13,
                    column: 31
                }
            },
            '8': {
                start: {
                    line: 14,
                    column: 8
                },
                end: {
                    line: 14,
                    column: 33
                }
            },
            '9': {
                start: {
                    line: 15,
                    column: 8
                },
                end: {
                    line: 15,
                    column: 57
                }
            },
            '10': {
                start: {
                    line: 16,
                    column: 8
                },
                end: {
                    line: 16,
                    column: 39
                }
            },
            '11': {
                start: {
                    line: 17,
                    column: 8
                },
                end: {
                    line: 17,
                    column: 30
                }
            },
            '12': {
                start: {
                    line: 18,
                    column: 8
                },
                end: {
                    line: 18,
                    column: 34
                }
            },
            '13': {
                start: {
                    line: 19,
                    column: 8
                },
                end: {
                    line: 19,
                    column: 65
                }
            },
            '14': {
                start: {
                    line: 20,
                    column: 8
                },
                end: {
                    line: 20,
                    column: 37
                }
            },
            '15': {
                start: {
                    line: 21,
                    column: 8
                },
                end: {
                    line: 21,
                    column: 63
                }
            },
            '16': {
                start: {
                    line: 22,
                    column: 8
                },
                end: {
                    line: 22,
                    column: 65
                }
            },
            '17': {
                start: {
                    line: 23,
                    column: 8
                },
                end: {
                    line: 23,
                    column: 57
                }
            },
            '18': {
                start: {
                    line: 24,
                    column: 8
                },
                end: {
                    line: 24,
                    column: 55
                }
            },
            '19': {
                start: {
                    line: 25,
                    column: 8
                },
                end: {
                    line: 25,
                    column: 77
                }
            },
            '20': {
                start: {
                    line: 26,
                    column: 8
                },
                end: {
                    line: 26,
                    column: 51
                }
            },
            '21': {
                start: {
                    line: 27,
                    column: 8
                },
                end: {
                    line: 27,
                    column: 49
                }
            },
            '22': {
                start: {
                    line: 28,
                    column: 8
                },
                end: {
                    line: 28,
                    column: 75
                }
            },
            '23': {
                start: {
                    line: 29,
                    column: 8
                },
                end: {
                    line: 29,
                    column: 51
                }
            },
            '24': {
                start: {
                    line: 30,
                    column: 8
                },
                end: {
                    line: 30,
                    column: 33
                }
            },
            '25': {
                start: {
                    line: 31,
                    column: 8
                },
                end: {
                    line: 31,
                    column: 35
                }
            },
            '26': {
                start: {
                    line: 34,
                    column: 8
                },
                end: {
                    line: 42,
                    column: 11
                }
            },
            '27': {
                start: {
                    line: 35,
                    column: 12
                },
                end: {
                    line: 41,
                    column: 13
                }
            },
            '28': {
                start: {
                    line: 36,
                    column: 16
                },
                end: {
                    line: 40,
                    column: 19
                }
            },
            '29': {
                start: {
                    line: 46,
                    column: 12
                },
                end: {
                    line: 51,
                    column: 13
                }
            },
            '30': {
                start: {
                    line: 47,
                    column: 26
                },
                end: {
                    line: 47,
                    column: 65
                }
            },
            '31': {
                start: {
                    line: 48,
                    column: 16
                },
                end: {
                    line: 50,
                    column: 17
                }
            },
            '32': {
                start: {
                    line: 49,
                    column: 20
                },
                end: {
                    line: 49,
                    column: 45
                }
            },
            '33': {
                start: {
                    line: 52,
                    column: 12
                },
                end: {
                    line: 52,
                    column: 22
                }
            },
            '34': {
                start: {
                    line: 56,
                    column: 8
                },
                end: {
                    line: 56,
                    column: 209
                }
            },
            '35': {
                start: {
                    line: 57,
                    column: 8
                },
                end: {
                    line: 57,
                    column: 71
                }
            },
            '36': {
                start: {
                    line: 58,
                    column: 8
                },
                end: {
                    line: 58,
                    column: 50
                }
            },
            '37': {
                start: {
                    line: 59,
                    column: 8
                },
                end: {
                    line: 59,
                    column: 41
                }
            },
            '38': {
                start: {
                    line: 61,
                    column: 8
                },
                end: {
                    line: 61,
                    column: 61
                }
            },
            '39': {
                start: {
                    line: 62,
                    column: 8
                },
                end: {
                    line: 62,
                    column: 69
                }
            },
            '40': {
                start: {
                    line: 63,
                    column: 8
                },
                end: {
                    line: 63,
                    column: 45
                }
            },
            '41': {
                start: {
                    line: 64,
                    column: 8
                },
                end: {
                    line: 64,
                    column: 59
                }
            },
            '42': {
                start: {
                    line: 65,
                    column: 8
                },
                end: {
                    line: 65,
                    column: 83
                }
            },
            '43': {
                start: {
                    line: 67,
                    column: 8
                },
                end: {
                    line: 67,
                    column: 81
                }
            },
            '44': {
                start: {
                    line: 71,
                    column: 8
                },
                end: {
                    line: 76,
                    column: 52
                }
            },
            '45': {
                start: {
                    line: 76,
                    column: 23
                },
                end: {
                    line: 76,
                    column: 50
                }
            },
            '46': {
                start: {
                    line: 80,
                    column: 8
                },
                end: {
                    line: 83,
                    column: 42
                }
            },
            '47': {
                start: {
                    line: 83,
                    column: 24
                },
                end: {
                    line: 83,
                    column: 40
                }
            },
            '48': {
                start: {
                    line: 87,
                    column: 8
                },
                end: {
                    line: 90,
                    column: 81
                }
            },
            '49': {
                start: {
                    line: 90,
                    column: 37
                },
                end: {
                    line: 90,
                    column: 79
                }
            },
            '50': {
                start: {
                    line: 94,
                    column: 8
                },
                end: {
                    line: 98,
                    column: 87
                }
            },
            '51': {
                start: {
                    line: 98,
                    column: 43
                },
                end: {
                    line: 98,
                    column: 85
                }
            },
            '52': {
                start: {
                    line: 102,
                    column: 8
                },
                end: {
                    line: 109,
                    column: 53
                }
            },
            '53': {
                start: {
                    line: 109,
                    column: 23
                },
                end: {
                    line: 109,
                    column: 51
                }
            },
            '54': {
                start: {
                    line: 113,
                    column: 8
                },
                end: {
                    line: 114,
                    column: 74
                }
            },
            '55': {
                start: {
                    line: 114,
                    column: 22
                },
                end: {
                    line: 114,
                    column: 72
                }
            },
            '56': {
                start: {
                    line: 118,
                    column: 8
                },
                end: {
                    line: 118,
                    column: 45
                }
            },
            '57': {
                start: {
                    line: 122,
                    column: 8
                },
                end: {
                    line: 122,
                    column: 69
                }
            },
            '58': {
                start: {
                    line: 123,
                    column: 8
                },
                end: {
                    line: 123,
                    column: 48
                }
            },
            '59': {
                start: {
                    line: 127,
                    column: 25
                },
                end: {
                    line: 127,
                    column: 77
                }
            },
            '60': {
                start: {
                    line: 128,
                    column: 23
                },
                end: {
                    line: 142,
                    column: 9
                }
            },
            '61': {
                start: {
                    line: 143,
                    column: 8
                },
                end: {
                    line: 143,
                    column: 35
                }
            },
            '62': {
                start: {
                    line: 147,
                    column: 8
                },
                end: {
                    line: 150,
                    column: 11
                }
            },
            '63': {
                start: {
                    line: 148,
                    column: 12
                },
                end: {
                    line: 148,
                    column: 66
                }
            },
            '64': {
                start: {
                    line: 149,
                    column: 12
                },
                end: {
                    line: 149,
                    column: 51
                }
            },
            '65': {
                start: {
                    line: 155,
                    column: 20
                },
                end: {
                    line: 155,
                    column: 85
                }
            },
            '66': {
                start: {
                    line: 156,
                    column: 8
                },
                end: {
                    line: 156,
                    column: 31
                }
            },
            '67': {
                start: {
                    line: 160,
                    column: 8
                },
                end: {
                    line: 165,
                    column: 10
                }
            },
            '68': {
                start: {
                    line: 162,
                    column: 16
                },
                end: {
                    line: 162,
                    column: 68
                }
            },
            '69': {
                start: {
                    line: 163,
                    column: 16
                },
                end: {
                    line: 163,
                    column: 49
                }
            },
            '70': {
                start: {
                    line: 170,
                    column: 8
                },
                end: {
                    line: 173,
                    column: 11
                }
            },
            '71': {
                start: {
                    line: 171,
                    column: 25
                },
                end: {
                    line: 171,
                    column: 90
                }
            },
            '72': {
                start: {
                    line: 172,
                    column: 12
                },
                end: {
                    line: 172,
                    column: 64
                }
            },
            '73': {
                start: {
                    line: 178,
                    column: 8
                },
                end: {
                    line: 183,
                    column: 11
                }
            },
            '74': {
                start: {
                    line: 179,
                    column: 25
                },
                end: {
                    line: 179,
                    column: 44
                }
            },
            '75': {
                start: {
                    line: 180,
                    column: 12
                },
                end: {
                    line: 182,
                    column: 69
                }
            },
            '76': {
                start: {
                    line: 181,
                    column: 30
                },
                end: {
                    line: 182,
                    column: 67
                }
            },
            '77': {
                start: {
                    line: 188,
                    column: 8
                },
                end: {
                    line: 191,
                    column: 11
                }
            },
            '78': {
                start: {
                    line: 189,
                    column: 25
                },
                end: {
                    line: 189,
                    column: 81
                }
            },
            '79': {
                start: {
                    line: 190,
                    column: 12
                },
                end: {
                    line: 190,
                    column: 52
                }
            },
            '80': {
                start: {
                    line: 196,
                    column: 8
                },
                end: {
                    line: 199,
                    column: 11
                }
            },
            '81': {
                start: {
                    line: 197,
                    column: 25
                },
                end: {
                    line: 197,
                    column: 103
                }
            },
            '82': {
                start: {
                    line: 198,
                    column: 12
                },
                end: {
                    line: 198,
                    column: 51
                }
            },
            '83': {
                start: {
                    line: 203,
                    column: 24
                },
                end: {
                    line: 208,
                    column: 9
                }
            },
            '84': {
                start: {
                    line: 209,
                    column: 8
                },
                end: {
                    line: 209,
                    column: 30
                }
            },
            '85': {
                start: {
                    line: 213,
                    column: 21
                },
                end: {
                    line: 213,
                    column: 44
                }
            },
            '86': {
                start: {
                    line: 214,
                    column: 8
                },
                end: {
                    line: 222,
                    column: 11
                }
            },
            '87': {
                start: {
                    line: 216,
                    column: 12
                },
                end: {
                    line: 221,
                    column: 13
                }
            },
            '88': {
                start: {
                    line: 217,
                    column: 33
                },
                end: {
                    line: 217,
                    column: 67
                }
            },
            '89': {
                start: {
                    line: 218,
                    column: 16
                },
                end: {
                    line: 218,
                    column: 44
                }
            },
            '90': {
                start: {
                    line: 220,
                    column: 16
                },
                end: {
                    line: 220,
                    column: 61
                }
            },
            '91': {
                start: {
                    line: 223,
                    column: 8
                },
                end: {
                    line: 223,
                    column: 38
                }
            },
            '92': {
                start: {
                    line: 227,
                    column: 8
                },
                end: {
                    line: 229,
                    column: 11
                }
            },
            '93': {
                start: {
                    line: 228,
                    column: 12
                },
                end: {
                    line: 228,
                    column: 37
                }
            },
            '94': {
                start: {
                    line: 233,
                    column: 19
                },
                end: {
                    line: 233,
                    column: 23
                }
            },
            '95': {
                start: {
                    line: 234,
                    column: 8
                },
                end: {
                    line: 238,
                    column: 9
                }
            },
            '96': {
                start: {
                    line: 235,
                    column: 12
                },
                end: {
                    line: 235,
                    column: 81
                }
            },
            '97': {
                start: {
                    line: 237,
                    column: 12
                },
                end: {
                    line: 237,
                    column: 54
                }
            },
            '98': {
                start: {
                    line: 239,
                    column: 29
                },
                end: {
                    line: 239,
                    column: 54
                }
            },
            '99': {
                start: {
                    line: 240,
                    column: 20
                },
                end: {
                    line: 240,
                    column: 39
                }
            },
            '100': {
                start: {
                    line: 241,
                    column: 8
                },
                end: {
                    line: 241,
                    column: 31
                }
            },
            '101': {
                start: {
                    line: 245,
                    column: 29
                },
                end: {
                    line: 245,
                    column: 54
                }
            },
            '102': {
                start: {
                    line: 246,
                    column: 20
                },
                end: {
                    line: 246,
                    column: 39
                }
            },
            '103': {
                start: {
                    line: 247,
                    column: 8
                },
                end: {
                    line: 247,
                    column: 31
                }
            },
            '104': {
                start: {
                    line: 251,
                    column: 27
                },
                end: {
                    line: 251,
                    column: 29
                }
            },
            '105': {
                start: {
                    line: 252,
                    column: 26
                },
                end: {
                    line: 262,
                    column: 9
                }
            },
            '106': {
                start: {
                    line: 263,
                    column: 8
                },
                end: {
                    line: 266,
                    column: 9
                }
            },
            '107': {
                start: {
                    line: 264,
                    column: 25
                },
                end: {
                    line: 264,
                    column: 40
                }
            },
            '108': {
                start: {
                    line: 265,
                    column: 12
                },
                end: {
                    line: 265,
                    column: 52
                }
            },
            '109': {
                start: {
                    line: 267,
                    column: 8
                },
                end: {
                    line: 267,
                    column: 28
                }
            },
            '110': {
                start: {
                    line: 271,
                    column: 8
                },
                end: {
                    line: 271,
                    column: 62
                }
            },
            '111': {
                start: {
                    line: 275,
                    column: 22
                },
                end: {
                    line: 275,
                    column: 57
                }
            },
            '112': {
                start: {
                    line: 277,
                    column: 8
                },
                end: {
                    line: 286,
                    column: 9
                }
            },
            '113': {
                start: {
                    line: 279,
                    column: 12
                },
                end: {
                    line: 279,
                    column: 84
                }
            },
            '114': {
                start: {
                    line: 281,
                    column: 12
                },
                end: {
                    line: 281,
                    column: 97
                }
            },
            '115': {
                start: {
                    line: 283,
                    column: 12
                },
                end: {
                    line: 283,
                    column: 103
                }
            },
            '116': {
                start: {
                    line: 285,
                    column: 12
                },
                end: {
                    line: 285,
                    column: 103
                }
            },
            '117': {
                start: {
                    line: 290,
                    column: 26
                },
                end: {
                    line: 290,
                    column: 44
                }
            },
            '118': {
                start: {
                    line: 291,
                    column: 8
                },
                end: {
                    line: 304,
                    column: 10
                }
            },
            '119': {
                start: {
                    line: 293,
                    column: 16
                },
                end: {
                    line: 296,
                    column: 17
                }
            },
            '120': {
                start: {
                    line: 294,
                    column: 20
                },
                end: {
                    line: 294,
                    column: 49
                }
            },
            '121': {
                start: {
                    line: 295,
                    column: 20
                },
                end: {
                    line: 295,
                    column: 39
                }
            },
            '122': {
                start: {
                    line: 297,
                    column: 16
                },
                end: {
                    line: 302,
                    column: 42
                }
            },
            '123': {
                start: {
                    line: 308,
                    column: 26
                },
                end: {
                    line: 308,
                    column: 28
                }
            },
            '124': {
                start: {
                    line: 309,
                    column: 8
                },
                end: {
                    line: 312,
                    column: 11
                }
            },
            '125': {
                start: {
                    line: 310,
                    column: 29
                },
                end: {
                    line: 310,
                    column: 41
                }
            },
            '126': {
                start: {
                    line: 311,
                    column: 12
                },
                end: {
                    line: 311,
                    column: 115
                }
            },
            '127': {
                start: {
                    line: 311,
                    column: 34
                },
                end: {
                    line: 311,
                    column: 113
                }
            },
            '128': {
                start: {
                    line: 313,
                    column: 8
                },
                end: {
                    line: 313,
                    column: 25
                }
            },
            '129': {
                start: {
                    line: 317,
                    column: 30
                },
                end: {
                    line: 317,
                    column: 70
                }
            },
            '130': {
                start: {
                    line: 317,
                    column: 60
                },
                end: {
                    line: 317,
                    column: 69
                }
            },
            '131': {
                start: {
                    line: 318,
                    column: 26
                },
                end: {
                    line: 318,
                    column: 69
                }
            },
            '132': {
                start: {
                    line: 319,
                    column: 8
                },
                end: {
                    line: 319,
                    column: 98
                }
            },
            '133': {
                start: {
                    line: 319,
                    column: 56
                },
                end: {
                    line: 319,
                    column: 96
                }
            },
            '134': {
                start: {
                    line: 320,
                    column: 8
                },
                end: {
                    line: 320,
                    column: 27
                }
            },
            '135': {
                start: {
                    line: 324,
                    column: 27
                },
                end: {
                    line: 324,
                    column: 47
                }
            },
            '136': {
                start: {
                    line: 325,
                    column: 27
                },
                end: {
                    line: 325,
                    column: 48
                }
            },
            '137': {
                start: {
                    line: 326,
                    column: 22
                },
                end: {
                    line: 326,
                    column: 35
                }
            },
            '138': {
                start: {
                    line: 327,
                    column: 21
                },
                end: {
                    line: 327,
                    column: 54
                }
            },
            '139': {
                start: {
                    line: 328,
                    column: 8
                },
                end: {
                    line: 328,
                    column: 29
                }
            },
            '140': {
                start: {
                    line: 332,
                    column: 24
                },
                end: {
                    line: 332,
                    column: 50
                }
            },
            '141': {
                start: {
                    line: 333,
                    column: 8
                },
                end: {
                    line: 333,
                    column: 35
                }
            },
            '142': {
                start: {
                    line: 334,
                    column: 8
                },
                end: {
                    line: 334,
                    column: 83
                }
            },
            '143': {
                start: {
                    line: 334,
                    column: 39
                },
                end: {
                    line: 334,
                    column: 81
                }
            },
            '144': {
                start: {
                    line: 338,
                    column: 0
                },
                end: {
                    line: 346,
                    column: 3
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 5,
                        column: 74
                    },
                    end: {
                        line: 43,
                        column: 5
                    }
                },
                line: 5
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 34,
                        column: 49
                    },
                    end: {
                        line: 34,
                        column: 50
                    }
                },
                loc: {
                    start: {
                        line: 34,
                        column: 59
                    },
                    end: {
                        line: 42,
                        column: 9
                    }
                },
                line: 34
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 45,
                        column: 4
                    },
                    end: {
                        line: 45,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 45,
                        column: 20
                    },
                    end: {
                        line: 53,
                        column: 9
                    }
                },
                line: 45
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 55,
                        column: 4
                    },
                    end: {
                        line: 55,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 55,
                        column: 14
                    },
                    end: {
                        line: 68,
                        column: 5
                    }
                },
                line: 55
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 70,
                        column: 4
                    },
                    end: {
                        line: 70,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 70,
                        column: 51
                    },
                    end: {
                        line: 77,
                        column: 5
                    }
                },
                line: 70
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 76,
                        column: 17
                    },
                    end: {
                        line: 76,
                        column: 18
                    }
                },
                loc: {
                    start: {
                        line: 76,
                        column: 23
                    },
                    end: {
                        line: 76,
                        column: 50
                    }
                },
                line: 76
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 79,
                        column: 4
                    },
                    end: {
                        line: 79,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 79,
                        column: 36
                    },
                    end: {
                        line: 84,
                        column: 5
                    }
                },
                line: 79
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 83,
                        column: 17
                    },
                    end: {
                        line: 83,
                        column: 18
                    }
                },
                loc: {
                    start: {
                        line: 83,
                        column: 24
                    },
                    end: {
                        line: 83,
                        column: 40
                    }
                },
                line: 83
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 86,
                        column: 4
                    },
                    end: {
                        line: 86,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 86,
                        column: 41
                    },
                    end: {
                        line: 91,
                        column: 5
                    }
                },
                line: 86
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 90,
                        column: 17
                    },
                    end: {
                        line: 90,
                        column: 18
                    }
                },
                loc: {
                    start: {
                        line: 90,
                        column: 37
                    },
                    end: {
                        line: 90,
                        column: 79
                    }
                },
                line: 90
            },
            '10': {
                name: '(anonymous_10)',
                decl: {
                    start: {
                        line: 93,
                        column: 4
                    },
                    end: {
                        line: 93,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 93,
                        column: 50
                    },
                    end: {
                        line: 99,
                        column: 5
                    }
                },
                line: 93
            },
            '11': {
                name: '(anonymous_11)',
                decl: {
                    start: {
                        line: 98,
                        column: 17
                    },
                    end: {
                        line: 98,
                        column: 18
                    }
                },
                loc: {
                    start: {
                        line: 98,
                        column: 43
                    },
                    end: {
                        line: 98,
                        column: 85
                    }
                },
                line: 98
            },
            '12': {
                name: '(anonymous_12)',
                decl: {
                    start: {
                        line: 101,
                        column: 4
                    },
                    end: {
                        line: 101,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 101,
                        column: 63
                    },
                    end: {
                        line: 110,
                        column: 5
                    }
                },
                line: 101
            },
            '13': {
                name: '(anonymous_13)',
                decl: {
                    start: {
                        line: 109,
                        column: 17
                    },
                    end: {
                        line: 109,
                        column: 18
                    }
                },
                loc: {
                    start: {
                        line: 109,
                        column: 23
                    },
                    end: {
                        line: 109,
                        column: 51
                    }
                },
                line: 109
            },
            '14': {
                name: '(anonymous_14)',
                decl: {
                    start: {
                        line: 112,
                        column: 4
                    },
                    end: {
                        line: 112,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 112,
                        column: 24
                    },
                    end: {
                        line: 115,
                        column: 5
                    }
                },
                line: 112
            },
            '15': {
                name: '(anonymous_15)',
                decl: {
                    start: {
                        line: 114,
                        column: 12
                    },
                    end: {
                        line: 114,
                        column: 13
                    }
                },
                loc: {
                    start: {
                        line: 114,
                        column: 22
                    },
                    end: {
                        line: 114,
                        column: 72
                    }
                },
                line: 114
            },
            '16': {
                name: '(anonymous_16)',
                decl: {
                    start: {
                        line: 117,
                        column: 4
                    },
                    end: {
                        line: 117,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 117,
                        column: 39
                    },
                    end: {
                        line: 119,
                        column: 5
                    }
                },
                line: 117
            },
            '17': {
                name: '(anonymous_17)',
                decl: {
                    start: {
                        line: 121,
                        column: 4
                    },
                    end: {
                        line: 121,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 121,
                        column: 40
                    },
                    end: {
                        line: 124,
                        column: 5
                    }
                },
                line: 121
            },
            '18': {
                name: '(anonymous_18)',
                decl: {
                    start: {
                        line: 126,
                        column: 4
                    },
                    end: {
                        line: 126,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 126,
                        column: 38
                    },
                    end: {
                        line: 144,
                        column: 5
                    }
                },
                line: 126
            },
            '19': {
                name: 'DialogController',
                decl: {
                    start: {
                        line: 129,
                        column: 33
                    },
                    end: {
                        line: 129,
                        column: 49
                    }
                },
                loc: {
                    start: {
                        line: 129,
                        column: 52
                    },
                    end: {
                        line: 129,
                        column: 54
                    }
                },
                line: 129
            },
            '20': {
                name: '(anonymous_20)',
                decl: {
                    start: {
                        line: 146,
                        column: 4
                    },
                    end: {
                        line: 146,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 146,
                        column: 27
                    },
                    end: {
                        line: 151,
                        column: 5
                    }
                },
                line: 146
            },
            '21': {
                name: '(anonymous_21)',
                decl: {
                    start: {
                        line: 147,
                        column: 69
                    },
                    end: {
                        line: 147,
                        column: 70
                    }
                },
                loc: {
                    start: {
                        line: 147,
                        column: 81
                    },
                    end: {
                        line: 150,
                        column: 9
                    }
                },
                line: 147
            },
            '22': {
                name: '(anonymous_22)',
                decl: {
                    start: {
                        line: 154,
                        column: 4
                    },
                    end: {
                        line: 154,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 154,
                        column: 24
                    },
                    end: {
                        line: 157,
                        column: 5
                    }
                },
                line: 154
            },
            '23': {
                name: '(anonymous_23)',
                decl: {
                    start: {
                        line: 159,
                        column: 4
                    },
                    end: {
                        line: 159,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 159,
                        column: 19
                    },
                    end: {
                        line: 166,
                        column: 5
                    }
                },
                line: 159
            },
            '24': {
                name: '(anonymous_24)',
                decl: {
                    start: {
                        line: 161,
                        column: 12
                    },
                    end: {
                        line: 161,
                        column: 13
                    }
                },
                loc: {
                    start: {
                        line: 161,
                        column: 24
                    },
                    end: {
                        line: 164,
                        column: 13
                    }
                },
                line: 161
            },
            '25': {
                name: '(anonymous_25)',
                decl: {
                    start: {
                        line: 168,
                        column: 4
                    },
                    end: {
                        line: 168,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 168,
                        column: 38
                    },
                    end: {
                        line: 174,
                        column: 5
                    }
                },
                line: 168
            },
            '26': {
                name: '(anonymous_26)',
                decl: {
                    start: {
                        line: 170,
                        column: 23
                    },
                    end: {
                        line: 170,
                        column: 24
                    }
                },
                loc: {
                    start: {
                        line: 170,
                        column: 35
                    },
                    end: {
                        line: 173,
                        column: 9
                    }
                },
                line: 170
            },
            '27': {
                name: '(anonymous_27)',
                decl: {
                    start: {
                        line: 176,
                        column: 4
                    },
                    end: {
                        line: 176,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 176,
                        column: 22
                    },
                    end: {
                        line: 184,
                        column: 5
                    }
                },
                line: 176
            },
            '28': {
                name: '(anonymous_28)',
                decl: {
                    start: {
                        line: 178,
                        column: 19
                    },
                    end: {
                        line: 178,
                        column: 20
                    }
                },
                loc: {
                    start: {
                        line: 178,
                        column: 29
                    },
                    end: {
                        line: 183,
                        column: 9
                    }
                },
                line: 178
            },
            '29': {
                name: '(anonymous_29)',
                decl: {
                    start: {
                        line: 181,
                        column: 16
                    },
                    end: {
                        line: 181,
                        column: 17
                    }
                },
                loc: {
                    start: {
                        line: 181,
                        column: 30
                    },
                    end: {
                        line: 182,
                        column: 67
                    }
                },
                line: 181
            },
            '30': {
                name: '(anonymous_30)',
                decl: {
                    start: {
                        line: 186,
                        column: 4
                    },
                    end: {
                        line: 186,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 186,
                        column: 22
                    },
                    end: {
                        line: 192,
                        column: 5
                    }
                },
                line: 186
            },
            '31': {
                name: '(anonymous_31)',
                decl: {
                    start: {
                        line: 188,
                        column: 19
                    },
                    end: {
                        line: 188,
                        column: 20
                    }
                },
                loc: {
                    start: {
                        line: 188,
                        column: 28
                    },
                    end: {
                        line: 191,
                        column: 9
                    }
                },
                line: 188
            },
            '32': {
                name: '(anonymous_32)',
                decl: {
                    start: {
                        line: 194,
                        column: 4
                    },
                    end: {
                        line: 194,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 194,
                        column: 20
                    },
                    end: {
                        line: 200,
                        column: 5
                    }
                },
                line: 194
            },
            '33': {
                name: '(anonymous_33)',
                decl: {
                    start: {
                        line: 196,
                        column: 18
                    },
                    end: {
                        line: 196,
                        column: 19
                    }
                },
                loc: {
                    start: {
                        line: 196,
                        column: 26
                    },
                    end: {
                        line: 199,
                        column: 9
                    }
                },
                line: 196
            },
            '34': {
                name: '(anonymous_34)',
                decl: {
                    start: {
                        line: 202,
                        column: 4
                    },
                    end: {
                        line: 202,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 202,
                        column: 34
                    },
                    end: {
                        line: 210,
                        column: 5
                    }
                },
                line: 202
            },
            '35': {
                name: '(anonymous_35)',
                decl: {
                    start: {
                        line: 212,
                        column: 4
                    },
                    end: {
                        line: 212,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 212,
                        column: 29
                    },
                    end: {
                        line: 224,
                        column: 5
                    }
                },
                line: 212
            },
            '36': {
                name: '(anonymous_36)',
                decl: {
                    start: {
                        line: 214,
                        column: 17
                    },
                    end: {
                        line: 214,
                        column: 18
                    }
                },
                loc: {
                    start: {
                        line: 214,
                        column: 26
                    },
                    end: {
                        line: 222,
                        column: 9
                    }
                },
                line: 214
            },
            '37': {
                name: '(anonymous_37)',
                decl: {
                    start: {
                        line: 226,
                        column: 4
                    },
                    end: {
                        line: 226,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 226,
                        column: 25
                    },
                    end: {
                        line: 230,
                        column: 5
                    }
                },
                line: 226
            },
            '38': {
                name: '(anonymous_38)',
                decl: {
                    start: {
                        line: 227,
                        column: 43
                    },
                    end: {
                        line: 227,
                        column: 44
                    }
                },
                loc: {
                    start: {
                        line: 227,
                        column: 49
                    },
                    end: {
                        line: 229,
                        column: 9
                    }
                },
                line: 227
            },
            '39': {
                name: '(anonymous_39)',
                decl: {
                    start: {
                        line: 232,
                        column: 4
                    },
                    end: {
                        line: 232,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 232,
                        column: 30
                    },
                    end: {
                        line: 242,
                        column: 5
                    }
                },
                line: 232
            },
            '40': {
                name: '(anonymous_40)',
                decl: {
                    start: {
                        line: 244,
                        column: 4
                    },
                    end: {
                        line: 244,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 244,
                        column: 30
                    },
                    end: {
                        line: 248,
                        column: 5
                    }
                },
                line: 244
            },
            '41': {
                name: '(anonymous_41)',
                decl: {
                    start: {
                        line: 250,
                        column: 4
                    },
                    end: {
                        line: 250,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 250,
                        column: 25
                    },
                    end: {
                        line: 268,
                        column: 5
                    }
                },
                line: 250
            },
            '42': {
                name: '(anonymous_42)',
                decl: {
                    start: {
                        line: 270,
                        column: 4
                    },
                    end: {
                        line: 270,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 270,
                        column: 21
                    },
                    end: {
                        line: 272,
                        column: 5
                    }
                },
                line: 270
            },
            '43': {
                name: '(anonymous_43)',
                decl: {
                    start: {
                        line: 274,
                        column: 4
                    },
                    end: {
                        line: 274,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 274,
                        column: 44
                    },
                    end: {
                        line: 287,
                        column: 5
                    }
                },
                line: 274
            },
            '44': {
                name: '(anonymous_44)',
                decl: {
                    start: {
                        line: 289,
                        column: 4
                    },
                    end: {
                        line: 289,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 289,
                        column: 18
                    },
                    end: {
                        line: 305,
                        column: 5
                    }
                },
                line: 289
            },
            '45': {
                name: '(anonymous_45)',
                decl: {
                    start: {
                        line: 292,
                        column: 12
                    },
                    end: {
                        line: 292,
                        column: 13
                    }
                },
                loc: {
                    start: {
                        line: 292,
                        column: 24
                    },
                    end: {
                        line: 303,
                        column: 13
                    }
                },
                line: 292
            },
            '46': {
                name: '(anonymous_46)',
                decl: {
                    start: {
                        line: 307,
                        column: 4
                    },
                    end: {
                        line: 307,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 307,
                        column: 43
                    },
                    end: {
                        line: 314,
                        column: 5
                    }
                },
                line: 307
            },
            '47': {
                name: '(anonymous_47)',
                decl: {
                    start: {
                        line: 309,
                        column: 20
                    },
                    end: {
                        line: 309,
                        column: 21
                    }
                },
                loc: {
                    start: {
                        line: 309,
                        column: 42
                    },
                    end: {
                        line: 312,
                        column: 9
                    }
                },
                line: 309
            },
            '48': {
                name: '(anonymous_48)',
                decl: {
                    start: {
                        line: 311,
                        column: 25
                    },
                    end: {
                        line: 311,
                        column: 26
                    }
                },
                loc: {
                    start: {
                        line: 311,
                        column: 34
                    },
                    end: {
                        line: 311,
                        column: 113
                    }
                },
                line: 311
            },
            '49': {
                name: '(anonymous_49)',
                decl: {
                    start: {
                        line: 316,
                        column: 4
                    },
                    end: {
                        line: 316,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 316,
                        column: 42
                    },
                    end: {
                        line: 321,
                        column: 5
                    }
                },
                line: 316
            },
            '50': {
                name: '(anonymous_50)',
                decl: {
                    start: {
                        line: 317,
                        column: 53
                    },
                    end: {
                        line: 317,
                        column: 54
                    }
                },
                loc: {
                    start: {
                        line: 317,
                        column: 60
                    },
                    end: {
                        line: 317,
                        column: 69
                    }
                },
                line: 317
            },
            '51': {
                name: '(anonymous_51)',
                decl: {
                    start: {
                        line: 319,
                        column: 43
                    },
                    end: {
                        line: 319,
                        column: 44
                    }
                },
                loc: {
                    start: {
                        line: 319,
                        column: 56
                    },
                    end: {
                        line: 319,
                        column: 96
                    }
                },
                line: 319
            },
            '52': {
                name: '(anonymous_52)',
                decl: {
                    start: {
                        line: 323,
                        column: 4
                    },
                    end: {
                        line: 323,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 323,
                        column: 24
                    },
                    end: {
                        line: 329,
                        column: 5
                    }
                },
                line: 323
            },
            '53': {
                name: '(anonymous_53)',
                decl: {
                    start: {
                        line: 331,
                        column: 4
                    },
                    end: {
                        line: 331,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 331,
                        column: 31
                    },
                    end: {
                        line: 335,
                        column: 5
                    }
                },
                line: 331
            },
            '54': {
                name: '(anonymous_54)',
                decl: {
                    start: {
                        line: 334,
                        column: 33
                    },
                    end: {
                        line: 334,
                        column: 34
                    }
                },
                loc: {
                    start: {
                        line: 334,
                        column: 39
                    },
                    end: {
                        line: 334,
                        column: 81
                    }
                },
                line: 334
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 35,
                        column: 12
                    },
                    end: {
                        line: 41,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 35,
                        column: 12
                    },
                    end: {
                        line: 41,
                        column: 13
                    }
                }, {
                    start: {
                        line: 35,
                        column: 12
                    },
                    end: {
                        line: 41,
                        column: 13
                    }
                }],
                line: 35
            },
            '1': {
                loc: {
                    start: {
                        line: 46,
                        column: 12
                    },
                    end: {
                        line: 51,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 46,
                        column: 12
                    },
                    end: {
                        line: 51,
                        column: 13
                    }
                }, {
                    start: {
                        line: 46,
                        column: 12
                    },
                    end: {
                        line: 51,
                        column: 13
                    }
                }],
                line: 46
            },
            '2': {
                loc: {
                    start: {
                        line: 48,
                        column: 16
                    },
                    end: {
                        line: 50,
                        column: 17
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 48,
                        column: 16
                    },
                    end: {
                        line: 50,
                        column: 17
                    }
                }, {
                    start: {
                        line: 48,
                        column: 16
                    },
                    end: {
                        line: 50,
                        column: 17
                    }
                }],
                line: 48
            },
            '3': {
                loc: {
                    start: {
                        line: 48,
                        column: 20
                    },
                    end: {
                        line: 48,
                        column: 37
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 48,
                        column: 20
                    },
                    end: {
                        line: 48,
                        column: 21
                    }
                }, {
                    start: {
                        line: 48,
                        column: 25
                    },
                    end: {
                        line: 48,
                        column: 37
                    }
                }],
                line: 48
            },
            '4': {
                loc: {
                    start: {
                        line: 216,
                        column: 12
                    },
                    end: {
                        line: 221,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 216,
                        column: 12
                    },
                    end: {
                        line: 221,
                        column: 13
                    }
                }, {
                    start: {
                        line: 216,
                        column: 12
                    },
                    end: {
                        line: 221,
                        column: 13
                    }
                }],
                line: 216
            },
            '5': {
                loc: {
                    start: {
                        line: 234,
                        column: 8
                    },
                    end: {
                        line: 238,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 234,
                        column: 8
                    },
                    end: {
                        line: 238,
                        column: 9
                    }
                }, {
                    start: {
                        line: 234,
                        column: 8
                    },
                    end: {
                        line: 238,
                        column: 9
                    }
                }],
                line: 234
            },
            '6': {
                loc: {
                    start: {
                        line: 265,
                        column: 28
                    },
                    end: {
                        line: 265,
                        column: 51
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 265,
                        column: 28
                    },
                    end: {
                        line: 265,
                        column: 43
                    }
                }, {
                    start: {
                        line: 265,
                        column: 47
                    },
                    end: {
                        line: 265,
                        column: 51
                    }
                }],
                line: 265
            },
            '7': {
                loc: {
                    start: {
                        line: 277,
                        column: 8
                    },
                    end: {
                        line: 286,
                        column: 9
                    }
                },
                type: 'switch',
                locations: [{
                    start: {
                        line: 278,
                        column: 8
                    },
                    end: {
                        line: 279,
                        column: 84
                    }
                }, {
                    start: {
                        line: 280,
                        column: 8
                    },
                    end: {
                        line: 281,
                        column: 97
                    }
                }, {
                    start: {
                        line: 282,
                        column: 8
                    },
                    end: {
                        line: 283,
                        column: 103
                    }
                }, {
                    start: {
                        line: 284,
                        column: 8
                    },
                    end: {
                        line: 285,
                        column: 103
                    }
                }],
                line: 277
            },
            '8': {
                loc: {
                    start: {
                        line: 293,
                        column: 16
                    },
                    end: {
                        line: 296,
                        column: 17
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 293,
                        column: 16
                    },
                    end: {
                        line: 296,
                        column: 17
                    }
                }, {
                    start: {
                        line: 293,
                        column: 16
                    },
                    end: {
                        line: 296,
                        column: 17
                    }
                }],
                line: 293
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0,
            '28': 0,
            '29': 0,
            '30': 0,
            '31': 0,
            '32': 0,
            '33': 0,
            '34': 0,
            '35': 0,
            '36': 0,
            '37': 0,
            '38': 0,
            '39': 0,
            '40': 0,
            '41': 0,
            '42': 0,
            '43': 0,
            '44': 0,
            '45': 0,
            '46': 0,
            '47': 0,
            '48': 0,
            '49': 0,
            '50': 0,
            '51': 0,
            '52': 0,
            '53': 0,
            '54': 0,
            '55': 0,
            '56': 0,
            '57': 0,
            '58': 0,
            '59': 0,
            '60': 0,
            '61': 0,
            '62': 0,
            '63': 0,
            '64': 0,
            '65': 0,
            '66': 0,
            '67': 0,
            '68': 0,
            '69': 0,
            '70': 0,
            '71': 0,
            '72': 0,
            '73': 0,
            '74': 0,
            '75': 0,
            '76': 0,
            '77': 0,
            '78': 0,
            '79': 0,
            '80': 0,
            '81': 0,
            '82': 0,
            '83': 0,
            '84': 0,
            '85': 0,
            '86': 0,
            '87': 0,
            '88': 0,
            '89': 0,
            '90': 0,
            '91': 0,
            '92': 0,
            '93': 0,
            '94': 0,
            '95': 0,
            '96': 0,
            '97': 0,
            '98': 0,
            '99': 0,
            '100': 0,
            '101': 0,
            '102': 0,
            '103': 0,
            '104': 0,
            '105': 0,
            '106': 0,
            '107': 0,
            '108': 0,
            '109': 0,
            '110': 0,
            '111': 0,
            '112': 0,
            '113': 0,
            '114': 0,
            '115': 0,
            '116': 0,
            '117': 0,
            '118': 0,
            '119': 0,
            '120': 0,
            '121': 0,
            '122': 0,
            '123': 0,
            '124': 0,
            '125': 0,
            '126': 0,
            '127': 0,
            '128': 0,
            '129': 0,
            '130': 0,
            '131': 0,
            '132': 0,
            '133': 0,
            '134': 0,
            '135': 0,
            '136': 0,
            '137': 0,
            '138': 0,
            '139': 0,
            '140': 0,
            '141': 0,
            '142': 0,
            '143': 0,
            '144': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0,
            '28': 0,
            '29': 0,
            '30': 0,
            '31': 0,
            '32': 0,
            '33': 0,
            '34': 0,
            '35': 0,
            '36': 0,
            '37': 0,
            '38': 0,
            '39': 0,
            '40': 0,
            '41': 0,
            '42': 0,
            '43': 0,
            '44': 0,
            '45': 0,
            '46': 0,
            '47': 0,
            '48': 0,
            '49': 0,
            '50': 0,
            '51': 0,
            '52': 0,
            '53': 0,
            '54': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0],
            '2': [0, 0],
            '3': [0, 0],
            '4': [0, 0],
            '5': [0, 0],
            '6': [0, 0],
            '7': [0, 0, 0, 0],
            '8': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var OrganizationDetailsController = function () {
    function OrganizationDetailsController($scope, LinkCategoriesTable, TagsTable, InteractionTypesTable, IndividualsTable, EconomicActivitiesTable, MunicipalitiesTable, $window, $mdPanel, NewEmailModalService, AppSettings, CurrencyConversionsTable, $rootScope, OrganizationEmailsTable, $mdToast, $document) {
        var _this = this;

        _classCallCheck(this, OrganizationDetailsController);

        cov_4tcvznqaj.f[0]++;
        cov_4tcvznqaj.s[0]++;

        this.LinkCategoriesTable = LinkCategoriesTable;
        cov_4tcvznqaj.s[1]++;
        this.InteractionTypesTable = InteractionTypesTable;
        cov_4tcvznqaj.s[2]++;
        this.TagsTable = TagsTable;
        cov_4tcvznqaj.s[3]++;
        this.IndividualsTable = IndividualsTable;
        cov_4tcvznqaj.s[4]++;
        this.EconomicActivitiesTable = EconomicActivitiesTable;
        cov_4tcvznqaj.s[5]++;
        this.MunicipalitiesTable = MunicipalitiesTable;
        cov_4tcvznqaj.s[6]++;
        this.newInteractionAt = new Date();
        cov_4tcvznqaj.s[7]++;
        this.$window = $window;
        cov_4tcvznqaj.s[8]++;
        this.$mdPanel = $mdPanel;
        cov_4tcvznqaj.s[9]++;
        this.NewEmailModalService = NewEmailModalService;
        cov_4tcvznqaj.s[10]++;
        this.AppSettings = AppSettings;
        cov_4tcvznqaj.s[11]++;
        this.fetching = false;
        cov_4tcvznqaj.s[12]++;
        this.fetchedResult = null;
        cov_4tcvznqaj.s[13]++;
        this.CurrencyConversionsTable = CurrencyConversionsTable;
        cov_4tcvznqaj.s[14]++;
        this.$rootScope = $rootScope;
        cov_4tcvznqaj.s[15]++;
        this.OrganizationEmailsTable = OrganizationEmailsTable;
        cov_4tcvznqaj.s[16]++;
        this.createCandidates = this.createCandidates.bind(this);
        cov_4tcvznqaj.s[17]++;
        this.patternBaker = this.patternBaker.bind(this);
        cov_4tcvznqaj.s[18]++;
        this.createEmail = this.createEmail.bind(this);
        cov_4tcvznqaj.s[19]++;
        this.getRelatedAutocomplete = this.getRelatedAutocomplete.bind(this);
        cov_4tcvznqaj.s[20]++;
        this.addEmails = this.addEmails.bind(this);
        cov_4tcvznqaj.s[21]++;
        this.addLinks = this.addLinks.bind(this);
        cov_4tcvznqaj.s[22]++;
        this.addEconomicActivities = this.addEconomicActivities.bind(this);
        cov_4tcvznqaj.s[23]++;
        this.addPeople = this.addPeople.bind(this);
        cov_4tcvznqaj.s[24]++;
        this.$mdToast = $mdToast;
        cov_4tcvznqaj.s[25]++;
        this.$document = $document;

        // files
        cov_4tcvznqaj.s[26]++;
        $scope.$watch('$ctrl.newFileAttachment', function (newVal) {
            cov_4tcvznqaj.f[1]++;
            cov_4tcvznqaj.s[27]++;

            if (angular.isString(newVal)) {
                cov_4tcvznqaj.b[0][0]++;
                cov_4tcvznqaj.s[28]++;

                _this.organization.$files.$create({
                    organization: _this.organization.id,
                    path: newVal,
                    name: _this.fileToName(newVal)
                });
            } else {
                cov_4tcvznqaj.b[0][1]++;
            }
        });
    }

    _createClass(OrganizationDetailsController, [{
        key: 'fileToName',
        value: function fileToName(url) {
            cov_4tcvznqaj.f[2]++;
            cov_4tcvznqaj.s[29]++;

            if (url) {
                cov_4tcvznqaj.b[1][0]++;

                var m = (cov_4tcvznqaj.s[30]++, url.toString().match(/.*\/(.+?)\.(.+)/));
                cov_4tcvznqaj.s[31]++;
                if ((cov_4tcvznqaj.b[3][0]++, m) && (cov_4tcvznqaj.b[3][1]++, m.length > 1)) {
                    cov_4tcvznqaj.b[2][0]++;
                    cov_4tcvznqaj.s[32]++;

                    return m[1] + '.' + m[2];
                } else {
                    cov_4tcvznqaj.b[2][1]++;
                }
            } else {
                cov_4tcvznqaj.b[1][1]++;
            }
            cov_4tcvznqaj.s[33]++;
            return "";
        }
    }, {
        key: '$onInit',
        value: function $onInit() {
            cov_4tcvznqaj.f[3]++;
            cov_4tcvznqaj.s[34]++;

            this.organization.$load('links').$load('organizationIndividuals').$load('comments').$load('tags').$load('interactions').$load('economicActivities').$load('emails').$load('siteVisits').$load('domains');
            cov_4tcvznqaj.s[35]++;
            this.organization.$organizationIndividuals.$load('individual');
            cov_4tcvznqaj.s[36]++;
            this.organization.$comments.$load('user');
            cov_4tcvznqaj.s[37]++;
            this.organization.$load('files');

            cov_4tcvznqaj.s[38]++;
            this.categories = this.LinkCategoriesTable.$search();
            cov_4tcvznqaj.s[39]++;
            this.interactionTypes = this.InteractionTypesTable.$search();
            cov_4tcvznqaj.s[40]++;
            this.tags = this.TagsTable.$search();
            cov_4tcvznqaj.s[41]++;
            this.individuals = this.IndividualsTable.$search();
            cov_4tcvznqaj.s[42]++;
            this.economicActivities = this.EconomicActivitiesTable.$search({ level: 5 });
            /* eslint-disable camelcase */
            cov_4tcvznqaj.s[43]++;
            this.municipalities = this.MunicipalitiesTable.$search({ page_size: 999 });
        }
    }, {
        key: 'createComment',
        value: function createComment(organization, newCommentContent) {
            var _this2 = this;

            cov_4tcvznqaj.f[4]++;
            cov_4tcvznqaj.s[44]++;

            this.organization.$comments.$create({
                objectId: organization.id,
                objectType: 'ORGANIZATION',
                content: newCommentContent
                /* eslint-disable no-return-assign */
            }).$then(function () {
                cov_4tcvznqaj.f[5]++;
                cov_4tcvznqaj.s[45]++;
                return _this2.newCommentContent = '';
            });
        }
    }, {
        key: 'onSelectTag',
        value: function onSelectTag(organization, item) {
            cov_4tcvznqaj.f[6]++;
            cov_4tcvznqaj.s[46]++;

            this.organization.$tags.$create({
                organization: organization.id,
                tag: item.id
            }).$then(function (tag) {
                cov_4tcvznqaj.f[7]++;
                cov_4tcvznqaj.s[47]++;
                return tag.$load('tag');
            });
        }
    }, {
        key: 'onSelectIndustry',
        value: function onSelectIndustry(organization, item) {
            cov_4tcvznqaj.f[8]++;
            cov_4tcvznqaj.s[48]++;

            this.organization.$economicActivities.$create({
                organization: organization.id,
                economicActivity: item.id
            }).$then(function (economicActivity) {
                cov_4tcvznqaj.f[9]++;
                cov_4tcvznqaj.s[49]++;
                return economicActivity.$load('economicActivity');
            });
        }
    }, {
        key: 'onSelectIndividual',
        value: function onSelectIndividual(organization, item, title) {
            cov_4tcvznqaj.f[10]++;
            cov_4tcvznqaj.s[50]++;

            this.organization.$organizationIndividuals.$create({
                organization1: organization.id,
                organization2: item.id,
                description: title
            }).$then(function (organizationIndividual) {
                cov_4tcvznqaj.f[11]++;
                cov_4tcvznqaj.s[51]++;
                return organizationIndividual.$load('individual');
            });
        }
    }, {
        key: 'createInteraction',
        value: function createInteraction(interactionType, description, at, email) {
            var _this3 = this;

            cov_4tcvznqaj.f[12]++;
            cov_4tcvznqaj.s[52]++;

            this.organization.$interactions.$create({
                organization: this.organization.id,
                email: email,
                description: description,
                interactedAt: at,
                interactionType: interactionType
                /* eslint-disable no-return-assign */
            }).$then(function () {
                cov_4tcvznqaj.f[13]++;
                cov_4tcvznqaj.s[53]++;
                return _this3.newInteractionName = '';
            });
        }
    }, {
        key: 'addIndividual',
        value: function addIndividual(name) {
            var _this4 = this;

            cov_4tcvznqaj.f[14]++;
            cov_4tcvznqaj.s[54]++;

            this.IndividualsTable.$create({ name: name }).$then(function (result) {
                cov_4tcvznqaj.f[15]++;
                cov_4tcvznqaj.s[55]++;
                return _this4.onSelectIndividual(_this4.organization, result);
            });
        }
    }, {
        key: 'displayForEconomicActivities',
        value: function displayForEconomicActivities(item) {
            cov_4tcvznqaj.f[16]++;
            cov_4tcvznqaj.s[56]++;

            return item.code + ' - ' + item.name;
        }
    }, {
        key: 'toogleFirstInteraction',
        value: function toogleFirstInteraction(interaction) {
            cov_4tcvznqaj.f[17]++;
            cov_4tcvznqaj.s[57]++;

            interaction.firstInteraction = !interaction.firstInteraction;
            cov_4tcvznqaj.s[58]++;
            interaction.$save(['firstInteraction']);
        }
    }, {
        key: 'editOrganizationIndividual',
        value: function editOrganizationIndividual(ev, x) {
            cov_4tcvznqaj.f[18]++;

            var position = (cov_4tcvznqaj.s[59]++, this.$mdPanel.newPanelPosition().absolute().center());
            var config = (cov_4tcvznqaj.s[60]++, {
                controller: function DialogController() {
                    cov_4tcvznqaj.f[19]++;
                },
                controllerAs: '$ctrl',
                templateUrl: 'app/components/organizationDetails/organizationIndividual.tmpl.html',
                hasBackdrop: true,
                position: position,
                trapFocus: true,
                zIndex: 150,
                clickOutsideToClose: true,
                escapeToClose: true,
                focusOnOpen: true,
                locals: {
                    individual: x
                }
            });
            cov_4tcvznqaj.s[61]++;
            this.$mdPanel.open(config);
        }
    }, {
        key: 'displayNewEmailModal',
        value: function displayNewEmailModal() {
            var _this5 = this;

            cov_4tcvznqaj.f[20]++;
            cov_4tcvznqaj.s[62]++;

            this.NewEmailModalService.display(this.organization.id).then(function (newEmail) {
                cov_4tcvznqaj.f[21]++;
                cov_4tcvznqaj.s[63]++;

                _this5.newInteractionName = 'Email para ' + newEmail.to;
                cov_4tcvznqaj.s[64]++;
                _this5.newInteractionEmail = newEmail.id;
            });
        }

        /* eslint-disable camelcase */

    }, {
        key: 'showEmail',
        value: function showEmail(email_id) {
            cov_4tcvznqaj.f[22]++;

            var url = (cov_4tcvznqaj.s[65]++, this.AppSettings.apiUrl + '/email-interactions/' + email_id + '/');
            cov_4tcvznqaj.s[66]++;
            this.$window.open(url);
        }
    }, {
        key: 'convert',
        value: function convert(field) {
            var _this6 = this;

            cov_4tcvznqaj.f[23]++;
            cov_4tcvznqaj.s[67]++;

            this.CurrencyConversionsTable.$create({ amount: this.organization[field] }).$then(function (response) {
                cov_4tcvznqaj.f[24]++;
                cov_4tcvznqaj.s[68]++;

                _this6.organization[field] = response.convertedAmount;
                cov_4tcvznqaj.s[69]++;
                _this6.organization.$save([field]);
            });
        }
    }, {
        key: 'addEconomicActivities',
        value: function addEconomicActivities(activities) {
            var _this7 = this;

            cov_4tcvznqaj.f[25]++;
            cov_4tcvznqaj.s[70]++;

            /* eslint-disable  array-callback-return*/
            activities.map(function (activity) {
                cov_4tcvznqaj.f[26]++;

                var data = (cov_4tcvznqaj.s[71]++, { organization: _this7.organization.id, economic_activity: activity });
                cov_4tcvznqaj.s[72]++;
                _this7.organization.$economicActivities.$create(data);
            });
        }
    }, {
        key: 'addPeople',
        value: function addPeople(people) {
            var _this8 = this;

            cov_4tcvznqaj.f[27]++;
            cov_4tcvznqaj.s[73]++;

            /* eslint-disable  array-callback-return*/
            people.map(function (person) {
                cov_4tcvznqaj.f[28]++;

                var data = (cov_4tcvznqaj.s[74]++, { name: person.name });
                cov_4tcvznqaj.s[75]++;
                _this8.IndividualsTable.$create(data).$then(function (individual) {
                    cov_4tcvznqaj.f[29]++;
                    cov_4tcvznqaj.s[76]++;
                    return _this8.onSelectIndividual(_this8.organization, individual, person.jobTitle);
                });
            });
        }
    }, {
        key: 'addEmails',
        value: function addEmails(emails) {
            var _this9 = this;

            cov_4tcvznqaj.f[30]++;
            cov_4tcvznqaj.s[77]++;

            /* eslint-disable  array-callback-return*/
            emails.map(function (email) {
                cov_4tcvznqaj.f[31]++;

                var data = (cov_4tcvznqaj.s[78]++, { email: email.email, organization: _this9.organization.id });
                cov_4tcvznqaj.s[79]++;
                _this9.organization.$emails.$create(data);
            });
        }
    }, {
        key: 'addLinks',
        value: function addLinks(links) {
            var _this10 = this;

            cov_4tcvznqaj.f[32]++;
            cov_4tcvznqaj.s[80]++;

            /* eslint-disable  array-callback-return*/
            links.map(function (link) {
                cov_4tcvznqaj.f[33]++;

                var data = (cov_4tcvznqaj.s[81]++, { link: link.link, category: link.category, organization: _this10.organization.id });
                cov_4tcvznqaj.s[82]++;
                _this10.organization.$links.$create(data);
            });
        }
    }, {
        key: 'getRelatedAutocomplete',
        value: function getRelatedAutocomplete(field) {
            cov_4tcvznqaj.f[34]++;

            var related = (cov_4tcvznqaj.s[83]++, {
                people: this.addPeople,
                economicActivities: this.addEconomicActivities,
                emails: this.addEmails,
                links: this.addLinks
            });
            cov_4tcvznqaj.s[84]++;
            return related[field];
        }
    }, {
        key: 'autocomplete',
        value: function autocomplete(completion) {
            var _this11 = this;

            cov_4tcvznqaj.f[35]++;

            var keys = (cov_4tcvznqaj.s[85]++, Object.keys(completion));
            cov_4tcvznqaj.s[86]++;
            keys.map(function (field) {
                cov_4tcvznqaj.f[36]++;
                cov_4tcvznqaj.s[87]++;

                /* eslint-disable angular/typecheck-array */
                if (Array.isArray(completion[field])) {
                    cov_4tcvznqaj.b[4][0]++;

                    var callback = (cov_4tcvznqaj.s[88]++, _this11.getRelatedAutocomplete(field));
                    cov_4tcvznqaj.s[89]++;
                    callback(completion[field]);
                } else {
                    cov_4tcvznqaj.b[4][1]++;
                    cov_4tcvznqaj.s[90]++;

                    _this11.organization[field] = completion[field];
                }
            });
            cov_4tcvznqaj.s[91]++;
            this.organization.$save(keys);
        }
    }, {
        key: 'deleteOrganization',
        value: function deleteOrganization() {
            var _this12 = this;

            cov_4tcvznqaj.f[37]++;
            cov_4tcvznqaj.s[92]++;

            this.organization.$destroy().$then(function () {
                cov_4tcvznqaj.f[38]++;
                cov_4tcvznqaj.s[93]++;

                _this12.$rootScope.goBack();
            });
        }
    }, {
        key: 'googleSearch',
        value: function googleSearch(query, site) {
            cov_4tcvznqaj.f[39]++;

            var base = (cov_4tcvznqaj.s[94]++, null);
            cov_4tcvznqaj.s[95]++;
            if (site) {
                cov_4tcvznqaj.b[5][0]++;
                cov_4tcvznqaj.s[96]++;

                base = 'https://www.google.com/search?as_sitesearch=' + site + '&q=';
            } else {
                cov_4tcvznqaj.b[5][1]++;
                cov_4tcvznqaj.s[97]++;

                base = 'https://www.google.com/search?q=';
            }
            var encodedQuery = (cov_4tcvznqaj.s[98]++, encodeURIComponent(query));
            var url = (cov_4tcvznqaj.s[99]++, base + encodedQuery);
            cov_4tcvznqaj.s[100]++;
            this.$window.open(url);
        }
    }, {
        key: 'openQueryURL',
        value: function openQueryURL(base, query) {
            cov_4tcvznqaj.f[40]++;

            var encodedQuery = (cov_4tcvznqaj.s[101]++, encodeURIComponent(query));
            var url = (cov_4tcvznqaj.s[102]++, base + encodedQuery);
            cov_4tcvznqaj.s[103]++;
            this.$window.open(url);
        }
    }, {
        key: 'accentFolding',
        value: function accentFolding(value) {
            cov_4tcvznqaj.f[41]++;

            var folded_value = (cov_4tcvznqaj.s[104]++, '');
            var accentMap = (cov_4tcvznqaj.s[105]++, {
                á: 'a',
                à: 'a',
                ã: 'a',
                é: 'e',
                õ: 'o',
                ó: 'o',
                ç: 'c',
                ñ: 'n',
                í: 'i'
            });
            cov_4tcvznqaj.s[106]++;
            for (var i = 0; i < value.length; i++) {
                var char = (cov_4tcvznqaj.s[107]++, value.charAt(i));
                cov_4tcvznqaj.s[108]++;
                folded_value += (cov_4tcvznqaj.b[6][0]++, accentMap[char]) || (cov_4tcvznqaj.b[6][1]++, char);
            }
            cov_4tcvznqaj.s[109]++;
            return folded_value;
        }
    }, {
        key: 'normalize',
        value: function normalize(value) {
            cov_4tcvznqaj.f[42]++;
            cov_4tcvznqaj.s[110]++;

            return this.accentFolding(value.trim().toLowerCase());
        }
    }, {
        key: 'patternBaker',
        value: function patternBaker(index, individual, domain) {
            cov_4tcvznqaj.f[43]++;

            var names = (cov_4tcvznqaj.s[111]++, this.decomposeName(individual.name));
            /* eslint-disable default-case */
            cov_4tcvznqaj.s[112]++;
            switch (index) {
                case 1:
                    cov_4tcvznqaj.b[7][0]++;
                    cov_4tcvznqaj.s[113]++;

                    return { email: names.first + '@' + domain, organization: individual.id };
                case 2:
                    cov_4tcvznqaj.b[7][1]++;
                    cov_4tcvznqaj.s[114]++;

                    return { email: names.first + names.last + '@' + domain, organization: individual.id };
                case 3:
                    cov_4tcvznqaj.b[7][2]++;
                    cov_4tcvznqaj.s[115]++;

                    return { email: names.first + '.' + names.last + '@' + domain, organization: individual.id };
                case 4:
                    cov_4tcvznqaj.b[7][3]++;
                    cov_4tcvznqaj.s[116]++;

                    return { email: names.first + '_' + names.last + '@' + domain, organization: individual.id };
            }
        }
    }, {
        key: 'createEmail',
        value: function createEmail() {
            var _this13 = this;

            cov_4tcvznqaj.f[44]++;

            var candidate = (cov_4tcvznqaj.s[117]++, this.candidates[0]);
            cov_4tcvznqaj.s[118]++;
            this.OrganizationEmailsTable.$create({ email: candidate.email, organization: candidate.organization }).$then(function (response) {
                cov_4tcvznqaj.f[45]++;
                cov_4tcvznqaj.s[119]++;

                if (response.validationCode !== 'OK') {
                    cov_4tcvznqaj.b[8][0]++;
                    cov_4tcvznqaj.s[120]++;

                    _this13.candidates.splice(0, 1);
                    cov_4tcvznqaj.s[121]++;
                    _this13.createEmail();
                } else {
                    cov_4tcvznqaj.b[8][1]++;
                }
                cov_4tcvznqaj.s[122]++;
                _this13.$mdToast.show(_this13.$mdToast.simple().textContent('Checked ' + candidate.email).position('bottom right').parent(angular.element(_this13.$document.body)).hideDelay(3000));
            });
        }
    }, {
        key: 'generatePatterned',
        value: function generatePatterned(individual, domains) {
            var _this14 = this;

            cov_4tcvznqaj.f[46]++;

            var generated = (cov_4tcvznqaj.s[123]++, []);
            cov_4tcvznqaj.s[124]++;
            domains.map(function (organizationDomain) {
                cov_4tcvznqaj.f[47]++;

                var patterns = (cov_4tcvznqaj.s[125]++, [1, 2, 3, 4]);
                cov_4tcvznqaj.s[126]++;
                patterns.map(function (index) {
                    cov_4tcvznqaj.f[48]++;
                    cov_4tcvznqaj.s[127]++;
                    return generated.push(_this14.patternBaker(index, individual, organizationDomain.domain));
                });
            });
            cov_4tcvznqaj.s[128]++;
            return generated;
        }
    }, {
        key: 'createCandidates',
        value: function createCandidates(individual, domains) {
            cov_4tcvznqaj.f[49]++;

            var currentEmails = (cov_4tcvznqaj.s[129]++, individual.$emails.map(function (obj) {
                cov_4tcvznqaj.f[50]++;
                cov_4tcvznqaj.s[130]++;
                return obj.email;
            }));
            var generated = (cov_4tcvznqaj.s[131]++, this.generatePatterned(individual, domains));
            cov_4tcvznqaj.s[132]++;
            this.candidates = generated.filter(function (candidate) {
                cov_4tcvznqaj.f[51]++;
                cov_4tcvznqaj.s[133]++;
                return !currentEmails.includes(candidate.email);
            });
            cov_4tcvznqaj.s[134]++;
            this.createEmail();
        }
    }, {
        key: 'decomposeName',
        value: function decomposeName(name) {
            cov_4tcvznqaj.f[52]++;

            var normalized = (cov_4tcvznqaj.s[135]++, this.normalize(name));
            var components = (cov_4tcvznqaj.s[136]++, normalized.split(' '));
            var first = (cov_4tcvznqaj.s[137]++, components[0]);
            var last = (cov_4tcvznqaj.s[138]++, components[components.length - 1]);
            cov_4tcvznqaj.s[139]++;
            return { first: first, last: last };
        }
    }, {
        key: 'generateEmails',
        value: function generateEmails(individual) {
            var _this15 = this;

            cov_4tcvznqaj.f[53]++;

            var domains = (cov_4tcvznqaj.s[140]++, this.organization.$domains);
            cov_4tcvznqaj.s[141]++;
            individual.$load('emails');
            cov_4tcvznqaj.s[142]++;
            individual.$emails.$then(function () {
                cov_4tcvznqaj.f[54]++;
                cov_4tcvznqaj.s[143]++;
                return _this15.createCandidates(individual, domains);
            });
        }
    }]);

    return OrganizationDetailsController;
}();

cov_4tcvznqaj.s[144]++;


angular.module('app').component('organizationDetails', {
    templateUrl: 'app/components/organizationDetails/organizationDetails.html',
    controller: OrganizationDetailsController,
    bindings: {
        organization: '<'
    }
});