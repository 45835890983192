'use strict';

var cov_t44w63jze = function () {
    var path = '/home/lobo/repos/prospector-app/src/app/shared/js/media/media.directive.js',
        hash = '5232c3b463c2968a75ff88e777c745cf95ee0c59',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/prospector-app/src/app/shared/js/media/media.directive.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 32,
                    column: 5
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 4
                },
                end: {
                    line: 6,
                    column: 54
                }
            },
            '2': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 30,
                    column: 10
                }
            },
            '3': {
                start: {
                    line: 13,
                    column: 31
                },
                end: {
                    line: 13,
                    column: 57
                }
            },
            '4': {
                start: {
                    line: 14,
                    column: 31
                },
                end: {
                    line: 14,
                    column: 44
                }
            },
            '5': {
                start: {
                    line: 15,
                    column: 31
                },
                end: {
                    line: 15,
                    column: 36
                }
            },
            '6': {
                start: {
                    line: 17,
                    column: 16
                },
                end: {
                    line: 28,
                    column: 19
                }
            },
            '7': {
                start: {
                    line: 18,
                    column: 33
                },
                end: {
                    line: 18,
                    column: 47
                }
            },
            '8': {
                start: {
                    line: 19,
                    column: 20
                },
                end: {
                    line: 27,
                    column: 21
                }
            },
            '9': {
                start: {
                    line: 20,
                    column: 40
                },
                end: {
                    line: 20,
                    column: 72
                }
            },
            '10': {
                start: {
                    line: 21,
                    column: 24
                },
                end: {
                    line: 21,
                    column: 48
                }
            },
            '11': {
                start: {
                    line: 22,
                    column: 24
                },
                end: {
                    line: 26,
                    column: 27
                }
            },
            '12': {
                start: {
                    line: 23,
                    column: 28
                },
                end: {
                    line: 23,
                    column: 49
                }
            },
            '13': {
                start: {
                    line: 25,
                    column: 28
                },
                end: {
                    line: 25,
                    column: 49
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 1
                    },
                    end: {
                        line: 1,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 13
                    },
                    end: {
                        line: 32,
                        column: 1
                    }
                },
                line: 1
            },
            '1': {
                name: 'MediaDirective',
                decl: {
                    start: {
                        line: 9,
                        column: 13
                    },
                    end: {
                        line: 9,
                        column: 27
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 36
                    },
                    end: {
                        line: 31,
                        column: 5
                    }
                },
                line: 9
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 17,
                        column: 50
                    },
                    end: {
                        line: 17,
                        column: 51
                    }
                },
                loc: {
                    start: {
                        line: 17,
                        column: 62
                    },
                    end: {
                        line: 28,
                        column: 17
                    }
                },
                line: 17
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 22,
                        column: 37
                    },
                    end: {
                        line: 22,
                        column: 38
                    }
                },
                loc: {
                    start: {
                        line: 22,
                        column: 53
                    },
                    end: {
                        line: 24,
                        column: 25
                    }
                },
                line: 22
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 24,
                        column: 27
                    },
                    end: {
                        line: 24,
                        column: 28
                    }
                },
                loc: {
                    start: {
                        line: 24,
                        column: 43
                    },
                    end: {
                        line: 26,
                        column: 25
                    }
                },
                line: 24
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 19,
                        column: 20
                    },
                    end: {
                        line: 27,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 19,
                        column: 20
                    },
                    end: {
                        line: 27,
                        column: 21
                    }
                }, {
                    start: {
                        line: 19,
                        column: 20
                    },
                    end: {
                        line: 27,
                        column: 21
                    }
                }],
                line: 19
            },
            '1': {
                loc: {
                    start: {
                        line: 19,
                        column: 24
                    },
                    end: {
                        line: 19,
                        column: 104
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 19,
                        column: 24
                    },
                    end: {
                        line: 19,
                        column: 28
                    }
                }, {
                    start: {
                        line: 19,
                        column: 32
                    },
                    end: {
                        line: 19,
                        column: 54
                    }
                }, {
                    start: {
                        line: 19,
                        column: 59
                    },
                    end: {
                        line: 19,
                        column: 79
                    }
                }, {
                    start: {
                        line: 19,
                        column: 83
                    },
                    end: {
                        line: 19,
                        column: 103
                    }
                }],
                line: 19
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0, 0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_t44w63jze.s[0]++;
(function () {
    'use strict';

    cov_t44w63jze.f[0]++;
    cov_t44w63jze.s[1]++;
    angular.module('app').directive('mediaAutoUpload', MediaDirective);

    /* @ngInject */
    function MediaDirective($parse) {
        cov_t44w63jze.f[1]++;
        cov_t44w63jze.s[2]++;

        return {
            require: '?ngModel',
            link: function link(scope, element, attributes) {
                var getter = (cov_t44w63jze.s[3]++, $parse(attributes.ngModel));
                var setter = (cov_t44w63jze.s[4]++, getter.assign);
                var $scope = (cov_t44w63jze.s[5]++, scope);

                cov_t44w63jze.s[6]++;
                $scope.$watch(attributes.ngModel, function () {
                    cov_t44w63jze.f[2]++;

                    var file = (cov_t44w63jze.s[7]++, getter($scope));
                    cov_t44w63jze.s[8]++;
                    if ((cov_t44w63jze.b[1][0]++, file) && (cov_t44w63jze.b[1][1]++, angular.isObject(file)) && ((cov_t44w63jze.b[1][2]++, file instanceof File) || (cov_t44w63jze.b[1][3]++, file instanceof Blob))) {
                        cov_t44w63jze.b[0][0]++;

                        var promise = (cov_t44w63jze.s[9]++, $scope.$root.$Media.upload(file));
                        cov_t44w63jze.s[10]++;
                        setter($scope, promise);
                        cov_t44w63jze.s[11]++;
                        promise.then(function (data) {
                            cov_t44w63jze.f[3]++;
                            cov_t44w63jze.s[12]++;

                            setter($scope, data);
                        }, function (data) {
                            cov_t44w63jze.f[4]++;
                            cov_t44w63jze.s[13]++;

                            setter($scope, data);
                        });
                    } else {
                        cov_t44w63jze.b[0][1]++;
                    }
                });
            }
        };
    }
})();