'use strict';

var cov_q4sq54caz = function () {
    var path = '/home/lobo/repos/prospector-app/src/app/components/userStoryDetails/userStoryDetails.js',
        hash = '2d8fb26b3d5dead96ae444f2e2abe8fb0e4cc005',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/prospector-app/src/app/components/userStoryDetails/userStoryDetails.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 36
                }
            },
            '1': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 47
                }
            },
            '2': {
                start: {
                    line: 9,
                    column: 8
                },
                end: {
                    line: 12,
                    column: 11
                }
            },
            '3': {
                start: {
                    line: 16,
                    column: 8
                },
                end: {
                    line: 16,
                    column: 56
                }
            },
            '4': {
                start: {
                    line: 17,
                    column: 8
                },
                end: {
                    line: 17,
                    column: 47
                }
            },
            '5': {
                start: {
                    line: 18,
                    column: 8
                },
                end: {
                    line: 18,
                    column: 49
                }
            },
            '6': {
                start: {
                    line: 19,
                    column: 8
                },
                end: {
                    line: 32,
                    column: 11
                }
            },
            '7': {
                start: {
                    line: 20,
                    column: 12
                },
                end: {
                    line: 31,
                    column: 15
                }
            },
            '8': {
                start: {
                    line: 21,
                    column: 16
                },
                end: {
                    line: 30,
                    column: 19
                }
            },
            '9': {
                start: {
                    line: 22,
                    column: 20
                },
                end: {
                    line: 29,
                    column: 21
                }
            },
            '10': {
                start: {
                    line: 23,
                    column: 24
                },
                end: {
                    line: 28,
                    column: 27
                }
            },
            '11': {
                start: {
                    line: 27,
                    column: 28
                },
                end: {
                    line: 27,
                    column: 52
                }
            },
            '12': {
                start: {
                    line: 33,
                    column: 8
                },
                end: {
                    line: 33,
                    column: 29
                }
            },
            '13': {
                start: {
                    line: 37,
                    column: 8
                },
                end: {
                    line: 40,
                    column: 11
                }
            },
            '14': {
                start: {
                    line: 38,
                    column: 12
                },
                end: {
                    line: 38,
                    column: 34
                }
            },
            '15': {
                start: {
                    line: 39,
                    column: 12
                },
                end: {
                    line: 39,
                    column: 33
                }
            },
            '16': {
                start: {
                    line: 44,
                    column: 8
                },
                end: {
                    line: 49,
                    column: 11
                }
            },
            '17': {
                start: {
                    line: 45,
                    column: 12
                },
                end: {
                    line: 45,
                    column: 38
                }
            },
            '18': {
                start: {
                    line: 46,
                    column: 12
                },
                end: {
                    line: 48,
                    column: 15
                }
            },
            '19': {
                start: {
                    line: 47,
                    column: 16
                },
                end: {
                    line: 47,
                    column: 41
                }
            },
            '20': {
                start: {
                    line: 53,
                    column: 0
                },
                end: {
                    line: 61,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 33
                    },
                    end: {
                        line: 6,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 8,
                        column: 4
                    },
                    end: {
                        line: 8,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 8,
                        column: 20
                    },
                    end: {
                        line: 13,
                        column: 5
                    }
                },
                line: 8
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 15,
                        column: 4
                    },
                    end: {
                        line: 15,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 15,
                        column: 14
                    },
                    end: {
                        line: 34,
                        column: 5
                    }
                },
                line: 15
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 19,
                        column: 45
                    },
                    end: {
                        line: 19,
                        column: 46
                    }
                },
                loc: {
                    start: {
                        line: 19,
                        column: 59
                    },
                    end: {
                        line: 32,
                        column: 9
                    }
                },
                line: 19
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 20,
                        column: 31
                    },
                    end: {
                        line: 20,
                        column: 32
                    }
                },
                loc: {
                    start: {
                        line: 20,
                        column: 44
                    },
                    end: {
                        line: 31,
                        column: 13
                    }
                },
                line: 20
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 21,
                        column: 79
                    },
                    end: {
                        line: 21,
                        column: 80
                    }
                },
                loc: {
                    start: {
                        line: 21,
                        column: 88
                    },
                    end: {
                        line: 30,
                        column: 17
                    }
                },
                line: 21
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 26,
                        column: 33
                    },
                    end: {
                        line: 26,
                        column: 34
                    }
                },
                loc: {
                    start: {
                        line: 26,
                        column: 41
                    },
                    end: {
                        line: 28,
                        column: 25
                    }
                },
                line: 26
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 36,
                        column: 4
                    },
                    end: {
                        line: 36,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 36,
                        column: 17
                    },
                    end: {
                        line: 41,
                        column: 5
                    }
                },
                line: 36
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 37,
                        column: 38
                    },
                    end: {
                        line: 37,
                        column: 39
                    }
                },
                loc: {
                    start: {
                        line: 37,
                        column: 49
                    },
                    end: {
                        line: 40,
                        column: 9
                    }
                },
                line: 37
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 43,
                        column: 4
                    },
                    end: {
                        line: 43,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 43,
                        column: 19
                    },
                    end: {
                        line: 50,
                        column: 5
                    }
                },
                line: 43
            },
            '10': {
                name: '(anonymous_10)',
                decl: {
                    start: {
                        line: 44,
                        column: 40
                    },
                    end: {
                        line: 44,
                        column: 41
                    }
                },
                loc: {
                    start: {
                        line: 44,
                        column: 46
                    },
                    end: {
                        line: 49,
                        column: 9
                    }
                },
                line: 44
            },
            '11': {
                name: '(anonymous_11)',
                decl: {
                    start: {
                        line: 46,
                        column: 51
                    },
                    end: {
                        line: 46,
                        column: 52
                    }
                },
                loc: {
                    start: {
                        line: 46,
                        column: 60
                    },
                    end: {
                        line: 48,
                        column: 13
                    }
                },
                line: 46
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 22,
                        column: 20
                    },
                    end: {
                        line: 29,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 22,
                        column: 20
                    },
                    end: {
                        line: 29,
                        column: 21
                    }
                }, {
                    start: {
                        line: 22,
                        column: 20
                    },
                    end: {
                        line: 29,
                        column: 21
                    }
                }],
                line: 22
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0
        },
        b: {
            '0': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var UserStoryDetailsController = function () {
    function UserStoryDetailsController(ComponentsTable) {
        _classCallCheck(this, UserStoryDetailsController);

        cov_q4sq54caz.f[0]++;
        cov_q4sq54caz.s[0]++;

        this.currentNav = 'details';
        cov_q4sq54caz.s[1]++;
        this.ComponentsTable = ComponentsTable;
    }

    _createClass(UserStoryDetailsController, [{
        key: '_buildComment',
        value: function _buildComment() {
            cov_q4sq54caz.f[1]++;
            cov_q4sq54caz.s[2]++;

            this.newComment = this.userStory.$comments.$build({
                objectType: 'USER_STORY',
                objectId: this.userStory.id
            });
        }
    }, {
        key: '$onInit',
        value: function $onInit() {
            var _this = this;

            cov_q4sq54caz.f[2]++;
            cov_q4sq54caz.s[3]++;

            this.userStory.$load('comments').$load('costs');
            cov_q4sq54caz.s[4]++;
            this.userStory.$comments.$load('user');
            cov_q4sq54caz.s[5]++;
            this.userStory.$costs.$load('component');
            cov_q4sq54caz.s[6]++;
            this.ComponentsTable.$search().$then(function (components) {
                cov_q4sq54caz.f[3]++;
                cov_q4sq54caz.s[7]++;

                components.forEach(function (component) {
                    cov_q4sq54caz.f[4]++;
                    cov_q4sq54caz.s[8]++;

                    _this.userStory.$costs.$search({ component: component.id }).$then(function (costs) {
                        cov_q4sq54caz.f[5]++;
                        cov_q4sq54caz.s[9]++;

                        if (costs.length === 0) {
                            cov_q4sq54caz.b[0][0]++;
                            cov_q4sq54caz.s[10]++;

                            _this.userStory.$costs.$create({
                                userStory: _this.userStory.id,
                                component: component.id
                            }).$then(function (cost) {
                                cov_q4sq54caz.f[6]++;
                                cov_q4sq54caz.s[11]++;

                                cost.$load('component');
                            });
                        } else {
                            cov_q4sq54caz.b[0][1]++;
                        }
                    });
                });
            });
            cov_q4sq54caz.s[12]++;
            this._buildComment();
        }
    }, {
        key: 'addComment',
        value: function addComment() {
            var _this2 = this;

            cov_q4sq54caz.f[7]++;
            cov_q4sq54caz.s[13]++;

            this.newComment.$save().$then(function (comment) {
                cov_q4sq54caz.f[8]++;
                cov_q4sq54caz.s[14]++;

                comment.$load('user');
                cov_q4sq54caz.s[15]++;
                _this2._buildComment();
            });
        }
    }, {
        key: 'saveCost',
        value: function saveCost(cost) {
            var _this3 = this;

            cov_q4sq54caz.f[9]++;
            cov_q4sq54caz.s[16]++;

            cost.$save(['estimated']).$then(function () {
                cov_q4sq54caz.f[10]++;
                cov_q4sq54caz.s[17]++;

                _this3.userStory.$refresh();
                cov_q4sq54caz.s[18]++;
                _this3.userStory.$costs.$refresh().$then(function (costs) {
                    cov_q4sq54caz.f[11]++;
                    cov_q4sq54caz.s[19]++;

                    costs.$load('component');
                });
            });
        }
    }]);

    return UserStoryDetailsController;
}();

cov_q4sq54caz.s[20]++;


angular.module('app').component('userStoryDetails', {
    templateUrl: 'app/components/userStoryDetails/userStoryDetails.html',
    controller: UserStoryDetailsController,
    bindings: {
        userStory: '<'
    }
});