'use strict';

var cov_1sw5rkra7j = function () {
    var path = '/home/lobo/repos/prospector-app/src/app/shared/js/ORM/tables.individuals.factory.js',
        hash = 'f0bebd888edbe2ad74c7ac7d6ee0d8d172607ff9',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/prospector-app/src/app/shared/js/ORM/tables.individuals.factory.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 64,
                    column: 5
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 4
                },
                end: {
                    line: 6,
                    column: 49
                }
            },
            '2': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 62,
                    column: 11
                }
            },
            '3': {
                start: {
                    line: 56,
                    column: 20
                },
                end: {
                    line: 58,
                    column: 21
                }
            },
            '4': {
                start: {
                    line: 57,
                    column: 24
                },
                end: {
                    line: 57,
                    column: 41
                }
            },
            '5': {
                start: {
                    line: 59,
                    column: 20
                },
                end: {
                    line: 59,
                    column: 50
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 1
                    },
                    end: {
                        line: 1,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 13
                    },
                    end: {
                        line: 64,
                        column: 1
                    }
                },
                line: 1
            },
            '1': {
                name: 'RestmodFactory',
                decl: {
                    start: {
                        line: 9,
                        column: 13
                    },
                    end: {
                        line: 9,
                        column: 27
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 37
                    },
                    end: {
                        line: 63,
                        column: 5
                    }
                },
                line: 9
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 54,
                        column: 37
                    },
                    end: {
                        line: 54,
                        column: 38
                    }
                },
                loc: {
                    start: {
                        line: 54,
                        column: 53
                    },
                    end: {
                        line: 60,
                        column: 17
                    }
                },
                line: 54
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 56,
                        column: 20
                    },
                    end: {
                        line: 58,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 56,
                        column: 20
                    },
                    end: {
                        line: 58,
                        column: 21
                    }
                }, {
                    start: {
                        line: 56,
                        column: 20
                    },
                    end: {
                        line: 58,
                        column: 21
                    }
                }],
                line: 56
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0
        },
        b: {
            '0': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_1sw5rkra7j.s[0]++;
(function () {
    'use strict';

    cov_1sw5rkra7j.f[0]++;
    cov_1sw5rkra7j.s[1]++;
    angular.module('app').factory('IndividualsTable', RestmodFactory);

    /* @ngInject */
    function RestmodFactory(restmod) {
        cov_1sw5rkra7j.f[1]++;
        cov_1sw5rkra7j.s[2]++;

        return restmod.model('/organizations').mix('MixinContainable', {
            type: {
                init: 'SINGULAR'
            },
            $config: {
                contains: {
                    links: {
                        type: 'hasMany',
                        table: 'LinksTable',
                        foreignKey: 'organization'
                    },
                    comments: {
                        type: 'hasMany',
                        table: 'CommentsTable',
                        foreignKey: 'object_id',
                        filters: {
                            /* eslint-disable camelcase */
                            object_type: 'INDIVIDUAL'
                        }
                    },
                    tags: {
                        type: 'hasMany',
                        table: 'OrganizationTagsTable',
                        foreignKey: 'organization'
                    },
                    files: {
                        type: 'hasMany',
                        table: 'OrganizationFilesTable',
                        foreignKey: 'organization'
                    },
                    interactions: {
                        type: 'hasMany',
                        table: 'OrganizationInteractionsTable',
                        foreignKey: 'organization'
                    },
                    emails: {
                        type: 'hasMany',
                        table: 'OrganizationEmailsTable',
                        foreignKey: 'organization'
                    }
                }
            },
            $hooks: {
                /* eslint-disable babel/object-shorthand */
                'before-fetch-many': function beforeFetchMany(_req) {
                    cov_1sw5rkra7j.f[2]++;
                    cov_1sw5rkra7j.s[3]++;

                    /* eslint-disable no-undef */
                    if (_.isUndefined(_req.params)) {
                        cov_1sw5rkra7j.b[0][0]++;
                        cov_1sw5rkra7j.s[4]++;

                        _req.params = {};
                    } else {
                        cov_1sw5rkra7j.b[0][1]++;
                    }
                    cov_1sw5rkra7j.s[5]++;
                    _req.params.type = 'SINGULAR';
                }
            }
        });
    }
})();