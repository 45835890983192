'use strict';

var cov_1ono14yl7f = function () {
    var path = '/home/lobo/repos/prospector-app/src/app/components/pagination/pagination.js',
        hash = '45b5cdec1e6f2b97f1f634373dc2ee948f62fc6a',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/prospector-app/src/app/components/pagination/pagination.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 37
                }
            },
            '1': {
                start: {
                    line: 8,
                    column: 8
                },
                end: {
                    line: 8,
                    column: 22
                }
            },
            '2': {
                start: {
                    line: 9,
                    column: 8
                },
                end: {
                    line: 9,
                    column: 55
                }
            },
            '3': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 10,
                    column: 29
                }
            },
            '4': {
                start: {
                    line: 14,
                    column: 0
                },
                end: {
                    line: 23,
                    column: 3
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 28
                    },
                    end: {
                        line: 5,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 7,
                        column: 4
                    },
                    end: {
                        line: 7,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 7,
                        column: 14
                    },
                    end: {
                        line: 11,
                        column: 5
                    }
                },
                line: 7
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0
        },
        f: {
            '0': 0,
            '1': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var PaginationController = function () {
    function PaginationController($rootScope) {
        _classCallCheck(this, PaginationController);

        cov_1ono14yl7f.f[0]++;
        cov_1ono14yl7f.s[0]++;

        this.$rootScope = $rootScope;
    }

    _createClass(PaginationController, [{
        key: '$onInit',
        value: function $onInit() {
            cov_1ono14yl7f.f[1]++;
            cov_1ono14yl7f.s[1]++;

            this.page = 1;
            cov_1ono14yl7f.s[2]++;
            this.links = Math.ceil(this.count / this.size);
            cov_1ono14yl7f.s[3]++;
            this.counter = Array;
        }
    }]);

    return PaginationController;
}();

cov_1ono14yl7f.s[4]++;


angular.module('app').component('pagination', {
    templateUrl: 'app/components/pagination/pagination.html',
    controller: PaginationController,
    bindings: {
        count: '<',
        size: '<'
    }
});