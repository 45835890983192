'use strict';

var cov_1k8w68wv96 = function () {
    var path = '/home/lobo/repos/prospector-app/src/app/components/autocompletes/autocompletes.js',
        hash = '38e0fd9048bf7b64180e3b3ee4ed9130509f38ee',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/prospector-app/src/app/components/autocompletes/autocompletes.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 38
                }
            },
            '1': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 121
                }
            },
            '2': {
                start: {
                    line: 6,
                    column: 8
                },
                end: {
                    line: 6,
                    column: 27
                }
            },
            '3': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 10,
                    column: 76
                }
            },
            '4': {
                start: {
                    line: 10,
                    column: 52
                },
                end: {
                    line: 10,
                    column: 74
                }
            },
            '5': {
                start: {
                    line: 11,
                    column: 8
                },
                end: {
                    line: 11,
                    column: 44
                }
            },
            '6': {
                start: {
                    line: 12,
                    column: 8
                },
                end: {
                    line: 12,
                    column: 42
                }
            },
            '7': {
                start: {
                    line: 16,
                    column: 25
                },
                end: {
                    line: 16,
                    column: 27
                }
            },
            '8': {
                start: {
                    line: 18,
                    column: 8
                },
                end: {
                    line: 18,
                    column: 77
                }
            },
            '9': {
                start: {
                    line: 18,
                    column: 35
                },
                end: {
                    line: 18,
                    column: 75
                }
            },
            '10': {
                start: {
                    line: 19,
                    column: 8
                },
                end: {
                    line: 19,
                    column: 24
                }
            },
            '11': {
                start: {
                    line: 23,
                    column: 8
                },
                end: {
                    line: 23,
                    column: 104
                }
            },
            '12': {
                start: {
                    line: 23,
                    column: 83
                },
                end: {
                    line: 23,
                    column: 102
                }
            },
            '13': {
                start: {
                    line: 27,
                    column: 8
                },
                end: {
                    line: 32,
                    column: 11
                }
            },
            '14': {
                start: {
                    line: 28,
                    column: 12
                },
                end: {
                    line: 31,
                    column: 14
                }
            },
            '15': {
                start: {
                    line: 36,
                    column: 8
                },
                end: {
                    line: 40,
                    column: 11
                }
            },
            '16': {
                start: {
                    line: 37,
                    column: 12
                },
                end: {
                    line: 39,
                    column: 14
                }
            },
            '17': {
                start: {
                    line: 44,
                    column: 8
                },
                end: {
                    line: 49,
                    column: 11
                }
            },
            '18': {
                start: {
                    line: 45,
                    column: 12
                },
                end: {
                    line: 48,
                    column: 14
                }
            },
            '19': {
                start: {
                    line: 53,
                    column: 27
                },
                end: {
                    line: 53,
                    column: 50
                }
            },
            '20': {
                start: {
                    line: 54,
                    column: 8
                },
                end: {
                    line: 54,
                    column: 66
                }
            },
            '21': {
                start: {
                    line: 55,
                    column: 8
                },
                end: {
                    line: 55,
                    column: 42
                }
            },
            '22': {
                start: {
                    line: 56,
                    column: 8
                },
                end: {
                    line: 56,
                    column: 42
                }
            },
            '23': {
                start: {
                    line: 57,
                    column: 8
                },
                end: {
                    line: 57,
                    column: 40
                }
            },
            '24': {
                start: {
                    line: 58,
                    column: 8
                },
                end: {
                    line: 58,
                    column: 36
                }
            },
            '25': {
                start: {
                    line: 59,
                    column: 8
                },
                end: {
                    line: 59,
                    column: 27
                }
            },
            '26': {
                start: {
                    line: 63,
                    column: 25
                },
                end: {
                    line: 63,
                    column: 41
                }
            },
            '27': {
                start: {
                    line: 64,
                    column: 8
                },
                end: {
                    line: 66,
                    column: 11
                }
            },
            '28': {
                start: {
                    line: 65,
                    column: 12
                },
                end: {
                    line: 65,
                    column: 31
                }
            },
            '29': {
                start: {
                    line: 70,
                    column: 8
                },
                end: {
                    line: 70,
                    column: 34
                }
            },
            '30': {
                start: {
                    line: 74,
                    column: 8
                },
                end: {
                    line: 74,
                    column: 69
                }
            },
            '31': {
                start: {
                    line: 74,
                    column: 53
                },
                end: {
                    line: 74,
                    column: 67
                }
            },
            '32': {
                start: {
                    line: 81,
                    column: 8
                },
                end: {
                    line: 81,
                    column: 77
                }
            },
            '33': {
                start: {
                    line: 82,
                    column: 8
                },
                end: {
                    line: 82,
                    column: 33
                }
            },
            '34': {
                start: {
                    line: 83,
                    column: 8
                },
                end: {
                    line: 83,
                    column: 77
                }
            },
            '35': {
                start: {
                    line: 84,
                    column: 8
                },
                end: {
                    line: 84,
                    column: 65
                }
            },
            '36': {
                start: {
                    line: 86,
                    column: 8
                },
                end: {
                    line: 88,
                    column: 17
                }
            },
            '37': {
                start: {
                    line: 87,
                    column: 12
                },
                end: {
                    line: 87,
                    column: 36
                }
            },
            '38': {
                start: {
                    line: 93,
                    column: 21
                },
                end: {
                    line: 93,
                    column: 97
                }
            },
            '39': {
                start: {
                    line: 94,
                    column: 8
                },
                end: {
                    line: 99,
                    column: 15
                }
            },
            '40': {
                start: {
                    line: 96,
                    column: 16
                },
                end: {
                    line: 98,
                    column: 17
                }
            },
            '41': {
                start: {
                    line: 97,
                    column: 20
                },
                end: {
                    line: 97,
                    column: 52
                }
            },
            '42': {
                start: {
                    line: 103,
                    column: 23
                },
                end: {
                    line: 120,
                    column: 9
                }
            },
            '43': {
                start: {
                    line: 121,
                    column: 8
                },
                end: {
                    line: 121,
                    column: 35
                }
            },
            '44': {
                start: {
                    line: 125,
                    column: 27
                },
                end: {
                    line: 125,
                    column: 41
                }
            },
            '45': {
                start: {
                    line: 126,
                    column: 8
                },
                end: {
                    line: 126,
                    column: 73
                }
            },
            '46': {
                start: {
                    line: 127,
                    column: 8
                },
                end: {
                    line: 127,
                    column: 63
                }
            },
            '47': {
                start: {
                    line: 128,
                    column: 8
                },
                end: {
                    line: 128,
                    column: 62
                }
            },
            '48': {
                start: {
                    line: 129,
                    column: 8
                },
                end: {
                    line: 129,
                    column: 61
                }
            },
            '49': {
                start: {
                    line: 130,
                    column: 8
                },
                end: {
                    line: 130,
                    column: 48
                }
            },
            '50': {
                start: {
                    line: 135,
                    column: 0
                },
                end: {
                    line: 143,
                    column: 3
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 28
                    },
                    end: {
                        line: 7,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 9,
                        column: 4
                    },
                    end: {
                        line: 9,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 14
                    },
                    end: {
                        line: 13,
                        column: 5
                    }
                },
                line: 9
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 10,
                        column: 43
                    },
                    end: {
                        line: 10,
                        column: 44
                    }
                },
                loc: {
                    start: {
                        line: 10,
                        column: 52
                    },
                    end: {
                        line: 10,
                        column: 74
                    }
                },
                line: 10
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 15,
                        column: 4
                    },
                    end: {
                        line: 15,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 15,
                        column: 23
                    },
                    end: {
                        line: 20,
                        column: 5
                    }
                },
                line: 15
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 18,
                        column: 26
                    },
                    end: {
                        line: 18,
                        column: 27
                    }
                },
                loc: {
                    start: {
                        line: 18,
                        column: 35
                    },
                    end: {
                        line: 18,
                        column: 75
                    }
                },
                line: 18
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 22,
                        column: 4
                    },
                    end: {
                        line: 22,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 22,
                        column: 25
                    },
                    end: {
                        line: 24,
                        column: 5
                    }
                },
                line: 22
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 23,
                        column: 77
                    },
                    end: {
                        line: 23,
                        column: 78
                    }
                },
                loc: {
                    start: {
                        line: 23,
                        column: 83
                    },
                    end: {
                        line: 23,
                        column: 102
                    }
                },
                line: 23
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 26,
                        column: 4
                    },
                    end: {
                        line: 26,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 26,
                        column: 13
                    },
                    end: {
                        line: 33,
                        column: 5
                    }
                },
                line: 26
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 27,
                        column: 67
                    },
                    end: {
                        line: 27,
                        column: 68
                    }
                },
                loc: {
                    start: {
                        line: 27,
                        column: 77
                    },
                    end: {
                        line: 32,
                        column: 9
                    }
                },
                line: 27
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 35,
                        column: 4
                    },
                    end: {
                        line: 35,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 35,
                        column: 13
                    },
                    end: {
                        line: 41,
                        column: 5
                    }
                },
                line: 35
            },
            '10': {
                name: '(anonymous_10)',
                decl: {
                    start: {
                        line: 36,
                        column: 66
                    },
                    end: {
                        line: 36,
                        column: 67
                    }
                },
                loc: {
                    start: {
                        line: 36,
                        column: 75
                    },
                    end: {
                        line: 40,
                        column: 9
                    }
                },
                line: 36
            },
            '11': {
                name: '(anonymous_11)',
                decl: {
                    start: {
                        line: 43,
                        column: 4
                    },
                    end: {
                        line: 43,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 43,
                        column: 12
                    },
                    end: {
                        line: 50,
                        column: 5
                    }
                },
                line: 43
            },
            '12': {
                name: '(anonymous_12)',
                decl: {
                    start: {
                        line: 44,
                        column: 65
                    },
                    end: {
                        line: 44,
                        column: 66
                    }
                },
                loc: {
                    start: {
                        line: 44,
                        column: 73
                    },
                    end: {
                        line: 49,
                        column: 9
                    }
                },
                line: 44
            },
            '13': {
                name: '(anonymous_13)',
                decl: {
                    start: {
                        line: 52,
                        column: 4
                    },
                    end: {
                        line: 52,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 52,
                        column: 21
                    },
                    end: {
                        line: 60,
                        column: 5
                    }
                },
                line: 52
            },
            '14': {
                name: '(anonymous_14)',
                decl: {
                    start: {
                        line: 62,
                        column: 4
                    },
                    end: {
                        line: 62,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 62,
                        column: 18
                    },
                    end: {
                        line: 67,
                        column: 5
                    }
                },
                line: 62
            },
            '15': {
                name: '(anonymous_15)',
                decl: {
                    start: {
                        line: 64,
                        column: 30
                    },
                    end: {
                        line: 64,
                        column: 31
                    }
                },
                loc: {
                    start: {
                        line: 64,
                        column: 36
                    },
                    end: {
                        line: 66,
                        column: 9
                    }
                },
                line: 64
            },
            '16': {
                name: '(anonymous_16)',
                decl: {
                    start: {
                        line: 69,
                        column: 4
                    },
                    end: {
                        line: 69,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 69,
                        column: 18
                    },
                    end: {
                        line: 71,
                        column: 5
                    }
                },
                line: 69
            },
            '17': {
                name: '(anonymous_17)',
                decl: {
                    start: {
                        line: 73,
                        column: 4
                    },
                    end: {
                        line: 73,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 73,
                        column: 20
                    },
                    end: {
                        line: 75,
                        column: 5
                    }
                },
                line: 73
            },
            '18': {
                name: '(anonymous_18)',
                decl: {
                    start: {
                        line: 74,
                        column: 45
                    },
                    end: {
                        line: 74,
                        column: 46
                    }
                },
                loc: {
                    start: {
                        line: 74,
                        column: 53
                    },
                    end: {
                        line: 74,
                        column: 67
                    }
                },
                line: 74
            },
            '19': {
                name: '(anonymous_19)',
                decl: {
                    start: {
                        line: 80,
                        column: 4
                    },
                    end: {
                        line: 80,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 80,
                        column: 69
                    },
                    end: {
                        line: 89,
                        column: 5
                    }
                },
                line: 80
            },
            '20': {
                name: '(anonymous_20)',
                decl: {
                    start: {
                        line: 86,
                        column: 18
                    },
                    end: {
                        line: 86,
                        column: 19
                    }
                },
                loc: {
                    start: {
                        line: 86,
                        column: 24
                    },
                    end: {
                        line: 88,
                        column: 9
                    }
                },
                line: 86
            },
            '21': {
                name: '(anonymous_21)',
                decl: {
                    start: {
                        line: 91,
                        column: 4
                    },
                    end: {
                        line: 91,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 91,
                        column: 23
                    },
                    end: {
                        line: 100,
                        column: 5
                    }
                },
                line: 91
            },
            '22': {
                name: '(anonymous_22)',
                decl: {
                    start: {
                        line: 95,
                        column: 12
                    },
                    end: {
                        line: 95,
                        column: 13
                    }
                },
                loc: {
                    start: {
                        line: 95,
                        column: 23
                    },
                    end: {
                        line: 99,
                        column: 13
                    }
                },
                line: 95
            },
            '23': {
                name: '(anonymous_23)',
                decl: {
                    start: {
                        line: 102,
                        column: 4
                    },
                    end: {
                        line: 102,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 102,
                        column: 39
                    },
                    end: {
                        line: 122,
                        column: 5
                    }
                },
                line: 102
            },
            '24': {
                name: '(anonymous_24)',
                decl: {
                    start: {
                        line: 124,
                        column: 4
                    },
                    end: {
                        line: 124,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 124,
                        column: 35
                    },
                    end: {
                        line: 131,
                        column: 5
                    }
                },
                line: 124
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 96,
                        column: 16
                    },
                    end: {
                        line: 98,
                        column: 17
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 96,
                        column: 16
                    },
                    end: {
                        line: 98,
                        column: 17
                    }
                }, {
                    start: {
                        line: 96,
                        column: 16
                    },
                    end: {
                        line: 98,
                        column: 17
                    }
                }],
                line: 96
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0,
            '28': 0,
            '29': 0,
            '30': 0,
            '31': 0,
            '32': 0,
            '33': 0,
            '34': 0,
            '35': 0,
            '36': 0,
            '37': 0,
            '38': 0,
            '39': 0,
            '40': 0,
            '41': 0,
            '42': 0,
            '43': 0,
            '44': 0,
            '45': 0,
            '46': 0,
            '47': 0,
            '48': 0,
            '49': 0,
            '50': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0
        },
        b: {
            '0': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var CompletionDialogController = function () {
    function CompletionDialogController(mdPanelRef) {
        _classCallCheck(this, CompletionDialogController);

        cov_1k8w68wv96.f[0]++;
        cov_1k8w68wv96.s[0]++;

        this._mdPanelRef = mdPanelRef;
        cov_1k8w68wv96.s[1]++;
        this.fields = ['nif', 'foundation', 'municipality', 'rawSize', 'rawTurnover', 'rawIncome', 'phone', 'legalForm'];
        cov_1k8w68wv96.s[2]++;
        this.selected = [];
    }

    _createClass(CompletionDialogController, [{
        key: '$onInit',
        value: function $onInit() {
            var _this = this;

            cov_1k8w68wv96.f[1]++;
            cov_1k8w68wv96.s[3]++;

            this.selected = this.fields.filter(function (field) {
                cov_1k8w68wv96.f[2]++;
                cov_1k8w68wv96.s[4]++;
                return _this.completion[field];
            });
            cov_1k8w68wv96.s[5]++;
            this.completion.readAt = new Date();
            cov_1k8w68wv96.s[6]++;
            this.completion.$save(['readAt']);
        }
    }, {
        key: 'filterCompletion',
        value: function filterCompletion() {
            var _this2 = this;

            cov_1k8w68wv96.f[3]++;

            var filtered = (cov_1k8w68wv96.s[7]++, {});
            /* eslint-disable no-return-assign */
            cov_1k8w68wv96.s[8]++;
            this.selected.map(function (field) {
                cov_1k8w68wv96.f[4]++;
                cov_1k8w68wv96.s[9]++;
                return filtered[field] = _this2.completion[field];
            });
            cov_1k8w68wv96.s[10]++;
            return filtered;
        }
    }, {
        key: 'economicActivities',
        value: function economicActivities() {
            cov_1k8w68wv96.f[5]++;
            cov_1k8w68wv96.s[11]++;

            return this.completion.$organizationAutocompleteEconomicActivity.map(function (ec) {
                cov_1k8w68wv96.f[6]++;
                cov_1k8w68wv96.s[12]++;
                return ec.economicActivity;
            });
        }
    }, {
        key: 'people',
        value: function people() {
            cov_1k8w68wv96.f[7]++;
            cov_1k8w68wv96.s[13]++;

            return this.completion.$organizationAutocompletePeople.map(function (person) {
                cov_1k8w68wv96.f[8]++;
                cov_1k8w68wv96.s[14]++;

                return {
                    name: person.name,
                    jobTitle: person.jobTitle
                };
            });
        }
    }, {
        key: 'emails',
        value: function emails() {
            cov_1k8w68wv96.f[9]++;
            cov_1k8w68wv96.s[15]++;

            return this.completion.$organizationAutocompleteEmail.map(function (email) {
                cov_1k8w68wv96.f[10]++;
                cov_1k8w68wv96.s[16]++;

                return {
                    email: email.email
                };
            });
        }
    }, {
        key: 'links',
        value: function links() {
            cov_1k8w68wv96.f[11]++;
            cov_1k8w68wv96.s[17]++;

            return this.completion.$organizationAutocompleteLink.map(function (link) {
                cov_1k8w68wv96.f[12]++;
                cov_1k8w68wv96.s[18]++;

                return {
                    link: link.link,
                    category: link.category
                };
            });
        }
    }, {
        key: 'handleComplete',
        value: function handleComplete() {
            cov_1k8w68wv96.f[13]++;

            var completion = (cov_1k8w68wv96.s[19]++, this.filterCompletion());
            cov_1k8w68wv96.s[20]++;
            completion.economicActivities = this.economicActivities();
            cov_1k8w68wv96.s[21]++;
            completion.people = this.people();
            cov_1k8w68wv96.s[22]++;
            completion.emails = this.emails();
            cov_1k8w68wv96.s[23]++;
            completion.links = this.links();
            cov_1k8w68wv96.s[24]++;
            this.complete({ completion: completion });
            cov_1k8w68wv96.s[25]++;
            this.closeDialog();
        }
    }, {
        key: 'closeDialog',
        value: function closeDialog() {
            cov_1k8w68wv96.f[14]++;

            var panelRef = (cov_1k8w68wv96.s[26]++, this._mdPanelRef);
            cov_1k8w68wv96.s[27]++;
            panelRef.close().then(function () {
                cov_1k8w68wv96.f[15]++;
                cov_1k8w68wv96.s[28]++;

                panelRef.destroy();
            });
        }
    }, {
        key: 'select',
        value: function select(field) {
            cov_1k8w68wv96.f[16]++;
            cov_1k8w68wv96.s[29]++;

            this.selected.push(field);
        }
    }, {
        key: 'deselect',
        value: function deselect(field) {
            cov_1k8w68wv96.f[17]++;
            cov_1k8w68wv96.s[30]++;

            this.selected = this.selected.filter(function (item) {
                cov_1k8w68wv96.f[18]++;
                cov_1k8w68wv96.s[31]++;
                return item !== field;
            });
        }
    }]);

    return CompletionDialogController;
}();

var AutocompletesController = function () {
    function AutocompletesController(OrganizationAutocompletesTable, $mdPanel, $interval) {
        var _this3 = this;

        _classCallCheck(this, AutocompletesController);

        cov_1k8w68wv96.f[19]++;
        cov_1k8w68wv96.s[32]++;

        this.OrganizationAutocompletesTable = OrganizationAutocompletesTable;
        cov_1k8w68wv96.s[33]++;
        this.$mdPanel = $mdPanel;
        cov_1k8w68wv96.s[34]++;
        this.position = this.$mdPanel.newPanelPosition().absolute().center();
        cov_1k8w68wv96.s[35]++;
        this.fetchCompletions = this.fetchCompletions.bind(this);

        cov_1k8w68wv96.s[36]++;
        $interval(function () {
            cov_1k8w68wv96.f[20]++;
            cov_1k8w68wv96.s[37]++;

            _this3.fetchCompletions();
        }, 1000);
    }

    _createClass(AutocompletesController, [{
        key: 'fetchCompletions',
        value: function fetchCompletions() {
            var _this4 = this;

            cov_1k8w68wv96.f[21]++;

            /* eslint-disable camelcase */
            var data = (cov_1k8w68wv96.s[38]++, { read_at__isnull: 'True', organization: this.organization.id, state: 'DONE' });
            cov_1k8w68wv96.s[39]++;
            this.OrganizationAutocompletesTable.$search(data).$then(function (results) {
                cov_1k8w68wv96.f[22]++;
                cov_1k8w68wv96.s[40]++;

                if (results.length > 0) {
                    cov_1k8w68wv96.b[0][0]++;
                    cov_1k8w68wv96.s[41]++;

                    _this4.displayCompletion(results);
                } else {
                    cov_1k8w68wv96.b[0][1]++;
                }
            });
        }
    }, {
        key: 'createCompletionDialog',
        value: function createCompletionDialog(completion) {
            cov_1k8w68wv96.f[23]++;

            var config = (cov_1k8w68wv96.s[42]++, {
                /** @ngInject */
                controller: CompletionDialogController,
                controllerAs: '$ctrl',
                templateUrl: 'app/components/autocompletes/autocompletes.html',
                hasBackdrop: true,
                panelClass: 'autocompletes-dialog',
                position: this.position,
                trapFocus: true,
                zIndex: 150,
                clickOutsideToClose: false,
                escapeToClose: false,
                focusOnOpen: true,
                locals: {
                    completion: completion,
                    complete: this.complete
                }
            });
            cov_1k8w68wv96.s[43]++;
            this.$mdPanel.open(config);
        }
    }, {
        key: 'displayCompletion',
        value: function displayCompletion(completions) {
            cov_1k8w68wv96.f[24]++;

            var completion = (cov_1k8w68wv96.s[44]++, completions[0]);
            cov_1k8w68wv96.s[45]++;
            completions[0].$load('organizationAutocompleteEconomicActivity');
            cov_1k8w68wv96.s[46]++;
            completions[0].$load('organizationAutocompletePeople');
            cov_1k8w68wv96.s[47]++;
            completions[0].$load('organizationAutocompleteEmail');
            cov_1k8w68wv96.s[48]++;
            completions[0].$load('organizationAutocompleteLink');
            cov_1k8w68wv96.s[49]++;
            this.createCompletionDialog(completion);
        }
    }]);

    return AutocompletesController;
}();

cov_1k8w68wv96.s[50]++;


angular.module('app').component('autocompletes', {
    controller: AutocompletesController,
    bindings: {
        organization: '<',
        complete: '&'
    }
});