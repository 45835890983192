'use strict';

var cov_2dsjyf4vcb = function () {
    var path = '/home/lobo/repos/prospector-app/src/app/components/organizationsList/organizationsList.js',
        hash = '1aa5eaee59e230c112c718e1327b4658838f6c33',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/prospector-app/src/app/components/organizationsList/organizationsList.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 71
                }
            },
            '1': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 55
                }
            },
            '2': {
                start: {
                    line: 6,
                    column: 8
                },
                end: {
                    line: 6,
                    column: 45
                }
            },
            '3': {
                start: {
                    line: 7,
                    column: 8
                },
                end: {
                    line: 7,
                    column: 35
                }
            },
            '4': {
                start: {
                    line: 8,
                    column: 8
                },
                end: {
                    line: 8,
                    column: 26
                }
            },
            '5': {
                start: {
                    line: 9,
                    column: 8
                },
                end: {
                    line: 9,
                    column: 45
                }
            },
            '6': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 10,
                    column: 41
                }
            },
            '7': {
                start: {
                    line: 11,
                    column: 8
                },
                end: {
                    line: 11,
                    column: 35
                }
            },
            '8': {
                start: {
                    line: 15,
                    column: 8
                },
                end: {
                    line: 15,
                    column: 41
                }
            },
            '9': {
                start: {
                    line: 18,
                    column: 8
                },
                end: {
                    line: 18,
                    column: 82
                }
            },
            '10': {
                start: {
                    line: 19,
                    column: 8
                },
                end: {
                    line: 19,
                    column: 55
                }
            },
            '11': {
                start: {
                    line: 20,
                    column: 8
                },
                end: {
                    line: 20,
                    column: 62
                }
            },
            '12': {
                start: {
                    line: 23,
                    column: 8
                },
                end: {
                    line: 23,
                    column: 47
                }
            },
            '13': {
                start: {
                    line: 24,
                    column: 8
                },
                end: {
                    line: 24,
                    column: 65
                }
            },
            '14': {
                start: {
                    line: 25,
                    column: 8
                },
                end: {
                    line: 25,
                    column: 57
                }
            },
            '15': {
                start: {
                    line: 26,
                    column: 8
                },
                end: {
                    line: 26,
                    column: 49
                }
            },
            '16': {
                start: {
                    line: 27,
                    column: 8
                },
                end: {
                    line: 27,
                    column: 71
                }
            },
            '17': {
                start: {
                    line: 28,
                    column: 8
                },
                end: {
                    line: 28,
                    column: 66
                }
            },
            '18': {
                start: {
                    line: 29,
                    column: 8
                },
                end: {
                    line: 29,
                    column: 64
                }
            },
            '19': {
                start: {
                    line: 30,
                    column: 8
                },
                end: {
                    line: 30,
                    column: 59
                }
            },
            '20': {
                start: {
                    line: 31,
                    column: 8
                },
                end: {
                    line: 31,
                    column: 64
                }
            },
            '21': {
                start: {
                    line: 32,
                    column: 8
                },
                end: {
                    line: 32,
                    column: 64
                }
            },
            '22': {
                start: {
                    line: 34,
                    column: 8
                },
                end: {
                    line: 36,
                    column: 10
                }
            },
            '23': {
                start: {
                    line: 38,
                    column: 8
                },
                end: {
                    line: 38,
                    column: 24
                }
            },
            '24': {
                start: {
                    line: 42,
                    column: 8
                },
                end: {
                    line: 44,
                    column: 9
                }
            },
            '25': {
                start: {
                    line: 43,
                    column: 12
                },
                end: {
                    line: 43,
                    column: 36
                }
            },
            '26': {
                start: {
                    line: 46,
                    column: 8
                },
                end: {
                    line: 46,
                    column: 39
                }
            },
            '27': {
                start: {
                    line: 47,
                    column: 8
                },
                end: {
                    line: 47,
                    column: 43
                }
            },
            '28': {
                start: {
                    line: 48,
                    column: 8
                },
                end: {
                    line: 48,
                    column: 43
                }
            },
            '29': {
                start: {
                    line: 50,
                    column: 8
                },
                end: {
                    line: 54,
                    column: 9
                }
            },
            '30': {
                start: {
                    line: 51,
                    column: 12
                },
                end: {
                    line: 51,
                    column: 40
                }
            },
            '31': {
                start: {
                    line: 53,
                    column: 12
                },
                end: {
                    line: 53,
                    column: 41
                }
            },
            '32': {
                start: {
                    line: 56,
                    column: 8
                },
                end: {
                    line: 56,
                    column: 45
                }
            },
            '33': {
                start: {
                    line: 57,
                    column: 8
                },
                end: {
                    line: 57,
                    column: 24
                }
            },
            '34': {
                start: {
                    line: 61,
                    column: 8
                },
                end: {
                    line: 61,
                    column: 50
                }
            },
            '35': {
                start: {
                    line: 65,
                    column: 8
                },
                end: {
                    line: 68,
                    column: 11
                }
            },
            '36': {
                start: {
                    line: 66,
                    column: 12
                },
                end: {
                    line: 66,
                    column: 50
                }
            },
            '37': {
                start: {
                    line: 72,
                    column: 0
                },
                end: {
                    line: 80,
                    column: 3
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 118
                    },
                    end: {
                        line: 12,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 14,
                        column: 4
                    },
                    end: {
                        line: 14,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 14,
                        column: 14
                    },
                    end: {
                        line: 39,
                        column: 5
                    }
                },
                line: 14
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 41,
                        column: 4
                    },
                    end: {
                        line: 41,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 41,
                        column: 33
                    },
                    end: {
                        line: 58,
                        column: 5
                    }
                },
                line: 41
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 60,
                        column: 4
                    },
                    end: {
                        line: 60,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 60,
                        column: 15
                    },
                    end: {
                        line: 62,
                        column: 5
                    }
                },
                line: 60
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 64,
                        column: 4
                    },
                    end: {
                        line: 64,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 64,
                        column: 34
                    },
                    end: {
                        line: 69,
                        column: 5
                    }
                },
                line: 64
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 65,
                        column: 56
                    },
                    end: {
                        line: 65,
                        column: 57
                    }
                },
                loc: {
                    start: {
                        line: 65,
                        column: 72
                    },
                    end: {
                        line: 67,
                        column: 9
                    }
                },
                line: 65
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 67,
                        column: 11
                    },
                    end: {
                        line: 67,
                        column: 12
                    }
                },
                loc: {
                    start: {
                        line: 67,
                        column: 23
                    },
                    end: {
                        line: 68,
                        column: 9
                    }
                },
                line: 67
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 42,
                        column: 8
                    },
                    end: {
                        line: 44,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 42,
                        column: 8
                    },
                    end: {
                        line: 44,
                        column: 9
                    }
                }, {
                    start: {
                        line: 42,
                        column: 8
                    },
                    end: {
                        line: 44,
                        column: 9
                    }
                }],
                line: 42
            },
            '1': {
                loc: {
                    start: {
                        line: 50,
                        column: 8
                    },
                    end: {
                        line: 54,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 50,
                        column: 8
                    },
                    end: {
                        line: 54,
                        column: 9
                    }
                }, {
                    start: {
                        line: 50,
                        column: 8
                    },
                    end: {
                        line: 54,
                        column: 9
                    }
                }],
                line: 50
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0,
            '28': 0,
            '29': 0,
            '30': 0,
            '31': 0,
            '32': 0,
            '33': 0,
            '34': 0,
            '35': 0,
            '36': 0,
            '37': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var OrganizationsListController = function () {
    function OrganizationsListController(NewOrganizationModalService, MunicipalitiesTable, DistrictsTable, TagsTable, $stateParams, $location) {
        _classCallCheck(this, OrganizationsListController);

        cov_2dsjyf4vcb.f[0]++;
        cov_2dsjyf4vcb.s[0]++;

        this.NewOrganizationModalService = NewOrganizationModalService;
        cov_2dsjyf4vcb.s[1]++;
        this.MunicipalitiesTable = MunicipalitiesTable;
        cov_2dsjyf4vcb.s[2]++;
        this.DistrictsTable = DistrictsTable;
        cov_2dsjyf4vcb.s[3]++;
        this.TagsTable = TagsTable;
        cov_2dsjyf4vcb.s[4]++;
        this.filters = {};
        cov_2dsjyf4vcb.s[5]++;
        this.filterYearFormat = 'foundation';
        cov_2dsjyf4vcb.s[6]++;
        this.$stateParams = $stateParams;
        cov_2dsjyf4vcb.s[7]++;
        this.$location = $location;
    }

    _createClass(OrganizationsListController, [{
        key: '$onInit',
        value: function $onInit() {
            cov_2dsjyf4vcb.f[1]++;
            cov_2dsjyf4vcb.s[8]++;

            this.filters = this.$stateParams;

            /* eslint-disable camelcase */
            cov_2dsjyf4vcb.s[9]++;
            this.municipalities = this.MunicipalitiesTable.$search({ page_size: 9999 });
            cov_2dsjyf4vcb.s[10]++;
            this.districts = this.DistrictsTable.$search();
            cov_2dsjyf4vcb.s[11]++;
            this.tags = this.TagsTable.$search({ page_size: 9999 });

            // gettings filter state from state params
            cov_2dsjyf4vcb.s[12]++;
            this.tagFilter = this.$stateParams.tag;
            cov_2dsjyf4vcb.s[13]++;
            this.municipalityFilter = this.$stateParams.municipality;
            cov_2dsjyf4vcb.s[14]++;
            this.turnoverFilter = this.$stateParams.turnover;
            cov_2dsjyf4vcb.s[15]++;
            this.sizeFilter = this.$stateParams.size;
            cov_2dsjyf4vcb.s[16]++;
            this.districtFilter = this.$stateParams.municipality__district;
            cov_2dsjyf4vcb.s[17]++;
            this.profileLevelFilter = this.$stateParams.profile_level;
            cov_2dsjyf4vcb.s[18]++;
            this.interactionFilter = this.$stateParams.interactions;
            cov_2dsjyf4vcb.s[19]++;
            this.peopleFilter = this.$stateParams.people_count;
            cov_2dsjyf4vcb.s[20]++;
            this.targetStateFilter = this.$stateParams.target_state;
            cov_2dsjyf4vcb.s[21]++;
            this.targetScoreFilter = this.$stateParams.target_score;

            cov_2dsjyf4vcb.s[22]++;
            this.organizations.$params = {
                page_size: 25
            };

            cov_2dsjyf4vcb.s[23]++;
            this._refresh();
        }
    }, {
        key: 'changeFilters',
        value: function changeFilters(filter, value) {
            cov_2dsjyf4vcb.f[2]++;
            cov_2dsjyf4vcb.s[24]++;

            if (angular.isArray(value)) {
                cov_2dsjyf4vcb.b[0][0]++;
                cov_2dsjyf4vcb.s[25]++;

                value = value.join(',');
            } else {
                cov_2dsjyf4vcb.b[0][1]++;
            }

            cov_2dsjyf4vcb.s[26]++;
            delete this.filters.foundation;
            cov_2dsjyf4vcb.s[27]++;
            delete this.filters.foundation__lt;
            cov_2dsjyf4vcb.s[28]++;
            delete this.filters.foundation__gt;

            cov_2dsjyf4vcb.s[29]++;
            if (value === null) {
                cov_2dsjyf4vcb.b[1][0]++;
                cov_2dsjyf4vcb.s[30]++;

                delete this.filters[filter];
            } else {
                cov_2dsjyf4vcb.b[1][1]++;
                cov_2dsjyf4vcb.s[31]++;

                this.filters[filter] = value;
            }

            cov_2dsjyf4vcb.s[32]++;
            this.$location.search(filter, value);
            cov_2dsjyf4vcb.s[33]++;
            this._refresh();
        }
    }, {
        key: '_refresh',
        value: function _refresh() {
            cov_2dsjyf4vcb.f[3]++;
            cov_2dsjyf4vcb.s[34]++;

            this.organizations.$refresh(this.filters);
        }
    }, {
        key: 'displayNewOrganizationModal',
        value: function displayNewOrganizationModal() {
            var _this = this;

            cov_2dsjyf4vcb.f[4]++;
            cov_2dsjyf4vcb.s[35]++;

            this.NewOrganizationModalService.display().then(function (organization) {
                cov_2dsjyf4vcb.f[5]++;
                cov_2dsjyf4vcb.s[36]++;

                _this.organizations.$add(organization);
            }, function () {
                cov_2dsjyf4vcb.f[6]++;
            });
        }
    }]);

    return OrganizationsListController;
}();

cov_2dsjyf4vcb.s[37]++;


angular.module('app').component('organizationsList', {
    templateUrl: 'app/components/organizationsList/organizationsList.html',
    controller: OrganizationsListController,
    bindings: {
        organizations: '<'
    }
});