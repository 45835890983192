'use strict';

var cov_987zu3t2a = function () {
    var path = '/home/lobo/repos/prospector-app/src/app/shared/js/ORM/tables.organization.autocomplete.email.factory.js',
        hash = '6c6dc4d1c6357b186b460bb9ec9edc66d28095e4',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/prospector-app/src/app/shared/js/ORM/tables.organization.autocomplete.email.factory.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 12,
                    column: 5
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 4
                },
                end: {
                    line: 6,
                    column: 71
                }
            },
            '2': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 10,
                    column: 94
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 1
                    },
                    end: {
                        line: 1,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 13
                    },
                    end: {
                        line: 12,
                        column: 1
                    }
                },
                line: 1
            },
            '1': {
                name: 'RestmodFactory',
                decl: {
                    start: {
                        line: 9,
                        column: 13
                    },
                    end: {
                        line: 9,
                        column: 27
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 37
                    },
                    end: {
                        line: 11,
                        column: 5
                    }
                },
                line: 9
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0
        },
        f: {
            '0': 0,
            '1': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_987zu3t2a.s[0]++;
(function () {
    'use strict';

    cov_987zu3t2a.f[0]++;
    cov_987zu3t2a.s[1]++;
    angular.module('app').factory('OrganizationAutocompleteEmailTable', RestmodFactory);

    /* @ngInject */
    function RestmodFactory(restmod) {
        cov_987zu3t2a.f[1]++;
        cov_987zu3t2a.s[2]++;

        return restmod.model('/organization-autocomplete-emails').mix('MixinContainable', {});
    }
})();