'use strict';

var cov_2fmf3psmic = function () {
    var path = '/home/lobo/repos/prospector-app/src/app/shared/js/ceil.js',
        hash = '4023cf03c9047c7da277c84ce3377b4f948c2024',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/prospector-app/src/app/shared/js/ceil.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 14,
                    column: 5
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 4
                },
                end: {
                    line: 6,
                    column: 30
                }
            },
            '2': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 12,
                    column: 10
                }
            },
            '3': {
                start: {
                    line: 11,
                    column: 12
                },
                end: {
                    line: 11,
                    column: 36
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 1
                    },
                    end: {
                        line: 1,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 13
                    },
                    end: {
                        line: 14,
                        column: 1
                    }
                },
                line: 1
            },
            '1': {
                name: 'ceil',
                decl: {
                    start: {
                        line: 9,
                        column: 13
                    },
                    end: {
                        line: 9,
                        column: 17
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 20
                    },
                    end: {
                        line: 13,
                        column: 5
                    }
                },
                line: 9
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 10,
                        column: 15
                    },
                    end: {
                        line: 10,
                        column: 16
                    }
                },
                loc: {
                    start: {
                        line: 10,
                        column: 32
                    },
                    end: {
                        line: 12,
                        column: 9
                    }
                },
                line: 10
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_2fmf3psmic.s[0]++;
(function () {
    'use strict';

    cov_2fmf3psmic.f[0]++;
    cov_2fmf3psmic.s[1]++;
    angular.module('app').filter('ceil', ceil);

    /* @ngInject */
    function ceil() {
        cov_2fmf3psmic.f[1]++;
        cov_2fmf3psmic.s[2]++;

        return function (input) {
            cov_2fmf3psmic.f[2]++;
            cov_2fmf3psmic.s[3]++;

            return Math.ceil(input);
        };
    }
})();