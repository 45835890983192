'use strict';

var cov_10q1dcowm2 = function () {
    var path = '/home/lobo/repos/prospector-app/src/app/components/organizationEmails/organizationEmails.js',
        hash = '83e09011a64498541cd33d95741a8cf7188b0f40',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/prospector-app/src/app/components/organizationEmails/organizationEmails.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 63
                }
            },
            '1': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 30
                }
            },
            '2': {
                start: {
                    line: 6,
                    column: 8
                },
                end: {
                    line: 6,
                    column: 34
                }
            },
            '3': {
                start: {
                    line: 10,
                    column: 19
                },
                end: {
                    line: 10,
                    column: 172
                }
            },
            '4': {
                start: {
                    line: 11,
                    column: 8
                },
                end: {
                    line: 11,
                    column: 52
                }
            },
            '5': {
                start: {
                    line: 15,
                    column: 8
                },
                end: {
                    line: 15,
                    column: 34
                }
            },
            '6': {
                start: {
                    line: 16,
                    column: 8
                },
                end: {
                    line: 23,
                    column: 9
                }
            },
            '7': {
                start: {
                    line: 17,
                    column: 12
                },
                end: {
                    line: 17,
                    column: 33
                }
            },
            '8': {
                start: {
                    line: 18,
                    column: 12
                },
                end: {
                    line: 22,
                    column: 15
                }
            },
            '9': {
                start: {
                    line: 19,
                    column: 16
                },
                end: {
                    line: 19,
                    column: 38
                }
            },
            '10': {
                start: {
                    line: 20,
                    column: 16
                },
                end: {
                    line: 20,
                    column: 53
                }
            },
            '11': {
                start: {
                    line: 21,
                    column: 16
                },
                end: {
                    line: 21,
                    column: 35
                }
            },
            '12': {
                start: {
                    line: 28,
                    column: 0
                },
                end: {
                    line: 37,
                    column: 3
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 41
                    },
                    end: {
                        line: 7,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 9,
                        column: 4
                    },
                    end: {
                        line: 9,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 28
                    },
                    end: {
                        line: 12,
                        column: 5
                    }
                },
                line: 9
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 14,
                        column: 4
                    },
                    end: {
                        line: 14,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 14,
                        column: 19
                    },
                    end: {
                        line: 24,
                        column: 5
                    }
                },
                line: 14
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 18,
                        column: 98
                    },
                    end: {
                        line: 18,
                        column: 99
                    }
                },
                loc: {
                    start: {
                        line: 18,
                        column: 110
                    },
                    end: {
                        line: 22,
                        column: 13
                    }
                },
                line: 18
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 16,
                        column: 8
                    },
                    end: {
                        line: 23,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 16,
                        column: 8
                    },
                    end: {
                        line: 23,
                        column: 9
                    }
                }, {
                    start: {
                        line: 16,
                        column: 8
                    },
                    end: {
                        line: 23,
                        column: 9
                    }
                }],
                line: 16
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0
        },
        b: {
            '0': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var OrganizationEmailsController = function () {
    function OrganizationEmailsController(EmailVerificationsTable) {
        _classCallCheck(this, OrganizationEmailsController);

        cov_10q1dcowm2.f[0]++;
        cov_10q1dcowm2.s[0]++;

        this.EmailVerificationsTable = EmailVerificationsTable;
        cov_10q1dcowm2.s[1]++;
        this.fetching = false;
        cov_10q1dcowm2.s[2]++;
        this.fetchedResult = null;
    }

    _createClass(OrganizationEmailsController, [{
        key: 'validEmailFormat',
        value: function validEmailFormat(email) {
            cov_10q1dcowm2.f[1]++;

            var re = (cov_10q1dcowm2.s[3]++, /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            cov_10q1dcowm2.s[4]++;
            return re.test(String(email).toLowerCase());
        }
    }, {
        key: 'emailUpdated',
        value: function emailUpdated() {
            var _this = this;

            cov_10q1dcowm2.f[2]++;
            cov_10q1dcowm2.s[5]++;

            this.fetchedResult = null;
            cov_10q1dcowm2.s[6]++;
            if (this.validEmailFormat(this.newEmail)) {
                cov_10q1dcowm2.b[0][0]++;
                cov_10q1dcowm2.s[7]++;

                this.fetching = true;
                cov_10q1dcowm2.s[8]++;
                this.emails.$create({ email: this.newEmail, organization: this.organization.id }).$then(function (response) {
                    cov_10q1dcowm2.f[3]++;
                    cov_10q1dcowm2.s[9]++;

                    _this.fetching = false;
                    cov_10q1dcowm2.s[10]++;
                    _this.fetchedResult = response.result;
                    cov_10q1dcowm2.s[11]++;
                    _this.newEmail = '';
                });
            } else {
                cov_10q1dcowm2.b[0][1]++;
            }
        }
    }]);

    return OrganizationEmailsController;
}();

cov_10q1dcowm2.s[12]++;


angular.module('app').component('organizationEmails', {
    templateUrl: 'app/components/organizationEmails/organizationEmails.html',
    controller: OrganizationEmailsController,
    bindings: {
        emails: '<',
        organization: '<'
    }
});