'use strict';

var cov_185515uukl = function () {
    var path = '/home/lobo/repos/prospector-app/src/app/components/search/search.js',
        hash = '96a834bde82f2839668508677a4107153d6ae7c0',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/prospector-app/src/app/components/search/search.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 37
                }
            },
            '1': {
                start: {
                    line: 8,
                    column: 0
                },
                end: {
                    line: 16,
                    column: 3
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 28
                    },
                    end: {
                        line: 5,
                        column: 5
                    }
                },
                line: 3
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0
        },
        f: {
            '0': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var SearchController = function SearchController($rootScope) {
    _classCallCheck(this, SearchController);

    cov_185515uukl.f[0]++;
    cov_185515uukl.s[0]++;

    this.$rootScope = $rootScope;
};

cov_185515uukl.s[1]++;


angular.module('app').component('search', {
    templateUrl: 'app/components/search/search.html',
    controller: SearchController,
    bindings: {
        label: '<'
    }
});