'use strict';

var cov_1w32ajqo4q = function () {
    var path = '/home/lobo/repos/prospector-app/src/app/components/municipalitiesList/municipalitiesList.js',
        hash = '81065bda8567186f9ced93e1bbc3197ebce049f1',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/prospector-app/src/app/components/municipalitiesList/municipalitiesList.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 26
                }
            },
            '1': {
                start: {
                    line: 8,
                    column: 8
                },
                end: {
                    line: 11,
                    column: 10
                }
            },
            '2': {
                start: {
                    line: 12,
                    column: 8
                },
                end: {
                    line: 12,
                    column: 24
                }
            },
            '3': {
                start: {
                    line: 16,
                    column: 8
                },
                end: {
                    line: 16,
                    column: 51
                }
            },
            '4': {
                start: {
                    line: 20,
                    column: 0
                },
                end: {
                    line: 28,
                    column: 3
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 18
                    },
                    end: {
                        line: 5,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 7,
                        column: 4
                    },
                    end: {
                        line: 7,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 7,
                        column: 14
                    },
                    end: {
                        line: 13,
                        column: 5
                    }
                },
                line: 7
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 15,
                        column: 4
                    },
                    end: {
                        line: 15,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 15,
                        column: 15
                    },
                    end: {
                        line: 17,
                        column: 5
                    }
                },
                line: 15
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var MunicipalitiesListController = function () {
    function MunicipalitiesListController() {
        _classCallCheck(this, MunicipalitiesListController);

        cov_1w32ajqo4q.f[0]++;
        cov_1w32ajqo4q.s[0]++;

        this.filters = {};
    }

    _createClass(MunicipalitiesListController, [{
        key: '$onInit',
        value: function $onInit() {
            cov_1w32ajqo4q.f[1]++;
            cov_1w32ajqo4q.s[1]++;

            this.municipalities.$params = {
                /* eslint-disable camelcase */
                page_size: 25
            };
            cov_1w32ajqo4q.s[2]++;
            this._refresh();
        }
    }, {
        key: '_refresh',
        value: function _refresh() {
            cov_1w32ajqo4q.f[2]++;
            cov_1w32ajqo4q.s[3]++;

            this.municipalities.$refresh(this.filters);
        }
    }]);

    return MunicipalitiesListController;
}();

cov_1w32ajqo4q.s[4]++;


angular.module('app').component('municipalitiesList', {
    templateUrl: 'app/components/municipalitiesList/municipalitiesList.html',
    controller: MunicipalitiesListController,
    bindings: {
        municipalities: '<'
    }
});