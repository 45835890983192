'use strict';

var cov_24jkhownuf = function () {
    var path = '/home/lobo/repos/prospector-app/src/app/shared/js/ORM/tables.organizations.factory.js',
        hash = '9ca3edee8169e808afff8ee1962c9fdee625244e',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/prospector-app/src/app/shared/js/ORM/tables.organizations.factory.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 100,
                    column: 5
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 4
                },
                end: {
                    line: 6,
                    column: 51
                }
            },
            '2': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 98,
                    column: 11
                }
            },
            '3': {
                start: {
                    line: 18,
                    column: 20
                },
                end: {
                    line: 20,
                    column: 21
                }
            },
            '4': {
                start: {
                    line: 19,
                    column: 24
                },
                end: {
                    line: 19,
                    column: 41
                }
            },
            '5': {
                start: {
                    line: 21,
                    column: 20
                },
                end: {
                    line: 21,
                    column: 51
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 1
                    },
                    end: {
                        line: 1,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 13
                    },
                    end: {
                        line: 100,
                        column: 1
                    }
                },
                line: 1
            },
            '1': {
                name: 'RestmodFactory',
                decl: {
                    start: {
                        line: 9,
                        column: 13
                    },
                    end: {
                        line: 9,
                        column: 27
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 37
                    },
                    end: {
                        line: 99,
                        column: 5
                    }
                },
                line: 9
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 16,
                        column: 37
                    },
                    end: {
                        line: 16,
                        column: 38
                    }
                },
                loc: {
                    start: {
                        line: 16,
                        column: 53
                    },
                    end: {
                        line: 22,
                        column: 17
                    }
                },
                line: 16
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 18,
                        column: 20
                    },
                    end: {
                        line: 20,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 18,
                        column: 20
                    },
                    end: {
                        line: 20,
                        column: 21
                    }
                }, {
                    start: {
                        line: 18,
                        column: 20
                    },
                    end: {
                        line: 20,
                        column: 21
                    }
                }],
                line: 18
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0
        },
        b: {
            '0': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_24jkhownuf.s[0]++;
(function () {
    'use strict';

    cov_24jkhownuf.f[0]++;
    cov_24jkhownuf.s[1]++;
    angular.module('app').factory('OrganizationsTable', RestmodFactory);

    /* @ngInject */
    function RestmodFactory(restmod) {
        cov_24jkhownuf.f[1]++;
        cov_24jkhownuf.s[2]++;

        return restmod.model('/organizations').mix('MixinContainable', {
            type: {
                init: 'COLECTIVE'
            },
            $hooks: {
                /* eslint-disable babel/object-shorthand */
                'before-fetch-many': function beforeFetchMany(_req) {
                    cov_24jkhownuf.f[2]++;
                    cov_24jkhownuf.s[3]++;

                    /* eslint-disable no-undef */
                    if (_.isUndefined(_req.params)) {
                        cov_24jkhownuf.b[0][0]++;
                        cov_24jkhownuf.s[4]++;

                        _req.params = {};
                    } else {
                        cov_24jkhownuf.b[0][1]++;
                    }
                    cov_24jkhownuf.s[5]++;
                    _req.params.type = 'COLECTIVE';
                }
            },
            $config: {
                contains: {
                    links: {
                        type: 'hasMany',
                        table: 'LinksTable',
                        foreignKey: 'organization'
                    },
                    comments: {
                        type: 'hasMany',
                        table: 'CommentsTable',
                        foreignKey: 'object_id',
                        filters: {
                            /* eslint-disable camelcase */
                            object_type: 'ORGANIZATION'
                        }
                    },
                    organizationIndividuals: {
                        type: 'hasMany',
                        table: 'OrganizationIndividualsTable',
                        foreignKey: 'organization1'
                    },
                    economicActivities: {
                        type: 'hasMany',
                        table: 'OrganizationEconomicActivitiesTable',
                        foreignKey: 'organization'
                    },
                    files: {
                        type: 'hasMany',
                        table: 'OrganizationFilesTable',
                        foreignKey: 'organization'
                    },
                    tags: {
                        type: 'hasMany',
                        table: 'OrganizationTagsTable',
                        foreignKey: 'organization'
                    },
                    interactions: {
                        type: 'hasMany',
                        table: 'OrganizationInteractionsTable',
                        foreignKey: 'organization'
                    },
                    municipality: {
                        type: 'belongsTo',
                        table: 'MunicipalitiesTable',
                        foreignKey: 'municipality'
                    },
                    emails: {
                        type: 'hasMany',
                        table: 'OrganizationEmailsTable',
                        foreignKey: 'organization'
                    },
                    rankings: {
                        type: 'hasMany',
                        table: 'RankingsTable',
                        foreignKey: 'organization'
                    },
                    emailInteractionReads: {
                        type: 'hasMany',
                        table: 'EmailInteractionReadsTable',
                        foreignKey: 'organization'

                    },
                    siteVisits: {
                        type: 'hasMany',
                        table: 'OrganizationSiteVisitsTable',
                        foreignKey: 'organization'
                    },
                    domains: {
                        type: 'hasMany',
                        table: 'OrganizationDomainsTable',
                        foreignKey: 'organization'
                    }
                }
            }
        });
    }
})();