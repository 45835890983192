'use strict';

var cov_1z5n2ygse = function () {
    var path = '/home/lobo/repos/prospector-app/src/app/components/profileLevel/profileLevel.js',
        hash = 'fed890248476a6d3291c3048cd1cf1a3d6e47b36',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/prospector-app/src/app/components/profileLevel/profileLevel.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 8,
                    column: 3
                }
            }
        },
        fnMap: {},
        branchMap: {},
        s: {
            '0': 0
        },
        f: {},
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_1z5n2ygse.s[0]++;
angular.module('app').component('profileLevel', {
    templateUrl: 'app/components/profileLevel/profileLevel.html',
    bindings: {
        level: '<'
    }
});