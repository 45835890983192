'use strict';

var cov_28pxhb2vh2 = function () {
    var path = '/home/lobo/repos/prospector-app/src/app/components/proposalSectionDetails/proposalSectionDetails.js',
        hash = '0d7c0116f32bce3a2c8d592f82c0a1ec4abe9ae6',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/prospector-app/src/app/components/proposalSectionDetails/proposalSectionDetails.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 57
                }
            },
            '1': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 53
                }
            },
            '2': {
                start: {
                    line: 6,
                    column: 8
                },
                end: {
                    line: 6,
                    column: 33
                }
            },
            '3': {
                start: {
                    line: 8,
                    column: 8
                },
                end: {
                    line: 13,
                    column: 11
                }
            },
            '4': {
                start: {
                    line: 9,
                    column: 12
                },
                end: {
                    line: 12,
                    column: 13
                }
            },
            '5': {
                start: {
                    line: 10,
                    column: 16
                },
                end: {
                    line: 10,
                    column: 54
                }
            },
            '6': {
                start: {
                    line: 11,
                    column: 16
                },
                end: {
                    line: 11,
                    column: 39
                }
            },
            '7': {
                start: {
                    line: 14,
                    column: 8
                },
                end: {
                    line: 25,
                    column: 10
                }
            },
            '8': {
                start: {
                    line: 16,
                    column: 31
                },
                end: {
                    line: 16,
                    column: 51
                }
            },
            '9': {
                start: {
                    line: 17,
                    column: 32
                },
                end: {
                    line: 17,
                    column: 62
                }
            },
            '10': {
                start: {
                    line: 18,
                    column: 16
                },
                end: {
                    line: 18,
                    column: 39
                }
            },
            '11': {
                start: {
                    line: 20,
                    column: 16
                },
                end: {
                    line: 22,
                    column: 19
                }
            },
            '12': {
                start: {
                    line: 21,
                    column: 20
                },
                end: {
                    line: 21,
                    column: 69
                }
            },
            '13': {
                start: {
                    line: 26,
                    column: 8
                },
                end: {
                    line: 26,
                    column: 43
                }
            },
            '14': {
                start: {
                    line: 29,
                    column: 8
                },
                end: {
                    line: 29,
                    column: 54
                }
            },
            '15': {
                start: {
                    line: 30,
                    column: 8
                },
                end: {
                    line: 30,
                    column: 111
                }
            },
            '16': {
                start: {
                    line: 31,
                    column: 8
                },
                end: {
                    line: 31,
                    column: 33
                }
            },
            '17': {
                start: {
                    line: 32,
                    column: 8
                },
                end: {
                    line: 32,
                    column: 26
                }
            },
            '18': {
                start: {
                    line: 36,
                    column: 8
                },
                end: {
                    line: 39,
                    column: 11
                }
            },
            '19': {
                start: {
                    line: 37,
                    column: 12
                },
                end: {
                    line: 37,
                    column: 55
                }
            },
            '20': {
                start: {
                    line: 38,
                    column: 12
                },
                end: {
                    line: 38,
                    column: 50
                }
            },
            '21': {
                start: {
                    line: 43,
                    column: 8
                },
                end: {
                    line: 43,
                    column: 43
                }
            },
            '22': {
                start: {
                    line: 44,
                    column: 8
                },
                end: {
                    line: 44,
                    column: 57
                }
            },
            '23': {
                start: {
                    line: 45,
                    column: 8
                },
                end: {
                    line: 45,
                    column: 36
                }
            },
            '24': {
                start: {
                    line: 46,
                    column: 8
                },
                end: {
                    line: 46,
                    column: 111
                }
            },
            '25': {
                start: {
                    line: 47,
                    column: 8
                },
                end: {
                    line: 47,
                    column: 33
                }
            },
            '26': {
                start: {
                    line: 48,
                    column: 8
                },
                end: {
                    line: 48,
                    column: 26
                }
            },
            '27': {
                start: {
                    line: 52,
                    column: 0
                },
                end: {
                    line: 63,
                    column: 4
                }
            },
            '28': {
                start: {
                    line: 61,
                    column: 35
                },
                end: {
                    line: 63,
                    column: 1
                }
            },
            '29': {
                start: {
                    line: 62,
                    column: 4
                },
                end: {
                    line: 62,
                    column: 33
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 81
                    },
                    end: {
                        line: 27,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 8,
                        column: 53
                    },
                    end: {
                        line: 8,
                        column: 54
                    }
                },
                loc: {
                    start: {
                        line: 8,
                        column: 63
                    },
                    end: {
                        line: 13,
                        column: 9
                    }
                },
                line: 8
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 15,
                        column: 26
                    },
                    end: {
                        line: 15,
                        column: 27
                    }
                },
                loc: {
                    start: {
                        line: 15,
                        column: 35
                    },
                    end: {
                        line: 23,
                        column: 13
                    }
                },
                line: 15
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 20,
                        column: 47
                    },
                    end: {
                        line: 20,
                        column: 48
                    }
                },
                loc: {
                    start: {
                        line: 20,
                        column: 53
                    },
                    end: {
                        line: 22,
                        column: 17
                    }
                },
                line: 20
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 28,
                        column: 4
                    },
                    end: {
                        line: 28,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 28,
                        column: 14
                    },
                    end: {
                        line: 33,
                        column: 5
                    }
                },
                line: 28
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 35,
                        column: 4
                    },
                    end: {
                        line: 35,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 35,
                        column: 27
                    },
                    end: {
                        line: 40,
                        column: 5
                    }
                },
                line: 35
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 36,
                        column: 49
                    },
                    end: {
                        line: 36,
                        column: 50
                    }
                },
                loc: {
                    start: {
                        line: 36,
                        column: 66
                    },
                    end: {
                        line: 39,
                        column: 9
                    }
                },
                line: 36
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 42,
                        column: 4
                    },
                    end: {
                        line: 42,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 42,
                        column: 41
                    },
                    end: {
                        line: 49,
                        column: 5
                    }
                },
                line: 42
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 61,
                        column: 27
                    },
                    end: {
                        line: 61,
                        column: 28
                    }
                },
                loc: {
                    start: {
                        line: 61,
                        column: 35
                    },
                    end: {
                        line: 63,
                        column: 1
                    }
                },
                line: 61
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 61,
                        column: 35
                    },
                    end: {
                        line: 61,
                        column: 36
                    }
                },
                loc: {
                    start: {
                        line: 61,
                        column: 50
                    },
                    end: {
                        line: 63,
                        column: 1
                    }
                },
                line: 61
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 9,
                        column: 12
                    },
                    end: {
                        line: 12,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 9,
                        column: 12
                    },
                    end: {
                        line: 12,
                        column: 13
                    }
                }, {
                    start: {
                        line: 9,
                        column: 12
                    },
                    end: {
                        line: 12,
                        column: 13
                    }
                }],
                line: 9
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0,
            '28': 0,
            '29': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0
        },
        b: {
            '0': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var ProposalSectionDetailsController = function () {
    function ProposalSectionDetailsController($scope, MediaTable, MediaSelectorService, AppSettings, $mdToast) {
        var _this = this;

        _classCallCheck(this, ProposalSectionDetailsController);

        cov_28pxhb2vh2.f[0]++;
        cov_28pxhb2vh2.s[0]++;

        this.MediaSelectorService = MediaSelectorService;
        cov_28pxhb2vh2.s[1]++;
        this.proposalsApp = AppSettings.proposalsApp;
        cov_28pxhb2vh2.s[2]++;
        this.$mdToast = $mdToast;
        // files
        cov_28pxhb2vh2.s[3]++;
        $scope.$watch('$ctrl.proposalSection.cover', function (newVal) {
            cov_28pxhb2vh2.f[1]++;
            cov_28pxhb2vh2.s[4]++;

            if (angular.isString(newVal)) {
                cov_28pxhb2vh2.b[0][0]++;
                cov_28pxhb2vh2.s[5]++;

                _this.proposalSection.$save(['cover']);
                cov_28pxhb2vh2.s[6]++;
                _this.medias.$refresh();
            } else {
                cov_28pxhb2vh2.b[0][1]++;
            }
        });
        cov_28pxhb2vh2.s[7]++;
        this.sortableContents = {
            orderChanged: function orderChanged(event) {
                cov_28pxhb2vh2.f[2]++;

                var newPos = (cov_28pxhb2vh2.s[8]++, event.dest.index + 1);
                var content = (cov_28pxhb2vh2.s[9]++, event.source.itemScope.content);
                cov_28pxhb2vh2.s[10]++;
                content.order = newPos;

                cov_28pxhb2vh2.s[11]++;
                content.$save(['order']).$then(function () {
                    cov_28pxhb2vh2.f[3]++;
                    cov_28pxhb2vh2.s[12]++;

                    _this.proposalSection.$sectionContents.$refresh();
                });
            },
            placeholder: '<div style="min-height: 60px;"></div>'
        };
        cov_28pxhb2vh2.s[13]++;
        this.medias = MediaTable.$search();
    }

    _createClass(ProposalSectionDetailsController, [{
        key: '$onInit',
        value: function $onInit() {
            cov_28pxhb2vh2.f[4]++;
            cov_28pxhb2vh2.s[14]++;

            this.proposalSection.$load('sectionContents');
            cov_28pxhb2vh2.s[15]++;
            this.editingContent = this.proposalSection.$sectionContents.$build({ section: this.proposalSection.id });
            cov_28pxhb2vh2.s[16]++;
            this.newDescription = '';
            cov_28pxhb2vh2.s[17]++;
            this.newName = '';
        }
    }, {
        key: 'displayMediaSelector',
        value: function displayMediaSelector() {
            var _this2 = this;

            cov_28pxhb2vh2.f[5]++;
            cov_28pxhb2vh2.s[18]++;

            this.MediaSelectorService.display().then(function (selectedMedia) {
                cov_28pxhb2vh2.f[6]++;
                cov_28pxhb2vh2.s[19]++;

                _this2.proposalSection.cover = selectedMedia;
                cov_28pxhb2vh2.s[20]++;
                _this2.proposalSection.$save(['cover']);
            });
        }
    }, {
        key: 'saveContent',
        value: function saveContent(newName, newDescription) {
            cov_28pxhb2vh2.f[7]++;
            cov_28pxhb2vh2.s[21]++;

            this.editingContent.name = newName;
            cov_28pxhb2vh2.s[22]++;
            this.editingContent.description = newDescription;
            cov_28pxhb2vh2.s[23]++;
            this.editingContent.$save();
            cov_28pxhb2vh2.s[24]++;
            this.editingContent = this.proposalSection.$sectionContents.$build({ section: this.proposalSection.id });
            cov_28pxhb2vh2.s[25]++;
            this.newDescription = '';
            cov_28pxhb2vh2.s[26]++;
            this.newName = '';
        }
    }]);

    return ProposalSectionDetailsController;
}();

cov_28pxhb2vh2.s[27]++;


angular.module('app').component('proposalSectionDetails', {
    templateUrl: 'app/components/proposalSectionDetails/proposalSectionDetails.html',
    controller: ProposalSectionDetailsController,
    bindings: {
        proposalSection: '<'
    }
}).filter('unsafe', ['$sce', function ($sce) {
    cov_28pxhb2vh2.f[8]++;
    cov_28pxhb2vh2.s[28]++;
    return function (val) {
        cov_28pxhb2vh2.f[9]++;
        cov_28pxhb2vh2.s[29]++;

        return $sce.trustAsHtml(val);
    };
}]);