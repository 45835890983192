'use strict';

var cov_d80am8t4y = function () {
    var path = '/home/lobo/repos/prospector-app/src/app/components/componentsList/componentsList.js',
        hash = 'dac86369119d813a704e0b7133100d2260db29cc',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/prospector-app/src/app/components/componentsList/componentsList.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 65
                }
            },
            '1': {
                start: {
                    line: 6,
                    column: 8
                },
                end: {
                    line: 9,
                    column: 11
                }
            },
            '2': {
                start: {
                    line: 7,
                    column: 12
                },
                end: {
                    line: 7,
                    column: 40
                }
            },
            '3': {
                start: {
                    line: 8,
                    column: 12
                },
                end: {
                    line: 8,
                    column: 26
                }
            },
            '4': {
                start: {
                    line: 11,
                    column: 8
                },
                end: {
                    line: 15,
                    column: 11
                }
            },
            '5': {
                start: {
                    line: 13,
                    column: 12
                },
                end: {
                    line: 13,
                    column: 55
                }
            },
            '6': {
                start: {
                    line: 14,
                    column: 12
                },
                end: {
                    line: 14,
                    column: 26
                }
            },
            '7': {
                start: {
                    line: 19,
                    column: 8
                },
                end: {
                    line: 19,
                    column: 51
                }
            },
            '8': {
                start: {
                    line: 23,
                    column: 8
                },
                end: {
                    line: 25,
                    column: 11
                }
            },
            '9': {
                start: {
                    line: 24,
                    column: 12
                },
                end: {
                    line: 24,
                    column: 44
                }
            },
            '10': {
                start: {
                    line: 29,
                    column: 0
                },
                end: {
                    line: 37,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 54
                    },
                    end: {
                        line: 16,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 6,
                        column: 38
                    },
                    end: {
                        line: 6,
                        column: 39
                    }
                },
                loc: {
                    start: {
                        line: 6,
                        column: 55
                    },
                    end: {
                        line: 9,
                        column: 9
                    }
                },
                line: 6
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 11,
                        column: 37
                    },
                    end: {
                        line: 11,
                        column: 38
                    }
                },
                loc: {
                    start: {
                        line: 11,
                        column: 54
                    },
                    end: {
                        line: 15,
                        column: 9
                    }
                },
                line: 11
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 18,
                        column: 4
                    },
                    end: {
                        line: 18,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 18,
                        column: 13
                    },
                    end: {
                        line: 20,
                        column: 5
                    }
                },
                line: 18
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 22,
                        column: 4
                    },
                    end: {
                        line: 22,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 22,
                        column: 31
                    },
                    end: {
                        line: 26,
                        column: 5
                    }
                },
                line: 22
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 23,
                        column: 53
                    },
                    end: {
                        line: 23,
                        column: 54
                    }
                },
                loc: {
                    start: {
                        line: 23,
                        column: 66
                    },
                    end: {
                        line: 25,
                        column: 9
                    }
                },
                line: 23
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var ComponentsListController = function () {
    function ComponentsListController(NewComponentModalService, $rootScope) {
        var _this = this;

        _classCallCheck(this, ComponentsListController);

        cov_d80am8t4y.f[0]++;
        cov_d80am8t4y.s[0]++;

        this.NewComponentModalService = NewComponentModalService;
        // eslint-disable-next-line angular/on-watch
        cov_d80am8t4y.s[1]++;
        $rootScope.$on('pageChanged', function (event, data) {
            cov_d80am8t4y.f[1]++;
            cov_d80am8t4y.s[2]++;

            _this.proposals.$page = data;
            cov_d80am8t4y.s[3]++;
            _this.update();
        });
        // eslint-disable-next-line angular/on-watch
        cov_d80am8t4y.s[4]++;
        $rootScope.$on('searchText', function (event, data) {
            cov_d80am8t4y.f[2]++;
            cov_d80am8t4y.s[5]++;

            // eslint-disable-next-line camelcase
            _this.requestData = { name__icontains: data };
            cov_d80am8t4y.s[6]++;
            _this.update();
        });
    }

    _createClass(ComponentsListController, [{
        key: 'update',
        value: function update() {
            cov_d80am8t4y.f[3]++;
            cov_d80am8t4y.s[7]++;

            this.components.$refresh(this.requestData);
        }
    }, {
        key: 'displayNewComponentModal',
        value: function displayNewComponentModal() {
            var _this2 = this;

            cov_d80am8t4y.f[4]++;
            cov_d80am8t4y.s[8]++;

            this.NewComponentModalService.display().then(function (component) {
                cov_d80am8t4y.f[5]++;
                cov_d80am8t4y.s[9]++;

                _this2.components.$add(component);
            });
        }
    }]);

    return ComponentsListController;
}();

cov_d80am8t4y.s[10]++;


angular.module('app').component('componentsList', {
    templateUrl: 'app/components/componentsList/componentsList.html',
    controller: ComponentsListController,
    bindings: {
        components: '<'
    }
});