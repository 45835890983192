'use strict';

var cov_23sabtvnex = function () {
    var path = '/home/lobo/repos/prospector-app/src/app/components/auth/auth.login.controller.js',
        hash = '4d301ebc3e1d309a283735cfcab6357bd6c30c44',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/prospector-app/src/app/components/auth/auth.login.controller.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 47,
                    column: 5
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 4
                },
                end: {
                    line: 6,
                    column: 51
                }
            },
            '2': {
                start: {
                    line: 10,
                    column: 19
                },
                end: {
                    line: 10,
                    column: 23
                }
            },
            '3': {
                start: {
                    line: 11,
                    column: 8
                },
                end: {
                    line: 11,
                    column: 25
                }
            },
            '4': {
                start: {
                    line: 12,
                    column: 8
                },
                end: {
                    line: 12,
                    column: 41
                }
            },
            '5': {
                start: {
                    line: 14,
                    column: 8
                },
                end: {
                    line: 14,
                    column: 30
                }
            },
            '6': {
                start: {
                    line: 16,
                    column: 8
                },
                end: {
                    line: 16,
                    column: 19
                }
            },
            '7': {
                start: {
                    line: 22,
                    column: 12
                },
                end: {
                    line: 26,
                    column: 15
                }
            },
            '8': {
                start: {
                    line: 23,
                    column: 16
                },
                end: {
                    line: 23,
                    column: 29
                }
            },
            '9': {
                start: {
                    line: 25,
                    column: 16
                },
                end: {
                    line: 25,
                    column: 34
                }
            },
            '10': {
                start: {
                    line: 30,
                    column: 12
                },
                end: {
                    line: 34,
                    column: 15
                }
            },
            '11': {
                start: {
                    line: 31,
                    column: 16
                },
                end: {
                    line: 31,
                    column: 29
                }
            },
            '12': {
                start: {
                    line: 33,
                    column: 16
                },
                end: {
                    line: 33,
                    column: 34
                }
            },
            '13': {
                start: {
                    line: 42,
                    column: 12
                },
                end: {
                    line: 44,
                    column: 15
                }
            },
            '14': {
                start: {
                    line: 43,
                    column: 16
                },
                end: {
                    line: 43,
                    column: 47
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 1
                    },
                    end: {
                        line: 1,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 13
                    },
                    end: {
                        line: 47,
                        column: 1
                    }
                },
                line: 1
            },
            '1': {
                name: 'Controller',
                decl: {
                    start: {
                        line: 9,
                        column: 13
                    },
                    end: {
                        line: 9,
                        column: 23
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 57
                    },
                    end: {
                        line: 46,
                        column: 5
                    }
                },
                line: 9
            },
            '2': {
                name: 'activate',
                decl: {
                    start: {
                        line: 18,
                        column: 17
                    },
                    end: {
                        line: 18,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 18,
                        column: 28
                    },
                    end: {
                        line: 19,
                        column: 9
                    }
                },
                line: 18
            },
            '3': {
                name: 'login',
                decl: {
                    start: {
                        line: 21,
                        column: 17
                    },
                    end: {
                        line: 21,
                        column: 22
                    }
                },
                loc: {
                    start: {
                        line: 21,
                        column: 25
                    },
                    end: {
                        line: 27,
                        column: 9
                    }
                },
                line: 21
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 22,
                        column: 58
                    },
                    end: {
                        line: 22,
                        column: 59
                    }
                },
                loc: {
                    start: {
                        line: 22,
                        column: 70
                    },
                    end: {
                        line: 24,
                        column: 13
                    }
                },
                line: 22
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 24,
                        column: 15
                    },
                    end: {
                        line: 24,
                        column: 16
                    }
                },
                loc: {
                    start: {
                        line: 24,
                        column: 32
                    },
                    end: {
                        line: 26,
                        column: 13
                    }
                },
                line: 24
            },
            '6': {
                name: 'loginFacebook',
                decl: {
                    start: {
                        line: 29,
                        column: 17
                    },
                    end: {
                        line: 29,
                        column: 30
                    }
                },
                loc: {
                    start: {
                        line: 29,
                        column: 33
                    },
                    end: {
                        line: 35,
                        column: 9
                    }
                },
                line: 29
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 30,
                        column: 45
                    },
                    end: {
                        line: 30,
                        column: 46
                    }
                },
                loc: {
                    start: {
                        line: 30,
                        column: 57
                    },
                    end: {
                        line: 32,
                        column: 13
                    }
                },
                line: 30
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 32,
                        column: 15
                    },
                    end: {
                        line: 32,
                        column: 16
                    }
                },
                loc: {
                    start: {
                        line: 32,
                        column: 32
                    },
                    end: {
                        line: 34,
                        column: 13
                    }
                },
                line: 32
            },
            '9': {
                name: 'afterLogin',
                decl: {
                    start: {
                        line: 41,
                        column: 17
                    },
                    end: {
                        line: 41,
                        column: 27
                    }
                },
                loc: {
                    start: {
                        line: 41,
                        column: 30
                    },
                    end: {
                        line: 45,
                        column: 9
                    }
                },
                line: 41
            },
            '10': {
                name: '(anonymous_10)',
                decl: {
                    start: {
                        line: 42,
                        column: 32
                    },
                    end: {
                        line: 42,
                        column: 33
                    }
                },
                loc: {
                    start: {
                        line: 42,
                        column: 44
                    },
                    end: {
                        line: 44,
                        column: 13
                    }
                },
                line: 42
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_23sabtvnex.s[0]++;
(function () {
    'use strict';

    cov_23sabtvnex.f[0]++;
    cov_23sabtvnex.s[1]++;
    angular.module('app').controller('AuthLoginController', Controller);

    /* @ngInject */
    function Controller($state, AuthService, $rootScope) {
        cov_23sabtvnex.f[1]++;

        var vm = (cov_23sabtvnex.s[2]++, this);
        cov_23sabtvnex.s[3]++;
        vm.login = login;
        cov_23sabtvnex.s[4]++;
        vm.loginFacebook = loginFacebook;

        cov_23sabtvnex.s[5]++;
        vm.authenticated = {};

        cov_23sabtvnex.s[6]++;
        activate();

        function activate() {
            cov_23sabtvnex.f[2]++;
        }

        function login() {
            cov_23sabtvnex.f[3]++;
            cov_23sabtvnex.s[7]++;

            AuthService.login(vm.email, vm.password).then(function () {
                cov_23sabtvnex.f[4]++;
                cov_23sabtvnex.s[8]++;

                afterLogin();
            }, function (error) {
                cov_23sabtvnex.f[5]++;
                cov_23sabtvnex.s[9]++;

                vm.member = error;
            });
        }

        function loginFacebook() {
            cov_23sabtvnex.f[6]++;
            cov_23sabtvnex.s[10]++;

            AuthService.loginFacebook().then(function () {
                cov_23sabtvnex.f[7]++;
                cov_23sabtvnex.s[11]++;

                afterLogin();
            }, function (error) {
                cov_23sabtvnex.f[8]++;
                cov_23sabtvnex.s[12]++;

                vm.member = error;
            });
        }

        // function logout () {
        //     AuthService.logout();
        // }

        function afterLogin() {
            cov_23sabtvnex.f[9]++;
            cov_23sabtvnex.s[13]++;

            $rootScope.me.$then(function () {
                cov_23sabtvnex.f[10]++;
                cov_23sabtvnex.s[14]++;

                $state.go('organizationsList');
            });
        }
    }
})();