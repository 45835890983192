'use strict';

var cov_qcpez89pa = function () {
    var path = '/home/lobo/repos/prospector-app/src/app/components/interactionsList/interactionsList.js',
        hash = 'b18ae91efaa0fc34cc9d9fe5d93e95ed82040e69',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/prospector-app/src/app/components/interactionsList/interactionsList.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 26
                }
            },
            '1': {
                start: {
                    line: 8,
                    column: 8
                },
                end: {
                    line: 12,
                    column: 10
                }
            },
            '2': {
                start: {
                    line: 13,
                    column: 8
                },
                end: {
                    line: 13,
                    column: 24
                }
            },
            '3': {
                start: {
                    line: 17,
                    column: 8
                },
                end: {
                    line: 17,
                    column: 49
                }
            },
            '4': {
                start: {
                    line: 21,
                    column: 8
                },
                end: {
                    line: 23,
                    column: 9
                }
            },
            '5': {
                start: {
                    line: 22,
                    column: 12
                },
                end: {
                    line: 22,
                    column: 36
                }
            },
            '6': {
                start: {
                    line: 25,
                    column: 8
                },
                end: {
                    line: 29,
                    column: 9
                }
            },
            '7': {
                start: {
                    line: 26,
                    column: 12
                },
                end: {
                    line: 26,
                    column: 40
                }
            },
            '8': {
                start: {
                    line: 28,
                    column: 12
                },
                end: {
                    line: 28,
                    column: 41
                }
            },
            '9': {
                start: {
                    line: 31,
                    column: 8
                },
                end: {
                    line: 31,
                    column: 24
                }
            },
            '10': {
                start: {
                    line: 36,
                    column: 0
                },
                end: {
                    line: 44,
                    column: 3
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 18
                    },
                    end: {
                        line: 5,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 7,
                        column: 4
                    },
                    end: {
                        line: 7,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 7,
                        column: 14
                    },
                    end: {
                        line: 14,
                        column: 5
                    }
                },
                line: 7
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 16,
                        column: 4
                    },
                    end: {
                        line: 16,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 16,
                        column: 15
                    },
                    end: {
                        line: 18,
                        column: 5
                    }
                },
                line: 16
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 20,
                        column: 4
                    },
                    end: {
                        line: 20,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 20,
                        column: 33
                    },
                    end: {
                        line: 32,
                        column: 5
                    }
                },
                line: 20
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 21,
                        column: 8
                    },
                    end: {
                        line: 23,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 21,
                        column: 8
                    },
                    end: {
                        line: 23,
                        column: 9
                    }
                }, {
                    start: {
                        line: 21,
                        column: 8
                    },
                    end: {
                        line: 23,
                        column: 9
                    }
                }],
                line: 21
            },
            '1': {
                loc: {
                    start: {
                        line: 25,
                        column: 8
                    },
                    end: {
                        line: 29,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 25,
                        column: 8
                    },
                    end: {
                        line: 29,
                        column: 9
                    }
                }, {
                    start: {
                        line: 25,
                        column: 8
                    },
                    end: {
                        line: 29,
                        column: 9
                    }
                }],
                line: 25
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var InteractionsListController = function () {
    function InteractionsListController() {
        _classCallCheck(this, InteractionsListController);

        cov_qcpez89pa.f[0]++;
        cov_qcpez89pa.s[0]++;

        this.filters = {};
    }

    _createClass(InteractionsListController, [{
        key: '$onInit',
        value: function $onInit() {
            cov_qcpez89pa.f[1]++;
            cov_qcpez89pa.s[1]++;

            this.interactions.$params = {
                /* eslint-disable camelcase */
                page_size: 25,
                ordering: '-interacted_at'
            };
            cov_qcpez89pa.s[2]++;
            this._refresh();
        }
    }, {
        key: '_refresh',
        value: function _refresh() {
            cov_qcpez89pa.f[2]++;
            cov_qcpez89pa.s[3]++;

            this.interactions.$refresh(this.filters);
        }
    }, {
        key: 'changeFilters',
        value: function changeFilters(filter, value) {
            cov_qcpez89pa.f[3]++;
            cov_qcpez89pa.s[4]++;

            if (angular.isArray(value)) {
                cov_qcpez89pa.b[0][0]++;
                cov_qcpez89pa.s[5]++;

                value = value.join(',');
            } else {
                cov_qcpez89pa.b[0][1]++;
            }

            cov_qcpez89pa.s[6]++;
            if (value === null) {
                cov_qcpez89pa.b[1][0]++;
                cov_qcpez89pa.s[7]++;

                delete this.filters[filter];
            } else {
                cov_qcpez89pa.b[1][1]++;
                cov_qcpez89pa.s[8]++;

                this.filters[filter] = value;
            }

            cov_qcpez89pa.s[9]++;
            this._refresh();
        }
    }]);

    return InteractionsListController;
}();

cov_qcpez89pa.s[10]++;


angular.module('app').component('interactionsList', {
    templateUrl: 'app/components/interactionsList/interactionsList.html',
    controller: InteractionsListController,
    bindings: {
        interactions: '<'
    }
});