'use strict';

var cov_6zmzyrf47 = function () {
    var path = '/home/lobo/repos/prospector-app/src/app/components/userStorySelector/userStorySelector.js',
        hash = '321c025f11ccad250b89d5735e6c576413620dbb',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/prospector-app/src/app/components/userStorySelector/userStorySelector.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 35,
                    column: 12
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 4
                },
                end: {
                    line: 6,
                    column: 71
                }
            },
            '2': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 17,
                    column: 10
                }
            },
            '3': {
                start: {
                    line: 11,
                    column: 12
                },
                end: {
                    line: 16,
                    column: 15
                }
            },
            '4': {
                start: {
                    line: 22,
                    column: 8
                },
                end: {
                    line: 22,
                    column: 38
                }
            },
            '5': {
                start: {
                    line: 24,
                    column: 8
                },
                end: {
                    line: 24,
                    column: 82
                }
            },
            '6': {
                start: {
                    line: 26,
                    column: 8
                },
                end: {
                    line: 28,
                    column: 10
                }
            },
            '7': {
                start: {
                    line: 27,
                    column: 12
                },
                end: {
                    line: 27,
                    column: 31
                }
            },
            '8': {
                start: {
                    line: 30,
                    column: 8
                },
                end: {
                    line: 33,
                    column: 10
                }
            },
            '9': {
                start: {
                    line: 31,
                    column: 12
                },
                end: {
                    line: 31,
                    column: 45
                }
            },
            '10': {
                start: {
                    line: 32,
                    column: 12
                },
                end: {
                    line: 32,
                    column: 53
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 1
                    },
                    end: {
                        line: 1,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 13
                    },
                    end: {
                        line: 35,
                        column: 1
                    }
                },
                line: 1
            },
            '1': {
                name: 'UserStorySelectorService',
                decl: {
                    start: {
                        line: 9,
                        column: 13
                    },
                    end: {
                        line: 9,
                        column: 37
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 49
                    },
                    end: {
                        line: 18,
                        column: 5
                    }
                },
                line: 9
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 10,
                        column: 23
                    },
                    end: {
                        line: 10,
                        column: 24
                    }
                },
                loc: {
                    start: {
                        line: 10,
                        column: 35
                    },
                    end: {
                        line: 17,
                        column: 9
                    }
                },
                line: 10
            },
            '3': {
                name: 'UserStorySelectorController',
                decl: {
                    start: {
                        line: 21,
                        column: 13
                    },
                    end: {
                        line: 21,
                        column: 40
                    }
                },
                loc: {
                    start: {
                        line: 21,
                        column: 78
                    },
                    end: {
                        line: 34,
                        column: 5
                    }
                },
                line: 21
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 26,
                        column: 29
                    },
                    end: {
                        line: 26,
                        column: 30
                    }
                },
                loc: {
                    start: {
                        line: 26,
                        column: 41
                    },
                    end: {
                        line: 28,
                        column: 9
                    }
                },
                line: 26
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 30,
                        column: 31
                    },
                    end: {
                        line: 30,
                        column: 32
                    }
                },
                loc: {
                    start: {
                        line: 30,
                        column: 48
                    },
                    end: {
                        line: 33,
                        column: 9
                    }
                },
                line: 30
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_6zmzyrf47.s[0]++;
(function () {
    'use strict';

    cov_6zmzyrf47.f[0]++;
    cov_6zmzyrf47.s[1]++;
    angular.module('app').service('UserStorySelectorService', UserStorySelectorService);

    /* @ngInject */
    function UserStorySelectorService($mdDialog) {
        cov_6zmzyrf47.f[1]++;
        cov_6zmzyrf47.s[2]++;

        this.display = function () {
            cov_6zmzyrf47.f[2]++;
            cov_6zmzyrf47.s[3]++;

            return $mdDialog.show({
                templateUrl: 'app/components/userStorySelector/userStorySelector.html',
                controller: UserStorySelectorController,
                clickOutsideToClose: true,
                escapeToClose: true
            });
        };
    }

    /* @ngInject */
    function UserStorySelectorController($scope, $mdDialog, UserStoriesTable) {
        cov_6zmzyrf47.f[3]++;
        cov_6zmzyrf47.s[4]++;

        $scope.selectedUserStory = '';
        /* eslint-disable camelcase */
        cov_6zmzyrf47.s[5]++;
        $scope.userStories = UserStoriesTable.$search({ proposal__isnull: 'True' });

        cov_6zmzyrf47.s[6]++;
        $scope.closeDialog = function () {
            cov_6zmzyrf47.f[4]++;
            cov_6zmzyrf47.s[7]++;

            $mdDialog.cancel();
        };

        cov_6zmzyrf47.s[8]++;
        $scope.sendUserStory = function (story) {
            cov_6zmzyrf47.f[5]++;
            cov_6zmzyrf47.s[9]++;

            $scope.selectedUserStory = story;
            cov_6zmzyrf47.s[10]++;
            $mdDialog.hide($scope.selectedUserStory);
        };
    }
})(angular);