'use strict';

var cov_1530ca7l7e = function () {
    var path = '/home/lobo/repos/prospector-app/src/app/shared/js/unique.js',
        hash = 'd9ddeca8643a2aba9d0a51a2517dc02afd7d5dd4',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/prospector-app/src/app/shared/js/unique.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 33,
                    column: 5
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 4
                },
                end: {
                    line: 6,
                    column: 34
                }
            },
            '2': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 31,
                    column: 10
                }
            },
            '3': {
                start: {
                    line: 12,
                    column: 27
                },
                end: {
                    line: 12,
                    column: 29
                }
            },
            '4': {
                start: {
                    line: 13,
                    column: 25
                },
                end: {
                    line: 13,
                    column: 27
                }
            },
            '5': {
                start: {
                    line: 17,
                    column: 12
                },
                end: {
                    line: 27,
                    column: 15
                }
            },
            '6': {
                start: {
                    line: 19,
                    column: 28
                },
                end: {
                    line: 19,
                    column: 41
                }
            },
            '7': {
                start: {
                    line: 21,
                    column: 16
                },
                end: {
                    line: 26,
                    column: 17
                }
            },
            '8': {
                start: {
                    line: 23,
                    column: 20
                },
                end: {
                    line: 23,
                    column: 35
                }
            },
            '9': {
                start: {
                    line: 25,
                    column: 20
                },
                end: {
                    line: 25,
                    column: 38
                }
            },
            '10': {
                start: {
                    line: 30,
                    column: 12
                },
                end: {
                    line: 30,
                    column: 26
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 1
                    },
                    end: {
                        line: 1,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 13
                    },
                    end: {
                        line: 33,
                        column: 1
                    }
                },
                line: 1
            },
            '1': {
                name: 'unique',
                decl: {
                    start: {
                        line: 9,
                        column: 13
                    },
                    end: {
                        line: 9,
                        column: 19
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 22
                    },
                    end: {
                        line: 32,
                        column: 5
                    }
                },
                line: 9
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 10,
                        column: 15
                    },
                    end: {
                        line: 10,
                        column: 16
                    }
                },
                loc: {
                    start: {
                        line: 10,
                        column: 46
                    },
                    end: {
                        line: 31,
                        column: 9
                    }
                },
                line: 10
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 17,
                        column: 40
                    },
                    end: {
                        line: 17,
                        column: 41
                    }
                },
                loc: {
                    start: {
                        line: 17,
                        column: 56
                    },
                    end: {
                        line: 27,
                        column: 13
                    }
                },
                line: 17
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 21,
                        column: 16
                    },
                    end: {
                        line: 26,
                        column: 17
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 21,
                        column: 16
                    },
                    end: {
                        line: 26,
                        column: 17
                    }
                }, {
                    start: {
                        line: 21,
                        column: 16
                    },
                    end: {
                        line: 26,
                        column: 17
                    }
                }],
                line: 21
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0
        },
        b: {
            '0': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_1530ca7l7e.s[0]++;
(function () {
    'use strict';

    cov_1530ca7l7e.f[0]++;
    cov_1530ca7l7e.s[1]++;
    angular.module('app').filter('unique', unique);

    /* @ngInject */
    function unique() {
        cov_1530ca7l7e.f[1]++;
        cov_1530ca7l7e.s[2]++;

        return function (collection, keyname) {
            cov_1530ca7l7e.f[2]++;

            // we define our output and keys array;
            var output = (cov_1530ca7l7e.s[3]++, []);
            var keys = (cov_1530ca7l7e.s[4]++, []);

            // we utilize angular's foreach function
            // this takes in our original collection and an iterator function
            cov_1530ca7l7e.s[5]++;
            angular.forEach(collection, function (item) {
                cov_1530ca7l7e.f[3]++;

                // we check to see whether our object exists
                var key = (cov_1530ca7l7e.s[6]++, item[keyname]);
                // if it's not already part of our keys array
                cov_1530ca7l7e.s[7]++;
                if (keys.indexOf(key) === -1) {
                    cov_1530ca7l7e.b[0][0]++;
                    cov_1530ca7l7e.s[8]++;

                    // add it to our keys array
                    keys.push(key);
                    // push this item to our final output array
                    cov_1530ca7l7e.s[9]++;
                    output.push(item);
                } else {
                    cov_1530ca7l7e.b[0][1]++;
                }
            });
            // return our array which should be devoid of
            // any duplicates
            cov_1530ca7l7e.s[10]++;
            return output;
        };
    }
})();