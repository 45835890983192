'use strict';

var cov_2asg6vt48m = function () {
    var path = '/home/lobo/repos/prospector-app/src/app/components/individualDetails/individualDetails.js',
        hash = 'd8c94bf3da306448331ff9f9a79d0a44fde7e95b',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/prospector-app/src/app/components/individualDetails/individualDetails.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 35
                }
            },
            '1': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 55
                }
            },
            '2': {
                start: {
                    line: 6,
                    column: 8
                },
                end: {
                    line: 6,
                    column: 59
                }
            },
            '3': {
                start: {
                    line: 7,
                    column: 8
                },
                end: {
                    line: 7,
                    column: 43
                }
            },
            '4': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 18,
                    column: 11
                }
            },
            '5': {
                start: {
                    line: 11,
                    column: 12
                },
                end: {
                    line: 17,
                    column: 13
                }
            },
            '6': {
                start: {
                    line: 12,
                    column: 16
                },
                end: {
                    line: 16,
                    column: 19
                }
            },
            '7': {
                start: {
                    line: 22,
                    column: 8
                },
                end: {
                    line: 27,
                    column: 9
                }
            },
            '8': {
                start: {
                    line: 23,
                    column: 22
                },
                end: {
                    line: 23,
                    column: 61
                }
            },
            '9': {
                start: {
                    line: 24,
                    column: 12
                },
                end: {
                    line: 26,
                    column: 13
                }
            },
            '10': {
                start: {
                    line: 25,
                    column: 16
                },
                end: {
                    line: 25,
                    column: 41
                }
            },
            '11': {
                start: {
                    line: 28,
                    column: 8
                },
                end: {
                    line: 28,
                    column: 18
                }
            },
            '12': {
                start: {
                    line: 32,
                    column: 8
                },
                end: {
                    line: 32,
                    column: 45
                }
            },
            '13': {
                start: {
                    line: 33,
                    column: 8
                },
                end: {
                    line: 33,
                    column: 61
                }
            },
            '14': {
                start: {
                    line: 34,
                    column: 8
                },
                end: {
                    line: 34,
                    column: 69
                }
            },
            '15': {
                start: {
                    line: 36,
                    column: 8
                },
                end: {
                    line: 36,
                    column: 124
                }
            },
            '16': {
                start: {
                    line: 37,
                    column: 8
                },
                end: {
                    line: 37,
                    column: 43
                }
            },
            '17': {
                start: {
                    line: 38,
                    column: 8
                },
                end: {
                    line: 38,
                    column: 50
                }
            },
            '18': {
                start: {
                    line: 39,
                    column: 8
                },
                end: {
                    line: 39,
                    column: 48
                }
            },
            '19': {
                start: {
                    line: 43,
                    column: 8
                },
                end: {
                    line: 48,
                    column: 52
                }
            },
            '20': {
                start: {
                    line: 48,
                    column: 23
                },
                end: {
                    line: 48,
                    column: 50
                }
            },
            '21': {
                start: {
                    line: 52,
                    column: 8
                },
                end: {
                    line: 59,
                    column: 53
                }
            },
            '22': {
                start: {
                    line: 59,
                    column: 23
                },
                end: {
                    line: 59,
                    column: 51
                }
            },
            '23': {
                start: {
                    line: 63,
                    column: 8
                },
                end: {
                    line: 66,
                    column: 42
                }
            },
            '24': {
                start: {
                    line: 66,
                    column: 24
                },
                end: {
                    line: 66,
                    column: 40
                }
            },
            '25': {
                start: {
                    line: 73,
                    column: 8
                },
                end: {
                    line: 77,
                    column: 9
                }
            },
            '26': {
                start: {
                    line: 74,
                    column: 12
                },
                end: {
                    line: 74,
                    column: 41
                }
            },
            '27': {
                start: {
                    line: 76,
                    column: 12
                },
                end: {
                    line: 76,
                    column: 41
                }
            },
            '28': {
                start: {
                    line: 80,
                    column: 8
                },
                end: {
                    line: 80,
                    column: 42
                }
            },
            '29': {
                start: {
                    line: 82,
                    column: 8
                },
                end: {
                    line: 82,
                    column: 42
                }
            },
            '30': {
                start: {
                    line: 83,
                    column: 8
                },
                end: {
                    line: 83,
                    column: 24
                }
            },
            '31': {
                start: {
                    line: 87,
                    column: 21
                },
                end: {
                    line: 87,
                    column: 46
                }
            },
            '32': {
                start: {
                    line: 88,
                    column: 25
                },
                end: {
                    line: 88,
                    column: 42
                }
            },
            '33': {
                start: {
                    line: 89,
                    column: 23
                },
                end: {
                    line: 89,
                    column: 38
                }
            },
            '34': {
                start: {
                    line: 92,
                    column: 8
                },
                end: {
                    line: 94,
                    column: 9
                }
            },
            '35': {
                start: {
                    line: 93,
                    column: 12
                },
                end: {
                    line: 93,
                    column: 71
                }
            },
            '36': {
                start: {
                    line: 95,
                    column: 8
                },
                end: {
                    line: 95,
                    column: 22
                }
            },
            '37': {
                start: {
                    line: 99,
                    column: 8
                },
                end: {
                    line: 103,
                    column: 9
                }
            },
            '38': {
                start: {
                    line: 100,
                    column: 12
                },
                end: {
                    line: 100,
                    column: 46
                }
            },
            '39': {
                start: {
                    line: 102,
                    column: 12
                },
                end: {
                    line: 102,
                    column: 40
                }
            },
            '40': {
                start: {
                    line: 107,
                    column: 23
                },
                end: {
                    line: 107,
                    column: 54
                }
            },
            '41': {
                start: {
                    line: 108,
                    column: 8
                },
                end: {
                    line: 108,
                    column: 93
                }
            },
            '42': {
                start: {
                    line: 108,
                    column: 65
                },
                end: {
                    line: 108,
                    column: 91
                }
            },
            '43': {
                start: {
                    line: 113,
                    column: 0
                },
                end: {
                    line: 121,
                    column: 3
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 79
                    },
                    end: {
                        line: 19,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 10,
                        column: 49
                    },
                    end: {
                        line: 10,
                        column: 50
                    }
                },
                loc: {
                    start: {
                        line: 10,
                        column: 59
                    },
                    end: {
                        line: 18,
                        column: 9
                    }
                },
                line: 10
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 21,
                        column: 4
                    },
                    end: {
                        line: 21,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 21,
                        column: 20
                    },
                    end: {
                        line: 29,
                        column: 5
                    }
                },
                line: 21
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 31,
                        column: 4
                    },
                    end: {
                        line: 31,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 31,
                        column: 14
                    },
                    end: {
                        line: 40,
                        column: 5
                    }
                },
                line: 31
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 42,
                        column: 4
                    },
                    end: {
                        line: 42,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 42,
                        column: 49
                    },
                    end: {
                        line: 49,
                        column: 5
                    }
                },
                line: 42
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 48,
                        column: 17
                    },
                    end: {
                        line: 48,
                        column: 18
                    }
                },
                loc: {
                    start: {
                        line: 48,
                        column: 23
                    },
                    end: {
                        line: 48,
                        column: 50
                    }
                },
                line: 48
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 51,
                        column: 4
                    },
                    end: {
                        line: 51,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 51,
                        column: 63
                    },
                    end: {
                        line: 60,
                        column: 5
                    }
                },
                line: 51
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 59,
                        column: 17
                    },
                    end: {
                        line: 59,
                        column: 18
                    }
                },
                loc: {
                    start: {
                        line: 59,
                        column: 23
                    },
                    end: {
                        line: 59,
                        column: 51
                    }
                },
                line: 59
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 62,
                        column: 4
                    },
                    end: {
                        line: 62,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 62,
                        column: 34
                    },
                    end: {
                        line: 67,
                        column: 5
                    }
                },
                line: 62
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 66,
                        column: 17
                    },
                    end: {
                        line: 66,
                        column: 18
                    }
                },
                loc: {
                    start: {
                        line: 66,
                        column: 24
                    },
                    end: {
                        line: 66,
                        column: 40
                    }
                },
                line: 66
            },
            '10': {
                name: '(anonymous_10)',
                decl: {
                    start: {
                        line: 69,
                        column: 4
                    },
                    end: {
                        line: 69,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 69,
                        column: 25
                    },
                    end: {
                        line: 84,
                        column: 5
                    }
                },
                line: 69
            },
            '11': {
                name: '(anonymous_11)',
                decl: {
                    start: {
                        line: 86,
                        column: 4
                    },
                    end: {
                        line: 86,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 86,
                        column: 27
                    },
                    end: {
                        line: 96,
                        column: 5
                    }
                },
                line: 86
            },
            '12': {
                name: '(anonymous_12)',
                decl: {
                    start: {
                        line: 98,
                        column: 4
                    },
                    end: {
                        line: 98,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 98,
                        column: 26
                    },
                    end: {
                        line: 104,
                        column: 5
                    }
                },
                line: 98
            },
            '13': {
                name: '(anonymous_13)',
                decl: {
                    start: {
                        line: 106,
                        column: 4
                    },
                    end: {
                        line: 106,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 106,
                        column: 32
                    },
                    end: {
                        line: 109,
                        column: 5
                    }
                },
                line: 106
            },
            '14': {
                name: '(anonymous_14)',
                decl: {
                    start: {
                        line: 108,
                        column: 57
                    },
                    end: {
                        line: 108,
                        column: 58
                    }
                },
                loc: {
                    start: {
                        line: 108,
                        column: 65
                    },
                    end: {
                        line: 108,
                        column: 91
                    }
                },
                line: 108
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 11,
                        column: 12
                    },
                    end: {
                        line: 17,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 11,
                        column: 12
                    },
                    end: {
                        line: 17,
                        column: 13
                    }
                }, {
                    start: {
                        line: 11,
                        column: 12
                    },
                    end: {
                        line: 17,
                        column: 13
                    }
                }],
                line: 11
            },
            '1': {
                loc: {
                    start: {
                        line: 22,
                        column: 8
                    },
                    end: {
                        line: 27,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 22,
                        column: 8
                    },
                    end: {
                        line: 27,
                        column: 9
                    }
                }, {
                    start: {
                        line: 22,
                        column: 8
                    },
                    end: {
                        line: 27,
                        column: 9
                    }
                }],
                line: 22
            },
            '2': {
                loc: {
                    start: {
                        line: 24,
                        column: 12
                    },
                    end: {
                        line: 26,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 24,
                        column: 12
                    },
                    end: {
                        line: 26,
                        column: 13
                    }
                }, {
                    start: {
                        line: 24,
                        column: 12
                    },
                    end: {
                        line: 26,
                        column: 13
                    }
                }],
                line: 24
            },
            '3': {
                loc: {
                    start: {
                        line: 24,
                        column: 16
                    },
                    end: {
                        line: 24,
                        column: 33
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 24,
                        column: 16
                    },
                    end: {
                        line: 24,
                        column: 17
                    }
                }, {
                    start: {
                        line: 24,
                        column: 21
                    },
                    end: {
                        line: 24,
                        column: 33
                    }
                }],
                line: 24
            },
            '4': {
                loc: {
                    start: {
                        line: 73,
                        column: 8
                    },
                    end: {
                        line: 77,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 73,
                        column: 8
                    },
                    end: {
                        line: 77,
                        column: 9
                    }
                }, {
                    start: {
                        line: 73,
                        column: 8
                    },
                    end: {
                        line: 77,
                        column: 9
                    }
                }],
                line: 73
            },
            '5': {
                loc: {
                    start: {
                        line: 92,
                        column: 8
                    },
                    end: {
                        line: 94,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 92,
                        column: 8
                    },
                    end: {
                        line: 94,
                        column: 9
                    }
                }, {
                    start: {
                        line: 92,
                        column: 8
                    },
                    end: {
                        line: 94,
                        column: 9
                    }
                }],
                line: 92
            },
            '6': {
                loc: {
                    start: {
                        line: 99,
                        column: 8
                    },
                    end: {
                        line: 103,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 99,
                        column: 8
                    },
                    end: {
                        line: 103,
                        column: 9
                    }
                }, {
                    start: {
                        line: 99,
                        column: 8
                    },
                    end: {
                        line: 103,
                        column: 9
                    }
                }],
                line: 99
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0,
            '28': 0,
            '29': 0,
            '30': 0,
            '31': 0,
            '32': 0,
            '33': 0,
            '34': 0,
            '35': 0,
            '36': 0,
            '37': 0,
            '38': 0,
            '39': 0,
            '40': 0,
            '41': 0,
            '42': 0,
            '43': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0],
            '2': [0, 0],
            '3': [0, 0],
            '4': [0, 0],
            '5': [0, 0],
            '6': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var IndividualDetailsController = function () {
    function IndividualDetailsController(TagsTable, LinkCategoriesTable, InteractionTypesTable, $scope) {
        var _this = this;

        _classCallCheck(this, IndividualDetailsController);

        cov_2asg6vt48m.f[0]++;
        cov_2asg6vt48m.s[0]++;

        this.TagsTable = TagsTable;
        cov_2asg6vt48m.s[1]++;
        this.LinkCategoriesTable = LinkCategoriesTable;
        cov_2asg6vt48m.s[2]++;
        this.InteractionTypesTable = InteractionTypesTable;
        cov_2asg6vt48m.s[3]++;
        this.newInteractionAt = new Date();

        // files
        cov_2asg6vt48m.s[4]++;
        $scope.$watch('$ctrl.newFileAttachment', function (newVal) {
            cov_2asg6vt48m.f[1]++;
            cov_2asg6vt48m.s[5]++;

            if (angular.isString(newVal)) {
                cov_2asg6vt48m.b[0][0]++;
                cov_2asg6vt48m.s[6]++;

                _this.individual.$files.$create({
                    organization: _this.individual.id,
                    path: newVal,
                    name: _this.fileToName(newVal)
                });
            } else {
                cov_2asg6vt48m.b[0][1]++;
            }
        });
    }

    _createClass(IndividualDetailsController, [{
        key: 'fileToName',
        value: function fileToName(url) {
            cov_2asg6vt48m.f[2]++;
            cov_2asg6vt48m.s[7]++;

            if (url) {
                cov_2asg6vt48m.b[1][0]++;

                var m = (cov_2asg6vt48m.s[8]++, url.toString().match(/.*\/(.+?)\.(.+)/));
                cov_2asg6vt48m.s[9]++;
                if ((cov_2asg6vt48m.b[3][0]++, m) && (cov_2asg6vt48m.b[3][1]++, m.length > 1)) {
                    cov_2asg6vt48m.b[2][0]++;
                    cov_2asg6vt48m.s[10]++;

                    return m[1] + '.' + m[2];
                } else {
                    cov_2asg6vt48m.b[2][1]++;
                }
            } else {
                cov_2asg6vt48m.b[1][1]++;
            }
            cov_2asg6vt48m.s[11]++;
            return "";
        }
    }, {
        key: '$onInit',
        value: function $onInit() {
            cov_2asg6vt48m.f[3]++;
            cov_2asg6vt48m.s[12]++;

            this.tags = this.TagsTable.$search();
            cov_2asg6vt48m.s[13]++;
            this.categories = this.LinkCategoriesTable.$search();
            cov_2asg6vt48m.s[14]++;
            this.interactionTypes = this.InteractionTypesTable.$search();

            cov_2asg6vt48m.s[15]++;
            this.individual.$load('comments').$load('tags').$load('links').$load('files').$load('interactions').$load('emails');
            cov_2asg6vt48m.s[16]++;
            this.individual.$tags.$load('tag');
            cov_2asg6vt48m.s[17]++;
            this.individual.$interactions.$load('tt');
            cov_2asg6vt48m.s[18]++;
            this.individual.$comments.$load('user');
        }
    }, {
        key: 'createComment',
        value: function createComment(individual, newCommentContent) {
            var _this2 = this;

            cov_2asg6vt48m.f[4]++;
            cov_2asg6vt48m.s[19]++;

            this.individual.$comments.$create({
                objectId: individual.id,
                objectType: 'INDIVIDUAL',
                content: newCommentContent
                /* eslint-disable no-return-assign */
            }).$then(function () {
                cov_2asg6vt48m.f[5]++;
                cov_2asg6vt48m.s[20]++;
                return _this2.newCommentContent = '';
            });
        }
    }, {
        key: 'createInteraction',
        value: function createInteraction(interactionType, description, at, email) {
            var _this3 = this;

            cov_2asg6vt48m.f[6]++;
            cov_2asg6vt48m.s[21]++;

            this.individual.$interactions.$create({
                organization: this.individual.id,
                email: email,
                description: description,
                interactedAt: at,
                interactionType: interactionType
                /* eslint-disable no-return-assign */
            }).$then(function () {
                cov_2asg6vt48m.f[7]++;
                cov_2asg6vt48m.s[22]++;
                return _this3.newInteractionName = '';
            });
        }
    }, {
        key: 'onSelectTag',
        value: function onSelectTag(individual, item) {
            cov_2asg6vt48m.f[8]++;
            cov_2asg6vt48m.s[23]++;

            this.individual.$tags.$create({
                organization: individual.id,
                tag: item.id
            }).$then(function (tag) {
                cov_2asg6vt48m.f[9]++;
                cov_2asg6vt48m.s[24]++;
                return tag.$load('tag');
            });
        }
    }, {
        key: 'extractHostname',
        value: function extractHostname(url) {
            cov_2asg6vt48m.f[10]++;

            var hostname = void 0;
            // find & remove protocol (http, ftp, etc.) and get hostname

            cov_2asg6vt48m.s[25]++;
            if (url.indexOf("://") > -1) {
                cov_2asg6vt48m.b[4][0]++;
                cov_2asg6vt48m.s[26]++;

                hostname = url.split('/')[2];
            } else {
                cov_2asg6vt48m.b[4][1]++;
                cov_2asg6vt48m.s[27]++;

                hostname = url.split('/')[0];
            }

            // find & remove port number
            cov_2asg6vt48m.s[28]++;
            hostname = hostname.split(':')[0];
            // find & remove "?"
            cov_2asg6vt48m.s[29]++;
            hostname = hostname.split('?')[0];
            cov_2asg6vt48m.s[30]++;
            return hostname;
        }
    }, {
        key: 'extractRootDomain',
        value: function extractRootDomain(url) {
            cov_2asg6vt48m.f[11]++;

            var domain = (cov_2asg6vt48m.s[31]++, this.extractHostname(url));
            var splitArr = (cov_2asg6vt48m.s[32]++, domain.split('.'));
            var arrLen = (cov_2asg6vt48m.s[33]++, splitArr.length);

            // extracting the root domain here
            cov_2asg6vt48m.s[34]++;
            if (arrLen > 2) {
                cov_2asg6vt48m.b[5][0]++;
                cov_2asg6vt48m.s[35]++;

                domain = splitArr[arrLen - 2] + '.' + splitArr[arrLen - 1];
            } else {
                cov_2asg6vt48m.b[5][1]++;
            }
            cov_2asg6vt48m.s[36]++;
            return domain;
        }
    }, {
        key: 'setCategoryLink',
        value: function setCategoryLink(data) {
            cov_2asg6vt48m.f[12]++;
            cov_2asg6vt48m.s[37]++;

            if (data.length === 1) {
                cov_2asg6vt48m.b[6][0]++;
                cov_2asg6vt48m.s[38]++;

                this.newLinkCategory = data[0].id;
            } else {
                cov_2asg6vt48m.b[6][1]++;
                cov_2asg6vt48m.s[39]++;

                this.newLinkCategory = null;
            }
        }
    }, {
        key: 'autoSelectCategory',
        value: function autoSelectCategory(newLink) {
            var _this4 = this;

            cov_2asg6vt48m.f[13]++;

            var domain = (cov_2asg6vt48m.s[40]++, this.extractRootDomain(newLink));
            cov_2asg6vt48m.s[41]++;
            this.LinkCategoriesTable.$search({ domain: domain }).$then(function (data) {
                cov_2asg6vt48m.f[14]++;
                cov_2asg6vt48m.s[42]++;
                return _this4.setCategoryLink(data);
            });
        }
    }]);

    return IndividualDetailsController;
}();

cov_2asg6vt48m.s[43]++;


angular.module('app').component('individualDetails', {
    templateUrl: 'app/components/individualDetails/individualDetails.html',
    controller: IndividualDetailsController,
    bindings: {
        individual: '<'
    }
});