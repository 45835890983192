'use strict';

var cov_24qnor96wl = function () {
    var path = '/home/lobo/repos/prospector-app/src/app/shared/js/media/media.table.factory.js',
        hash = '87727f5f2d401febffe3bf069574b8bc9deb4cd5',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/prospector-app/src/app/shared/js/media/media.table.factory.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 12,
                    column: 5
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 4
                },
                end: {
                    line: 6,
                    column: 47
                }
            },
            '2': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 10,
                    column: 67
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 1
                    },
                    end: {
                        line: 1,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 13
                    },
                    end: {
                        line: 12,
                        column: 1
                    }
                },
                line: 1
            },
            '1': {
                name: 'RestmodFactory',
                decl: {
                    start: {
                        line: 9,
                        column: 13
                    },
                    end: {
                        line: 9,
                        column: 27
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 37
                    },
                    end: {
                        line: 11,
                        column: 5
                    }
                },
                line: 9
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0
        },
        f: {
            '0': 0,
            '1': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_24qnor96wl.s[0]++;
(function () {
    'use strict';

    cov_24qnor96wl.f[0]++;
    cov_24qnor96wl.s[1]++;
    angular.module('app').factory('MediaTable', RestmodFactory);

    /* @ngInject */
    function RestmodFactory(restmod) {
        cov_24qnor96wl.f[1]++;
        cov_24qnor96wl.s[2]++;

        return restmod.model('/media').mix('MixinContainable', {});
    }
})();