'use strict';

var cov_1cxpa7vte8 = function () {
    var path = '/home/lobo/repos/prospector-app/src/app/components/searchableSelector/searchableSelector.js',
        hash = 'b3d3745384b764644d1f5770cc0613b7f7b6d58e',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/prospector-app/src/app/components/searchableSelector/searchableSelector.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 35
                }
            },
            '1': {
                start: {
                    line: 8,
                    column: 8
                },
                end: {
                    line: 8,
                    column: 46
                }
            },
            '2': {
                start: {
                    line: 9,
                    column: 8
                },
                end: {
                    line: 9,
                    column: 29
                }
            },
            '3': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 10,
                    column: 26
                }
            },
            '4': {
                start: {
                    line: 11,
                    column: 8
                },
                end: {
                    line: 11,
                    column: 56
                }
            },
            '5': {
                start: {
                    line: 15,
                    column: 8
                },
                end: {
                    line: 15,
                    column: 56
                }
            },
            '6': {
                start: {
                    line: 19,
                    column: 8
                },
                end: {
                    line: 19,
                    column: 76
                }
            },
            '7': {
                start: {
                    line: 23,
                    column: 24
                },
                end: {
                    line: 30,
                    column: 43
                }
            },
            '8': {
                start: {
                    line: 32,
                    column: 8
                },
                end: {
                    line: 34,
                    column: 11
                }
            },
            '9': {
                start: {
                    line: 33,
                    column: 12
                },
                end: {
                    line: 33,
                    column: 41
                }
            },
            '10': {
                start: {
                    line: 38,
                    column: 0
                },
                end: {
                    line: 71,
                    column: 7
                }
            },
            '11': {
                start: {
                    line: 54,
                    column: 8
                },
                end: {
                    line: 70,
                    column: 10
                }
            },
            '12': {
                start: {
                    line: 58,
                    column: 16
                },
                end: {
                    line: 68,
                    column: 19
                }
            },
            '13': {
                start: {
                    line: 59,
                    column: 39
                },
                end: {
                    line: 59,
                    column: 46
                }
            },
            '14': {
                start: {
                    line: 60,
                    column: 20
                },
                end: {
                    line: 62,
                    column: 21
                }
            },
            '15': {
                start: {
                    line: 61,
                    column: 24
                },
                end: {
                    line: 61,
                    column: 61
                }
            },
            '16': {
                start: {
                    line: 63,
                    column: 20
                },
                end: {
                    line: 63,
                    column: 62
                }
            },
            '17': {
                start: {
                    line: 65,
                    column: 20
                },
                end: {
                    line: 67,
                    column: 21
                }
            },
            '18': {
                start: {
                    line: 66,
                    column: 24
                },
                end: {
                    line: 66,
                    column: 40
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 27
                    },
                    end: {
                        line: 5,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 7,
                        column: 4
                    },
                    end: {
                        line: 7,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 7,
                        column: 22
                    },
                    end: {
                        line: 12,
                        column: 5
                    }
                },
                line: 7
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 14,
                        column: 4
                    },
                    end: {
                        line: 14,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 14,
                        column: 23
                    },
                    end: {
                        line: 16,
                        column: 5
                    }
                },
                line: 14
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 18,
                        column: 4
                    },
                    end: {
                        line: 18,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 18,
                        column: 20
                    },
                    end: {
                        line: 20,
                        column: 5
                    }
                },
                line: 18
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 22,
                        column: 4
                    },
                    end: {
                        line: 22,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 22,
                        column: 17
                    },
                    end: {
                        line: 35,
                        column: 5
                    }
                },
                line: 22
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 32,
                        column: 42
                    },
                    end: {
                        line: 32,
                        column: 43
                    }
                },
                loc: {
                    start: {
                        line: 32,
                        column: 52
                    },
                    end: {
                        line: 34,
                        column: 9
                    }
                },
                line: 32
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 53,
                        column: 35
                    },
                    end: {
                        line: 53,
                        column: 36
                    }
                },
                loc: {
                    start: {
                        line: 53,
                        column: 47
                    },
                    end: {
                        line: 71,
                        column: 5
                    }
                },
                line: 53
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 58,
                        column: 29
                    },
                    end: {
                        line: 58,
                        column: 30
                    }
                },
                loc: {
                    start: {
                        line: 58,
                        column: 41
                    },
                    end: {
                        line: 64,
                        column: 17
                    }
                },
                line: 58
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 64,
                        column: 19
                    },
                    end: {
                        line: 64,
                        column: 20
                    }
                },
                loc: {
                    start: {
                        line: 64,
                        column: 37
                    },
                    end: {
                        line: 68,
                        column: 17
                    }
                },
                line: 64
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 19,
                        column: 15
                    },
                    end: {
                        line: 19,
                        column: 75
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 19,
                        column: 15
                    },
                    end: {
                        line: 19,
                        column: 35
                    }
                }, {
                    start: {
                        line: 19,
                        column: 39
                    },
                    end: {
                        line: 19,
                        column: 62
                    }
                }, {
                    start: {
                        line: 19,
                        column: 66
                    },
                    end: {
                        line: 19,
                        column: 75
                    }
                }],
                line: 19
            },
            '1': {
                loc: {
                    start: {
                        line: 65,
                        column: 20
                    },
                    end: {
                        line: 67,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 65,
                        column: 20
                    },
                    end: {
                        line: 67,
                        column: 21
                    }
                }, {
                    start: {
                        line: 65,
                        column: 20
                    },
                    end: {
                        line: 67,
                        column: 21
                    }
                }],
                line: 65
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0
        },
        b: {
            '0': [0, 0, 0],
            '1': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var SearchableSelectorController = function () {
    function SearchableSelectorController($mdDialog) {
        _classCallCheck(this, SearchableSelectorController);

        cov_1cxpa7vte8.f[0]++;
        cov_1cxpa7vte8.s[0]++;

        this.$mdDialog = $mdDialog;
    }

    _createClass(SearchableSelectorController, [{
        key: 'selectItemProxy',
        value: function selectItemProxy() {
            cov_1cxpa7vte8.f[1]++;
            cov_1cxpa7vte8.s[1]++;

            this.selectItem({ item: this.ngModel });
            cov_1cxpa7vte8.s[2]++;
            this.searchText = '';
            cov_1cxpa7vte8.s[3]++;
            this.ngModel = '';
            cov_1cxpa7vte8.s[4]++;
            this.searchItems({ searchText: this.searchText });
        }
    }, {
        key: 'searchItemsProxy',
        value: function searchItemsProxy() {
            cov_1cxpa7vte8.f[2]++;
            cov_1cxpa7vte8.s[5]++;

            this.searchItems({ searchText: this.searchText });
        }
    }, {
        key: 'displayTF',
        value: function displayTF(item) {
            cov_1cxpa7vte8.f[3]++;
            cov_1cxpa7vte8.s[6]++;

            return (cov_1cxpa7vte8.b[0][0]++, this.display({ item: item })) || (cov_1cxpa7vte8.b[0][1]++, item[this.displayField]) || (cov_1cxpa7vte8.b[0][2]++, item.name);
        }
    }, {
        key: 'showDialog',
        value: function showDialog() {
            var _this = this;

            cov_1cxpa7vte8.f[4]++;

            var confirm = (cov_1cxpa7vte8.s[7]++, this.$mdDialog.prompt().title('What is the Individual name?').placeholder('Name').ariaLabel('Name').initialValue('').required(true).ok('Add').cancel('Cancel'));

            cov_1cxpa7vte8.s[8]++;
            this.$mdDialog.show(confirm).then(function (result) {
                cov_1cxpa7vte8.f[5]++;
                cov_1cxpa7vte8.s[9]++;

                _this.addItem({ name: result });
            });
        }
    }]);

    return SearchableSelectorController;
}();

cov_1cxpa7vte8.s[10]++;


angular.module('app').component('searchableSelector', {
    controller: SearchableSelectorController,
    templateUrl: 'app/components/searchableSelector/searchableSelector.html',
    bindings: {
        items: '<',
        selectItem: '&',
        searchItems: '&',
        searchPlaceholder: '@',
        addItem: '&',
        displayField: '@',
        display: '&'
    }
}).directive('forceSelectFocus', function () {
    cov_1cxpa7vte8.f[6]++;
    cov_1cxpa7vte8.s[11]++;

    return {
        restrict: 'A',
        require: ['^^mdSelect', '^ngModel'],
        link: function link(scope, element) {
            cov_1cxpa7vte8.s[12]++;

            scope.$watch(function () {
                cov_1cxpa7vte8.f[7]++;

                var foundElement = (cov_1cxpa7vte8.s[13]++, element);
                cov_1cxpa7vte8.s[14]++;
                while (!foundElement.hasClass('md-select-menu-container')) {
                    cov_1cxpa7vte8.s[15]++;

                    foundElement = foundElement.parent();
                }
                cov_1cxpa7vte8.s[16]++;
                return foundElement.hasClass('md-active');
            }, function (newVal) {
                cov_1cxpa7vte8.f[8]++;
                cov_1cxpa7vte8.s[17]++;

                if (newVal) {
                    cov_1cxpa7vte8.b[1][0]++;
                    cov_1cxpa7vte8.s[18]++;

                    element.focus();
                } else {
                    cov_1cxpa7vte8.b[1][1]++;
                }
            });
        }
    };
});