'use strict';

var cov_2ej3aqgido = function () {
    var path = '/home/lobo/repos/prospector-app/src/app/shared/js/ORM/tables.proposals.factory.js',
        hash = '3ba08e8a2eed9534d14862adacb56d0eb830131c',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/prospector-app/src/app/shared/js/ORM/tables.proposals.factory.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 65,
                    column: 5
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 4
                },
                end: {
                    line: 6,
                    column: 47
                }
            },
            '2': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 63,
                    column: 11
                }
            },
            '3': {
                start: {
                    line: 51,
                    column: 20
                },
                end: {
                    line: 53,
                    column: 21
                }
            },
            '4': {
                start: {
                    line: 52,
                    column: 24
                },
                end: {
                    line: 52,
                    column: 47
                }
            },
            '5': {
                start: {
                    line: 58,
                    column: 20
                },
                end: {
                    line: 60,
                    column: 21
                }
            },
            '6': {
                start: {
                    line: 59,
                    column: 24
                },
                end: {
                    line: 59,
                    column: 47
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 1
                    },
                    end: {
                        line: 1,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 13
                    },
                    end: {
                        line: 65,
                        column: 1
                    }
                },
                line: 1
            },
            '1': {
                name: 'RestmodFactory',
                decl: {
                    start: {
                        line: 9,
                        column: 13
                    },
                    end: {
                        line: 9,
                        column: 27
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 37
                    },
                    end: {
                        line: 64,
                        column: 5
                    }
                },
                line: 9
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 51,
                        column: 20
                    },
                    end: {
                        line: 53,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 51,
                        column: 20
                    },
                    end: {
                        line: 53,
                        column: 21
                    }
                }, {
                    start: {
                        line: 51,
                        column: 20
                    },
                    end: {
                        line: 53,
                        column: 21
                    }
                }],
                line: 51
            },
            '1': {
                loc: {
                    start: {
                        line: 58,
                        column: 20
                    },
                    end: {
                        line: 60,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 58,
                        column: 20
                    },
                    end: {
                        line: 60,
                        column: 21
                    }
                }, {
                    start: {
                        line: 58,
                        column: 20
                    },
                    end: {
                        line: 60,
                        column: 21
                    }
                }],
                line: 58
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0
        },
        f: {
            '0': 0,
            '1': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_2ej3aqgido.s[0]++;
(function () {
    'use strict';

    cov_2ej3aqgido.f[0]++;
    cov_2ej3aqgido.s[1]++;
    angular.module('app').factory('ProposalsTable', RestmodFactory);

    /* @ngInject */
    function RestmodFactory(restmod) {
        cov_2ej3aqgido.f[1]++;
        cov_2ej3aqgido.s[2]++;

        return restmod.model('/proposals').mix('MixinContainable', {
            $config: {
                contains: {
                    proposalServices: {
                        type: 'hasMany',
                        table: 'ProposalServicesTable',
                        foreignKey: 'proposal'
                    },
                    proposalSections: {
                        type: 'hasMany',
                        table: 'ProposalSectionsTable',
                        foreignKey: 'proposal'
                    },
                    client: {
                        type: 'belongsTo',
                        table: 'OrganizationsTable',
                        foreignKey: 'client'
                    },
                    files: {
                        type: 'hasMany',
                        table: 'ProposalFilesTable',
                        foreignKey: 'proposal'
                    },
                    comments: {
                        type: 'hasMany',
                        table: 'CommentsTable',
                        foreignKey: 'object_id',
                        filters: {
                            /* eslint-disable camelcase */
                            object_type: 'PROPOSAL'
                        }
                    },
                    userStories: {
                        type: 'hasMany',
                        table: 'UserStoriesTable',
                        foreignKey: 'proposal'
                    }
                }
            },
            date: {
                decode: function decode(value) {
                    cov_2ej3aqgido.s[3]++;

                    if (value !== null) {
                        cov_2ej3aqgido.b[0][0]++;
                        cov_2ej3aqgido.s[4]++;

                        return new Date(value);
                    } else {
                        cov_2ej3aqgido.b[0][1]++;
                    }
                }
            },
            expirationDate: {
                decode: function decode(value) {
                    cov_2ej3aqgido.s[5]++;

                    if (value !== null) {
                        cov_2ej3aqgido.b[1][0]++;
                        cov_2ej3aqgido.s[6]++;

                        return new Date(value);
                    } else {
                        cov_2ej3aqgido.b[1][1]++;
                    }
                }
            }
        });
    }
})();