'use strict';

var cov_qahatxbtd = function () {
    var path = '/home/lobo/repos/prospector-app/src/app/components/rankings/rankings.js',
        hash = 'f52931c8f36778e224f56facbfa9fd39ad8298d9',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/prospector-app/src/app/components/rankings/rankings.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 43
                }
            },
            '1': {
                start: {
                    line: 8,
                    column: 8
                },
                end: {
                    line: 8,
                    column: 44
                }
            },
            '2': {
                start: {
                    line: 12,
                    column: 27
                },
                end: {
                    line: 19,
                    column: 9
                }
            },
            '3': {
                start: {
                    line: 20,
                    column: 8
                },
                end: {
                    line: 20,
                    column: 45
                }
            },
            '4': {
                start: {
                    line: 24,
                    column: 0
                },
                end: {
                    line: 32,
                    column: 3
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 31
                    },
                    end: {
                        line: 5,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 7,
                        column: 4
                    },
                    end: {
                        line: 7,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 7,
                        column: 14
                    },
                    end: {
                        line: 9,
                        column: 5
                    }
                },
                line: 7
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 11,
                        column: 4
                    },
                    end: {
                        line: 11,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 11,
                        column: 29
                    },
                    end: {
                        line: 21,
                        column: 5
                    }
                },
                line: 11
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var RankingsController = function () {
    function RankingsController(RankingsTable) {
        _classCallCheck(this, RankingsController);

        cov_qahatxbtd.f[0]++;
        cov_qahatxbtd.s[0]++;

        this.RankingsTable = RankingsTable;
    }

    _createClass(RankingsController, [{
        key: '$onInit',
        value: function $onInit() {
            cov_qahatxbtd.f[1]++;
            cov_qahatxbtd.s[1]++;

            this.organization.$load('rankings');
        }
    }, {
        key: 'indicatorAbbrev',
        value: function indicatorAbbrev(ranking) {
            cov_qahatxbtd.f[2]++;

            var indicators = (cov_qahatxbtd.s[2]++, {
                TURNOVER: 'T',
                INCOME: 'I',
                RAW_SIZE: 'S',
                RAW_TURNOVER_PER_RAW_SIZE: 'TS',
                RAW_INCOME_PER_RAW_SIZE: 'IS',
                RAW_INCOME_PER_RAW_TURNOVER: 'IT'
            });
            cov_qahatxbtd.s[3]++;
            return indicators[ranking.indicator];
        }
    }]);

    return RankingsController;
}();

cov_qahatxbtd.s[4]++;


angular.module('app').component('rankings', {
    templateUrl: 'app/components/rankings/rankings.html',
    controller: RankingsController,
    bindings: {
        organization: '<'
    }
});